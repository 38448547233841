import React, { Suspense, lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  CircularProgress,
  Typography,
} from "@mui/material";
import { GetCallApi } from "../../../Action/Action";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { Config } from "../../../Utils/Config";
import Select from "react-select";
import { customFilter } from "../../Components/CustomFilterForReactSelect";

const ImgPopUp = lazy(() => import("../../Components/ImgPopUp"));
const PaymentTable = lazy(() =>
  import("../../user/PaymentHistory/PaymentTable")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCellsEvent = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "ProdImg",
    label: "Product Image",
  },
  {
    id: "associationProductName",
    label: "Product Name",
  },
  {
    id: "associationName",
    label: "Association Name",
  },
  {
    id: "totalCount",
    label: "Quantity",
  },
  {
    id: "singlePrice",
    label: "Current Price",
  },
  {
    id: "totalPrice",
    label: "Total Price",
  },
];

function AssociationProductSalesReport() {
  const [dataOfTable, setDataOfTable] = useState([]);
  const [associationList, setAssociationList] = useState([]);
  const [associationId, setAssociationId] = useState();
  const [openCircle, setOpenCircle] = useState(false);
  const [filterPage, setFilterPage] = useState(false);

  useEffect(() => {
    document.title = "FIG Events | Association Product Invoice Approval";
    getAssoList();
    // eslint-disable-next-line
  }, []);

  const logindetail = useSelector((state) => state.login.LoginDetails);
  const getAssoList = async () => {
    let seData = {
      url:
        logindetail?.roleId === 1
          ? `/api/Association/GetAllAssociation`
          : `/api/Association/GetAllAssociation?userId=${logindetail?.userId}`,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        let data = res.data.payload?.map((x, i) => {
          return {
            ...x,
            id: i + 1,
            value: x?.associationId,
            label: x?.name,
          };
        });
        setAssociationList(data);
        GetProductReportList(data[0]?.associationId);
      }
    }
  };
  const GetProductReportList = async (id) => {
    setDataOfTable([]);
    setAssociationId(id);
    if (id) {
      setOpenCircle(true);
      let seData = {
        url: `/api/Reports/AssociationProductSalesReport?userId=${logindetail?.userId}&associationId=${id}`,
      };
      let res = await GetCallApi(seData);
      setFilterPage(!filterPage);
      setOpenCircle(false);
      if (res.status === 200) {
        if (res.data.success) {
          setOpenCircle(false);
          let comList = res?.data?.payload?.map((c, i) => {
            return {
              id: i + 1,
              ...c,
              ProdImg: c?.associationProductImage ? (
                <ImgPopUp
                  img={Config.API_HOST_URL_live + c?.associationProductImage}
                  addClass={"h_fix"}
                  hide_icon={true}
                />
              ) : (
                ""
              ),
              groupUserList:
                c?.userDetailsList?.length > 0
                  ? c?.userDetailsList?.map((x, index) => {
                      return {
                        ...x,
                        id: index + 1,
                      };
                    })
                  : [],
            };
          });
          setDataOfTable(comList);
        } else {
          setOpenCircle(false);
        }
      } else {
        setOpenCircle(false);
      }
    }
  };

  return (
    <>
      <Backdrop open={openCircle} sx={{ color: "#fff", zIndex: () => 9999 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography style={{ marginLeft: "20px" }}>
                    <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                    <span className="filter_by">Filter By</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className="d-flex flex-wrap gap-4 mb-3"
                    style={{ marginLeft: "20px" }}
                  >
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="form-label">Association</label>
                        <Select
                          isMulti={false}
                          name="colors"
                          options={associationList}
                          isClearable={true}
                          className="dropdown-single-select"
                          classNamePrefix="select"
                          placeholder="Select Association"
                          filterOption={customFilter}
                          value={associationList?.filter(
                            (obj) => obj.value === associationId
                          )}
                          onChange={(e) => GetProductReportList(e?.value)}
                        />
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <div className="col-md-12 accordian_card p-0 mb-4">
                <PaymentTable
                  title="Association Product Sales Report"
                  action={true}
                  headCells={headCellsEvent}
                  ListData={dataOfTable}
                  dropdown={false}
                  addBtn={false}
                  showUserListInTable={true}
                  fileName={"Association Product Sales Report"}
                  exportDataList={false}
                  innerProduct={true}
                  filterPage={filterPage}
                  subHeadCells={[
                    {
                      id: "id",
                      label: "id",
                    },
                    {
                      id: "userName",
                      label: "User Name",
                    },
                    {
                      id: "userEmail",
                      label: "Email",
                    },
                    {
                      id: "userPhone",
                      label: "Phone",
                    },
                    {
                      id: "productQuantity",
                      label: "Product Quantity",
                    },
                    {
                      id: "productPrice",
                      label: "Product Price",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default AssociationProductSalesReport;
