import React, { Suspense, lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GetCallApi } from "../../../Action/Action";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  CircularProgress,
  Typography,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import Select from "react-select";
import { customFilter } from "../../Components/CustomFilterForReactSelect";
import { ToastError } from "../../Components/Toast";

const PaymentTable = lazy(() =>
  import("../../user/PaymentHistory/PaymentTable")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const headCellsEvent = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "sessionTitle",
    label: "Session Title",
  },
  {
    id: "numberOfAttendees",
    label: "Number of Attendees",
  },
];

function SessionAttendeesWithSpecialNeedsReport() {
  const [dataOfTable, setDataOfTable] = useState([]);
  const [conferenceList, setConferenceList] = useState([]);

  const [confId, setConfId] = useState("");
  const [filterPage, setFilterPage] = useState(false);

  const [openCircle, setOpenCircle] = useState(false);
  const LogDetails = useSelector((state) => state.login.LoginDetails);

  useEffect(() => {
    document.title = "FIG Events | Session Attendee Report";
    geConfeList();
    // eslint-disable-next-line
  }, []);

  const geConfeList = async () => {
    let seData = {
      url: `/api/Conference/GetApproveConferenceList?userId=${LogDetails?.userId}`,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        let comList = res?.data?.payload?.map((x) => {
          return {
            ...x,
            label: x?.conferenceName,
            value: x?.conferenceId,
          };
        });

        setConferenceList(comList);
        setConfId(comList[0]?.conferenceId);
        GetUserList(comList[0]?.conferenceId);
      }
    }
  };

  const GetUserList = async (id) => {
    setDataOfTable([]);
    setConfId(id);
    if (id) {
      setOpenCircle(true);
      let res = await GetCallApi({
        url: `/api/Reports/SessionAttendeesWithSpecialNeedsReport?userId=${LogDetails?.userId}&conferenceId=${id}`,
      });
      setOpenCircle(false);
      setFilterPage(!filterPage);

      if (res.status === 200 && res.data.success) {
        setOpenCircle(false);
        let comList = [];
        await res?.data?.payload?.map((x) => {
          if (
            comList?.filter((y) => y?.sessionId === x?.sessionId)?.length > 0
          ) {
            return (comList = comList?.map((y) => {
              if (y?.sessionId === x?.sessionId) {
                let usList =
                  y?.userDeatils?.length > 0 && x?.userDeatils?.length > 0
                    ? [...y.userDeatils, ...x.userDeatils]
                    : [].concat(y?.userDeatils || [], x?.userDeatils || []);

                return {
                  ...y,
                  numberOfAttendees:
                    y?.numberOfAttendees + x?.numberOfAttendees,
                  userDeatils: usList,
                };
              }
              return y;
            }));
          }
          return comList?.push(x);
        });

        comList = comList?.map((c, i) => {
          return {
            ...c,
            id: i + 1,
            groupUserList:
              c?.userDeatils?.length > 0
                ? c?.userDeatils?.map((x, index) => {
                    return {
                      ...x,
                      id: index + 1,
                    };
                  })
                : [],
          };
        });
        setDataOfTable(comList);
      } else {
        ToastError(res.data.message);
      }
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography style={{ marginLeft: "20px" }}>
                    <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                    <span className="filter_by">Filter By</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className="d-flex flex-wrap gap-4 mb-3"
                    style={{ marginLeft: "20px" }}
                  >
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="form-label">Conference</label>
                        <Select
                          isMulti={false}
                          name="colors"
                          options={conferenceList}
                          isClearable={true}
                          className="dropdown-single-select"
                          classNamePrefix="select"
                          placeholder="Select Conference"
                          filterOption={customFilter}
                          value={conferenceList?.filter(
                            (obj) => obj.value === confId
                          )}
                          onChange={(e) => GetUserList(e?.value)}
                        />
                        {/* <select
                          className="form-select "
                          value={confId}
                          onChange={(e) => GetUserList(e?.target?.value)}
                        >
                          <option value="">Select Conference</option>
                          {conferenceList &&
                            conferenceList?.map((c) => (
                              <option
                                value={c?.conferenceId}
                                key={c?.conferenceId}
                              >
                                {c?.conferenceName}
                              </option>
                            ))}
                        </select> */}
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <div className="col-md-12 accordian_card p-0 mb-4">
                <PaymentTable
                  title="Session Attendees With Special Needs Report"
                  action={true}
                  headCells={headCellsEvent}
                  ListData={dataOfTable}
                  dropdown={false}
                  addBtn={false}
                  showUserListInTable={true}
                  fileName={"Session Attendees With Special Needs Report"}
                  exportDataList={true}
                  innerProduct={true}
                  filterPage={filterPage}
                  subHeadCells={[
                    {
                      id: "id",
                      label: "id",
                    },

                    {
                      id: "userName",
                      label: "Attendee Name",
                    },
                    {
                      id: "companyName",
                      label: "Company Name",
                    },
                    {
                      id: "associationName",
                      label: "Association Name",
                      widthFix: 600,
                    },
                    {
                      id: "groupName",
                      label: "Group Name",
                      widthFix: 600,
                    },
                    {
                      id: "adaRequirements",
                      label: "Ada/Dietary restrictions",
                      widthFix: 600,
                    },
                    {
                      id: "mealNeeds",
                      label: "Meal Needs",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default SessionAttendeesWithSpecialNeedsReport;
