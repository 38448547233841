import React, { useEffect, useMemo, useState } from "react";
import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { PostCallApi } from "../../../../../../Action/Action";
import { toast } from "react-toastify";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import { useNavigate, useParams } from "react-router-dom";
import {
  // CardElement,
  Elements,
  useElements,
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import useResponsiveFontSize from "../../../Association/UserAssociation/useResponsiveFontSize";
import { ToastError, ToastSuccess } from "../../../../../Components/Toast";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

function AssociationProductPayment(props) {
  const { id } = useParams();
  const { companyData } = props;
  const [clientPublicKey, setClientPublicKey] = useState();

  const [acBtn, setAcBtn] = useState(true);
  const [subtotal, setSubTotal] = useState("");
  const [payment, setPayment] = useState();
  const [discountValue] = useState();
  // const [discountValue, setDiscountValue] = useState();
  const { setValue } = useForm();

  const LogDetails = useSelector((state) => state.login.LoginDetails);

  const nav = useNavigate();
  useEffect(() => {
    if (companyData) {
      setValue("amount", companyData.subtotal);
      setSubTotal(companyData?.subtotal);
    }
    // eslint-disable-next-line
  }, [companyData]);

  const CLSec = async () => {
    if (parseInt(subtotal) === 0) {
      let paymentData = {
        ...companyData,
        createdBy: LogDetails?.userId,
        userId: LogDetails?.userId,
        email: LogDetails?.email,
        amount: subtotal,
        // discountAmount: discountValue?.discountValue,
        // promoCodeId: discountValue?.promoCodeId,
        associationId: id,
        paymentMode: "Online",
      };
      let sveCC = {
        url: `/api/AssociationProductPayment/AssociationProductPaymentProcess`,
        body: paymentData,
      };
      let res = await PostCallApi(sveCC);
      if (res?.status === 200) {
        if (res.data.isPaid) {
          let svpId = res?.data?.paymentId;
          SaveOrder(svpId, "Online");
        }
      }
    } else {
      setPayment(true);
    }
  };

  const RequestInvoice = async (data) => {
    let seData = {
      url: `/api/AssociationProductPayment/AddAssociationProductInvoice`,
      body: {
        ...data,
        createdBy: LogDetails?.userId,
        userId: LogDetails?.userId,
        associationId: id,
        totalAmount: subtotal,
        paymentMode: "Invoice",
        paymentStatus: "Success",
        invoiceStatus: "Approve",
        discountValue: companyData?.discountAmount,
        orderNumber: companyData?.orderNumber,
        subtotal: companyData?.subtotal,
        shippingAddress: companyData?.shippingAddress,
        associationMemberId: companyData?.associationMemberId,
        companyName: companyData?.companyName,
      },
    };
    let res = await PostCallApi(seData);
    if (res?.status === 200) {
      if (res?.data?.success) {
        let svpId = res?.data?.payload?.associationProductPaymentId;
        SaveOrder(svpId, "Invoice");
      }
    }
  };

  const ShowInvoice = ({ RequestInvoice }) => {
    const { handleSubmit } = useForm();

    return (
      <form
        onSubmit={handleSubmit(async (data) => {
          RequestInvoice({ ...data });
        })}
      >
        <div className="row">
          <div className="col-md-6 mt-auto">
            <div className="form-group mb-3">
              <div
                className={`address_box  active
                `}
              >
                <label className="personal_radio_label">
                  <input
                    type="checkbox"
                    className="login_check"
                    name="address"
                    checked={true}
                    disabled
                  />
                  <span className="checkmark"></span>
                </label>
                <div className="Address">
                  <h6>{companyData?.addressDeatils?.addressType}</h6>
                  <p>
                    {companyData?.addressDeatils?.address1}
                    <br />
                    {companyData?.addressDeatils?.city}{" "}
                    {companyData?.addressDeatils?.state}{" "}
                    {companyData?.addressDeatils?.zip}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 ">
            <Button className="detail_button mt-3" type="submit">
              Submit
            </Button>
          </div>
        </div>
      </form>
    );
  };

  // const DiscountApply = ({ discountValue, handlePromo, handleRemoveDC }) => {
  //   const {
  //     register,
  //     handleSubmit,
  //     formState: { errors },
  //   } = useForm();
  //   return (
  //     <form onSubmit={handleSubmit(handlePromo)} className="mt-3">
  //       <div className="row">
  //         <div className="col-md-6">
  //           <div className="form-group">
  //             <label className="form-label">Discount Code</label>
  //             <input
  //               type="text"
  //               placeholder="Enter Discount Code"
  //               className="form-control"
  //               disabled={discountValue ? true : false}
  //               {...register("promoCode", {
  //                 pattern: {
  //                   value: /^[^\s]+(\s+[^\s]+)*$/,
  //                   message: "Starting and Ending Space not allowed",
  //                 },
  //               })}
  //             />
  //             {errors.promoCode && (
  //               <span role="alert" className="error_text">
  //                 {errors.promoCode.message}
  //               </span>
  //             )}
  //           </div>
  //         </div>
  //         <div className="col-md-6 my-auto">
  //           <Button
  //             className="detail_button"
  //             type="submit"
  //             disabled={discountValue ? true : false}
  //           >
  //             Apply
  //           </Button>
  //           {discountValue ? (
  //             <Button
  //               className="detail_button ms-3"
  //               onClick={() => handleRemoveDC()}
  //             >
  //               Remove
  //             </Button>
  //           ) : (
  //             ""
  //           )}
  //         </div>
  //       </div>
  //     </form>
  //   );
  // };
  // const applyPromo = async (data, pro) => {
  //   setDiscountValue();
  //   let seData = {
  //     url: `/api/Payment/GetAmountAfterApplyPromoCodeOnConference`,
  //     body: data,
  //     headers: headers,
  //   };
  //   let res = await PostCallApi(seData);
  //   if (res?.status === 200) {
  //     if (res.data.success) {
  //       let am = res.data.payload;
  //       setSubTotal(am?.finalAmount);
  //       setDiscountValue(am);
  //     } else {
  //       setSubTotal(companyData?.subtotal);

  //       toast.error(res.data.message, {
  //         position: "top-right",
  //         autoClose: 1500,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "dark",
  //       });
  //     }
  //   } else {
  //     toast.error(res.data.message, {
  //       position: "top-right",
  //       autoClose: 1500,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "dark",
  //     });
  //   }
  // };
  // const handlePromo = (d) => {
  //   if (d) {
  //     let send = {
  //       amount: subtotal,
  //       conferenceId: companyData?.conferenceId,
  //       userId: LogDetails?.userId,
  //       promoCode: d?.promoCode,
  //     };
  //     applyPromo(send, d);
  //   } else {
  //     Swal.fire({
  //       position: "center",
  //       width: "50%",
  //       title: "Please enter discount code",
  //       confirmButtonText: "Close",
  //       confirmButtonColor: "#E64942",
  //     });
  //     setSubTotal(companyData?.subtotal);
  //   }
  // };

  // const handleRemoveDC = () => {
  //   setDiscountValue();
  //   setSubTotal(companyData?.subtotal);
  // };
  const handlePay = () => {
    CLSec();
  };

  useEffect(() => {
    PCSec();
    // eslint-disable-next-line
  }, []);
  const PCSec = async () => {
    let seData = {
      url: `/api/AssociationPayment/GetPaymentKeysByAssociationId?associationId=${id}`,
      body: {},
    };
    const res = await PostCallApi(seData);

    if (res?.status === 200 && res.data.success) {
      const clientPub = res.data?.payload?.publicKey;
      setClientPublicKey(clientPub);
    } else {
      ToastError(res.data.message);
    }
  };
  const stripePromise = loadStripe(clientPublicKey);

  const CheckoutForm = ({ SaveOrder }) => {
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();
    const [derror, setDerror] = useState();
    useEffect(() => {
      setTimeout(() => {
        loadCaptchaEnginge(6, "black", "white");
      }, 500);
    }, []);
    const [dis, setDis] = useState(false);
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();
    const handleSubmitStripe = async (data) => {
      // event.preventDefault();
      setDerror({ h_er: "" });

      if (validateCaptcha(data?.captcha, false) === true) {
        loadCaptchaEnginge(6, "black", "white");
        if (!stripe || !elements) {
          return;
        }

        const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: data?.cardName,
            address: { postal_code: data?.zip ? data?.zip : null },
          },
          // card: elements.getElement(CardElement),
        });

        if (error) {
          // handle payment error
        } else {
          const sourceId = paymentMethod.id;
          let paymentData = {
            ...companyData,
            createdBy: LogDetails?.userId,
            userId: LogDetails?.userId,
            email: LogDetails?.email,
            amount: subtotal,
            // discountAmount: discountValue?.discountValue,
            // promoCodeId: discountValue?.promoCodeId,
            associationId: id,
            paymentMode: "Online",
            PaymentMethodId: sourceId,
          };
          let sveCC = {
            url: `/api/AssociationProductPayment/AssociationProductPaymentProcess`,
            body: paymentData,
          };
          setDis(true);
          let res = await PostCallApi(sveCC);
          if (res.status === 200) {
            if (res.data.isPaid) {
              let svpId = res?.data?.paymentId;
              await SaveOrder(svpId, "Online");
              setDis(false);

              // toast.success(res.data.message, {
              //   position: "top-right",
              //   autoClose: 1500,
              //   hideProgressBar: true,
              //   closeOnClick: true,
              //   pauseOnHover: true,
              //   draggable: true,
              //   progress: undefined,
              //   theme: "dark",
              // });
            } else {
              setDis(false);

              toast.error(res.data.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          } else {
            setDis(false);

            toast.error(res.data.message, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }
        }
      } else {
        setDerror({ h_er: "Captcha does not match." });
      }
    };

    return (
      <form onSubmit={handleSubmit(handleSubmitStripe)}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Name On Card</label>{" "}
              <input
                type="text"
                className="form-control"
                placeholder="Enter name on card"
                {...register("cardName", {
                  required: "This field is required",
                })}
              />
              {errors.cardName && (
                <span role="alert" className="error_text">
                  {errors.cardName.message}
                </span>
              )}
            </div>
          </div>
          <div
            className="col-md-6 "
            // style={{ padding: "0.5rem", border: "1px solid gainsboro" }}
          >
            <div className="form-group">
              <label className="form-label">Card Number</label>
              <CardNumberElement className="form-control" options={options} />
            </div>
            {/* <CardElement options={CARD_OPTIONS} /> */}
          </div>
          <div className="col-md-6 ">
            <div className="form-group">
              <label className="form-label"> Expiration date</label>
              <CardExpiryElement className="form-control" options={options} />
            </div>
          </div>
          <div className="col-md-6 ">
            <div className="form-group">
              <label className="form-label">CVC</label>
              <CardCvcElement className="form-control" options={options} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Zip Code</label>{" "}
              <input
                type="text"
                className="form-control"
                placeholder="Enter Zip Code"
                {...register("zip", {
                  // required: "This field is required",
                  maxLength: {
                    value: 6,
                    message: "Max 6 characters",
                  },
                })}
              />
              {errors.zip && (
                <span role="alert" className="error_text">
                  {errors.zip.message}
                </span>
              )}
            </div>
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-6 my-auto">
            <div className="captcha_box ">
              <LoadCanvasTemplate />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Captcha</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Captcha"
                {...register("captcha", {
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                  maxLength: {
                    value: 6,
                    message: "Max 6 characters",
                  },
                })}
              />
              {errors?.captcha && (
                <span role="alert" className="d-block error_text">
                  {errors.captcha.message}
                </span>
              )}
              {derror?.h_er && (
                <span role="alert" className="d-block error_text">
                  {derror.h_er}
                </span>
              )}
            </div>
          </div>
        </div>
        <Button
          className="detail_button"
          type="submit"
          disabled={!stripe || dis}
        >
          Complete Payment
        </Button>
      </form>
    );
  };

  const SaveOrder = async (svpId, mode) => {
    let newApi = {
      url: `/api/AssociationProductPayment/CreateAssociationProductUserAllocation`,
      body: {
        ...companyData,
        associationProductPaymentId: svpId,
        createdBy: LogDetails?.userId,
        userId: LogDetails?.userId,
        discount: discountValue?.discountValue,
        paymentStatus: "success",
        totalAmount: subtotal,
        paymentMode: mode,
      },
    };
    let res = await PostCallApi(newApi);
    if (res?.status === 200 && res?.data?.success) {
      nav(`/association-product-history/reciept/${svpId}`);
      ToastSuccess(res.data.message);
    } else {
      ToastError(res.data.message);
    }
  };
  return (
    <>
      <div className="">
        <div className="title attend-title mb-3">Payment Details</div>
        <div className="col-md-6">
          <div className="pay_process_details border-bottom-0 mb-4">
            <ul>
              <li>
                Order Number
                <span>{companyData?.orderNumber}</span>
              </li>{" "}
              <li>
                Fees
                <span>
                  {companyData?.subtotal?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </span>
              </li>{" "}
              {discountValue ? (
                <li>
                  Discount
                  <span>
                    {" "}
                    -
                    {discountValue
                      ? discountValue.discountValue?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      : ""}
                  </span>
                </li>
              ) : (
                ""
              )}
            </ul>
            <div className="subtotal">
              <p>
                Total{" "}
                <span>
                  {subtotal
                    ? subtotal?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    : "$0.00"}
                </span>
              </p>
            </div>
            {/* {!payment && (
                <DiscountApply
                  handlePromo={handlePromo}
                  handleRemoveDC={handleRemoveDC}
                  discountValue={discountValue}
                />
              )} */}
            {!payment && (
              <div className="mt-3 text-end">
                <Button className="detail_button" onClick={handlePay}>
                  Pay
                </Button>
              </div>
            )}
          </div>
        </div>
        {payment ? (
          <>
            <div className="d-flex gap-3 mb-3">
              <Button
                className={`${acBtn ? "detail_button" : "back_button"}`}
                onClick={() => setAcBtn(true)}
              >
                Pay Online
              </Button>
              <Button
                className={`${!acBtn ? "detail_button" : "back_button"}`}
                onClick={() => setAcBtn(false)}
              >
                Request Invoice
              </Button>
            </div>
            {acBtn ? (
              <Elements stripe={stripePromise}>
                <CheckoutForm SaveOrder={SaveOrder} />
              </Elements>
            ) : (
              <ShowInvoice RequestInvoice={RequestInvoice} />
            )}
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default AssociationProductPayment;
