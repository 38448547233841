import React, { lazy, Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Tooltip,
  Typography,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import { IoReceiptOutline } from "react-icons/io5";
import TableHeader from "../../../TableHeader.json";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { useForm } from "react-hook-form";
import { ToastError } from "../../Components/Toast";
import { GetAllWithoutTokenConferenceListDropDown } from "../../../Action/ConferenceListApi";
import { customFilter } from "../../Components/CustomFilterForReactSelect";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import Select from "react-select";

const UnregisterPopup = lazy(() =>
  import("../../Components/Reusable/UnregisterPopup")
);
const PaymentTable = lazy(() => import("./PaymentTable"));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function PaymentHistory() {
  const [dataOfTable, setDataOfTable] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [filterPage, setFilterPage] = useState(false);
  const [conList, setConList] = useState([]);
  const [confId, setConfId] = useState();
  const [paymentStatus, setPaymentStatus] = useState();
  const [paymentType, setPaymentType] = useState();
  const [leaList, setLeaList] = useState([]);
  const [selectLea, setSelectLea] = useState([]);
  const [agencyList, setAgencyList] = useState([]);
  const [selectAgency, setSelectAgency] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [selectIndustry, setSelectIndustry] = useState([]);

  const LogDetails = useSelector((state) => state.login.LoginDetails);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    document.title = "FIG Events | Payment History";
    getConfList();
    getLEAList();
    getAgencyist();
    getIndustryist();
    // eslint-disable-next-line
  }, []);

  const getConfList = async () => {
    let res = await GetAllWithoutTokenConferenceListDropDown();
    if (res?.length > 0) {
      setConList(res);
      // handleConfereceSelect(res[0]?.value);
    }
  };

  const getLEAList = async () => {
    let seData = {
      url: `/api/AssociationMembers/GetAssociationMembersListbyUserIdAndType?type=${"LEA"}&userId=${
        LogDetails?.userId
      }`,
    };
    let res = await GetCallApi(seData);
    if (res.status === 200) {
      if (res.data.success) {
        let data = res.data.payload?.map((x) => {
          return {
            ...x,
            value: x?.associationMemberId,
            label: x?.name,
          };
        });
        setLeaList(data);
      }
    }
  };

  const getAgencyist = async () => {
    let seData = {
      url: `/api/AssociationMembers/GetAssociationMembersListbyUserIdAndType?type=${"State Agency"}&userId=${
        LogDetails?.userId
      }`,
    };
    let res = await GetCallApi(seData);
    if (res.status === 200) {
      if (res.data.success) {
        let data = res.data.payload?.map((x) => {
          return {
            ...x,
            value: x?.associationMemberId,
            label: x?.name,
          };
        });
        setAgencyList(data);
      }
    }
  };

  const getIndustryist = async () => {
    let seData = {
      url: `/api/AssociationMembers/GetAssociationMembersListbyUserIdAndType?type=${"Industry"}&userId=${
        LogDetails?.userId
      }`,
    };
    let res = await GetCallApi(seData);
    if (res.status === 200) {
      if (res.data.success) {
        let data = res.data.payload?.map((x) => {
          return {
            ...x,
            value: x?.associationMemberId,
            label: x?.name,
          };
        });
        setIndustryList(data);
      }
    }
  };

  const handleChangeLea = (e) => {
    setSelectLea(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const handleChangeAgency = (e) => {
    setSelectAgency(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const handleChangeIndustry = (e) => {
    setSelectIndustry(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const GetPaymentList = async (data) => {
    setDataOfTable([]);
    setOpenCircle(true);
    let memberList = [];
    if (selectLea?.length > 0) {
      memberList = selectLea;
    }
    if (selectAgency?.length > 0) {
      memberList = [...memberList, ...selectAgency];
    }
    if (selectIndustry?.length > 0) {
      memberList = [...memberList, ...selectIndustry];
    }
    let res = await PostCallApi({
      url: `/api/Payment/PaymentHistory`,
      body: {
        ...data,
        loginUserId: LogDetails?.userId,
        associationMemberId: memberList?.length > 0 ? memberList : [],
        ConferenceIdList: conList?.map((x) => x?.value),
      },
    });
    setOpenCircle(false);
    setFilterPage(!filterPage);

    if (res.status === 200 && res.data.success) {
      let comList = res?.data?.payload?.map((c, i) => {
        let body =
          c?.registerType === "Stripe"
            ? c?.userType === "User"
              ? {
                  attendeePaymentId: c?.paymentId,
                  createdBy: LogDetails?.userId,
                }
              : c?.userType === "Exhibitor"
              ? {
                  boothBookingPaymentId: c?.paymentId,
                  createdBy: LogDetails?.userId,
                }
              : {
                  memberManagementId: c?.paymentId,
                  createdBy: LogDetails?.userId,
                }
            : "";
        let unUrl =
          c?.registerType === "Stripe"
            ? c?.userType === "User"
              ? "/api/Unregister/UnregisterAttendeeRegistartion"
              : c?.userType === "Exhibitor"
              ? "/api/Unregister/UnregisterExhibitorRegistartion"
              : "/api/Unregister/UnregisterMemberManagementRegistartion"
            : c?.userType === "User" || c?.userType === "Exhibitor"
            ? `/api/ExhibitorAttendeeInvoice/DeleteExhibitorAttendeeInvoiceByInvoiceId?exhibitorAttendeeInvoiceId=${c?.invoiceId}&createdBy=${LogDetails?.userId}&deletePermanently=false`
            : `/api/MemberManagement/DeleteMemberManagementInvoiceByInvoiceId?memberManagementInvoiceId=${c?.paymentId}&createdBy=${LogDetails?.userId}&deletePermanently=false`;
        return {
          ...c,
          id: i + 1,
          phoneNumber: c?.phoneNumber,
          phone: c?.phoneNumber,
          groupUserList:
            c?.groupUserList?.length > 0
              ? c?.groupUserList?.map((x, index) => {
                  return {
                    ...x,
                    id: index + 1,
                  };
                })
              : [],
          date: c?.date
            ? new Date(c?.date).toLocaleDateString("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              })
            : "",
          dueDate: c?.dueDate
            ? new Date(c?.dueDate).toLocaleDateString("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              })
            : "",
          Action:
            c?.registerType === "Stripe" ? (
              <>
                <Tooltip title="View Receipt">
                  <Link
                    className="btn_edit"
                    to={`/stripe-recipe/${c?.userType}/${c?.paymentId}`}
                  >
                    <IoReceiptOutline />
                  </Link>
                </Tooltip>
                {LogDetails?.roleId === 1 ||
                LogDetails?.roleName === "Event Organizer" ? (
                  <>
                    <span className="ms-2"></span>
                    <UnregisterPopup
                      title={`Unregister ${c?.userType}`}
                      Unregister={true}
                      text={`${c?.userName}${
                        c?.userType === "Group" ? "'s Group" : " "
                      }`}
                      url={unUrl}
                      body={body}
                      callBack={true}
                      callBackFunction={CallBackApi}
                    />
                  </>
                ) : (
                  ""
                )}
              </>
            ) : c?.groupUserList?.length > 0 ? (
              <>
                <Tooltip title="View Receipt">
                  <Link
                    className="btn_edit"
                    to={`/association-members-invoice-approval/view/${
                      c?.invoiceId
                    }/${c?.paymentAmount === 0 ? true : false}`}
                  >
                    <IoReceiptOutline />
                  </Link>
                </Tooltip>
                {LogDetails?.roleId === 1 ||
                LogDetails?.roleName === "Event Organizer" ? (
                  <>
                    <span className="ms-2"></span>
                    <UnregisterPopup
                      title={`Unregister ${c?.userType}`}
                      Unregister={true}
                      text={`${c?.userName}${
                        c?.userType === "Group" ? "'s Group" : " "
                      }`}
                      url={unUrl}
                      callBack={true}
                      callBackFunction={CallBackApi}
                    />
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <Tooltip title="View Receipt">
                  <Link
                    className="btn_edit"
                    to={`/invoice-view/${c?.invoiceId}`}
                  >
                    <IoReceiptOutline />
                  </Link>
                </Tooltip>
                {LogDetails?.roleId === 1 ||
                LogDetails?.roleName === "Event Organizer" ? (
                  <>
                    <span className="ms-2"></span>
                    <UnregisterPopup
                      title={`Unregister ${c?.userType}`}
                      Unregister={true}
                      text={`${c?.userName}${
                        c?.userType === "Group" ? "'s Group" : " "
                      }`}
                      url={unUrl}
                      callBack={true}
                      callBackFunction={CallBackApi}
                    />
                  </>
                ) : (
                  ""
                )}
              </>
            ),
        };
      });
      setDataOfTable(comList);
    } else {
      ToastError(res?.data?.message);
    }
  };

  const CallBackApi = () => {
    let send = {
      conferenceId: getValues("conferenceId"),
      paymentStatus: getValues("paymentStatus"),
      paymentType: getValues("paymentType"),
      paymentDate: getValues("paymentDate"),
      paymentReference: getValues("paymentReference"),
      poNumber: getValues("poNumber"),
    };
    GetPaymentList(send);
  };

  const handleConfereceSelect = (e) => {
    setConfId(e);
    setValue("conferenceId", e);
  };

  const PaymentStatusOption = [
    { value: "Pending", label: "Pending" },
    { value: "Success", label: "Success" },
  ];

  const handlePaymentStatus = (e) => {
    setPaymentStatus(e);
    setValue("paymentStatus", e);
  };

  const PaymentTypeOption = [
    { value: "Group Invoice", label: "Group Invoice" },
    { value: "Individual Invoice", label: "Individual Invoice" },
    { value: "Stripe", label: "Stripe" },
  ];

  const handlePaymentType = (e) => {
    setPaymentType(e);
    setValue("paymentType", e);
  };

  const handleClearFilter = () => {
    setConfId();
    setPaymentStatus();
    setPaymentType();
    setSelectLea([]);
    setSelectIndustry([]);
    setSelectAgency([]);
    setDataOfTable([]);
    reset();
  };

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard  py-4">
          <div className="main_wrapper">
            <div className="row">
              {" "}
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography style={{ marginLeft: "20px" }}>
                    <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                    <span className="filter_by">Filter By</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <form onSubmit={handleSubmit(GetPaymentList)}>
                    <div className="row" style={{ marginLeft: "20px" }}>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Conference</label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={conList}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Conference"
                            filterOption={customFilter}
                            value={conList?.filter(
                              (obj) => obj.value === confId
                            )}
                            onChange={(e) => handleConfereceSelect(e?.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">PO Number</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter PO Number"
                            {...register("poNumber", {
                              pattern: {
                                value: /^[^\s]+(\s+[^\s]+)*$/,
                                message:
                                  "Starting and Ending Space not allowed",
                              },
                              maxLength: {
                                value: 200,
                                message: "Max 200 characters",
                              },
                            })}
                          />
                          {errors.poNumber && (
                            <span role="alert" className="error_text">
                              {errors.poNumber.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">
                            Payment Reference
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Payment Reference"
                            {...register("paymentReference", {
                              pattern: {
                                value: /^[^\s]+(\s+[^\s]+)*$/,
                                message:
                                  "Starting and Ending Space not allowed",
                              },
                              maxLength: {
                                value: 200,
                                message: "Max 200 characters",
                              },
                            })}
                          />
                          {errors.paymentReference && (
                            <span role="alert" className="error_text">
                              {errors.paymentReference.message}
                            </span>
                          )}
                        </div>
                      </div>{" "}
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Payment Status</label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={PaymentStatusOption}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Payment Status"
                            filterOption={customFilter}
                            value={PaymentStatusOption?.filter(
                              (obj) => obj.value === paymentStatus
                            )}
                            onChange={(e) => handlePaymentStatus(e?.value)}
                          />
                        </div>
                      </div>{" "}
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Payment Date</label>
                          <input
                            type="date"
                            className="form-control"
                            {...register("paymentDate", {
                              valueAsDate: true,
                            })}
                          />
                          {errors.paymentDate && (
                            <span role="alert" className="error_text">
                              {errors.paymentDate.message}
                            </span>
                          )}
                        </div>
                      </div>{" "}
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Payment Type</label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={PaymentTypeOption}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Payment Type"
                            filterOption={customFilter}
                            value={PaymentTypeOption?.filter(
                              (obj) => obj.value === paymentType
                            )}
                            onChange={(e) => handlePaymentType(e?.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">LEA Member</label>
                          <Select
                            isMulti
                            name="colors"
                            options={leaList}
                            className="company-multi-select"
                            classNamePrefix="select"
                            placeholder="Select LEA Member"
                            filterOption={customFilter}
                            value={leaList?.filter((obj) =>
                              selectLea?.includes(obj.value)
                            )}
                            onChange={handleChangeLea}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">State Agencies</label>
                          <Select
                            isMulti
                            name="colors"
                            options={agencyList}
                            className="company-multi-select"
                            classNamePrefix="select"
                            placeholder="Select State Agencies"
                            filterOption={customFilter}
                            value={agencyList?.filter((obj) =>
                              selectAgency?.includes(obj.value)
                            )}
                            onChange={handleChangeAgency}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Industry Member</label>
                          <Select
                            isMulti
                            name="colors"
                            options={industryList}
                            className="company-multi-select"
                            classNamePrefix="select"
                            placeholder="Select Industry Member"
                            filterOption={customFilter}
                            value={industryList?.filter((obj) =>
                              selectIndustry?.includes(obj.value)
                            )}
                            onChange={handleChangeIndustry}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 d-flex justify-content-start mt-auto">
                        <div className="form-group">
                          <Button
                            className="detail_button ms-3"
                            type="submit"
                            disabled={openCircle ? true : false}
                          >
                            {openCircle ? (
                              <CircularProgress
                                color="inherit"
                                sx={{
                                  color: "#fff",
                                  marginLeft: "0.5rem",
                                  height: "23px !important",
                                  width: "23px !important",
                                }}
                              />
                            ) : (
                              "Apply Filter"
                            )}
                          </Button>
                          <Button
                            className={`back_button ms-3 ${
                              window.innerWidth > 400 ? "" : "mt-3"
                            }`}
                            onClick={() => handleClearFilter()}
                          >
                            Clear Filter
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                </AccordionDetails>
              </Accordion>
              <div className="col-md-12 accordian_card p-0 mb-4">
                <PaymentTable
                  title="Payment History"
                  action={true}
                  headCells={TableHeader?.PaymentHistoryHeader}
                  ListData={dataOfTable}
                  dropdown={false}
                  addBtn={false}
                  showUserListInTable={true}
                  fileName={"Payment History"}
                  exportDataList={true}
                  innerProduct={true}
                  filterPage={filterPage}
                  subHeadCells={TableHeader?.PaymentHistorySubHeader}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default PaymentHistory;
