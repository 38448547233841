import React, { Suspense, lazy, useEffect, useState } from "react";
import "./index.scss";
import figWhitelogo from "../../../../../assets/images/figEventWhite.png";
import NotFound from "../../../../../assets/images/Image_not_available.jpg";
import { Link, useParams } from "react-router-dom";
import {
  Backdrop,
  Badge,
  Box,
  Button,
  CircularProgress,
  Tab,
} from "@mui/material";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import { Config } from "../../../../../Utils/Config";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { IoMdRefreshCircle } from "react-icons/io";
import AOS from "aos";
import "aos/dist/aos.css";
import { TabContext, TabList } from "@mui/lab";
import { FaCartPlus } from "react-icons/fa";
// import {
//   addToCart,
//   calculatePrice,
//   resetCart,
// } from "../../../../../reducers/Cart";

const StoreProductCard = lazy(() => import("./Card"));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function StoreDetails() {
  const [openCircle, setOpenCircle] = useState(false);
  const [asDetails, setAsDetails] = useState();
  const [storeList, setStoreList] = useState();
  const [storeId, setStoreId] = useState("1");
  const [prdList, setPrdList] = useState();
  const [prdSearchField, setPrdSearchField] = useState("");
  // const { cartItems } = useSelector((state) => state.Cart);
  const [cartItems, setCartItems] = useState([]);

  const { id } = useParams();
  const LogDetails = useSelector((state) => state.login.LoginDetails);
  // let dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      GetAsDetails(id);
      GetStoreList(id);
      GetCartData();
      // CheckCartAndRemove(id);
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    AOS.init({
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  // const CheckCartAndRemove = (id) => {
  //   if (cartItems?.length > 0) {
  //     let cart = cartItems?.find((x) => x?.associationId === id);
  //     if (!cart) {
  //       dispatch(resetCart());
  //     }
  //   }
  // };
  const GetCartData = async () => {
    let seData = {
      url: `/api/AssociationProductAddToCart/GetAllAddToCartProductByUserId?userId=${LogDetails?.userId}&associationId=${id}`,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        let ud = res.data.payload;
        setCartItems(ud);
      }
    }
  };

  const GetAsDetails = async (id) => {
    let seData = {
      url: `/api/Association/GetAssociationByAssociationId?associationId=${id}`,
    };

    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);

    if (res.status === 200) {
      if (res.data.success) {
        let useDe = res.data.payload;

        setAsDetails(useDe);
      }
    }
  };

  const GetStoreList = async (id) => {
    let seData = {
      url: `/api/AssociationStore/GetAllAssociationStoreList?associationId=${id}&userId=${LogDetails?.userId}`,
    };

    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);

    if (res.status === 200) {
      if (res.data.success) {
        let useDe = res.data.payload;
        if (useDe?.length > 0) {
          setStoreList(useDe);
          setStoreId(useDe[0]?.associationStoreId);
          getAssoProductList(useDe[0]?.associationStoreId);
        }
      }
    }
  };

  const getAssoProductList = async (stId) => {
    setPrdList([]);
    let seData = {
      url: `/api/AssociationProduct/GetAllAssociationProductList?associationId=${id}&associationStoreId=${stId}&userId=${LogDetails?.userId}`,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        let data = res.data.payload?.map((x) => {
          return {
            ...x,
            cardImg: x?.multipleImages?.[0]?.imagePath
              ? x?.multipleImages?.[0]?.imagePath
              : NotFound,
            cardTitle: x.productName,
            des: x?.shortDescription,
            quentity: 1,
          };
        });
        setPrdList(data);
      }
    }
  };
  const handleRefresh = () => {
    getAssoProductList(storeId);
  };
  const userInLike = async (p) => {
    let seData = {
      url: "/api/AssociationProduct/CreateAssociationProductUserInteraction",
      body: {
        AssociationProductId: p,
        userId: LogDetails?.userId,
        status: "Like",
      },
    };
    let res = await PostCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        handleRefresh();
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  };
  const searchitem = ["cardTitle", "categoryName", "price"];
  const formattedSearchResults = prdList?.filter((user) => {
    if (prdSearchField) {
      return searchitem?.some((newItem) => {
        return (
          user[newItem]
            ?.toString()
            ?.toLowerCase()
            ?.indexOf(prdSearchField.toLowerCase()) > -1
        );
      });
    } else return user;
  });
  const handleStore = (e, newValue) => {
    setStoreId(newValue);
    getAssoProductList(newValue);
  };
  const addToCartHandler = async (data) => {
    setOpenCircle(true);
    let res = await PostCallApi({
      url: `/api/AssociationProductAddToCart/AddUpdateAddToCartProduct`,
      body: {
        associationProductId: data?.associationProductId,
        quntity: 1,
        associationId: id,
        userId: LogDetails?.userId,
        createdBy: LogDetails?.userId,
      },
    });
    setOpenCircle(false);

    if (res?.status === 200 && res?.data?.success) {
      GetCartData();
      toast.success(res?.data?.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      toast.error(res?.data?.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }

    // dispatch(addToCart(data));
    // dispatch(calculatePrice());
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="storePgae">
          <section className="store_details">
            {" "}
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6 index-9" data-aos="fade-in">
                  <div className="banner_content">
                    <div style={{ padding: "50px" }}>
                      <img
                        loading="lazy"
                        src={
                          asDetails?.logo
                            ? `${Config.API_HOST_URL_live}${asDetails.logo}`
                            : figWhitelogo
                        }
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = figWhitelogo;
                        }}
                        width={"60%"}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6" data-aos="fade-in">
                  <div className="user_detail_QR">
                    <div className="info">
                      <h1 className="title">{asDetails?.name}</h1>
                      <p>Contact Details</p>
                      <p>Name: {asDetails?.contactName}</p>
                      <p>
                        <img src={"/assets/icons/mail.svg"} alt="" />
                        <Link to={`mailto:${asDetails?.email}`}>
                          {asDetails?.email}
                        </Link>
                      </p>
                      <p>
                        <img src={"/assets/icons/phone.svg"} alt="" />
                        {asDetails?.phone}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img
              src={"/assets/icons/dot.svg"}
              className="prelex_vector top img-fluid"
              alt=""
            />
            <img
              src={"/assets/icons/homepage_vector.svg"}
              className="prelex_vector bottom img-fluid"
              alt=""
            />
          </section>
          <section className="_profile">
            <div className="main_wrapper">
              <div className="exhibitor_main_box mb-4" data-aos="zoom-in">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <h4 className="title m-0">Product List</h4>
                  <div className="d-flex align-items-center w-50">
                    <div className=" search_sec ms-auto">
                      <div className="search_input w-100">
                        <img src={"/assets/icons/search_icon.svg"} alt="" />
                        <input
                          onChange={(e) => {
                            setPrdSearchField(e.target.value);
                          }}
                          type="text"
                          className="form-control"
                          placeholder="Type your search here"
                        />
                      </div>
                    </div>

                    {openCircle ? (
                      <Button
                        className="dashboard_btn background ms-3"
                        style={{ textDecoration: "none", height: "45px" }}
                      >
                        <CircularProgress
                          sx={{
                            color: "white",
                            height: "23px !important",
                            width: "23px !important",
                          }}
                        />
                      </Button>
                    ) : (
                      <Button
                        className="dashboard_btn background ms-3"
                        type="submit"
                        style={{ textDecoration: "none", height: "45px" }}
                        onClick={handleRefresh}
                      >
                        <IoMdRefreshCircle fontSize={30} />
                      </Button>
                    )}
                    <Badge badgeContent={cartItems?.length} color="warning">
                      <Link
                        to={"product-cart"}
                        className="dashboard_btn background ms-2"
                      >
                        <FaCartPlus fontSize={24} />
                      </Link>
                    </Badge>
                  </div>
                </div>
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={storeId}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleStore}
                        variant="scrollable"
                        scrollButtons={false}
                        aria-label="lab API tabs example"
                        className={`store-tab `}
                      >
                        {storeList?.map((se, i) => (
                          <Tab
                            key={i}
                            label={se?.storeName}
                            value={se?.associationStoreId}
                            className="head-tab"
                          />
                        ))}
                      </TabList>
                    </Box>
                    <div className="row mt-3">
                      <StoreProductCard
                        data={formattedSearchResults}
                        userInLike={userInLike}
                        addToCartHandler={addToCartHandler}
                      />
                    </div>
                  </TabContext>
                </Box>
              </div>
            </div>
          </section>
        </section>
      </Suspense>
    </>
  );
}

export default StoreDetails;
