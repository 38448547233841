import React, { useEffect, useState } from 'react'
import { Navigate, Route, BrowserRouter as Router, Routes, useParams } from 'react-router-dom';
import './App.scss';
import 'animate.css';
import AdminDashboard from './Modules/SuperAdmin/Dashboard';
import ExhibitorDashboard from './Modules/Exhibitor/Dashboard';
import PrivateRoute from './Routes/PrivateRoute';
import ConferenceListMain from './Modules/SuperAdmin/ConferenceList';
import ConferenceSetupProcess from './Modules/SuperAdmin/ConferenceSetupProcess';
import ConferenceSponsor from './Modules/SuperAdmin/ConferenceSponsor';
import FloorPlanEdit from './Modules/SuperAdmin/FloorPlanEdit';
import Company from './Modules/Company';
import Login from './pages/Login';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import UserRegisterationList from './Modules/SuperAdmin/UserRegisterationList';
import EditUser from './Modules/SuperAdmin/UserRegisterationList/EditUser';
import CreateCompany from './Modules/Company/CreateCompany';
import AccesssManagement from './Modules/SuperAdmin/Menu/access/AccessManagement';
import MenuCreate from './Modules/SuperAdmin/Menu/create';
import MenuList from './Modules/SuperAdmin/Menu';
import ConferenceApproval from './Modules/SuperAdmin/ConferenceApproval';
import EventListMain from './Modules/SuperAdmin/Event';
import SpeakerList from './Modules/SuperAdmin/Speaker';
import SpeakerCreate from './Modules/SuperAdmin/Speaker/create';
import ConferenceClasses from './Modules/SuperAdmin/ConferenceClasses';
import SessionCreate from './Modules/SuperAdmin/ConferenceClasses/create';
import Gallery from './Modules/SuperAdmin/HomeManagment/Gallery';
import GalleryCreate from './Modules/SuperAdmin/HomeManagment/Gallery/create';
import ResetPassword from './pages/Resetpassword';
import ConferenceCalendar from './pages/ConferenceCalendar';
import WebsiteHome from './pages/WebsiteHome';
import ConferencesDetail from './pages/WebsiteHome/ConferencesDetail';
import PrivacyPolicy from './pages/WebsiteHome/PrivacyPolicy';
import HowItWorks from './pages/WebsiteHome/HowItWorks';
import StepRegister from './Modules/ConferenceRegistration/Attendee/StepRegister';
import ConferenceRegisterUser from './Modules/Report/ConferenceRegisterUser';
import PaymentHistory from './Modules/user/PaymentHistory';
import SessionAttend from './Modules/SuperAdmin/SessionAttend';
import SessionAttendedList from './Modules/SuperAdmin/SessionAttend/SessionAttendedList';
import CertificateUserNew from './Modules/Components/Certificate_New';
import ExhibitorProfile from './Modules/Exhibitor/ExhibitorProfile';
import VendorCreate from './Modules/Exhibitor/ExhibitorProfile/Vendor';
import ExhibitorProfilePublic from './Modules/Exhibitor/ExhibitorProfilePublic';
import ProductDetailPublic from './Modules/Exhibitor/ExhibitorProfilePublic/ProductDetailPublic';
import ProductCreate from './Modules/Exhibitor/ExhibitorProfile/ProductCreate';
import QualifyingQuestionforNonFigEvents from './Modules/Exhibitor/ExhibitorProfile/QualifyingQuestionforNonFigEvents';
import QualifyingQuestionforNonFigEventsCreate from './Modules/Exhibitor/ExhibitorProfile/QualifyingQuestionforNonFigEvents/Create';
import MyRegisterList from './Modules/user/MyRegisterList';
import About from './Modules/SuperAdmin/HomeManagment/About';
import ConferenceUserFloorPlan from './pages/ConferenceUserFloorPlan';
import AttendeeDashboard from './Modules/user/Dashboard';
import PublicRoute from './Routes/PublicRoute';
import SalesRepresentative from './Modules/SuperAdmin/SalesRepresentative';
import ProductList from './Modules/Exhibitor/ProductList';
import NewCommunity from './Modules/NewCommunity';
import Messages from './Modules/Messages';
import EmailPreference from './Modules/EmailPreference';
import EventCreate from './Modules/SuperAdmin/Event/Create';
import AttendeeProfile from './Modules/user/AttendeeProfile';
import BlockReportList from './Modules/SuperAdmin/BlockReportList';
import DiscountList from './Modules/SuperAdmin/Discount';
import DiscountCreate from './Modules/SuperAdmin/Discount/create';
import CompanyForm from './pages/Exhibitorcompany';
import RegisterMultiPaymentSuccess from './pages/RegisterMultiPaymentSuccess';
import Association from './Modules/SuperAdmin/MemberManagement/Association';
import AssociationCreate from './Modules/SuperAdmin/MemberManagement/Association/Create';
import AssociationMemberList from './Modules/SuperAdmin/MemberManagement/AssociationMember';
import AssociationMemberCreate from './Modules/SuperAdmin/MemberManagement/AssociationMember/Create';
import AssignList from './Modules/SuperAdmin/AssignUsers/AssignList';
import InVoiceApproval from "./Modules/SuperAdmin/InVoiceApproval"
import UpdatePassword from './Modules/user/AttendeeProfile/UpdatePassword';
import SubscriptionPriceList from './Modules/SuperAdmin/SubscriptionPrice';
import SubscriptionPriceCreate from './Modules/SuperAdmin/SubscriptionPrice/create';
import Subcription from './Modules/Exhibitor/Subscription';
import SubscriptionBuy from './Modules/Exhibitor/Subscription/SubscriptionBuy';
import CompanyLead from './Modules/SuperAdmin/CompanyLeadTrackingUpdates';
import InvoiceList from './Modules/SuperAdmin/InvoiceManagement';
import InvoiceEdit from './Modules/SuperAdmin/InvoiceManagement/Edit/index';
import InvoiceView from './Modules/SuperAdmin/InvoiceManagement/View';
import AttendeeRegistration from './Modules/Report/AttendeeRegistration';
import LeadsReport from './Modules/Report/LeadsReport';
import GroupInvoiceApproval from './Modules/SuperAdmin/GroupInvoiceApproval';
import GroupInvoiceView from './Modules/SuperAdmin/GroupInvoiceApproval/View';
import GroupRegisterEdit from './Modules/SuperAdmin/GroupInvoiceApproval/GroupRegisterEdit';
import GroupCEUReport from './Modules/SuperAdmin/MemberManagement/GroupCEUReport';
import UserAssociation from './Modules/SuperAdmin/MemberManagement/Association/UserAssociation';
import AssociationRegisterPay from './Modules/SuperAdmin/MemberManagement/Association/UserAssociation/AssociationRegisterPay';
import UserAssociationPaymentSuccess from './Modules/SuperAdmin/MemberManagement/Association/UserAssociation/UserAssociationPaymentSuccess';
import UserAssociationInvoiceApproval from './Modules/SuperAdmin/MemberManagement/Association/UserAssociation/InvoiceApproval';
import UserAssociationPaymentHistory from './Modules/SuperAdmin/MemberManagement/Association/UserAssociation/PaymentHistory';
import EditAssociationRegisterPay from './Modules/SuperAdmin/MemberManagement/Association/UserAssociation/InvoiceApproval/EditAssociationRegisterPay';
import ViewAssociationUserInvoice from './Modules/SuperAdmin/MemberManagement/Association/UserAssociation/InvoiceApproval/ViewInvoice';
import ExhibitorSubscriptionList from './Modules/SuperAdmin/ExhibitorSubscriptionList';
import AttendeeSchedule from './Modules/user/AttendeeSchedule';
import MySchedule from './Modules/user/AttendeeSchedule/MySchedule';
import StripeRecipe from './Modules/user/PaymentHistory/StripeRecipe';
import UserAssociationStripeRecipe from './Modules/SuperAdmin/MemberManagement/Association/UserAssociation/PaymentHistory/StripeRecipe';
import UserQRScan from './Modules/user/UserQRScan';
import AssociationStore from './Modules/SuperAdmin/MemberManagement/Association/Store';
import AssociationStoreCreate from './Modules/SuperAdmin/MemberManagement/Association/Store/Create';
import AssociationProduct from './Modules/SuperAdmin/MemberManagement/Association/Product';
import AssociationProductCreate from './Modules/SuperAdmin/MemberManagement/Association/Product/Create';
import AssociationStoreForUser from './Modules/SuperAdmin/MemberManagement/AssociationStoreForUser';
import StoreDetails from './Modules/SuperAdmin/MemberManagement/AssociationStoreForUser/StoreDetails';
import AssociationProductDetail from './Modules/SuperAdmin/MemberManagement/AssociationStoreForUser/StoreDetails/AssociationProductDetail';
import PayToSchedule from './Modules/user/AttendeeSchedule/PayToSchedule';
import ProductCart from './Modules/SuperAdmin/MemberManagement/AssociationStoreForUser/StoreDetails/ProductCart';
import AssociationProductPaymentHistory from './Modules/SuperAdmin/MemberManagement/AssociationStoreForUser/PaymentHistory';
import AssociationProductStripeRecipe from './Modules/SuperAdmin/MemberManagement/AssociationStoreForUser/PaymentHistory/StripeRecipe/index';
import AssociationProductInvoiceApproval from './Modules/SuperAdmin/MemberManagement/AssociationStoreForUser/InvoiceApproval';
import AssociationProductSalesReport from './Modules/Report/AssociationProductSalesReport';
import AttendeeSummaryReport from './Modules/Report/AttendeeSummaryReport';
import SessionAttendeesWithSpecialNeedsReport from './Modules/Report/SessionAttendeesWithSpecialNeedsReport';
import EventAttendedList from './Modules/SuperAdmin/EventAttendedList';
import PostBlockCategoryList from './Modules/SuperAdmin/PostBlockCategory';
import PostBlockCategoryCreate from './Modules/SuperAdmin/PostBlockCategory/Create';
import AppVersionUpdate from './Modules/SuperAdmin/HomeManagment/AppVersionUpdate';
import PushNotification from './Modules/SuperAdmin/HomeManagment/PushNotification';
import PushNotificationCreate from './Modules/SuperAdmin/HomeManagment/PushNotification/Create';
import InquiryList from './Modules/SuperAdmin/InquiryList';
import Blogs from './pages/WebsiteHome/Blogs';
import Viewblogs from './pages/WebsiteHome/Blogs/Viewblogs';
import EventPromotionList from './Modules/SuperAdmin/HomeManagment/EventPromotion';
import EventPromotionCreate from './Modules/SuperAdmin/HomeManagment/EventPromotion/create';
import WithoutLoginUpdatePassword from './pages/WithoutLoginUpdatePassword';
import ViewUserAnswer from './Modules/Report/LeadsReport/ViewUserAnswer';
import OurProducts from './pages/WebsiteHome/OurProducts';
import ProfileForConnection from './Modules/user/ProfileForConnection';
import TemplateList from './Modules/SuperAdmin/Template';
import CreateTemplate from './Modules/SuperAdmin/Template/create';
import SendTemplateUserList from './Modules/TemplateToUser';
import ViewProduct from './pages/WebsiteHome/OurProducts/ViewProduct';
import HomeConferenceList from './pages/WebsiteHome/ConferenceList';
import SubscriptionReceipt from './Modules/SuperAdmin/ExhibitorSubscriptionList/SubscriptionReceipt';
import Dashboard from './Modules/CRM/Dashboard/index';
import ContactList from './Modules/CRM/ContactList';
import ContactCard from './Modules/CRM/ContactCard';
import ContactEdit from './Modules/CRM/ContactEdit';
import Pipeline from './Modules/CRM/Pipeline';
import ProjectsBoard from './Modules/CRM/ProjectsBoard';
import TaskList from './Modules/CRM/TaskList';
import CreateInvoice from './Modules/CRM/CreateInvoice/index';
import InvoicePreview from './Modules/CRM/InvoicePreview/index';
import ConferenceFeedbackQuestion from './Modules/SuperAdmin/ConferenceFeedbackQuestion';
import ConferenceFeedbackQuestionDiscount from './Modules/SuperAdmin/ConferenceFeedbackQuestion/Create';
import UserConferenceFeedBackForm from './Modules/UserConferenceFeedBackForm';
import ConferenceEchibitorRegister from './Modules/ConferenceRegistration/Exhibitor';
import ConferenceFeedbackReport from './Modules/Report/ConferenceFeedbackReport';
import GroupRegistration from './Modules/ConferenceRegistration/GroupRegistration';
import UserConferenceVideoAudioClipUploadForm from './Modules/UserConferenceVideoAudioClipUploadForm';
import BoothApproval from './Modules/SuperAdmin/BoothApproval';
import FloorPlanApproval from './Modules/SuperAdmin/BoothApproval/FloorPlanApproval/index';
import ConferenceTestimonialVideoOrAudioReport from './Modules/Report/ConferenceTestimonialVideoOrAudioReport';
import ConferenceTestimonialVideoOrAudioList from './Modules/SuperAdmin/ConferenceTestimonialVideoOrAudioList';
import MYQRCodes from './Modules/MyQRCodes';
import SpeakerPublicProfile from './Modules/Speaker/SpeakerPublicProfile';
import SpeakerProfileUpdate from './Modules/Speaker/SpeakerProfile';
import AssociationMembershipReport from './Modules/Report/AssociationMembershipReport';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import AssociationProductDiscountCreate from './Modules/SuperAdmin/MemberManagement/Association/Product/Discount/create';
import AssociationProductDiscountList from './Modules/SuperAdmin/MemberManagement/Association/Product/Discount';
import UnregisterUserReport from './Modules/Report/UnregisterUserReport';
import UnregisterUserReceipt from './Modules/Report/UnregisterUserReport/Receipt';
import ConferenceBoothsReport from './Modules/Report/ConferenceBoothsReport';
import AppVideo from './pages/AppVideo';

function OldProfileRedirect() {
  const { id, conf } = useParams(); // Extract the ID from the URL
  if (conf) {

    return <Navigate to={`/exhibitor-profile/${id}/${conf}`} />; // Redirect to the new URL
  } else {

    return <Navigate to={`/exhibitor-profile/${id}`} />; // Redirect to the new URL
  }
}

function AllRoutes() {
  return (
    <Router>
      <Routes>
        {/* start main home pages */}
        <Route path='/app-video' element={<AppVideo />} />
        <Route path='/' element={<WebsiteHome />} />
        <Route path='/conference' element={<HomeConferenceList />} />
        <Route path='/conference-detail' element={<ConferencesDetail />} />
        <Route path='/conference-floor-plan' element={<><ConferenceUserFloorPlan /></>} />
        <Route path='/conference-floor/:name/:id/:exhi?/:name?' element={<ConferenceUserFloorPlan hideNav={true} />} />
        <Route path='/how-it-works' element={<HowItWorks />} />
        <Route path='/blogs' element={<Blogs />} />
        <Route path="/blog/:id" element={<Viewblogs />} />
        <Route path="/our-products" element={<OurProducts />} />
        <Route path="/our-products/:id" element={<ViewProduct />} />
        {/* start privacy policy */}
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        {/* end privacy policy */}
        {/* end main home pages */}

        {/* start login reset passowors, forgot password */}
        <Route element={<Login />} exact path='/login' />
        <Route element={<Login />} path='/token/:id' />
        <Route element={<Login />} path='/speaker-login/:id' />
        <Route path='/register' element={<Register />} />
        <Route path='/forgot-passowrd' element={<ForgotPassword />} />
        <Route path='/resetpassword/:email' element={<ResetPassword />} />
        <Route path='/reset-password/:email' element={<ResetPassword />} />
        {/* reset speaker password  */}
        {/* end login reset passowors, forgot password */}

        {/* start SuperAdmin  */}
        <Route element={<PrivateRoute />} >
          <Route path='/home' element={<AdminDashboard />} />
          {/* start conference create, upadte, approve , sponsor , event , session, floor plan edit, discount  */}
          <Route path='/conference-list' element={<><ConferenceListMain /></>} />
          <Route path='/conference-setup-process' element={<><ConferenceSetupProcess /></>} />
          <Route path='/conference-setup-process/:id' element={<><ConferenceSetupProcess /></>} />
          <Route path='/conference-approval' element={<><ConferenceApproval /></>} />
          <Route path='/conference-sponsors' element={<><ConferenceSponsor /></>} />
          <Route path='/event-list' element={<><EventListMain /></>} />
          <Route path='/event-create' element={<><EventCreate /></>} />
          <Route path='/event-edit/:id' element={<><EventCreate /></>} />
          <Route path='/sessions' element={<><ConferenceClasses /></>} />
          <Route path='/session-create' element={<><SessionCreate /></>} />
          <Route path='/session-edit/:id' element={<><SessionCreate /></>} />
          <Route path='/floor-plan/edit/:id' element={<><FloorPlanEdit /></>} />
          <Route path='/discount/:cid' element={<><DiscountList /> </>} />
          <Route path='/discount/:cid/create' element={<><DiscountCreate /> </>} />
          <Route path='/discount/:cid/edit/:id' element={<><DiscountCreate /> </>} />
          {/* end conference create approve , sponsor , event , session, floor plan edit, discount */}

          {/* start user create and update */}
          <Route path='/users' element={<><UserRegisterationList /></>} />
          <Route path='/user-create' element={<><EditUser /></>} />
          <Route path='/user-edit/:id' element={<><EditUser /></>} />
          {/* end user create and update */}

          {/* start menu create , update and assign */}
          <Route path='/menus' element={<><MenuList /></>} />
          <Route path='/menu-create' element={<><MenuCreate /></>} />
          <Route path='/menu-edit/:id' element={<><MenuCreate /></>} />
          <Route path='/menu-access' element={<><AccesssManagement /></>} />
          {/* end menu create , update and assign */}

          {/* Start speaker create and update */}
          <Route path='/speakers' element={<><SpeakerList /></>} />
          <Route path='/speaker-create' element={<><SpeakerCreate /></>} />
          <Route path='/speaker-edit/:id' element={<><SpeakerCreate /></>} />
          {/* end speaker create and update */}

          {/* start subcription create update and buy from exhibitor */}
          <Route path='/subscription-price' element={<><SubscriptionPriceList /> </>} />
          <Route path='/subscription-price/create' element={<><SubscriptionPriceCreate /> </>} />
          <Route path='/subscription-price/edit/:id' element={<><SubscriptionPriceCreate /> </>} />
          <Route path='/subscription/:name' element={<><Subcription /> </>} />
          <Route path='/subscription/:name/:id' element={<><SubscriptionBuy /> </>} />
          {/* end subcription create update and buy from exhibitor */}

          {/* start exhibitor subscription list and receipt */}
          <Route path='/exhibitor-subscription-list' element={<><ExhibitorSubscriptionList /> </>} />
          <Route path='/exhibitor-subscription-list/:id' element={<><SubscriptionReceipt /> </>} />
          {/* end exhibitor subscription list and receipt */}

          {/* start gellery create, update */}
          <Route path='/gallery' element={<><Gallery /></>} />
          <Route path='/gallery-create' element={<><GalleryCreate /></>} />
          <Route path='/gallery-edit/:id' element={<><GalleryCreate /></>} />
          {/* end gellery create, update */}

          {/* start about  update */}
          <Route path='/home-management/about' element={<><About /></>} />
          {/* end about  update */}

          {/* start app version  update */}
          <Route path='/app-version-update' element={<><AppVersionUpdate /></>} />
          {/* end app version  update */}

          {/* start push notification */}
          <Route path='/push-notification' element={<><PushNotification /></>} />
          <Route path='/push-notification/create' element={<><PushNotificationCreate /></>} />
          <Route path='/push-notification/edit/:id' element={<><PushNotificationCreate /></>} />
          {/* end push notification */}

          {/* start booth approval */}
          <Route path='/booth-approval' element={<><BoothApproval /></>} />
          <Route path='/booth-approval/:id/:userId/:pId' element={<><FloorPlanApproval /></>} />
          {/* end booth approval */}

          {/* start invoice approval */}
          <Route path='/invoice-approval' element={<><InVoiceApproval /> </>} />
          {/* end invoice approval */}

          {/* start memnber invoice approval */}
          <Route path='/association-members-invoice-approval' element={<><GroupInvoiceApproval /> </>} />
          <Route path='/association-members-invoice-approval/edit/:id' element={<><GroupRegisterEdit /> </>} />
          {/* <Route path='/association-members-invoice-approval/edit/:id' element={<PrivateRoute><GroupInvoiceEdit /> </PrivateRoute>} /> */}
          <Route path='/association-members-invoice-approval/view/:id' element={<><GroupInvoiceView /> </>} />
          <Route path='/association-members-invoice-approval/view/:id/:name' element={<><GroupInvoiceView /> </>} />
          {/* end member invoice approval */}

          {/* start block post report list  */}
          <Route path='/block-report-list' element={<><BlockReportList /> </>} />
          {/* end block post report list  */}

          {/* start block post category */}
          <Route path='/post-block-category' element={<><PostBlockCategoryList /> </>} />
          <Route path='/post-block-category/create' element={<><PostBlockCategoryCreate /> </>} />
          <Route path='/post-block-category/edit/:id' element={<><PostBlockCategoryCreate /> </>} />
          {/* end block post category */}



          {/* end SuperAdmin  */}

          {/* start Exhibitor */}
          <Route path='/exhibitor-dashboard' element={<><ExhibitorDashboard /></>} />
          <Route path='/exhibitor' element={<><ExhibitorProfile /></>} />
          <Route path='/exhibitor-product-view/:id' element={<><ProductDetailPublic /></>} />

          {/* start sales representative */}
          <Route path='/vendor-create' element={<><VendorCreate /></>} />
          <Route path='/vendor-edit/:id' element={<><VendorCreate /></>} />
          <Route path='/sales-representative' element={<><SalesRepresentative /></>} />
          {/* end sales representative */}

          {/* start Product create and update */}
          <Route path='/product-list' element={<><ProductList /></>} />
          <Route path='/product-create' element={<><ProductCreate /></>} />
          <Route path='/product-edit/:id' element={<><ProductCreate /></>} />
          {/* end Product create and update */}

          {/* start lead trecking  */}
          <Route path='/company-lead-tracking-updates' element={<><CompanyLead /> </>} />
          {/* end lead trecking  */}

          {/* start lead trecking dashboard  */}
          {/* <Route path='/lead-tracking-dashboard' element={<PrivateRoute><LeadTrackDashboard /></PrivateRoute>} /> */}
          {/* end lead trecking dashboard */}

          {/* end Exhibitor */}

          {/* start speaker */}
          <Route path='/speaker-profile' element={<><SpeakerProfileUpdate /></>} />
          {/* end speaker */}

          {/* start conference register and payment success */}
          <Route path='/conference-user-register/:id' element={<><StepRegister /></>} />
          <Route path='/conference-exhibotor-register/:id' element={<><ConferenceEchibitorRegister /></>} />
          {/* end conference register  and payment success */}

          {/* start company create and update  */}
          <Route path='/company' element={<><Company /></>} />
          <Route path='/company/:name/:id?' element={<><CreateCompany /></>} />
          {/* end company create and update  */}

          {/* start payment history, pending */}
          <Route path='/payment-history' element={<><PaymentHistory /></>} />
          <Route path='/stripe-recipe/:name/:id' element={<><StripeRecipe /></>} />
          {/* end payment history, pending */}

          {/* start Attendee pages */}
          <Route path='/attendee-dashboard' element={<><AttendeeDashboard /></>} />
          <Route path='/update-password' element={<><UpdatePassword /> </>} />
          <Route path='/my-qr-codes' element={<><MYQRCodes /> </>} />
          {/* end Attendee pages */}

          {/* start register history for all users */}
          <Route path='/register-history' element={<><MyRegisterList /></>} />
          {/* end register history for all users */}

          {/* start member regitster conference */}
          <Route path='/conference-group-register' element={<><GroupRegistration /> </>} />
          <Route path='/register-payment-success/:id/:amt' element={<><RegisterMultiPaymentSuccess /> </>} />
          {/* end member regitster conference */}

          {/* start conference calender */}
          <Route path='/conference-calendar' element={<><ConferenceCalendar /></>} />
          {/* end conference calender */}

          {/* start session attend approval and session attended list for SuperAdmin  */}
          <Route path='/sessions-attend' element={<><SessionAttend /></>} />
          <Route path='/attended-list' element={<><SessionAttendedList /></>} />
          <Route path='/event-attended-list' element={<><EventAttendedList /></>} />
          {/* end session attend approval and session attended list for SuperAdmin  */}

          {/* start certificate */}
          <Route path='/certificate/:userid/:sessionid/:name' element={<><CertificateUserNew /> </>} />
          {/* end certificate */}

          {/* start community messages */}
          <Route path='/community' element={<><NewCommunity /></>} />
          <Route path='/messages' element={<><Messages /> </>} />
          <Route path='/messages/:id' element={<><Messages /> </>} />
          {/* end community messages */}

          {/* start email preference */}
          <Route path='/email-preference' element={<><EmailPreference /> </>} />
          {/* end email preference */}

          {/* start member management */}

          {/* start association create update */}
          <Route path='/association' element={<><Association /> </>} />
          <Route path='/association/:name/:id?' element={<><AssociationCreate /> </>} />
          {/* association store */}
          <Route path='/association/:asid/store' element={<><AssociationStore /> </>} />
          <Route path='/association/:asid/store/:sname/:id?' element={<><AssociationStoreCreate /> </>} />
          <Route path='/association/:asid/store/:stId/product' element={<><AssociationProduct /> </>} />
          <Route path='/association/:asid/store/:stId/product/:pname/:id?' element={<><AssociationProductCreate /> </>} />
          <Route path='/association/:asid/store/:stId/product/:prdId/discount' element={<AssociationProductDiscountList />} />
          <Route path='/association/:asid/store/:stId/product/:prdId/discount/:dname/:id?' element={<AssociationProductDiscountCreate />} />
          <Route path='/association/store/user' element={<><AssociationStoreForUser /> </>} />
          <Route path='/association/store/user/:id' element={<><StoreDetails /> </>} />
          <Route path='/association/store/user/:id/view/:pId' element={<><AssociationProductDetail /> </>} />
          <Route path='/association/store/user/:id/product-cart' element={<><ProductCart /> </>} />

          <Route path='/association-product-history' element={<><AssociationProductPaymentHistory /> </>} />
          <Route path='/association-product-history/reciept/:id' element={<><AssociationProductStripeRecipe /> </>} />
          <Route path='/association-product-invoice-approval' element={<><AssociationProductInvoiceApproval /> </>} />
          {/* association store */}

          {/* end association create update */}

          {/* start association member create update */}
          <Route path='/state-agencies' element={<><AssociationMemberList /> </>} />
          <Route path='/state-agencies/:name/:id?' element={<><AssociationMemberCreate /> </>} />
          <Route path='/lea-members' element={<><AssociationMemberList /> </>} />
          <Route path='/lea-members/:name/:id?' element={<><AssociationMemberCreate /> </>} />
          <Route path='/industry-members' element={<><AssociationMemberList /> </>} />
          <Route path='/industry-members/:name/:id?' element={<><AssociationMemberCreate /> </>} />
          {/* end association member create update */}

          {/* assign user to association , state agency, lea member, industry member */}
          <Route path='/assign-user' element={<><AssignList /> </>} />

          {/* start user assocition */}
          <Route path='/association-list' element={<><UserAssociation /> </>} />
          <Route path='/association-list/pay/:id' element={<><AssociationRegisterPay /> </>} />
          <Route path='/association-list/payment-success/:id/:amt' element={<><UserAssociationPaymentSuccess /> </>} />
          <Route path='/association-invoice-list' element={<><UserAssociationInvoiceApproval /> </>} />
          <Route path='/association-invoice-list/edit/:id/:invoiceId' element={<><EditAssociationRegisterPay /> </>} />
          <Route path='/association-invoice-list/view/:id' element={<><ViewAssociationUserInvoice /> </>} />
          <Route path='/association-payment-history' element={<><UserAssociationPaymentHistory /> </>} />
          <Route path='/association-stripe-recipe/:id' element={<><UserAssociationStripeRecipe /> </>} />
          {/* end user association */}

          {/* end member management */}

          {/* start invoice management create update */}
          <Route path='/invoice-list' element={<><InvoiceList /> </>} />
          <Route path='/invoice-edit/:id' element={<><InvoiceEdit /> </>} />
          <Route path='/invoice-view/:id' element={<><InvoiceView /> </>} />
          {/* end invoice management create update */}

          {/* Attendee Schedule */}
          <Route path='/attendee-schedule' element={<><AttendeeSchedule /> </>} />
          <Route path='/my-schedule' element={<><MySchedule /> </>} />
          <Route path='/user-qr-scan' element={<><UserQRScan /> </>} />
          <Route path='/pay-to-schedule/:id' element={<><PayToSchedule /> </>} />

          {/*  Attendee Schedule */}

          {/* start  report */}
          <Route path='/group-ceu-report' element={<><GroupCEUReport /> </>} />
          <Route path='/conference-register-list' element={<><ConferenceRegisterUser /></>} />
          <Route path='/attendee-registration-report' element={<><AttendeeRegistration /> </>} />
          <Route path='/association-product-sales-report' element={<><AssociationProductSalesReport /> </>} />
          <Route path='/attendee-summary-report' element={<><AttendeeSummaryReport /> </>} />
          <Route path='/session-attendees-with-special-needs-report' element={<><SessionAttendeesWithSpecialNeedsReport /> </>} />
          <Route path='/leads-report' element={<><LeadsReport /> </>} />
          <Route path='/leads-report/view' element={<><ViewUserAnswer /> </>} />
          <Route path='/conference-feedback-report' element={<><ConferenceFeedbackReport /></>} />
          <Route path='/conference-testimonial-video-audio-report' element={<><ConferenceTestimonialVideoOrAudioReport /></>} />
          <Route path='/association-membership-report' element={<><AssociationMembershipReport /></>} />
          <Route path='/unregister-user-report' element={<UnregisterUserReport />} />
          <Route path='/unregister-user-report/receipt/:type/:id' element={<UnregisterUserReceipt />} />
          <Route path='/conference-booths-report' element={<ConferenceBoothsReport />} />
          {/* end report */}

          {/* start conference testimonial video audio list for users to see and delete */}
          <Route path='/conference-testimonial-video-audio-list' element={< ><ConferenceTestimonialVideoOrAudioList /></>} />
          {/* end conference testimonial video audio list for users to see and delete */}

          {/* Start Qualifying Question for Non Fig Events */}
          <Route path='/qualifying-question' element={<><QualifyingQuestionforNonFigEvents /> </>} />
          <Route path='/qualifying-question/create' element={<><QualifyingQuestionforNonFigEventsCreate /> </>} />
          <Route path='/qualifying-question/edit/:id' element={<><QualifyingQuestionforNonFigEventsCreate /> </>} />
          {/* End Qualifying Question for Non Fig Events */}

          {/* start inquiry */}
          <Route path='/inquiry-list' element={<InquiryList />} />
          {/* end inquiry */}

          {/* start Event Promotion */}
          <Route path='/event-promotion' element={<><EventPromotionList /> </>} />
          <Route path='/event-promotion/:name/:id?' element={<><EventPromotionCreate /> </>} />
          {/* end Event Promotion */}

          {/* start Template*/}
          <Route path='/template' element={<><TemplateList /> </>} />
          <Route path='/template/create' element={<><CreateTemplate /> </>} />
          <Route path='/template/edit/:id' element={<><CreateTemplate /> </>} />
          {/* end Template*/}

          {/* start send email template to user */}
          <Route path='/send-template-to-user' element={<><SendTemplateUserList /> </>} />
          {/* end send email template to user */}

          {/* start user connection profile */}
          <Route path='/user-connection-profile/:id' element={<><ProfileForConnection /> </>} />
          {/* end user connection profile */}

          {/* start ConferenceFeedbackQuestion */}
          <Route path='/conference-feedback-question' element={<><ConferenceFeedbackQuestion /> </>} />
          <Route path='/conference-feedback-question/:name/:id?' element={<><ConferenceFeedbackQuestionDiscount /> </>} />
          {/* end ConferenceFeedbackQuestion */}

        </Route>

        {/* start Exhibitor */}
        <Route path='/exhibitor-profile/:id?/:conf?/:name?' element={<PublicRoute><ExhibitorProfilePublic /></PublicRoute>} />
        <Route path='/exhibitor-profile-new/:id/:conf?' element={<OldProfileRedirect />} />
        <Route path='/exhibitor-product-detail/:id/:conf?' element={<PublicRoute><ProductDetailPublic /></PublicRoute>} />
        <Route path='/exhibitor-company-create' element={<PublicRoute><CompanyForm /></PublicRoute>} />
        {/* end Exhibitor */}

        {/* start Attendee pages */}
        <Route path='/attendee-profile' element={<PublicRoute><AttendeeProfile /></PublicRoute>} />
        <Route path='/attendee-profile/view/:id' element={<PublicRoute><AttendeeProfile /></PublicRoute>} />
        {/* end Attendee pages */}

        {/* start speaker profile */}
        <Route path='/speaker-public-profile/:id?' element={<PublicRoute><SpeakerPublicProfile /></PublicRoute>} />
        {/* end speaker profile */}

        {/* start certificate */}
        <Route path='/certificated/app/:userid/:sessionid/:name' element={<CertificateUserNew />} />
        {/* end certificate */}

        {/* Start Without Login Password Page */}
        <Route path='/new-user/:id' element={<WithoutLoginUpdatePassword />} />
        {/* End Without Login Password Page */}


        {/* start user ConferenceFeedback form  */}
        <Route path='/conference-user-feedback/:id?/:uId?' element={<UserConferenceFeedBackForm />} />
        <Route path='/conference-user-videoaudioclip/:id?/:uId?' element={<UserConferenceVideoAudioClipUploadForm />} />
        {/* end user ConferenceFeedback form */}


        <Route path='*' element={<Navigate to={"/"} />} />
        {/*  not using routes List */}

        {/* start badge printing */}
        {/* <Route path='/badge-printing' element={<PrivateRoute><BadgePrinting /> </PrivateRoute>} /> */}
        {/* end badge printing */}

        {/* discountcode assign to user hide */}
        {/* <Route path='/payment-success' element={<PrivateRoute><PaymentSuccess /></PrivateRoute>} /> */}

        {/* <Route path='/exhibitor-product-view/:id' element={<PrivateRoute><ProductDetail /></PrivateRoute>} /> */}

        {/* <Route path='/event-organizer-dashboard' element={<PrivateRoute><EventOrganizerDashboard /></PrivateRoute>} /> */}
        {/* <Route path='/register-list' element={<PrivateRoute><RegisterList /></PrivateRoute>} /> */}

        {/* CRM start */}
        {/* <Route path='/dashboard' element={<PrivateRoute><Dashboard /></PrivateRoute>} /> */}
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/contact-list' element={<ContactList />} />
        <Route path='/contact-card' element={<ContactCard />} />
        <Route path='/contact-edit' element={<ContactEdit />} />
        <Route path='/pipeline' element={<Pipeline />} />
        <Route path='/projects-board' element={<ProjectsBoard />} />
        <Route path='/invoice-list' element={<InvoiceList />} />
        <Route path='/task-list' element={<TaskList />} />
        <Route path='/create-invoice' element={<CreateInvoice />} />
        <Route path='/invoice-preview' element={<InvoicePreview />} />
        {/* CRM end */}

        {/* {loggedIn ? <Route path='*' element={<Navigate to={"/home"} />} /> : <Route path='*' element={<Navigate to={"/"} />} />} */}
      </Routes>
    </Router>
  )
}

function App() {
  const [mes, setMes] = useState()
  const [open, setOpen] = useState(true)
  useEffect(() => {
    getSite();
  }, [])
  const getSite = async () => {
    try {
      let res = await axios.get(`http://apieventplan.vidhaninfotech.com/api/Common/GetSiteOnOffStatus`)
      setOpen(false)
      if (res?.status === 200) {
        if (!res?.data?.success) {
          setMes(res?.data?.message)
        }
      }
    } catch (error) {
      console.log(error);
      setOpen(false);
    }
  }

  return (
    <>
      {open || mes ?
        open ?
          <div className="loader">
            <CircularProgress sx={{ color: "#86c65a" }} />
          </div> :
          <div
            style={{ height: '100vh', display: "flex", justifyContent: "center", alignItems: "center" }}
          >
            <h1>
              {mes}
            </h1>
          </div>
        :
        <AllRoutes />
      }
    </>
  );
}

export default App;
