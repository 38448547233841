import React, { Suspense } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import CRMHeader from "../Shared/CRMHeader";
import { MdContacts } from "react-icons/md";
import { FaRegStar } from "react-icons/fa";
import { BsWindow } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";
import { BsTrash3 } from "react-icons/bs";
import { LuSliders } from "react-icons/lu";
import { BsUpload } from "react-icons/bs";
import { MdOutlineModeEdit } from "react-icons/md";
import { FiPlusSquare } from "react-icons/fi";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { RxCross2 } from "react-icons/rx";
import { Button, CircularProgress } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { TfiImport } from "react-icons/tfi";
import { TfiExport } from "react-icons/tfi";
import { CiSettings } from "react-icons/ci";
import { LuArchive } from "react-icons/lu";
import { LuBook } from "react-icons/lu";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

function CreateInvoice() {
  const [openSide, setOpenSide] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <CRMHeader />
      <Suspense fallback={renderLoader()}>
        <section className="contactlist-section">
          <div className="container-fluid">
            <div className="row">
              <div
                className={`${
                  openSide ? "" : "hide_side"
                } col-lg-2 col-md-4 contact-border`}
              >
                <div className="cotact-left-section">
                  <Link to="" className="primary-btn btn-border w-100">
                    Create
                  </Link>
                  <div className="menu-group">
                    <ul>
                      <li>
                        <Link className="nav-link">
                          <MdContacts /> All Invoices
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <FaRegStar /> Sent
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <BsWindow /> Archived
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <FaRegEdit /> Pending
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <BsTrash3 /> Deleted
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="separator separator-light"></div>
                  <div className="menu-group">
                    <ul>
                      <li className="menu-title">Manage</li>
                      <li>
                        <Link className="nav-link">
                          <TfiExport /> Manage Invoices
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <TfiImport /> Recurring Invoices
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <BsWindow /> Manage Contacts
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <BsWindow /> Saved Templates
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="separator separator-light"></div>
                  <div className="menu-group">
                    <ul>
                      <li className="menu-title">Info</li>
                      <li>
                        <Link className="nav-link">
                          <MdContacts /> Business Info
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <BsWindow /> Tax Info
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="separator separator-light"></div>
                </div>
                <div className="contactapp-fixednav">
                  <div className="menu-group">
                    <ul>
                      <li>
                        <Link>
                          <CiSettings />
                        </Link>
                      </li>
                      <li>
                        <Link>
                          <LuArchive />
                        </Link>
                      </li>
                      <li>
                        <Link>
                          <LuBook />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  openSide ? "col-lg-10 col-md-8" : "col-lg-12"
                } contact-right-side`}
              >
                <div className="togglebar">
                  <div
                    className="sidebar-togglable"
                    onClick={() => setOpenSide(!openSide)}
                  >
                    <MdKeyboardArrowLeft />
                  </div>
                </div>
                <div className="top-contact">
                  <h3>Standard Template</h3>
                  <div className="standard-right">
                    <Link className="filter-icon">
                      <LuSliders />
                    </Link>
                    <Link to="/invoice-preview" className="perview-btn me-3">
                      Perview
                    </Link>
                    <Link className="primary-btn">Save</Link>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-9">
                    <div className="invoice-section">
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="upload-logo">
                            <div className="dropify-wrapper">
                              <span className="file-icon">
                                <BsUpload />
                              </span>
                              <p>Upload Logo</p>
                              <input type="file" className="dropify-2" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="d-flex align-items-center gap-3 justify-content-end">
                            <Link className="invoice-icon">
                              <MdOutlineModeEdit />
                            </Link>
                            <h2>Invoice</h2>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-5">
                        <div className="col-lg-4">
                          <Link className="primarysky-color">
                            - Your business information
                          </Link>
                          <div className="address-wrap">
                            <h6>Hencework</h6>
                            <p>4747, Pearl Street</p>
                            <p>Rainy Day Drive, </p>
                            <p>Washington DC 42156</p>
                            <p>jampack_01@hencework.com</p>
                          </div>
                          <Link className="primarysky-color">
                            <MdOutlineModeEdit /> Edit Info
                          </Link>
                        </div>
                        <div className="col-lg-8 text-end">
                          <form>
                            <div className="row mb-3 justify-content-end">
                              <div className="col-md-3 from-group">
                                <input
                                  className="form-control"
                                  placeholder="Invoice No*"
                                  type="text"
                                />
                              </div>
                              <div className="col-md-3 from-group">
                                <input
                                  className="form-control"
                                  placeholder="0001"
                                  type="text"
                                />
                              </div>
                            </div>
                            <div className="row mb-3 justify-content-end">
                              <div className="col-md-3 from-group">
                                <input
                                  className="form-control"
                                  placeholder="Invoice Date*"
                                  type="text"
                                />
                              </div>
                              <div className="col-md-3 from-group">
                                <input
                                  className="form-control"
                                  placeholder="24/2/2020"
                                  type="text"
                                />
                              </div>
                            </div>
                            <div className="row mb-3 justify-content-end">
                              <div className="col-md-3 from-group">
                                <input
                                  className="form-control"
                                  placeholder="Due date*"
                                  type="text"
                                />
                              </div>
                              <div className="col-md-3 from-group">
                                <select className="form-select">
                                  <option selected="">Due on Reciept</option>
                                  <option value="1">One</option>
                                  <option value="2">Two</option>
                                  <option value="3">Three</option>
                                </select>
                              </div>
                            </div>
                            <div className="row mb-3 justify-content-end">
                              <div className="col-md-3 from-group">
                                <input
                                  className="form-control"
                                  placeholder="Customer No"
                                  type="text"
                                />
                              </div>
                              <div className="col-md-3 from-group">
                                <input
                                  className="form-control"
                                  placeholder="32321"
                                  type="text"
                                />
                              </div>
                            </div>
                            <Link to={""} className="primarysky-color">
                              <FiPlusSquare className="me-2" /> Add more fields
                            </Link>
                          </form>
                        </div>
                      </div>
                      <div className="separator separator-light"></div>
                      <div className="row">
                        <div className="col-xxl-3 mb-xxl-0 mb-4">
                          <h6>Billed To</h6>
                          <form>
                            <div className="form-group">
                              <select className="form-select">
                                <option selected="">
                                  Supernova consultant
                                </option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                            </div>
                            <Link
                              to={""}
                              className="primarysky-color"
                              onClick={handleOpen}
                            >
                              <FiPlusSquare className="me-2" /> Add new client
                            </Link>
                          </form>
                        </div>
                        <div className="col-xxl-4 offset-xxl-5">
                          <h6>Ship To</h6>
                          <div className="row gx-3">
                            <div className="col-sm-12 form-group">
                              <input
                                className="form-control"
                                placeholder="Client business name"
                                type="text"
                              />
                            </div>
                            <div className="col-sm-12 form-group">
                              <input
                                className="form-control"
                                placeholder="Address"
                                type="text"
                              />
                            </div>
                            <div className="col-lg-6 form-group">
                              <input
                                className="form-control"
                                placeholder="City"
                                type="text"
                              />
                            </div>
                            <div className="col-lg-6 form-group">
                              <input
                                className="form-control"
                                placeholder="Postal Code"
                                type="text"
                              />
                            </div>
                            <div className="col-sm-12 form-group">
                              <input
                                className="form-control"
                                placeholder="State"
                                type="text"
                              />
                            </div>
                            <div className="col-sm-12 form-group">
                              <input
                                className="form-control"
                                placeholder="Country"
                                type="text"
                              />
                            </div>
                            <div className="col-sm-12 form-group">
                              <input
                                className="form-control"
                                placeholder="GSTIN Enter GSTIN here(optional)"
                                type="text"
                              />
                            </div>
                            <Link to={""} className="primarysky-color">
                              <FiPlusSquare className="me-2" /> Add shipping
                              address
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-5">
                        <div className="col-sm">
                          <form className="form-inline p-3 bg-grey-light-5 rounded">
                            <div className="row gx-3 align-items-center">
                              <div className="col-xl-auto mb-xl-0 mb-2">
                                <label className="form-label mb-xl-0">
                                  Filters
                                </label>
                              </div>
                              <div className="col-lg-3 mb-xl-0 mb-2">
                                <select className="form-select w-100">
                                  <option selected="">Number format</option>
                                  <option value="1">One</option>
                                  <option value="2">Two</option>
                                  <option value="3">Three</option>
                                </select>
                              </div>
                              <div className="col-lg-3 mb-xl-0 mb-2">
                                <select className="form-select w-100">
                                  <option selected="">
                                    Add/Remove columns
                                  </option>
                                  <option value="1">One</option>
                                  <option value="2">Two</option>
                                  <option value="3">Three</option>
                                </select>
                              </div>
                              <div className="col-lg-3">
                                <select className="form-select">
                                  <option selected="">US Dollar ($ USD)</option>
                                  <option value="1">One</option>
                                  <option value="2">Two</option>
                                  <option value="3">Three</option>
                                </select>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="table-wrap mt-5">
                        <div className="invoice-table-wrap">
                          <table className="table table-bordered invoice-table">
                            <thead className="thead-primary">
                              <tr>
                                <th>Item</th>
                                <th>Quantity</th>
                                <th>Price</th>
                                <th colSpan="2">Discount</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="table-row-gap">
                                <td></td>
                              </tr>
                              <tr>
                                <td className="w-50 rounded-top-start border-end-0 border-bottom-0">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value="Redesiging of agencyclick.com"
                                  />
                                </td>
                                <td className="border-end-0 border-bottom-0">
                                  <input
                                    type="text"
                                    className="form-control qty"
                                    value="1"
                                  />
                                </td>
                                <td className="w-15 border-end-0 border-bottom-0">
                                  <input
                                    type="text"
                                    className="form-control price"
                                    value="150.00"
                                  />
                                </td>
                                <td className="border-end-0 border-bottom-0">
                                  <input
                                    type="text"
                                    className="form-control discount w-60p"
                                    value="2"
                                  />
                                </td>
                                <td className="border-end-0 border-bottom-0 disc-type">
                                  <select className="form-select ">
                                    <option value="1">%</option>
                                    <option value="2">₹</option>
                                  </select>
                                </td>
                                <td
                                  className="w-20  rounded-end  bg-primary-light-5 close-over position-relative"
                                  rowSpan="2"
                                >
                                  <input
                                    type="text"
                                    className="form-control bg-transparent border-0 p-0 total"
                                    value="147.00"
                                    readOnly=""
                                  />
                                  <button
                                    type="button"
                                    className="close-row btn-close"
                                  >
                                    <span aria-hidden="true">×</span>
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  colSpan="5"
                                  className="rounded-bottom-start border-end-0"
                                >
                                  <input
                                    type="text"
                                    className="form-control"
                                    value="This is my project description. if the line do not filt like the sentence is to big the area will start getting bigger"
                                  />
                                </td>
                              </tr>
                              <tr className="table-row-gap">
                                <td></td>
                              </tr>
                              <tr>
                                <td className="w-50 rounded-top-start border-end-0 border-bottom-0">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Item Title"
                                  />
                                </td>
                                <td className="border-end-0 border-bottom-0">
                                  <input
                                    type="text"
                                    className="form-control qty"
                                    placeholder="0"
                                  />
                                </td>
                                <td className="w-15 border-end-0 border-bottom-0">
                                  <input
                                    type="text"
                                    className="form-control price"
                                    placeholder="0"
                                  />
                                </td>
                                <td className="border-end-0 border-bottom-0">
                                  <input
                                    type="text"
                                    className="form-control discount w-60p"
                                    placeholder="0"
                                  />
                                </td>
                                <td className="border-end-0 border-bottom-0 disc-type">
                                  <select className="form-select ">
                                    <option value="1">%</option>
                                    <option value="2">₹</option>
                                  </select>
                                </td>
                                <td
                                  className="w-20  rounded-end  bg-primary-light-5 close-over position-relative"
                                  rowSpan="2"
                                >
                                  <input
                                    type="text"
                                    className="form-control bg-transparent border-0 p-0 total"
                                    placeholder="0.00"
                                    readOnly=""
                                  />
                                  <button
                                    type="button"
                                    className="close-row btn-close"
                                  >
                                    <span aria-hidden="true">×</span>
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  colSpan="5"
                                  className="rounded-bottom-start border-end-0"
                                >
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Item Description"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <Link to={""} className="primarysky-color">
                            <FiPlusSquare className="me-2" /> Add new item
                          </Link>
                        </div>
                      </div>
                      <div className="row justify-content-end">
                        <div className="col-xxl-6 mt-5">
                          <div className="table-wrap">
                            <div className="table-responsive">
                              <table className="table table-bordered subtotal-table">
                                <tbody>
                                  <tr>
                                    <td
                                      colSpan="3"
                                      className="rounded-top-start border-end-0 border-bottom-0"
                                    >
                                      Subtotal
                                    </td>
                                    <td className="rounded-top-end border-bottom-0 w-30 bg-primary-light-5">
                                      <input
                                        type="text"
                                        className="form-control bg-transparent border-0 p-0 gross-total"
                                        value="147.00"
                                        readOnly=""
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colSpan="3"
                                      className="border-end-0 border-bottom-0"
                                    >
                                      Item Discount
                                    </td>
                                    <td className="border-bottom-0  bg-primary-light-5">
                                      <input
                                        type="text"
                                        className="form-control bg-transparent border-0 p-0 gross-discount"
                                        value="2"
                                        readOnly=""
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="border-end-0 border-bottom-0">
                                      Extra Discount
                                    </td>
                                    <td className="border-end-0 border-bottom-0 w-25">
                                      <input
                                        type="text"
                                        className="form-control extdiscount"
                                        value="0"
                                      />
                                    </td>
                                    <td className="border-end-0 border-bottom-0 w-25">
                                      <select className="form-select extra-disc-type">
                                        <option selected="" value="1">
                                          %
                                        </option>
                                        <option value="2">₹</option>
                                      </select>
                                    </td>
                                    <td className="border-bottom-0  bg-primary-light-5">
                                      <input
                                        type="text"
                                        className="form-control bg-transparent border-0 p-0 extdiscount-read"
                                        value="0"
                                        readOnly=""
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colSpan="3"
                                      className="rounded-bottom-start border-end-0 bg-primary-light-5"
                                    >
                                      <span className="text-dark">Total</span>
                                    </td>
                                    <td className="rounded-bottom-end  bg-primary-light-5">
                                      <input
                                        type="text"
                                        className="form-control bg-transparent border-0 p-0 subtotal"
                                        value="147.00"
                                        readOnly=""
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-xxl-5 order-2 order-xxl-0">
                          <div className="form-group">
                            <div className="form-label-group">
                              <label className="form-label">
                                Note to client
                              </label>
                              <small className="text-muted">1400</small>
                            </div>
                            <textarea
                              className="form-control"
                              rows="6"
                              placeholder="Write an internal note"
                              spellCheck="false"
                            ></textarea>
                            <button className="note-btn mt-2">Add Note</button>
                          </div>
                        </div>
                        <div className="col-xxl-4 offset-xxl-3 text-xxl-end mb-xxl-0 mb-3">
                          <div className="btn-link btn-file pb-2">
                            <span className="d-inline-flex align-items-center">
                              <span className="primarysky-color">
                                <FiPlusSquare className="me-2" /> Add signature
                                (Optional)
                              </span>
                              <input type="file" className="upload" />
                            </span>
                          </div>
                          <Link className="primarysky-color">
                            <FiPlusSquare className="me-2" /> Add Name & Label
                          </Link>
                          <div
                            className="mt-5 collapse show"
                            id="label_collpase"
                          >
                            <div className="form-group close-over">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Katherine Zeta Jones"
                              />
                              <button
                                type="button"
                                className="close-input btn-close"
                              >
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div className="form-group close-over">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Co-founder Hencework"
                              />
                              <button
                                type="button"
                                className="close-input btn-close"
                              >
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="separator separator-light"></div>
                      <h6 className="mb-4">Terms &amp; Condition</h6>
                      <div className="repeater">
                        <div className="form-group close-over">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Please quote invoice number when remitting funds."
                          />
                          <button
                            type="button"
                            className="close-input btn-close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="form-group close-over">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Please quote invoice number when remitting funds."
                          />
                          <button
                            type="button"
                            className="close-input btn-close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <Link className="primarysky-color">
                          <FiPlusSquare className="me-2" /> Add New Term Row
                        </Link>
                      </div>
                      <div className="separator separator-light"></div>
                      <div className="btn btn-light btn-file mb-4">
                        Attach files
                        <input type="file" className="upload" />
                      </div>
                      <div className="my-2">
                        <Link className="primarysky-color">
                          <FiPlusSquare className="me-2" /> Add a personal memo
                        </Link>
                      </div>
                      <div className="row">
                        <div className="col-xxl-5">
                          <div className="form-group">
                            <div className="form-label-group">
                              <label className="form-label">
                                Personal Memo
                              </label>
                              <small className="text-muted">1400</small>
                            </div>
                            <textarea
                              className="form-control"
                              rows="6"
                              placeholder="Write an internal note"
                            ></textarea>
                            <button className="note-btn mt-2">Add Note</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="invoice-setting">
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          Currency
                        </AccordionSummary>
                        <div className="form-group mt-2">
                          <label className="form-label">Currency Symbol</label>
                          <select className="form-select">
                            <option selected="">US Dollar ($ USD)</option>
                            <option value="1">IND Rupees (₹ USD)</option>
                          </select>
                        </div>
                      </Accordion>
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          Typography
                        </AccordionSummary>
                        <div>
                          <div className="form-group mt-2">
                            <label className="form-label">Font</label>
                            <select className="form-select">
                              <option selected="">Arial</option>
                              <option value="1">Times New Roman</option>
                            </select>
                          </div>
                          <div className="form-group">
                            <label className="form-label">Size</label>
                            <select className="form-select">
                              <option selected="">16px</option>
                              <option value="1">20px</option>
                              <option value="2">24px</option>
                            </select>
                          </div>
                          <div className="form-group">
                            <label className="form-label">Align</label>
                            <div>
                              <div className="btn-group" role="group">
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary"
                                >
                                  <i className="fa fa-align-left primarysky-color"></i>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary"
                                >
                                  <i className="fa fa-align-center"></i>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary"
                                >
                                  <i className="fa fa-align-right"></i>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary"
                                >
                                  <i className="fa fa-align-justify"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion>
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3-content"
                          id="panel3-header"
                        >
                          Color
                        </AccordionSummary>
                        <div>
                          <div className="form-group mt-2">
                            <label className="form-label">Accent</label>
                            <div className="input-group color-picker">
                              <div className="input-group-text colorpicker-input-addon">
                                <input type="color" />
                              </div>
                              <input
                                type="text"
                                className="form-control colorpicker-value"
                                value="#007D88"
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="form-label">Heading Color</label>
                            <div className="input-group color-picker">
                              <div className="input-group-text colorpicker-input-addon">
                                <input type="color" />
                              </div>
                              <input
                                type="text"
                                className="form-control colorpicker-value"
                                value="#1F2327"
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="form-label">Text Color</label>
                            <div className="input-group color-picker">
                              <div className="input-group-text colorpicker-input-addon">
                                <input type="color" />
                              </div>
                              <input
                                type="text"
                                className="form-control colorpicker-value"
                                value="#646A71"
                              />
                            </div>
                          </div>
                        </div>
                      </Accordion>
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel4-content"
                          id="panel4-header"
                        >
                          Action
                        </AccordionSummary>
                        <div className="form-group mt-2">
                          <label className="form-label">Schedule send</label>
                          <input type="text" className="form-control" />
                        </div>
                      </Accordion>
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel5-content"
                          id="panel5-header"
                        >
                          Option
                        </AccordionSummary>
                        <div className="button-list">
                          <button className="btn btn-light mb-3">
                            Get Link
                          </button>
                          <button className="btn btn-light">
                            Download Invoice
                          </button>
                        </div>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>

      {/* Add deal popup */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="deal-popup"
      >
        <Box sx={style}>
          <div className="modal-head">
            <h5>Billed To</h5>
            <Link onClick={handleClose}>
              <RxCross2 />
            </Link>
          </div>
          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">Company Name</label>
                    <input
                      className="form-control task-name"
                      type="text"
                      placeholder="Supernova Consultants"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">Email ID</label>
                    <input
                      className="form-control task-name"
                      type="text"
                      placeholder="thompson_peter@super.co"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">Address Line One</label>
                    <input
                      className="form-control task-name"
                      type="text"
                      placeholder="Sycamore Street"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">Address Line Two</label>
                    <input
                      className="form-control task-name"
                      type="text"
                      placeholder="San Antonio Valley, CA 34668"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <Button
              type="submit"
              className="secondary-btn me-2"
              onClick={handleClose}
            >
              Discard
            </Button>
            <Button type="submit" className="primary-btn">
              Add
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default CreateInvoice;
