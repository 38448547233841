import React, { Suspense } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import CRMHeader from "../Shared/CRMHeader";
import { MdContacts } from "react-icons/md";
import { FaRegStar } from "react-icons/fa";
import { BsWindow } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";
import { BsTrash3 } from "react-icons/bs";
import { FaRegMessage } from "react-icons/fa6";
import { FaUserCheck } from "react-icons/fa6";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import { TfiImport } from "react-icons/tfi";
import { TfiExport } from "react-icons/tfi";
import { MdKeyboardArrowLeft } from "react-icons/md";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { RxCross2 } from "react-icons/rx";
import { BsUpload } from "react-icons/bs";
import { Button, CircularProgress } from "@mui/material";
import { IoIosList } from "react-icons/io";
import { LuRefreshCw } from "react-icons/lu";
import { RiSettings2Line } from "react-icons/ri";
import { FiGrid } from "react-icons/fi";
import { GoPlus } from "react-icons/go";
import { CiSettings } from "react-icons/ci";
import { LuArchive } from "react-icons/lu";
import { LuBook } from "react-icons/lu";
import { FaStar } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FcVideoCall } from "react-icons/fc";
import { TbPhoneCall } from "react-icons/tb";
import { LuShield } from "react-icons/lu";
import { MdModeEdit } from "react-icons/md";
import { RxShare2 } from "react-icons/rx";
import { FaRegHeart } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { FiZap } from "react-icons/fi";
import { FiActivity } from "react-icons/fi";
import { FiEdit3 } from "react-icons/fi";
import { IoMdCheckboxOutline } from "react-icons/io";
import { FaRegClock } from "react-icons/fa6";
import { FaBehance } from "react-icons/fa";
import { FaGoogleDrive } from "react-icons/fa";
import { FaDropbox } from "react-icons/fa6";
import { FaGithub } from "react-icons/fa6";
import { IoIosArrowDown } from "react-icons/io";
import { MdChevronRight } from "react-icons/md";

const danial = "/assets/images/danial.jpg";
const dean = "/assets/images/dean.jpg";
const huma = "/assets/images/huma.jpg";
const katharine = "/assets/images/katharine.jpg";
const morgan = "/assets/images/morgan.jpg";
const tom = "/assets/images/tom.jpg";
const winston = "/assets/images/winston.jpg";
const cavin = "/assets/images/cavin.jpg";
const avatar_img = "/assets/images/avatar.jpg";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  maxHeight: "95%",
};

function ContactCard() {
  const [openSide, setOpenSide] = React.useState(true);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [openlabel, setOpenlabel] = React.useState(false);
  const [opentag, setOpentag] = React.useState(false);
  const [openprofile, setOpenprofile] = React.useState(false);
  const handleOpenlabel = () => setOpenlabel(true);
  const handleCloselabel = () => setOpenlabel(false);
  const handleOpentag = () => setOpentag(true);
  const handleClosetag = () => setOpentag(false);
  const handleOpenprofile = () => setOpenprofile(true);
  const handleCloseprofile = () => setOpenprofile(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleListClick = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleListClose = () => {
    setAnchorEl2(null);
  };

  return (
    <>
      <CRMHeader />
      <Suspense fallback={renderLoader()}>
        <section className="contactlist-section">
          <div className="container-fluid">
            <div className="row">
              <div
                className={`${
                  openSide ? "" : "hide_side"
                } col-lg-2 col-md-4 contact-border`}
              >
                <div className="cotact-left-section">
                  <Link
                    to=""
                    className="primary-btn btn-border"
                    onClick={handleOpen}
                  >
                    Add New Contact
                  </Link>
                  <div className="menu-group">
                    <ul>
                      <li>
                        <Link className="nav-link">
                          <MdContacts /> All Contacts
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <FaRegStar /> Important
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <BsWindow /> Archive
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <FaRegEdit /> Pending
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <BsTrash3 /> Deleted
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="separator separator-light"></div>
                  <div className="menu-group">
                    <ul>
                      <li>
                        <Link className="nav-link">
                          <TfiExport /> Export
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <TfiImport /> Import
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <BsWindow /> Print
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="separator separator-light"></div>
                  <div className="menu-group">
                    <ul>
                      <li className="menu-title">
                        Labels
                        <button
                          className="btn btn-light btn-icon"
                          onClick={handleOpenlabel}
                        >
                          <GoPlus />
                        </button>
                      </li>
                      <li>
                        <Link className="nav-link">
                          Design
                          <span className="badge badge-pill badge-sm badge-soft-primary ms-auto">
                            136
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          Development
                          <span className="badge badge-pill badge-sm badge-soft-primary ms-auto">
                            16
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          Inventory
                          <span className="badge badge-pill badge-sm badge-soft-primary ms-auto">
                            6
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          Human Resource
                          <span className="badge badge-pill badge-sm badge-soft-primary ms-auto">
                            25
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="separator separator-light"></div>
                  <div className="tag-group">
                    <div className="menu-title">
                      Tags
                      <button
                        className="btn btn-light btn-icon"
                        onClick={handleOpentag}
                      >
                        <GoPlus />
                      </button>
                    </div>
                    <Link className="tag-menu">Collaboration</Link>
                    <Link className="tag-menu">React Developer</Link>
                    <Link className="tag-menu">Angular Developer</Link>
                    <Link className="tag-menu">promotion</Link>
                    <Link className="tag-menu">Advertisement</Link>
                  </div>
                  <div className="separator separator-light"></div>
                </div>
                <div className="contactapp-fixednav">
                  <div className="menu-group">
                    <ul>
                      <li>
                        <Link>
                          <CiSettings />
                        </Link>
                      </li>
                      <li>
                        <Link>
                          <LuArchive />
                        </Link>
                      </li>
                      <li>
                        <Link>
                          <LuBook />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  openSide ? "col-lg-10 col-md-8" : "col-lg-12"
                } contact-right-side`}
              >
                <div className="togglebar">
                  <div
                    className="sidebar-togglable"
                    onClick={() => setOpenSide(!openSide)}
                  >
                    <MdKeyboardArrowLeft />
                  </div>
                </div>
                <div className="top-contact">
                  <div className="title-text">
                    <h3>Contact</h3>
                    <select className="form-select me-auto">
                      <option value="1" selected>
                        Create New
                      </option>
                      <option value="2">Add New Contact</option>
                      <option value="2">Add New Department</option>
                      <option value="2">Add Category</option>
                      <option value="3">Add New Tag</option>
                    </select>
                  </div>
                  <div className="contact-options-wrap">
                    <div className="list-dropdown">
                      <IconButton
                        aria-label="more"
                        id="list-button"
                        aria-controls={open ? "list-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleListClick}
                        className="icon-btn"
                      >
                        <IoIosList />
                      </IconButton>
                    </div>
                    <Link>
                      <LuRefreshCw />
                    </Link>
                    <div className="v-separator d-lg-block d-none"></div>
                    <IconButton
                      aria-label="more"
                      id="setting-button"
                      aria-controls={open ? "setting-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleClick}
                      className="icon-btn"
                    >
                      <RiSettings2Line />
                    </IconButton>
                    <IconButton
                      aria-label="more"
                      id="setting-button"
                      aria-controls={open ? "setting-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleClick}
                      className="icon-btn"
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </div>
                </div>
                <div className="bottom-contact">
                  <div className="left-bottom-contact">
                    <div className="d-flex gap-3 align-items-center">
                      <select className="form-select me-auto">
                        <option value="Edit" selected>
                          Edit
                        </option>
                        <option value="Bulk">Bulk action</option>
                        <option value="Move">Move to trash</option>
                      </select>
                      <button type="text" className="apply-btn">
                        Apply
                      </button>
                      <p className="m-0 w-50">Sort by:</p>
                      <select className="form-select w-auto me-auto">
                        <option value="1" selected>
                          Date Created
                        </option>
                        <option value="2">Date Edited</option>
                        <option value="3">Recently Added</option>
                      </select>
                      <select className="form-select w-auto me-auto">
                        <option value="1" selected>
                          Export to CSV
                        </option>
                        <option value="2">Export to PDF</option>
                        <option value="3">Send Message</option>
                        <option value="4">Delegate Access</option>
                      </select>
                    </div>
                  </div>
                  <div className="right-bottom-contact">
                    <input
                      type="search"
                      placeholder="Search"
                      className="form-control w-auto"
                    />
                  </div>
                </div>
                <div className="card-section">
                  <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <div className="contact-card">
                        <div className="header-card">
                          <FormGroup className="check-box">
                            <FormControlLabel control={<Checkbox />} />
                          </FormGroup>
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                            className="icon-btn"
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </div>
                        <div className="images">
                          <img
                            src={danial}
                            alt="morgan"
                            title="Morgan Freeman"
                          />
                        </div>
                        <div className="contact-details">
                          <p className="black-color">Morgan Freeman</p>
                          <p>morgan@jampack.com</p>
                          <p>+145 52 5689</p>
                          <div className="user-desg">
                            <span className="badge badge-primary badge-indicator badge-indicator-nobdr me-2"></span>
                            <span>Design</span>
                          </div>
                        </div>
                      </div>
                      <div className="footer-card">
                        <Link to="" className="menu-link">
                          {" "}
                          <FaRegMessage className="me-2" /> Message
                        </Link>
                        <div className="divider"></div>
                        <Link className="menu-link" onClick={handleOpenprofile}>
                          {" "}
                          <FaUserCheck className="me-2" /> Profile
                        </Link>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <div className="contact-card">
                        <div className="header-card">
                          <FormGroup className="check-box">
                            <FormControlLabel control={<Checkbox />} />
                          </FormGroup>
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                            className="icon-btn"
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </div>
                        <div className="images">
                          <img src={huma} alt="Huma" title="Huma Therman" />
                        </div>
                        <div className="contact-details">
                          <p className="black-color">Huma Therman</p>
                          <p>huma@clariesup.au</p>
                          <p>+234 48 2365</p>
                          <div className="user-desg">
                            <span className="badge badge-primary badge-indicator badge-indicator-nobdr me-2"></span>
                            <span>Developer</span>
                          </div>
                        </div>
                      </div>
                      <div className="footer-card">
                        <Link to="" className="menu-link">
                          {" "}
                          <FaRegMessage className="me-2" /> Message
                        </Link>
                        <div className="divider"></div>
                        <Link className="menu-link" onClick={handleOpenprofile}>
                          {" "}
                          <FaUserCheck className="me-2" /> Profile
                        </Link>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <div className="contact-card">
                        <div className="header-card">
                          <FormGroup className="check-box">
                            <FormControlLabel control={<Checkbox />} />
                          </FormGroup>
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                            className="icon-btn"
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </div>
                        <div className="images">
                          <div className="bg-lightsky withoutimg primarysky-color">
                            C
                          </div>
                        </div>
                        <div className="contact-details">
                          <p className="black-color">
                            <FaStar className="yellow-color me-1" /> Charlie
                            Chaplin
                          </p>
                          <p>charlie@leernoca.monster</p>
                          <p>+741 56 1916</p>
                          <div className="user-desg">
                            <span className="badge badge-primary badge-indicator badge-indicator-nobdred me-2"></span>
                            <span>Inventory</span>
                          </div>
                        </div>
                      </div>
                      <div className="footer-card">
                        <Link to="" className="menu-link">
                          {" "}
                          <FaRegMessage className="me-2" /> Message
                        </Link>
                        <div className="divider"></div>
                        <Link className="menu-link" onClick={handleOpenprofile}>
                          {" "}
                          <FaUserCheck className="me-2" /> Profile
                        </Link>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <div className="contact-card">
                        <div className="header-card">
                          <FormGroup className="check-box">
                            <FormControlLabel control={<Checkbox />} />
                          </FormGroup>
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                            className="icon-btn"
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </div>
                        <div className="images">
                          <img
                            src={winston}
                            alt="Winston Churchil"
                            title="Winston Churchil"
                          />
                        </div>
                        <div className="contact-details">
                          <p className="black-color">
                            <FaStar className="yellow-color me-1" /> Winston
                            Churchil
                          </p>
                          <p>winston@worthniza.ga</p>
                          <p>+145 52 5463</p>
                          <div className="user-desg">
                            <span className="badge badge-primary badge-indicator badge-indicator-nobdr me-2"></span>
                            <span> Human Resource</span>
                          </div>
                        </div>
                      </div>
                      <div className="footer-card">
                        <Link to="" className="menu-link">
                          {" "}
                          <FaRegMessage className="me-2" /> Message
                        </Link>
                        <div className="divider"></div>
                        <Link className="menu-link" onClick={handleOpenprofile}>
                          {" "}
                          <FaUserCheck className="me-2" /> Profile
                        </Link>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <div className="contact-card">
                        <div className="header-card">
                          <FormGroup className="check-box">
                            <FormControlLabel control={<Checkbox />} />
                          </FormGroup>
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                            className="icon-btn"
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </div>
                        <div className="images">
                          <img
                            src={avatar_img}
                            alt="Jaquiline Joker"
                            title="Jaquiline Joker"
                          />
                        </div>
                        <div className="contact-details">
                          <p className="black-color">Jaquiline Joker</p>
                          <p>contact@hencework.com</p>
                          <p>+91-34-2636-1916</p>
                          <div className="user-desg">
                            <span className="badge badge-primary badge-indicator badge-indicator-nobdr me-2"></span>
                            <span>Design</span>
                          </div>
                        </div>
                      </div>
                      <div className="footer-card">
                        <Link to="" className="menu-link">
                          {" "}
                          <FaRegMessage className="me-2" /> Message
                        </Link>
                        <div className="divider"></div>
                        <Link className="menu-link" onClick={handleOpenprofile}>
                          {" "}
                          <FaUserCheck className="me-2" /> Profile
                        </Link>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <div className="contact-card">
                        <div className="header-card">
                          <FormGroup className="check-box">
                            <FormControlLabel control={<Checkbox />} />
                          </FormGroup>
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                            className="icon-btn"
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </div>
                        <div className="images">
                          <img src={tom} alt="Tom Cruz" title="Tom Cruz" />
                        </div>
                        <div className="contact-details">
                          <p className="black-color">Tom Cruz</p>
                          <p>morgan@jampack.com</p>
                          <p>+456 52 4862</p>
                          <div className="user-desg">
                            <span className="badge badge-primary badge-indicator badge-indicator-nobdr me-2"></span>
                            <span>Developer</span>
                          </div>
                        </div>
                      </div>
                      <div className="footer-card">
                        <Link to="" className="menu-link">
                          {" "}
                          <FaRegMessage className="me-2" /> Message
                        </Link>
                        <div className="divider"></div>
                        <Link className="menu-link" onClick={handleOpenprofile}>
                          {" "}
                          <FaUserCheck className="me-2" /> Profile
                        </Link>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <div className="contact-card">
                        <div className="header-card">
                          <FormGroup className="check-box">
                            <FormControlLabel control={<Checkbox />} />
                          </FormGroup>
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                            className="icon-btn"
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </div>
                        <div className="images">
                          <div className="bg-lightpink withoutimg primaryred-color">
                            D
                          </div>
                        </div>
                        <div className="contact-details">
                          <p className="black-color">Daniel Craig</p>
                          <p>danialc@jampack.com</p>
                          <p>+145 52 1916</p>
                          <div className="user-desg">
                            <span className="badge badge-primary badge-indicator badge-indicator-nobdr me-2"></span>
                            <span>Design</span>
                          </div>
                        </div>
                      </div>
                      <div className="footer-card">
                        <Link to="" className="menu-link">
                          {" "}
                          <FaRegMessage className="me-2" /> Message
                        </Link>
                        <div className="divider"></div>
                        <Link className="menu-link" onClick={handleOpenprofile}>
                          {" "}
                          <FaUserCheck className="me-2" /> Profile
                        </Link>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <div className="contact-card">
                        <div className="header-card">
                          <FormGroup className="check-box">
                            <FormControlLabel control={<Checkbox />} />
                          </FormGroup>
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                            className="icon-btn"
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </div>
                        <div className="images">
                          <img
                            src={katharine}
                            alt="Katharine Jones"
                            title="Katharine Jones"
                          />
                        </div>
                        <div className="contact-details">
                          <p>Katharine Jones</p>
                          <p>joneskath@jampack.com</p>
                          <p>+741 56 1916</p>
                          <div className="user-desg">
                            <span className="badge badge-primary badge-indicator badge-indicator-nobdr me-2"></span>
                            <span>Inventory</span>
                          </div>
                        </div>
                      </div>
                      <div className="footer-card">
                        <Link to="" className="menu-link">
                          {" "}
                          <FaRegMessage className="me-2" /> Message
                        </Link>
                        <div className="divider"></div>
                        <Link className="menu-link" onClick={handleOpenprofile}>
                          {" "}
                          <FaUserCheck className="me-2" /> Profile
                        </Link>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <div className="contact-card">
                        <div className="header-card">
                          <FormGroup className="check-box">
                            <FormControlLabel control={<Checkbox />} />
                          </FormGroup>
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                            className="icon-btn"
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </div>
                        <div className="images">
                          <img
                            src={dean}
                            alt="Daniel Raynolds"
                            title="Daniel Raynolds"
                          />
                        </div>
                        <div className="contact-details">
                          <p className="black-color">Daniel Raynolds</p>
                          <p>danialraynolds@hencework.com</p>
                          <p>+145 36 1916</p>
                          <div className="user-desg">
                            <span className="badge badge-primary badge-indicator badge-indicator-nobdr me-2"></span>
                            <span>Design</span>
                          </div>
                        </div>
                      </div>
                      <div className="footer-card">
                        <Link to="" className="menu-link">
                          {" "}
                          <FaRegMessage className="me-2" /> Message
                        </Link>
                        <div className="divider"></div>
                        <Link className="menu-link" onClick={handleOpenprofile}>
                          {" "}
                          <FaUserCheck className="me-2" /> Profile
                        </Link>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <div className="contact-card">
                        <div className="header-card">
                          <FormGroup className="check-box">
                            <FormControlLabel control={<Checkbox />} />
                          </FormGroup>
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                            className="icon-btn"
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </div>
                        <div className="images">
                          <img
                            src={cavin}
                            alt="Cavin Spancy"
                            title="Cavin Spancy"
                          />
                        </div>
                        <div className="contact-details">
                          <p className="black-color">Cavin Spancy</p>
                          <p>cavins11@budgequot.press</p>
                          <p>+234 48 1916</p>
                          <div className="user-desg">
                            <span className="badge badge-primary badge-indicator badge-indicator-nobdr me-2"></span>
                            <span>Design</span>
                          </div>
                        </div>
                      </div>
                      <div className="footer-card">
                        <Link to="" className="menu-link">
                          {" "}
                          <FaRegMessage className="me-2" /> Message
                        </Link>
                        <div className="divider"></div>
                        <Link className="menu-link" onClick={handleOpenprofile}>
                          {" "}
                          <FaUserCheck className="me-2" /> Profile
                        </Link>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <div className="contact-card">
                        <div className="header-card">
                          <FormGroup className="check-box">
                            <FormControlLabel control={<Checkbox />} />
                          </FormGroup>
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                            className="icon-btn"
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </div>
                        <div className="images">
                          <img
                            src={morgan}
                            alt="Justin Bieber"
                            title="Justin Bieber"
                          />
                        </div>
                        <div className="contact-details">
                          <p className="black-color">Justin Bieber</p>
                          <p>justin@tulberga.ga</p>
                          <p>+745 56 1916</p>
                          <div className="user-desg">
                            <span className="badge badge-primary badge-indicator badge-indicator-nobdr me-2"></span>
                            <span>Inventory</span>
                          </div>
                        </div>
                      </div>
                      <div className="footer-card">
                        <Link to="" className="menu-link">
                          {" "}
                          <FaRegMessage className="me-2" /> Message
                        </Link>
                        <div className="divider"></div>
                        <Link className="menu-link" onClick={handleOpenprofile}>
                          {" "}
                          <FaUserCheck className="me-2" /> Profile
                        </Link>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <div className="contact-card">
                        <div className="header-card">
                          <FormGroup className="check-box">
                            <FormControlLabel control={<Checkbox />} />
                          </FormGroup>
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                            className="icon-btn"
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </div>
                        <div className="images">
                          <img
                            src={
                              // auston
                              "/assets/images/auston.jpg"
                            }
                            alt="Auston Kutcher"
                            title="Auston Kutcher"
                          />
                        </div>
                        <div className="contact-details">
                          <p className="black-color">Auston Kutcher</p>
                          <p>auston@cutcher.com</p>
                          <p>+145 52 5689</p>
                          <div className="user-desg">
                            <span className="badge badge-primary badge-indicator badge-indicator-nobdred me-2"></span>
                            <span> Human Resource</span>
                          </div>
                        </div>
                      </div>
                      <div className="footer-card">
                        <Link to="" className="menu-link">
                          {" "}
                          <FaRegMessage className="me-2" /> Message
                        </Link>
                        <div className="divider"></div>
                        <Link className="menu-link" onClick={handleOpenprofile}>
                          {" "}
                          <FaUserCheck className="me-2" /> Profile
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem>
          <Link to={``} className="dropdown-link">
            {" "}
            Reply
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to={``} className="dropdown-link">
            {" "}
            Forward
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to={``} className="dropdown-link">
            Delete{" "}
          </Link>
        </MenuItem>
      </Menu>

      <Menu
        id="list-menu"
        MenuListProps={{
          "aria-labelledby": "list-button",
        }}
        anchorEl={anchorEl2}
        open={open2}
        onClose={handleListClose}
      >
        <MenuItem>
          <Link to={`/contact-list`} className="dropdown-link">
            {" "}
            <IoIosList className="me-2" /> List View
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to={`/contact-card`} className="dropdown-link">
            {" "}
            <FiGrid className="me-2" /> Grid View
          </Link>
        </MenuItem>
      </Menu>

      {/* Add Contact popup */}
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="contact-popup"
      >
        <Box sx={style}>
          <div className="modal-head">
            <h5>Create New Conatct</h5>
            <Link onClick={handleClose1}>
              <RxCross2 />
            </Link>
          </div>
          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-2">
                  <div className="upload-logo">
                    <div className="dropify-wrapper">
                      <span className="file-icon">
                        <BsUpload />
                      </span>
                      <p>Upload Logo</p>
                      <input type="file" className="dropify-2" />
                    </div>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      rows="4"
                      placeholder="Add Biography"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="title-text">
                {" "}
                <span>Basic INFO</span>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">First Name</label>
                    <input className="form-control task-name" type="text" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">Middle Name</label>
                    <input className="form-control task-name" type="text" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">Last Name</label>
                    <input className="form-control task-name" type="text" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Email ID</label>
                    <input className="form-control task-name" type="text" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Phone</label>
                    <input className="form-control task-name" type="text" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">City</label>
                    <select className="form-control custom-select">
                      <option selected="">Choose Category</option>
                      <option value="1">--</option>
                      <option value="2">Open</option>
                      <option value="3">Two</option>
                      <option value="4">Three</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">State</label>
                    <select className="form-control custom-select">
                      <option value="1">--</option>
                      <option value="2">Open</option>
                      <option value="3">Two</option>
                      <option value="4">Three</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">Country</label>
                    <select className="form-control custom-select">
                      <option value="1">--</option>
                      <option value="2">Open</option>
                      <option value="3">Two</option>
                      <option value="4">Three</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="title-text">
                {" "}
                <span>COMPANY INFO</span>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Company Name</label>
                    <input className="form-control task-name" type="text" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Designation</label>
                    <input className="form-control task-name" type="text" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Website</label>
                    <input className="form-control task-name" type="text" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Work Phone</label>
                    <input className="form-control task-name" type="text" />
                  </div>
                </div>
              </div>
              <div className="title-text">
                {" "}
                <span>ADDITIONAL INFO</span>
              </div>
              <div className="row mt-2">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">Tags</label>
                    <input className="form-control task-name" type="text" />
                    <span className="mt-1 primarylight-color">
                      You can add upto 4 tags per contact
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      className="form-control task-name"
                      type="text"
                      placeholder="Facebook"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      className="form-control task-name"
                      type="text"
                      placeholder="Twitter"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      className="form-control task-name"
                      type="text"
                      placeholder="Linkedin"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      className="form-control task-name"
                      type="text"
                      placeholder="Gmail"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <Button
              type="submit"
              className="secondary-btn me-2"
              onClick={handleClose1}
            >
              Discard
            </Button>
            <Button type="submit" className="primary-btn">
              Create Contact
            </Button>
          </div>
        </Box>
      </Modal>

      {/* Add new labels popup */}
      <Modal
        open={openlabel}
        onClose={handleCloselabel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="deal-popup"
      >
        <Box sx={style}>
          <div className="modal-head">
            <h5>ADD LABEL</h5>
            <Link onClick={handleCloselabel}>
              <RxCross2 />
            </Link>
          </div>
          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      className="form-control task-name"
                      type="text"
                      placeholder="Label Name"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <Button
              type="submit"
              className="primary-btn"
              onClick={handleCloselabel}
            >
              Add
            </Button>
          </div>
        </Box>
      </Modal>

      {/* Add new tag popup */}
      <Modal
        open={opentag}
        onClose={handleClosetag}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="deal-popup"
      >
        <Box sx={style}>
          <div className="modal-head">
            <h5>ADD TAG</h5>
            <Link onClick={handleClosetag}>
              <RxCross2 />
            </Link>
          </div>
          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <textarea className="form-control" rows="2"></textarea>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <Button
              type="submit"
              className="primary-btn"
              onClick={handleClosetag}
            >
              Add
            </Button>
          </div>
        </Box>
      </Modal>

      {/* Add profile popup */}
      <Modal
        open={openprofile}
        onClose={handleCloseprofile}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="profile-popup"
      >
        <Box sx={style}>
          <div className="modal-body">
            <form>
              <div className="profiles-head">
                <div className="profile-details">
                  <div className="profile-img">
                    <img src={avatar_img} alt="" />
                  </div>
                  <div className="contact-details">
                    <h6>Mendaline Shane</h6>
                    <p>No phone calls Always busy</p>
                    <div className="star-block">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                    </div>
                  </div>
                </div>
                <div className="profile-right-head">
                  <div className="contact-social">
                    <div className="icon">
                      {" "}
                      <MdEmail />{" "}
                    </div>
                    <div className="icon">
                      {" "}
                      <TbPhoneCall />{" "}
                    </div>
                    <div className="icon">
                      {" "}
                      <FcVideoCall />{" "}
                    </div>
                  </div>
                  <Button
                    aria-label="more"
                    id="long-menu"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    className="progress-btn"
                  >
                    Action <IoIosArrowDown className="ml-2" />
                  </Button>
                  <p className="primarylight-color">1 - 10 of 30</p>
                  <Link>
                    <MdKeyboardArrowLeft />
                  </Link>
                  <Link>
                    <MdChevronRight />
                  </Link>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    className="icon-btn"
                  >
                    <MoreVertIcon />
                  </IconButton>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 contact-section">
                  <div className="profile-info-block">
                    <div className="title-head">
                      <h5>Profile Information</h5>
                      <Button type="text" className="edit-icon">
                        <MdModeEdit />
                      </Button>
                    </div>
                    <ul>
                      <li>
                        <h6>First Name</h6>
                        <p>Morgan</p>
                      </li>
                      <li>
                        <h6>Last Name</h6>
                        <p>Freeman</p>
                      </li>
                      <li>
                        <h6>Email</h6>
                        <p>morgan@flights.com</p>
                      </li>
                      <li>
                        <h6>Phone</h6>
                        <p>+912-4532-1234</p>
                      </li>
                      <li>
                        <h6>Location</h6>
                        <p>Newyork</p>
                      </li>
                    </ul>
                  </div>
                  <div className="separator-full"></div>
                  <div className="profile-info-block">
                    <div className="title-head">
                      <h5>More Info</h5>
                      <Button type="text" className="edit-icon">
                        <MdModeEdit />
                      </Button>
                    </div>
                    <ul>
                      <li>
                        <h6>Designation</h6>
                        <p>Morgan</p>
                      </li>
                      <li>
                        <h6>Company</h6>
                        <p>Freeman</p>
                      </li>
                      <li>
                        <h6>Language</h6>
                        <p>morgan@flights.com</p>
                      </li>
                      <li>
                        <h6>Birthday</h6>
                        <p>-</p>
                      </li>
                      <li>
                        <h6>Location</h6>
                        <p>Newyork</p>
                      </li>
                    </ul>
                  </div>
                  <div className="separator-full"></div>
                  <div className="profile-info-block">
                    <div className="title-head">
                      <h5>Tags</h5>
                      <Button type="text" className="edit-icon">
                        <MdModeEdit />
                      </Button>
                    </div>
                    <div className="tag-group">
                      <Link className="tag-menu color-violet">
                        Collaboration
                      </Link>
                      <Link className="tag-menu color-red">
                        React Developer
                      </Link>
                    </div>
                  </div>
                  <div className="separator-full"></div>
                  <div className="profile-info-block">
                    <div className="title-head">
                      <h5>Social Profile</h5>
                    </div>
                    <ul className="social-icon">
                      <li>
                        <Link className="social-color bg-sky">
                          <FaBehance />
                        </Link>
                      </li>
                      <li>
                        <Link className="social-color bg-yellow">
                          <FaGoogleDrive />
                        </Link>
                      </li>
                      <li>
                        <Link className="social-color bg-lightsky">
                          <FaDropbox />
                        </Link>
                      </li>
                      <li>
                        <Link className="social-color bg-black">
                          <FaGithub />
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="separator-full"></div>
                  <div className="profile-info-block">
                    <div className="title-head">
                      <h5>Biography</h5>
                      <Button type="text" className="edit-icon">
                        <MdModeEdit />
                      </Button>
                    </div>
                    <p>
                      Hello there, Morgan Freeman is a full-stack frontend
                      developer working under pressure is his quality.
                    </p>
                  </div>
                  <div className="separator-full"></div>
                  <div className="profile-info-block">
                    <div className="title-head">
                      <h5>Settings</h5>
                    </div>
                    <ul className="seeting-block">
                      <li>
                        <Link className="seeting-link">
                          {" "}
                          <RxShare2 className="me-2" /> Share Contact
                        </Link>
                        <Link className="seeting-link">
                          {" "}
                          <FaRegHeart className="me-2" /> Add to Favourites
                        </Link>
                        <Link className="seeting-link color-red">
                          {" "}
                          <AiFillDelete className="me-2" /> Delete Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="contact-tab-section">
                    <Tabs aria-label="Basic tabs" defaultValue={0}>
                      <TabList>
                        <Tab>
                          <FiZap className="me-2" />
                          Summery
                        </Tab>
                        <Tab>
                          <FiActivity className="me-2" />
                          Activity
                        </Tab>
                        <Tab>
                          <FiEdit3 className="me-2" />
                          Notes
                        </Tab>
                        {/* <Tab><MdEmail className="me-2" />Email</Tab>
                          <Tab><IoCallOutline className="me-2" />Calls</Tab> */}
                        <Tab>
                          <IoMdCheckboxOutline className="me-2" />
                          Tasks
                        </Tab>
                        <Tab>
                          <FaRegClock className="me-2" />
                          Schedule
                        </Tab>
                        <Tab>
                          <LuShield className="me-2" />
                          Sales
                        </Tab>
                      </TabList>
                      <TabPanel value={0}>
                        <div className="mt-5 tab-description">
                          <form>
                            <div className="row">
                              <div className="col-md-12 form-group">
                                <div className="form-label-group d-flex justify-content-between">
                                  <label className="pb-2">Write a Note</label>
                                  <small className="text-muted">1200</small>
                                </div>
                                <textarea
                                  className="form-control"
                                  rows="8"
                                  placeholder="Write an internal note"
                                ></textarea>
                              </div>
                            </div>
                            <button className="t-2 primary-btn w-auto">
                              Add Note
                            </button>
                          </form>
                          <div className="pipeline-status-wrap mt-5">
                            <h5>Lead Pipeline Status</h5>
                            <ul className="pipeline-stutus">
                              <li className="completed">
                                <span>In Pipeline</span>
                              </li>
                              <li className="active">
                                <span>Follow Up</span>
                              </li>
                              <li>
                                <span>Scheduled Service</span>
                              </li>
                              <li>
                                <span>Conversation</span>
                              </li>
                              <li>
                                <span>Win/Lost</span>
                              </li>
                            </ul>
                            <div className="clearfix"></div>
                          </div>
                          <div className="activity-wrap mt-5">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                              <h5 className="mb-0">Activity</h5>
                              <select className="form-select w-auto">
                                <option selected="">Activity</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                            </div>
                            <div className="mb-3">June 24</div>
                            <ul className="activity-thread">
                              <li>
                                <div className="media">
                                  <div className="media-head">
                                    <MdEmail />
                                  </div>
                                  <div className="media-body">
                                    <div>
                                      <h6>
                                        You sent{" "}
                                        <span className="text-bold">
                                          1 message
                                        </span>{" "}
                                        to the contact.
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="media">
                                  <div className="media-head bg-pink">M</div>
                                  <div className="media-body ">
                                    <div>
                                      <h6>
                                        <span className="text-bold">
                                          Morgan Freeman
                                        </span>{" "}
                                        as it is sometimes known, is dummy text
                                        used in laying out print, graphic or web
                                        designs. The passage is attributed to an
                                        unknown typesetter in the 15th century
                                        who is thought to have scrambled.
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="media">
                                  <div className="media-head bg-sky">
                                    <LuShield />
                                  </div>
                                  <div className="media-body">
                                    <div>
                                      <h6>
                                        Your deal value{" "}
                                        <span className="text-bold">
                                          $208.15
                                        </span>{" "}
                                        is paid through PayU Money online on{" "}
                                        <span className="text-bold">
                                          02.12.18
                                        </span>{" "}
                                        at{" "}
                                        <span className="text-bold">
                                          15:30, Monday
                                        </span>
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                            <div className="mt-5 mb-3">June 25</div>
                            <ul className="activity-thread">
                              <li>
                                <div className="media">
                                  <div className="media-head bg-pink">M</div>
                                  <div className="media-body ">
                                    <div>
                                      <h6>
                                        <span className="text-bold">
                                          Morgan Freeman
                                        </span>{" "}
                                        responded to your appointment schedule
                                        question.
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel value={1}>
                        <div className="tab-description">
                          <div className="pipeline-status-wrap mt-5">
                            <h5>Lead Pipeline Status</h5>
                            <ul className="pipeline-stutus">
                              <li className="completed">
                                <span>In Pipeline</span>
                              </li>
                              <li className="active">
                                <span>Follow Up</span>
                              </li>
                              <li>
                                <span>Scheduled Service</span>
                              </li>
                              <li>
                                <span>Conversation</span>
                              </li>
                              <li>
                                <span>Win/Lost</span>
                              </li>
                            </ul>
                            <div className="clearfix"></div>
                          </div>
                          <div className="activity-wrap mt-5">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                              <h5 className="mb-0">Activity</h5>
                              <select className="form-select w-auto">
                                <option selected="">Activity</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                            </div>
                            <div className="mb-3">June 24</div>
                            <ul className="activity-thread">
                              <li>
                                <div className="media">
                                  <div className="media-head">
                                    <MdEmail />
                                  </div>
                                  <div className="media-body">
                                    <div>
                                      <h6>
                                        You sent{" "}
                                        <span className="text-bold">
                                          1 message
                                        </span>{" "}
                                        to the contact.
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="media">
                                  <div className="media-head bg-pink">M</div>
                                  <div className="media-body ">
                                    <div>
                                      <h6>
                                        <span className="text-bold">
                                          Morgan Freeman
                                        </span>{" "}
                                        as it is sometimes known, is dummy text
                                        used in laying out print, graphic or web
                                        designs. The passage is attributed to an
                                        unknown typesetter in the 15th century
                                        who is thought to have scrambled.
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="media">
                                  <div className="media-head bg-sky">
                                    <LuShield />
                                  </div>
                                  <div className="media-body">
                                    <div>
                                      <h6>
                                        Your deal value{" "}
                                        <span className="text-bold">
                                          $208.15
                                        </span>{" "}
                                        is paid through PayU Money online on{" "}
                                        <span className="text-bold">
                                          02.12.18
                                        </span>{" "}
                                        at{" "}
                                        <span className="text-bold">
                                          15:30, Monday
                                        </span>
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                            <div className="mt-5 mb-3">June 25</div>
                            <ul className="activity-thread">
                              <li>
                                <div className="media">
                                  <div className="media-head bg-pink">M</div>
                                  <div className="media-body ">
                                    <div>
                                      <h6>
                                        <span className="text-bold">
                                          Morgan Freeman
                                        </span>{" "}
                                        responded to your appointment schedule
                                        question.
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel value={2}>
                        <div className="tab-description">
                          <div className="pipeline-status-wrap mt-5">
                            <h5>Lead Pipeline Status</h5>
                            <ul className="pipeline-stutus">
                              <li className="completed">
                                <span>In Pipeline</span>
                              </li>
                              <li className="active">
                                <span>Follow Up</span>
                              </li>
                              <li>
                                <span>Scheduled Service</span>
                              </li>
                              <li>
                                <span>Conversation</span>
                              </li>
                              <li>
                                <span>Win/Lost</span>
                              </li>
                            </ul>
                            <div className="clearfix"></div>
                          </div>
                          <div className="activity-wrap mt-5">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                              <h5 className="mb-0">Activity</h5>
                              <select className="form-select w-auto">
                                <option selected="">Activity</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                            </div>
                            <div className="mb-3">June 24</div>
                            <ul className="activity-thread">
                              <li>
                                <div className="media">
                                  <div className="media-head">
                                    <MdEmail />
                                  </div>
                                  <div className="media-body">
                                    <div>
                                      <h6>
                                        You sent{" "}
                                        <span className="text-bold">
                                          1 message
                                        </span>{" "}
                                        to the contact.
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="media">
                                  <div className="media-head bg-pink">M</div>
                                  <div className="media-body ">
                                    <div>
                                      <h6>
                                        <span className="text-bold">
                                          Morgan Freeman
                                        </span>{" "}
                                        as it is sometimes known, is dummy text
                                        used in laying out print, graphic or web
                                        designs. The passage is attributed to an
                                        unknown typesetter in the 15th century
                                        who is thought to have scrambled.
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="media">
                                  <div className="media-head bg-sky">
                                    <LuShield />
                                  </div>
                                  <div className="media-body">
                                    <div>
                                      <h6>
                                        Your deal value{" "}
                                        <span className="text-bold">
                                          $208.15
                                        </span>{" "}
                                        is paid through PayU Money online on{" "}
                                        <span className="text-bold">
                                          02.12.18
                                        </span>{" "}
                                        at{" "}
                                        <span className="text-bold">
                                          15:30, Monday
                                        </span>
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                            <div className="mt-5 mb-3">June 25</div>
                            <ul className="activity-thread">
                              <li>
                                <div className="media">
                                  <div className="media-head bg-pink">M</div>
                                  <div className="media-body ">
                                    <div>
                                      <h6>
                                        <span className="text-bold">
                                          Morgan Freeman
                                        </span>{" "}
                                        responded to your appointment schedule
                                        question.
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel value={3}>
                        <div className="tab-description">
                          <div className="pipeline-status-wrap mt-5">
                            <h5>Lead Pipeline Status</h5>
                            <ul className="pipeline-stutus">
                              <li className="completed">
                                <span>In Pipeline</span>
                              </li>
                              <li className="active">
                                <span>Follow Up</span>
                              </li>
                              <li>
                                <span>Scheduled Service</span>
                              </li>
                              <li>
                                <span>Conversation</span>
                              </li>
                              <li>
                                <span>Win/Lost</span>
                              </li>
                            </ul>
                            <div className="clearfix"></div>
                          </div>
                          <div className="activity-wrap mt-5">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                              <h5 className="mb-0">Activity</h5>
                              <select className="form-select w-auto">
                                <option selected="">Activity</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                            </div>
                            <div className="mb-3">June 24</div>
                            <ul className="activity-thread">
                              <li>
                                <div className="media">
                                  <div className="media-head">
                                    <MdEmail />
                                  </div>
                                  <div className="media-body">
                                    <div>
                                      <h6>
                                        You sent{" "}
                                        <span className="text-bold">
                                          1 message
                                        </span>{" "}
                                        to the contact.
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="media">
                                  <div className="media-head bg-pink">M</div>
                                  <div className="media-body ">
                                    <div>
                                      <h6>
                                        <span className="text-bold">
                                          Morgan Freeman
                                        </span>{" "}
                                        as it is sometimes known, is dummy text
                                        used in laying out print, graphic or web
                                        designs. The passage is attributed to an
                                        unknown typesetter in the 15th century
                                        who is thought to have scrambled.
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="media">
                                  <div className="media-head bg-sky">
                                    <LuShield />
                                  </div>
                                  <div className="media-body">
                                    <div>
                                      <h6>
                                        Your deal value{" "}
                                        <span className="text-bold">
                                          $208.15
                                        </span>{" "}
                                        is paid through PayU Money online on{" "}
                                        <span className="text-bold">
                                          02.12.18
                                        </span>{" "}
                                        at{" "}
                                        <span className="text-bold">
                                          15:30, Monday
                                        </span>
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                            <div className="mt-5 mb-3">June 25</div>
                            <ul className="activity-thread">
                              <li>
                                <div className="media">
                                  <div className="media-head bg-pink">M</div>
                                  <div className="media-body ">
                                    <div>
                                      <h6>
                                        <span className="text-bold">
                                          Morgan Freeman
                                        </span>{" "}
                                        responded to your appointment schedule
                                        question.
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel value={4}>
                        <div className="tab-description">
                          <div className="pipeline-status-wrap mt-5">
                            <h5>Lead Pipeline Status</h5>
                            <ul className="pipeline-stutus">
                              <li className="completed">
                                <span>In Pipeline</span>
                              </li>
                              <li className="active">
                                <span>Follow Up</span>
                              </li>
                              <li>
                                <span>Scheduled Service</span>
                              </li>
                              <li>
                                <span>Conversation</span>
                              </li>
                              <li>
                                <span>Win/Lost</span>
                              </li>
                            </ul>
                            <div className="clearfix"></div>
                          </div>
                          <div className="activity-wrap mt-5">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                              <h5 className="mb-0">Activity</h5>
                              <select className="form-select w-auto">
                                <option selected="">Activity</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                            </div>
                            <div className="mb-3">June 24</div>
                            <ul className="activity-thread">
                              <li>
                                <div className="media">
                                  <div className="media-head">
                                    <MdEmail />
                                  </div>
                                  <div className="media-body">
                                    <div>
                                      <h6>
                                        You sent{" "}
                                        <span className="text-bold">
                                          1 message
                                        </span>{" "}
                                        to the contact.
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="media">
                                  <div className="media-head bg-pink">M</div>
                                  <div className="media-body ">
                                    <div>
                                      <h6>
                                        <span className="text-bold">
                                          Morgan Freeman
                                        </span>{" "}
                                        as it is sometimes known, is dummy text
                                        used in laying out print, graphic or web
                                        designs. The passage is attributed to an
                                        unknown typesetter in the 15th century
                                        who is thought to have scrambled.
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="media">
                                  <div className="media-head bg-sky">
                                    <LuShield />
                                  </div>
                                  <div className="media-body">
                                    <div>
                                      <h6>
                                        Your deal value{" "}
                                        <span className="text-bold">
                                          $208.15
                                        </span>{" "}
                                        is paid through PayU Money online on{" "}
                                        <span className="text-bold">
                                          02.12.18
                                        </span>{" "}
                                        at{" "}
                                        <span className="text-bold">
                                          15:30, Monday
                                        </span>
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                            <div className="mt-5 mb-3">June 25</div>
                            <ul className="activity-thread">
                              <li>
                                <div className="media">
                                  <div className="media-head bg-pink">M</div>
                                  <div className="media-body ">
                                    <div>
                                      <h6>
                                        <span className="text-bold">
                                          Morgan Freeman
                                        </span>{" "}
                                        responded to your appointment schedule
                                        question.
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel value={5}>
                        <div className="tab-description">
                          <div className="pipeline-status-wrap mt-5">
                            <h5>Lead Pipeline Status</h5>
                            <ul className="pipeline-stutus">
                              <li className="completed">
                                <span>In Pipeline</span>
                              </li>
                              <li className="active">
                                <span>Follow Up</span>
                              </li>
                              <li>
                                <span>Scheduled Service</span>
                              </li>
                              <li>
                                <span>Conversation</span>
                              </li>
                              <li>
                                <span>Win/Lost</span>
                              </li>
                            </ul>
                            <div className="clearfix"></div>
                          </div>
                          <div className="activity-wrap mt-5">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                              <h5 className="mb-0">Activity</h5>
                              <select className="form-select w-auto">
                                <option selected="">Activity</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                            </div>
                            <div className="mb-3">June 24</div>
                            <ul className="activity-thread">
                              <li>
                                <div className="media">
                                  <div className="media-head">
                                    <MdEmail />
                                  </div>
                                  <div className="media-body">
                                    <div>
                                      <h6>
                                        You sent{" "}
                                        <span className="text-bold">
                                          1 message
                                        </span>{" "}
                                        to the contact.
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="media">
                                  <div className="media-head bg-pink">M</div>
                                  <div className="media-body ">
                                    <div>
                                      <h6>
                                        <span className="text-bold">
                                          Morgan Freeman
                                        </span>{" "}
                                        as it is sometimes known, is dummy text
                                        used in laying out print, graphic or web
                                        designs. The passage is attributed to an
                                        unknown typesetter in the 15th century
                                        who is thought to have scrambled.
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="media">
                                  <div className="media-head bg-sky">
                                    <LuShield />
                                  </div>
                                  <div className="media-body">
                                    <div>
                                      <h6>
                                        Your deal value{" "}
                                        <span className="text-bold">
                                          $208.15
                                        </span>{" "}
                                        is paid through PayU Money online on{" "}
                                        <span className="text-bold">
                                          02.12.18
                                        </span>{" "}
                                        at{" "}
                                        <span className="text-bold">
                                          15:30, Monday
                                        </span>
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                            <div className="mt-5 mb-3">June 25</div>
                            <ul className="activity-thread">
                              <li>
                                <div className="media">
                                  <div className="media-head bg-pink">M</div>
                                  <div className="media-body ">
                                    <div>
                                      <h6>
                                        <span className="text-bold">
                                          Morgan Freeman
                                        </span>{" "}
                                        responded to your appointment schedule
                                        question.
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel value={6}>
                        <div className="tab-description">
                          <div className="pipeline-status-wrap mt-5">
                            <h5>Lead Pipeline Status</h5>
                            <ul className="pipeline-stutus">
                              <li className="completed">
                                <span>In Pipeline</span>
                              </li>
                              <li className="active">
                                <span>Follow Up</span>
                              </li>
                              <li>
                                <span>Scheduled Service</span>
                              </li>
                              <li>
                                <span>Conversation</span>
                              </li>
                              <li>
                                <span>Win/Lost</span>
                              </li>
                            </ul>
                            <div className="clearfix"></div>
                          </div>
                          <div className="activity-wrap mt-5">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                              <h5 className="mb-0">Activity</h5>
                              <select className="form-select w-auto">
                                <option selected="">Activity</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                            </div>
                            <div className="mb-3">June 24</div>
                            <ul className="activity-thread">
                              <li>
                                <div className="media">
                                  <div className="media-head">
                                    <MdEmail />
                                  </div>
                                  <div className="media-body">
                                    <div>
                                      <h6>
                                        You sent{" "}
                                        <span className="text-bold">
                                          1 message
                                        </span>{" "}
                                        to the contact.
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="media">
                                  <div className="media-head bg-pink">M</div>
                                  <div className="media-body ">
                                    <div>
                                      <h6>
                                        <span className="text-bold">
                                          Morgan Freeman
                                        </span>{" "}
                                        as it is sometimes known, is dummy text
                                        used in laying out print, graphic or web
                                        designs. The passage is attributed to an
                                        unknown typesetter in the 15th century
                                        who is thought to have scrambled.
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="media">
                                  <div className="media-head bg-sky">
                                    <LuShield />
                                  </div>
                                  <div className="media-body">
                                    <div>
                                      <h6>
                                        Your deal value{" "}
                                        <span className="text-bold">
                                          $208.15
                                        </span>{" "}
                                        is paid through PayU Money online on{" "}
                                        <span className="text-bold">
                                          02.12.18
                                        </span>{" "}
                                        at{" "}
                                        <span className="text-bold">
                                          15:30, Monday
                                        </span>
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                            <div className="mt-5 mb-3">June 25</div>
                            <ul className="activity-thread">
                              <li>
                                <div className="media">
                                  <div className="media-head bg-pink">M</div>
                                  <div className="media-body ">
                                    <div>
                                      <h6>
                                        <span className="text-bold">
                                          Morgan Freeman
                                        </span>{" "}
                                        responded to your appointment schedule
                                        question.
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel value={7}>
                        <div className="tab-description">
                          <div className="pipeline-status-wrap mt-5">
                            <h5>Lead Pipeline Status</h5>
                            <ul className="pipeline-stutus">
                              <li className="completed">
                                <span>In Pipeline</span>
                              </li>
                              <li className="active">
                                <span>Follow Up</span>
                              </li>
                              <li>
                                <span>Scheduled Service</span>
                              </li>
                              <li>
                                <span>Conversation</span>
                              </li>
                              <li>
                                <span>Win/Lost</span>
                              </li>
                            </ul>
                            <div className="clearfix"></div>
                          </div>
                          <div className="activity-wrap mt-5">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                              <h5 className="mb-0">Activity</h5>
                              <select className="form-select w-auto">
                                <option selected="">Activity</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                            </div>
                            <div className="mb-3">June 24</div>
                            <ul className="activity-thread">
                              <li>
                                <div className="media">
                                  <div className="media-head">
                                    <MdEmail />
                                  </div>
                                  <div className="media-body">
                                    <div>
                                      <h6>
                                        You sent{" "}
                                        <span className="text-bold">
                                          1 message
                                        </span>{" "}
                                        to the contact.
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="media">
                                  <div className="media-head bg-pink">M</div>
                                  <div className="media-body ">
                                    <div>
                                      <h6>
                                        <span className="text-bold">
                                          Morgan Freeman
                                        </span>{" "}
                                        as it is sometimes known, is dummy text
                                        used in laying out print, graphic or web
                                        designs. The passage is attributed to an
                                        unknown typesetter in the 15th century
                                        who is thought to have scrambled.
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="media">
                                  <div className="media-head bg-sky">
                                    <LuShield />
                                  </div>
                                  <div className="media-body">
                                    <div>
                                      <h6>
                                        Your deal value{" "}
                                        <span className="text-bold">
                                          $208.15
                                        </span>{" "}
                                        is paid through PayU Money online on{" "}
                                        <span className="text-bold">
                                          02.12.18
                                        </span>{" "}
                                        at{" "}
                                        <span className="text-bold">
                                          15:30, Monday
                                        </span>
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                            <div className="mt-5 mb-3">June 25</div>
                            <ul className="activity-thread">
                              <li>
                                <div className="media">
                                  <div className="media-head bg-pink">M</div>
                                  <div className="media-body ">
                                    <div>
                                      <h6>
                                        <span className="text-bold">
                                          Morgan Freeman
                                        </span>{" "}
                                        responded to your appointment schedule
                                        question.
                                      </h6>
                                      <p>10.00 pm</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ContactCard;
