import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "./index.scss";
import { Backdrop, CircularProgress, Button } from "@mui/material";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
import { useSelector } from "react-redux";

function AppVersionUpdate() {
  const [openCircle, setOpenCircle] = useState(false);
  const LogDetails = useSelector((state) => state.login.LoginDetails);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    getAppInfo();
    document.title = "FIG Events | App Version Update";
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (data) => {
    let seData = {
      url: "/api/AppUpdateVersion/CreateAppUpdateVersion",
      body: {
        ...data,
        createdBy: LogDetails?.userId,
        isForceUpdate: data?.isForceUpdate === "true" ? true : false,
      },
    };
    setOpenCircle(true);
    let res = await PostCallApi(seData);
    setOpenCircle(false);

    if (res?.status === 200) {
      if (res.data.success) {
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        reset();
        setTimeout(() => {
          getAppInfo();
        }, 200);
      } else {
        toast.error(res.data.message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } else {
      toast.error(res.data.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const getAppInfo = async () => {
    let seData = {
      url: "/api/AppUpdateVersion/GetAppUpdateVersion",
    };
    let res = await GetCallApi(seData);
    if (res.status === 200) {
      if (res.data.success) {
        let abt = res.data.payload;
        setValue("androidPlayStoreUrl", abt?.androidPlayStoreUrl);
        setValue(
          "androidUpdateDate",
          abt?.androidUpdateDate ? abt?.androidUpdateDate?.split("T")[0] : ""
        );
        setValue("androidVersionCode", abt?.androidVersionCode);
        setValue("iosappStoreUrl", abt?.iosappStoreUrl);
        setValue(
          "iosupdateDate",
          abt?.iosupdateDate ? abt?.iosupdateDate?.split("T")[0] : ""
        );
        setValue("iosversionCode", abt?.iosversionCode);
        setValue("isForceUpdate", abt?.isForceUpdate?.toString());
        setValue("versionId", abt?.versionId);
      }
    }
  };

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: () => 9999 }} open={openCircle}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="create_event_class">
        <div className="main_wrapper">
          <div className="title mb-3">App Version Update</div>
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)} className="form_box">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Android Version Code
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Android Version Code"
                        {...register("androidVersionCode", {
                          required: "This field is required",
                          pattern: {
                            value: /^[^\s]+(\s+[^\s]+)*$/,
                            message: "Starting and Ending Space not allowed",
                          },
                          maxLength: {
                            value: 150,
                            message: "Max character 150 allowed",
                          },
                        })}
                      />
                      {errors.androidVersionCode && (
                        <span role="alert" className="error_text">
                          {errors.androidVersionCode.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Android Play Store URL
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Android Play Store URL"
                        {...register("androidPlayStoreUrl", {
                          required: "This field is required",
                          pattern: {
                            value: /^[^\s]+(\s+[^\s]+)*$/,
                            message: "Starting and Ending Space not allowed",
                          },
                          maxLength: {
                            value: 150,
                            message: "Max character 150 allowed",
                          },
                        })}
                      />
                      {errors.androidPlayStoreUrl && (
                        <span role="alert" className="error_text">
                          {errors.androidPlayStoreUrl.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Android Update Date
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        {...register("androidUpdateDate", {
                          required: "This field is required",
                          valueAsDate: true,
                        })}
                      />
                      {errors.androidUpdateDate && (
                        <span role="alert" className="error_text">
                          {errors.androidUpdateDate.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        IOS Version Code
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter IOS Version Code"
                        {...register("iosversionCode", {
                          required: "This field is required",
                          pattern: {
                            value: /^[^\s]+(\s+[^\s]+)*$/,
                            message: "Starting and Ending Space not allowed",
                          },
                          maxLength: {
                            value: 150,
                            message: "Max character 150 allowed",
                          },
                        })}
                      />
                      {errors.iosversionCode && (
                        <span role="alert" className="error_text">
                          {errors.iosversionCode.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        IOS App Store URL
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter  IOS App Store URL"
                        {...register("iosappStoreUrl", {
                          required: "This field is required",
                          pattern: {
                            value: /^[^\s]+(\s+[^\s]+)*$/,
                            message: "Starting and Ending Space not allowed",
                          },
                          maxLength: {
                            value: 150,
                            message: "Max character 150 allowed",
                          },
                        })}
                      />
                      {errors.iosappStoreUrl && (
                        <span role="alert" className="error_text">
                          {errors.iosappStoreUrl.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        IOS Update Date
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        {...register("iosupdateDate", {
                          required: "This field is required",
                          valueAsDate: true,
                        })}
                      />
                      {errors.iosupdateDate && (
                        <span role="alert" className="error_text">
                          {errors.iosupdateDate.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Is Force Update
                        <span className="red-color"> *</span>
                      </label>
                      <select
                        type="date"
                        className="form-select"
                        {...register("isForceUpdate", {
                          required: "This field is required",
                        })}
                      >
                        <option value="true">True</option>
                        <option value="false">False</option>
                      </select>
                      {errors.isForceUpdate && (
                        <span role="alert" className="error_text">
                          {errors.isForceUpdate.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 d-flex justify-content-end mt-3">
                    <div className="form-group">
                      <Button className="detail_button" type="submit">
                        Update
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AppVersionUpdate;
