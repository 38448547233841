import React, { useEffect, useState } from "react";
import { Backdrop, Box, Button, CircularProgress, Modal } from "@mui/material";
import { useFieldArray, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  DeleteCallApi,
  GetCallApi,
  PostCallApi,
} from "../../../../Action/Action";
import { MdDelete } from "react-icons/md";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

function MultiAddress() {
  //   const { usDetails } = props;
  const { id } = useParams();

  const [stateList, setStateList] = useState([]);
  const [tempListwr, setTempListwr] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [openDeleteD, setOpenDeleteD] = useState();
  const [open, setOpen] = React.useState(false);
  const handleOpen = (e, index) => {
    setOpen(true);
    setOpenDeleteD({ ...e, index: index });
  };
  const handleClose = () => setOpen(false);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useForm();
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const { fields, append, remove } = useFieldArray({
    control,
    name: "addresses",
  });
  const onSubmit = async (data) => {
    if (id) {
      return false;
    }
    let seData = {
      url: `/api/UserMst/SaveAddress`,
      body: {
        userId: LogDetail?.userId,
        address: data?.addresses?.map((x) => {
          if (x?.addressType === "Home") {
            return { ...x, isPrimary: true };
          }
          return x;
        }),
      },
      headers: headers,
    };
    let res = await PostCallApi(seData);
    if (res?.status === 200) {
      if (res?.data?.success) {
        GetUsDetails(LogDetail?.userId);
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast.error(res.data.message, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } else {
      toast.error(res.data.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  useEffect(() => {
    GetStateList();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (stateList?.length > 0) {
      GetUsDetails(LogDetail?.userId);
    }
    // eslint-disable-next-line
  }, [stateList]);
  const GetUsDetails = async (id) => {
    let seData = {
      url: `/api/UserMst/GetAttendeeRoleData?userId=${id}`,
      headers: headers,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res.data.success) {
        let ud = res.data.payload[0];
        if (ud?.address?.length > 0) {
          setValue("addresses", ud?.address);
        } else {
          append({
            companyName: "",
            city: "",
            zip: "",
            addressType: "Home",
            state: "",
            address1: "",
          });
        }
      }
    }
  };

  const GetStateList = async () => {
    let seData = {
      url: "/api/Common/GetStateList",
      headers: headers,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        setStateList(res.data.payload);
      }
    }
  };
  const handleAdd = (ind) => {
    if (ind === 2) {
      Swal.fire({
        position: "top-center",
        width: 400,
        // icon: "success",
        allowOutsideClick: false,
        text: "You can not add more than 3 address",
        confirmButtonColor: "#808080",
        // color: "#ffffff",
        background: "gainsboro",
        // showClass: {
        //   popup: "animate__animated animate__fadeInDown",
        // },
        // hideClass: {
        //   popup: "animate__animated animate__fadeOutRight",
        // },
      });
    } else {
      append({
        companyName: "",
        city: "",
        zip: "",
        state: "",
        address1: "",
        addressType:
          ind === 0
            ? getValues(`addresses.${ind}.addressType`) === "Home"
              ? "Office"
              : getValues(`addresses.${ind}.addressType`) === "Office"
              ? "Home"
              : "Home"
            : ind === 1
            ? getValues(`addresses.${ind - 1}.addressType`) === "Home" &&
              getValues(`addresses.${ind}.addressType`) === "Office"
              ? "Other"
              : getValues(`addresses.${ind - 1}.addressType`) === "Office" &&
                getValues(`addresses.${ind}.addressType`) === "Home"
              ? "Other"
              : getValues(`addresses.${ind - 1}.addressType`) === "Other" &&
                getValues(`addresses.${ind}.addressType`) === "Office"
              ? "Home"
              : getValues(`addresses.${ind - 1}.addressType`) === "Other" &&
                getValues(`addresses.${ind}.addressType`) === "Home"
              ? "Office"
              : "Home"
            : "",
        costs: "",
      });
    }
  };
  const handleDropManage = (e, index) => {
    const temp = [...Array(parseInt(fields?.length)).keys()]?.map((as, i) => {
      if (i !== index) {
        if (getValues(`addresses.${i}.addressType`) === e) {
          setValue(`addresses.${index}.addressType`, "");
          return Swal.fire({
            position: "top-center",
            width: 400,
            // icon: "success",
            text: "You can not add same template please choose other one",
            confirmButtonColor: "#808080",
            // color: "#ffffff",
            background: "gainsboro",
            // showClass: {
            //   popup: "animate__animated animate__fadeInDown",
            // },
            // hideClass: {
            //   popup: "animate__animated animate__fadeOutRight",
            // },
          });
        }
      }
      return undefined;
    });
    if (temp?.length > 0) {
      setTempListwr(temp);
    } else {
      setTempListwr(tempListwr);
    }
  };

  const handledelete = async () => {
    if (openDeleteD?.addressId) {
      setOpenCircle(true);
      let seData = {
        url: `/api/UserMst/DeleteAddress?addressId=${openDeleteD?.addressId}`,
        headers: headers,
      };
      let responce = await DeleteCallApi(seData);
      if (responce.data.success) {
        setOpenCircle(false);
        remove(openDeleteD?.index);
        handleClose();
        toast.success(responce.data.message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        GetUsDetails(LogDetail?.userId);
      }
    } else {
      handleClose();
      remove(openDeleteD?.index);
    }
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="title my-3">Addresses</div>
      <div className="card">
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)} className="form_box">
            <div className="row">
              {fields?.map((item, index) => {
                return (
                  <div
                    className={`col-md-12 set-backcolor mb-3 ${
                      fields?.length === index + 1 ? "" : "border-bottom"
                    }`}
                    key={index}
                  >
                    <div className="row">
                      <div className="col-xl-3">
                        <div className="form-group">
                          <label className="form-label">
                            Address Type
                            {/* <span className="red-color"> *</span> */}
                          </label>
                          <select
                            className="form-select"
                            placeholder="Enter sponsorship descriptions"
                            disabled={id ? true : false}
                            {...register(`addresses.${index}.addressType`, {
                              // required: {
                              //   value: true,
                              //   message: "This field is required",
                              // },
                              onChange: (e) =>
                                handleDropManage(e.target.value, index),
                            })}
                          >
                            <option value="Home">Home</option>
                            <option value="Office">Office</option>
                            <option value="Other">Other</option>
                          </select>
                          {/* {errors.addresses?.[index]?.addressType && (
                            <span role="alert" className="error_text">
                              {errors.addresses?.[index]?.addressType.message}
                            </span>
                          )} */}
                        </div>
                      </div>

                      <div className="col-xl-3">
                        <div className="form-group">
                          <label className="form-label">
                            City
                            {/* <span className="red-color"> *</span> */}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter City"
                            disabled={id ? true : false}
                            {...register(`addresses.${index}.city`, {
                              // required: {
                              //   value: true,
                              //   message: "This field is required",
                              // },
                              pattern: {
                                value: /^[^\s]+(\s+[^\s]+)*$/,
                                message:
                                  "Starting and Ending Space not allowed",
                              },
                            })}
                          />
                          {/* {errors.addresses?.[index]?.city && (
                            <span role="alert" className="error_text">
                              {errors.addresses?.[index]?.city.message}
                            </span>
                          )} */}
                        </div>
                      </div>
                      <div className="col-xl-3">
                        <div className="form-group">
                          <label className="form-label">
                            Zip
                            {/* <span className="red-color"> *</span> */}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Zip"
                            disabled={id ? true : false}
                            {...register(`addresses.${index}.zip`, {
                              // required: {
                              //   value: true,
                              //   message: "This field is required",
                              // },
                              maxLength: {
                                value: 6,
                                message: "Max 6 Number",
                              },
                            })}
                          />
                          {errors.addresses?.[index]?.zip && (
                            <span role="alert" className="error_text">
                              {errors.addresses?.[index]?.zip.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-3">
                        <div className="form-group">
                          <label className="form-label">
                            State
                            {/* <span className="red-color"> *</span> */}
                          </label>
                          <select
                            type="text"
                            className="form-select"
                            placeholder="Enter State"
                            disabled={id ? true : false}
                            {...register(`addresses.${index}.state`, {
                              // required: {
                              //   value: true,
                              //   message: "This field is required",
                              // },
                            })}
                          >
                            <option value="">Select State</option>
                            {stateList &&
                              stateList?.map((st, i) => (
                                <option value={st?.stateName} key={i}>
                                  {st?.stateName}
                                </option>
                              ))}
                          </select>
                          {/* {errors.addresses?.[index]?.state && (
                            <span role="alert" className="error_text">
                              {errors.addresses?.[index]?.state.message}
                            </span>
                          )} */}
                        </div>
                      </div>
                      <div className="col-xl-3">
                        <div className="form-group">
                          <label className="form-label">
                            Address
                            {/* <span className="red-color"> *</span> */}
                          </label>
                          <textarea
                            rows={3}
                            className="form-control"
                            placeholder="Enter Address"
                            disabled={id ? true : false}
                            {...register(`addresses.${index}.address1`, {
                              // required: {
                              //   value: true,
                              //   message: "This field is required",
                              // },
                              pattern: {
                                value: /^[^\s]+(\s+[^\s]+)*$/,
                                message:
                                  "Starting and Ending Space not allowed",
                              },
                            })}
                          ></textarea>
                          {/* {errors.addresses?.[index]?.address1 && (
                            <span role="alert" className="error_text">
                              {errors.addresses?.[index]?.address1.message}
                            </span>
                          )} */}
                        </div>
                      </div>
                      <div className="col-xl-3">
                        <div className="form-group">
                          <label className="form-label">Company Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Company Name"
                            disabled={id ? true : false}
                            {...register(`addresses.${index}.companyName`, {
                              pattern: {
                                value: /^[^\s]+(\s+[^\s]+)*$/,
                                message:
                                  "Starting and Ending Space not allowed",
                              },
                            })}
                          />
                          {errors.addresses?.[index]?.companyName && (
                            <span role="alert" className="error_text">
                              {errors.addresses?.[index]?.companyName.message}
                            </span>
                          )}
                        </div>
                      </div>
                      {!id ? (
                        <div className="col-md-6 ms-auto my-auto">
                          <div className="text-end form-group m-0">
                            {index === 0 ? (
                              <Button
                                className="ms-3 detail_button"
                                type="button"
                                onClick={() => handleAdd(fields?.length - 1)}
                              >
                                Add
                              </Button>
                            ) : (
                              <div style={{ cursor: "pointer" }}>
                                <Button
                                  className="delet_Icon_button"
                                  onClick={() => handleOpen(item, index)}
                                  // onClick={() => remove(index)}
                                >
                                  <MdDelete fontSize={24} />
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })}
              {!id ? (
                <div className=" col-md-12">
                  <div className="w-auto form-group filter_btn mt-auto">
                    <Button className="detail_button" type="submit">
                      Save
                    </Button>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </form>
        </div>
      </div>
      {open && (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="toggle_delete_data"
          >
            <Box sx={style} className="delete_data_box">
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openCircle}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <div className="popup_delete_detail_box">
                <h6>Delete Address</h6>
                <div className="popup_delete_detail">
                  <p className="delete_text">
                    Are you sure you want to Delete
                    <span> {openDeleteD?.addressType} address ?</span>
                  </p>
                  <button
                    type="button"
                    className="popup_btn delete"
                    onClick={handledelete}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="popup_btn cancel"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}

export default MultiAddress;
