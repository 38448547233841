import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Collapse, Nav, Navbar, NavbarToggler } from "reactstrap";
import "./index.scss";
import { useSelector } from "react-redux";
import logo from "../../assets/images/fig_events.png";
import { Link } from "react-scroll";
import { Config } from "../../Utils/Config";

const pro_img = "/assets/images/default_profile.jpg";

function WebMenu() {
  // const showSub = (event, item) => {
  //   if (item.ChildMenus !== undefined && item?.ChildMenus?.length > 0) {
  //     setAnchorEl(event.currentTarget);
  //   } else {
  //     setAnchorEl();
  //   }
  // };

  const [isOpen, setIsOpen] = useState(false);
  let nav = useNavigate();
  let { pathname } = useLocation();
  // const HeadName = useSelector((state) => state.headerMenu.headerName);
  const accountInformation = useSelector((state) => state.login.LoginDetails);

  // const headers = {
  //   Authorization: `Bearer ${token}`,
  //   "Content-Type": "application/json",
  //   Accept: "*/*",
  // };
  // let LoginUserd = useSelector((state) => state.login?.LoginDetails);

  // const handleClickBook = (event) => {
  //   setAnchorEl1(event?.currentTarget);
  // };

  // const loggedIn = useSelector(
  //   (state) => state.login?.LoginDetails?.authenticate
  // );
  // let location = useLocation();

  const [settingoptions, setSettingoptions] = useState([
    {
      pathname: "/users",
      menuName: "Manage Users",
    },
    {
      pathname: "/event-approval",
      menuName: "Event Approval",
    },
  ]);
  useEffect(() => {
    if (accountInformation?.roleId === 1) {
      let sm = [
        {
          pathname: "/menus",
          menuName: "Menu List",
        },
        {
          pathname: "/menu-access",
          menuName: "Menu Access",
        },
      ];
      setSettingoptions([...settingoptions, ...sm]);
    }
    // eslint-disable-next-line
  }, [accountInformation]);
  const gotodash = () => {
    if (
      accountInformation?.roleName === "Exhibitor" ||
      accountInformation?.roleId === 4
    ) {
      nav("/exhibitor-dashboard", { replace: true });
    } else if (
      accountInformation?.roleName === "User" ||
      accountInformation?.roleId === 5
    ) {
      nav("/attendee-dashboard", {
        replace: true,
      });
    } else if (
      accountInformation?.roleName === "Admin" ||
      accountInformation?.roleId === 1 ||
      accountInformation?.roleId === 2
    ) {
      nav("/home", { replace: true });
    } else if (accountInformation?.roleName === "Speaker") {
      nav("/speaker-profile", { replace: true });
    } else {
      nav(-1);
    }
  };
  const gotologin = () => {
    nav("/login", { replace: true });
  };
  return (
    <>
      <section className={`webmenu`}>
        <div className="container">
          <Navbar expand="lg" className="nav_bar">
            <div className="mobile_toggle">
              <NavLink className="NavLink" to={"/"}>
                {/* Conference Planner */}
                <img
                  src={logo}
                  style={{ height: "70px" }}
                  className="logo img-fluid"
                  alt=""
                />
              </NavLink>
              {accountInformation.authenticate ? (
                <NavbarToggler
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 12H16"
                      stroke="#666666"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3 6H21"
                      stroke="#fff"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3 18H21"
                      stroke="#fff"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </NavbarToggler>
              ) : (
                <Link className="hover login-btn" to="" onClick={gotologin}>
                  Sign in or Register
                </Link>
              )}
            </div>
            <Collapse isOpen={isOpen} navbar className="header_nav">
              <Nav
                navbar
                className="m-auto nav_action align-items-center "
                // style={{ visibility: "hidden" }}
              >
                <li className="hover_link">
                  <NavLink className="hover" to="/">
                    Home
                  </NavLink>
                </li>
                <li className="hover_link">
                  <NavLink className="hover" to="/conference">
                    Conference
                  </NavLink>
                </li>
                <li className="hover_link">
                  {pathname === "/" ? (
                    <Link className="hover" to="about" duration={500}>
                      About
                    </Link>
                  ) : (
                    <NavLink className="hover" to="/">
                      About
                    </NavLink>
                  )}
                </li>
                <li className="hover_link">
                  <NavLink className="hover" to="/how-it-works">
                    How It Works
                  </NavLink>
                </li>
                <li className="hover_link">
                  <NavLink className="hover" to="/our-products">
                    Our Products
                  </NavLink>
                </li>
                <li className="hover_link">
                  <NavLink className="hover" to="/blogs">
                    Blogs
                  </NavLink>
                </li>
              </Nav>
              {accountInformation.authenticate ? (
                <div
                  className="header_option"
                  style={{ backgroundColor: "transparent" }}
                  onClick={gotodash}
                >
                  {accountInformation?.profileImage ? (
                    <img
                      src={`${Config.API_HOST_URL_live}${accountInformation?.profileImage}`}
                      alt=""
                      className="user_profile"
                      width={60}
                      height={60}
                      style={{ borderRadius: "50px", objectFit: "cover" }}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = pro_img;
                      }}
                    />
                  ) : (
                    <>
                      <img
                        src={pro_img}
                        style={{ borderRadius: "50px", objectFit: "cover" }}
                        className="user_profile"
                        width={60}
                        height={60}
                        alt=""
                      />
                    </>
                  )}
                  <div className="profile_info">
                    <span className={`f-15-500`}>
                      {accountInformation?.firstName
                        ? accountInformation?.firstName +
                          " " +
                          accountInformation?.lastName
                        : accountInformation?.name}
                    </span>
                    <span className="user_type">
                      {accountInformation?.roleName}
                    </span>
                  </div>
                </div>
              ) : (
                <Link className="hover login-btn" to="" onClick={gotologin}>
                  Sign in or Register
                </Link>
              )}
            </Collapse>
          </Navbar>
        </div>
      </section>
    </>
  );
}

export default React.memo(WebMenu);
