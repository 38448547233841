import React, { lazy, Suspense, useEffect, useState } from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";
import { GetCallApi } from "../../../Action/Action";
import { Config } from "../../../Utils/Config";
import { VscEye } from "react-icons/vsc";
import { Link } from "react-router-dom";

const NewLIstTable = lazy(() =>
  import("../../Components/Reusable/NewLIstTable")
);
const DeletePopup = lazy(() => import("../../Components/Reusable/DeletePopup"));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCellsUser = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "conferenceName",
    label: "Conference Name",
  },
  {
    id: "firstName",
    label: "First Name",
  },
  {
    id: "lastName",
    label: "Last Name",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "schoolDistrict",
    label: "School District",
  },
  {
    id: "submittedDate",
    label: "Submitted Date",
  },
];

function ConferenceTestimonialVideoOrAudioList() {
  const [openCircle, setOpenCircle] = useState(false);
  const [attendeeList, setAttendeeList] = useState([]);
  const logindetail = useSelector((state) => state.login.LoginDetails);

  useEffect(() => {
    document.title = "FIG Events | Conference Testimonial Video/Audio List";
    GetAttList();
    // eslint-disable-next-line
  }, []);

  const GetAttList = async () => {
    setOpenCircle(true);
    setAttendeeList([]);

    let seData = {
      url: `/api/ConferenceTestimonialVideoOrAudio/GetConferenceTestimonialVideoOrAudioList?userId=${logindetail?.userId}`,
    };
    let res = await GetCallApi(seData);

    setOpenCircle(false);
    if (res?.status === 200) {
      if (res.data.success) {
        let comList = res?.data?.payload?.map((c, i) => {
          return {
            id: i + 1,
            ...c,
            submittedDate: c?.submittedDate
              ? new Date(c?.submittedDate)?.toLocaleDateString("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                })
              : "",
            groupUserList:
              c?.videoOrAudioList?.length > 0
                ? c?.videoOrAudioList?.map((x, index) => {
                    return {
                      ...x,
                      id: index + 1,

                      View: (
                        <div className="d-flex">
                          {x?.attachmentPath ? (
                            <Link
                              target="_blank"
                              className="btn_edit me-3 d-flex align-items-center"
                              to={Config.API_HOST_URL_live + x?.attachmentPath}
                            >
                              <VscEye />
                            </Link>
                          ) : (
                            ""
                          )}
                        </div>
                      ),
                    };
                  })
                : "",
            Action: (
              <DeletePopup
                title={"Delete Conmference Testimonial Video/Audio"}
                text={
                  c?.firstName +
                  " " +
                  c?.lastName +
                  "'s Testimonial Video/Audio"
                }
                url={`/api/ConferenceTestimonialVideoOrAudio/DeleteConferenceTestimonialVideoOrAudioById?conferenceTestimonialVideoOrAudioId=${c?.conferenceTestimonialVideoOrAudioId}`}
                callBack={true}
                callBackFunction={GetAttList}
              />
            ),
          };
        });
        setAttendeeList(comList);
      }
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="conference_testimonial_video_audio_list">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-12 p-0 new_card">
                <NewLIstTable
                  title="Conference Testimonial Video/Audio List"
                  headCells={headCellsUser}
                  ListData={attendeeList}
                  action={true}
                  //   fileName={"Conference Testimonial Video/Audio Report"}
                  //   exportDataList={false}
                  showUserListInTable={true}
                  innerProduct={true}
                  subHeadCells={[
                    {
                      id: "id",
                      label: "id",
                    },
                    {
                      id: "attachmentTitle",
                      label: "Attachment Title",
                    },
                    {
                      id: "attachmentType",
                      label: "Attachment Type",
                    },
                    {
                      id: "View",
                      label: "Attachment Link",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default ConferenceTestimonialVideoOrAudioList;
