import { CircularProgress } from "@mui/material";
import React, { Suspense, lazy } from "react";
import { useParams } from "react-router-dom";

const CreateCompanyForm = lazy(() => import("./CreateComForm"));
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function CreateCompany() {
  // const [companyId, setCompanyId] = useState("");
  const { id } = useParams();

  // useEffect(() => {
  //   if (id) {
  //     setCompanyId(id);
  //   }
  //   // eslint-disable-next-line
  // }, [id]);

  return (
    <Suspense fallback={renderLoader()}>
      <section className="create_company">
        <div className="main_wrapper">
          <div className="title mb-3">{id ? "Edit" : "Create"} Company </div>
          <div className="create_course">
            <CreateCompanyForm />
          </div>
        </div>
      </section>
    </Suspense>
  );
}

export default CreateCompany;
