import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
// import whitelogo from "../../assets/images/fig_events.png";
// import QR_left from "../../assets/images/QR_left.png";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import html2canvas from "html2canvas";
import QRCode from "react-qr-code";
import { customFilter } from "../Components/CustomFilterForReactSelect";
import { GetCallApi } from "../../Action/Action";
import Select from "react-select";
import { ToastError } from "../Components/Toast";
import { useReactToPrint } from "react-to-print";
import NewPaginations from "../Components/Reusable/NewPaginations/Pagination";
import { Config } from "../../Utils/Config";

function MYQRCodes() {
  const qrRef = useRef();
  const [qrSave, setQRSave] = useState(false);
  const [openCircle, setOpenCircle] = useState(false);
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const [conList, setConList] = useState([]);
  const [confId, setConfId] = useState();
  const [confDetail, setConfDetail] = useState();
  const [usDetails, setUsDetails] = useState();
  // const [userList, setUserList] = useState();
  const [userId, setUserId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchField, setSearchField] = useState("");

  useEffect(() => {
    if (qrSave) {
      let conferenceName = confId
        ? conList?.find((obj) => obj.value === confId)?.conferenceName
        : "Fig Events";
      exportAsImage(1, conferenceName + " QR");
    }
    // eslint-disable-next-line
  }, [qrSave]);
  const componentRef = React.useRef(null);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  // const saveQR = () => {
  //   setQRSave(true);
  //   setOpenCircle(true);
  // };

  const exportAsImage = async (el, imageFileName) => {
    const canvas = await html2canvas(qrRef.current);
    const image = canvas.toDataURL("image/png", 1.0);
    downloadQRCode(image, imageFileName);
  };

  const downloadQRCode = async (blob, fileName) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
    setQRSave(false);
    setOpenCircle(false);
  };
  useEffect(() => {
    document.title = "FIG Events | My QR Codes";
    getConfList(LogDetail?.userId);
    GetUserQRDetail("", LogDetail?.userId);
    setUserId(LogDetail?.userId);
    // if (LogDetail?.roleId === 1) {
    //   GetUserList();
    // }
    // eslint-disable-next-line
  }, []);

  const getConfList = async (uId) => {
    setConList([]);
    let seData = {
      url: `/api/Conference/GetApproveConferenceList?userId=${uId}`,
    };
    let res = await GetCallApi(seData);
    if (res.status === 200 && res.data.success) {
      let co = res.data.payload?.map((x) => {
        return {
          ...x,
          value: x?.conferenceId,
          label: x?.conferenceName,
        };
      });
      setConList(co);
    }
  };

  const GetUserQRDetail = async (e, uId) => {
    setConfId(e);
    setSearchField("");
    setCurrentPage(1);
    setUsDetails([]);
    if (e) {
      let conf = conList?.find((obj) => obj.value === e);
      setConfDetail(conf);
    } else {
      setConfDetail();
    }

    if (uId) {
      setOpenCircle(true);
      let res = await GetCallApi({
        url: `/api/UserMst/GetUserQRCodeString?userId=${uId}&conferenceId=${
          e ? e : ""
        }`,
      });
      setOpenCircle(false);
      if (res?.status === 200 && res?.data?.success) {
        let usd = res?.data?.payload;
        setUsDetails(usd);
      } else {
        ToastError(res?.data?.message);
      }
    }
  };

  // const GetUserList = async () => {
  //   let res = await PostCallApi({
  //     url: "/api/UserMst/UserList",
  //     body: {
  //       roleId: null,
  //       userName: "",
  //       email: "",
  //       userId: LogDetail?.userId,
  //     },
  //   });
  //   if (res.status === 200 && res.data.success) {
  //     let comList = res?.data?.payload?.map((c) => {
  //       let fName = c?.firstName ? c?.firstName : "",
  //         lName = c?.lastName ? " " + c?.lastName : "",
  //         role = c?.rollname ? " - " + c?.rollname : "";
  //       return {
  //         ...c,
  //         value: c?.userId,
  //         label: fName + lName + role,
  //         userName: fName + lName,
  //       };
  //     });
  //     setUserList(comList);
  //   }
  // };

  // const handleUser = (e) => {
  //   setUserId(e);
  //   setConfId();
  //   getConfList(e);
  //   GetUserQRDetail("", e);
  // };

  // const Print = () => {
  //   let printContents = document.getElementById("printablediv").innerHTML;

  //   console.log("pull");

  //   let win = window.open(
  //     "",
  //     "_blank",
  //     "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes"
  //   );
  //   win.document.write(printContents);
  //   setTimeout(function () {
  //     win.document.close();
  //     win.focus();
  //     win.print();
  //     win.close();
  //   }, 1000);
  // };
  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "AwesomeFileName",
    removeAfterPrint: true,
  });

  const mainCrad = {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    overflow: "hidden",
    border: "1px solid gainsboro",
    boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.11)",
    // boxShadow: "0px 4px 20px 0px rgba(238, 238, 238, 0.5019607843)",
    margin: "0.5rem",
    ".Card_of_user": {
      backgroundImage: `url(${Config.API_HOST_URL}/assets/images/QR_left.png)`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      display: "flex",
      justifyContent: "center",
      paddingTop: window.innerWidth < 681 ? "2.75rem" : "",
      ".info": {
        ".con_de": {
          display: "flex",
          ".left_text": {
            width: "85px",
          },
          ".right_text": {
            marginLeft: "0.5rem",
          },
        },
      },
    },
  };

  const searchitem = ["userName", "role"];
  const formattedSearchResults = usDetails?.filter((user) => {
    if (searchField) {
      return searchitem?.some((newItem) => {
        return (
          user[newItem]
            ?.toString()
            ?.toLowerCase()
            ?.indexOf(searchField.toLowerCase()) > -1
        );
      });
    } else return user;
  });

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="my-qr-codes-page">
        <style type="text/css" media="print">
          {
            "\
   @page { size: portrait; }\
"
          }
        </style>
        <div className="main_wrapper">
          <div className="title mb-3">My Badges </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Conference</label>
                    <Select
                      isMulti={false}
                      name="colors"
                      options={conList}
                      isClearable={true}
                      className="dropdown-single-select"
                      classNamePrefix="select"
                      placeholder="Select Conference"
                      filterOption={customFilter}
                      value={conList?.filter((obj) => obj.value === confId)}
                      onChange={(e) => GetUserQRDetail(e?.value, userId)}
                    />
                  </div>
                </div>
              </div>
              {usDetails ? (
                <div
                  className="user_detail_QR"
                  style={{ width: qrSave ? "100%" : "" }}
                >
                  <div className="d-flex">
                    <button
                      type="button"
                      className="dashboard_btn background"
                      style={{ marginRight: "auto" }}
                      onClick={() => handlePrint()}
                      // onClick={(e) => saveQR(e, LogDetail?.userId)}
                    >
                      Download QR Code
                    </button>
                    {usDetails?.length > 1 ? (
                      <div className={` search_sec ms-auto`}>
                        <div className="search_input w-100">
                          <img src={"/assets/icons/search_icon.svg"} alt="" />
                          <input
                            onChange={(e) => {
                              setSearchField(e.target.value);
                              setCurrentPage(1);
                            }}
                            type="text"
                            className="form-control"
                            placeholder="Type your search here"
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {usDetails?.length > 1 ? (
                    <div className="page_nation">
                      <div className="rowsPerPageOptions">
                        <span className="p_18_text">Items per page:</span>
                        <select
                          className="pagination_select p_16_text"
                          value={pageSize}
                          onChange={(e) => {
                            setPageSize(parseInt(e.target.value));
                            setCurrentPage(1);
                          }}
                        >
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="200">200</option>
                          <option value="500">500</option>
                        </select>
                      </div>
                      <NewPaginations
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={formattedSearchResults?.length}
                        pageSize={pageSize}
                        onPageChange={(page) => setCurrentPage(page)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="row m-0" id="printablediv" ref={componentRef}>
                    {formattedSearchResults
                      ?.slice(
                        (currentPage - 1) * pageSize,
                        (currentPage - 1) * pageSize + pageSize
                      )
                      ?.map((x, i) => (
                        <div
                          className={`col-lg-6   `}
                          style={{
                            // marginTop: "2rem",
                            marginBottom: "3rem",
                          }}
                          key={i}
                        >
                          <Box className="qr-card" sx={mainCrad} ref={qrRef}>
                            <div className="Card_of_user">
                              <div className="info" style={{ padding: "1rem" }}>
                                <div
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "600",
                                    marginBottom: "0rem",
                                    color: "#80c242",
                                  }}
                                >
                                  <>
                                    {x?.userName ? x?.userName : "Unknown"}
                                    {x?.roleName ? " - " + x?.roleName : ""}
                                  </>
                                </div>
                                <div
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    marginBottom: "0rem",
                                    height: x?.companyName ? "" : "21px",
                                  }}
                                >
                                  {x?.companyName ? x?.companyName : ""}
                                </div>
                                <div
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    marginBottom: "0rem",
                                    height: x?.userTitle ? "" : "18px",
                                  }}
                                >
                                  {x?.userTitle ? x?.userTitle : ""}
                                </div>
                                {confId ? (
                                  <div>
                                    <h6
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {confDetail?.conferenceName}
                                    </h6>
                                    <div
                                      className="con_de"
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      <span className="left_text">Date </span>:
                                      <span className="right_text">
                                        {" "}
                                        {confDetail?.conferenceStartDate
                                          ? new Date(
                                              confDetail?.conferenceStartDate
                                            )?.toLocaleString("en-us", {
                                              year: "numeric",
                                              month: "2-digit",
                                              day: "2-digit",
                                              hour: "2-digit",
                                              minute: "2-digit",
                                              hour12: true,
                                            })
                                          : ""}
                                      </span>
                                    </div>
                                    <div
                                      className="con_de"
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      <span className="left_text">
                                        Address{" "}
                                      </span>
                                      :
                                      <span className="right_text">
                                        {" "}
                                        {confDetail?.address}
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <div style={{ height: "60px" }}></div>
                                )}
                                {usDetails ? (
                                  <div
                                    className="qr_box"
                                    style={{
                                      textAlign: "center",
                                      // backgroundColor: "#fff",
                                      padding: "1rem 0",
                                    }}
                                  >
                                    <QRCode
                                      size={100}
                                      value={x?.userQRCode ? x?.userQRCode : ""}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {/* </div> */}
                              </div>
                            </div>
                          </Box>
                        </div>
                      ))}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MYQRCodes;
