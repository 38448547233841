import React, { Suspense, lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  CircularProgress,
  Typography,
} from "@mui/material";
import { GetCallApi } from "../../../Action/Action";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import Select from "react-select";
import { customFilter } from "../../Components/CustomFilterForReactSelect";

const PaymentTable = lazy(() =>
  import("../../user/PaymentHistory/PaymentTable")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCellsEvent = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "customerName",
    label: "Customer Name",
  },
  {
    id: "customerEmail",
    label: "Customer Email",
  },
  {
    id: "phone",
    label: "Phone",
  },
  {
    id: "companyName",
    label: "Company Name",
  },
  {
    id: "associationName",
    label: "Association Name",
    widthFix: 600,
  },
  {
    id: "groupName",
    label: "Group Name",
    widthFix: 600,
  },
  {
    id: "totalCost",
    label: "Total Cost",
  },
  {
    id: "conferenceType",
    label: "Registration Type",
  },
  {
    id: "chargeType",
    label: "Charge Type",
  },
  {
    id: "purchaseType",
    label: "Payment Reference",
  },
  {
    id: "poNumber",
    label: "PONumber",
  },
  {
    id: "datePaid",
    label: "Date Paid",
  },
  {
    id: "checkNumber",
    label: "Check Number",
  },
  {
    id: "paymentStatus",
    label: "Payment Status",
  },
  {
    id: "dateRegistered",
    label: "Date Registered",
  },
  {
    id: "registeredBy",
    label: "Registered By",
  },
  {
    id: "registeredType",
    label: "Registered Type",
  },
  {
    id: "adaDietaryRestrictions",
    label: "Ada/Dietary restrictions",
    widthFix: 600,
  },
  {
    id: "eventList",
    label: "Events",
    widthFix: 600,
  },
  {
    id: "sessionList",
    label: "Sessions",
    widthFix: 600,
  },
];

function AttendeeSummaryReport() {
  const [dataOfTable, setDataOfTable] = useState([]);
  const [conferenceList, setConferenceList] = useState([]);

  const [confId, setConfId] = useState("");
  const [filterPage, setFilterPage] = useState(false);
  const [openCircle, setOpenCircle] = useState(false);

  useEffect(() => {
    document.title = "FIG Events | Attendee Summary Report";
    geConfeList();
    // eslint-disable-next-line
  }, []);

  const logindetail = useSelector((state) => state.login.LoginDetails);
  const geConfeList = async () => {
    let seData = {
      url: `/api/Conference/GetApproveConferenceList?userId=${logindetail?.userId}`,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        let comList = res?.data?.payload?.map((x) => {
          return {
            ...x,
            label: x?.conferenceName,
            value: x?.conferenceId,
          };
        });

        setConferenceList(comList);
        setConfId(comList[0]?.conferenceId);
        GetSMReportList(comList[0]?.conferenceId);
      }
    }
  };
  const GetSMReportList = async (id) => {
    setDataOfTable([]);
    setConfId(id);
    if (id) {
      setOpenCircle(true);
      let seData = {
        url: `/api/Reports/AttendeeConferenceSummaryReport?userId=${logindetail?.userId}&conferenceId=${id}`,
      };
      let res = await GetCallApi(seData);
      setOpenCircle(false);
      setFilterPage(!filterPage);

      if (res.status === 200) {
        if (res.data.success) {
          setOpenCircle(false);
          let comList = res?.data?.payload?.map((c, i) => {
            return {
              id: i + 1,
              ...c,
              datePaid: c?.datePaid
                ? new Date(c?.datePaid).toLocaleDateString("en-us", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })
                : "",
              dateRegistered: c?.dateRegistered
                ? new Date(c?.dateRegistered).toLocaleDateString("en-us", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })
                : "",
              groupUserList:
                c?.userDetailsList?.length > 0
                  ? c?.userDetailsList?.map((x, index) => {
                      return {
                        ...x,
                        id: index + 1,
                      };
                    })
                  : [],
            };
          });
          setDataOfTable(comList);
        } else {
          setOpenCircle(false);
        }
      } else {
        setOpenCircle(false);
      }
    }
  };

  return (
    <>
      <Backdrop open={openCircle} sx={{ color: "#fff", zIndex: () => 9999 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography style={{ marginLeft: "20px" }}>
                    <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                    <span className="filter_by">Filter By</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className="d-flex flex-wrap gap-4 mb-3"
                    style={{ marginLeft: "20px" }}
                  >
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="form-label">Conference</label>
                        <Select
                          isMulti={false}
                          name="colors"
                          options={conferenceList}
                          isClearable={true}
                          className="dropdown-single-select"
                          classNamePrefix="select"
                          placeholder="Select Conference"
                          filterOption={customFilter}
                          value={conferenceList?.filter(
                            (obj) => obj.value === confId
                          )}
                          onChange={(e) => GetSMReportList(e?.value)}
                        />
                        {/* <select
                          className="form-select "
                          value={confId}
                          onChange={(e) => GetSMReportList(e?.target?.value)}
                        >
                          <option value="">Select Conference</option>
                          {conferenceList &&
                            conferenceList?.map((c) => (
                              <option
                                value={c?.conferenceId}
                                key={c?.conferenceId}
                              >
                                {c?.conferenceName}
                              </option>
                            ))}
                        </select> */}
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <div className="col-md-12 accordian_card p-0 mb-4">
                <PaymentTable
                  title="Attendee Summary Report"
                  action={false}
                  headCells={headCellsEvent}
                  ListData={dataOfTable}
                  dropdown={false}
                  addBtn={false}
                  showUserListInTable={false}
                  fileName={"Attendee Summary Report"}
                  exportDataList={true}
                  innerProduct={false}
                  hidePDF={true}
                  filterPage={filterPage}
                  subHeadCells={[
                    {
                      id: "id",
                      label: "id",
                    },
                    {
                      id: "userName",
                      label: "User Name",
                    },
                    {
                      id: "userEmail",
                      label: "Email",
                    },
                    {
                      id: "userPhone",
                      label: "Phone",
                    },
                    {
                      id: "productQuantity",
                      label: "Product Quantity",
                    },
                    {
                      id: "productPrice",
                      label: "Product Price",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default AttendeeSummaryReport;
