import React, { Suspense, lazy, useEffect, useState } from "react";
import "./index.scss";
import { Backdrop, CircularProgress } from "@mui/material";
import { GetCallApi } from "../../../../Action/Action";

const AssociationCard = lazy(() =>
  import("../../../Components/Reusable/AssociationCard")
);
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function AssociationStoreForUser() {
  const [openCircle, setOpenCircle] = useState(false);
  const [dataOfTable, setDataOfTable] = useState([]);

  useEffect(() => {
    document.title = "FIG Events | Association Store";
    getAssoList();
    // eslint-disable-next-line
  }, []);
  const getAssoList = async () => {
    let seData = {
      url: `/api/Association/GetAllAssociation`,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        let data = res.data.payload?.map((x, i) => {
          return {
            ...x,
            id: i + 1,
          };
        });
        setDataOfTable(data);
      }
    }
  };

  return (
    <>
      {" "}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="associationStore_for_user_page">
          <div className="main_wrapper">
            <div className="new_card">
              <div className="new_listdata_control_sec">
                <div className="title">Associtaion Stores</div>
              </div>
              <AssociationCard associationList={dataOfTable} />
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default AssociationStoreForUser;
