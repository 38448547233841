import React, { useState } from "react";
import "./index.scss";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress, TextField } from "@mui/material";
// import eye from "../../../../assets/icons/eye.svg";
// import eyeOff from "../../../../assets/icons/eye-off.svg";
import { ToastContainer, toast } from "react-toastify";
import { PostCallApi } from "../../../../Action/Action.jsx";
import { useSelector } from "react-redux";

function UpdatePassword() {
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [passwordSh, setPasswordSh] = useState(false);
  const [derror, setDerror] = useState();
  const [openCircle, setOpenCircle] = useState(false);
  let nav = useNavigate();

  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const logindetail = useSelector((state) => state.login.LoginDetails);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    let rsData = {
      ...data,
      userId: logindetail?.userId,
    };
    setOpenCircle(true);
    let seData = {
      url: `/api/UserMst/UpdateUserPassword`,
      body: rsData,
      headers: headers,
    };
    let res = await PostCallApi(seData);
    setOpenCircle(false);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        reset();

        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setTimeout(function () {
          nav(-1);
        }, 2100);
        setDerror({ Log: "" });
      } else {
        setOpenCircle(false);

        setDerror({ Log: res.data?.message });
        toast.error(res.data.message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } else {
      setOpenCircle(false);
      toast.error(res.data.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <section className="update_old_new_password">
        <div className="main_box">
          <div className="row m-0">
            <div className="col-md-12 p-0">
              <div className="inner_box">
                <h1>UPDATE PASSWORD</h1>
                <form
                  className="d-flex flex-column"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-group">
                    <div className="password">
                      <TextField
                        label="Old Password"
                        variant="outlined"
                        name="current-password"
                        className="w-100"
                        type={passwordSh ? "text" : "password"}
                        {...register("oldPassword", {
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                          minLength: {
                            value: 8,
                            message: "Must be 8 characters",
                          },
                          maxLength: {
                            value: 15,
                            message: "Max 15 characters",
                          },
                          pattern: {
                            value: /^\S+$/,
                            message: "Space not allowed",
                          },
                        })}
                      />
                      <span
                        className="eye"
                        onClick={() => setPasswordSh(!passwordSh)}
                      >
                        <img
                          src={
                            passwordSh === true
                              ? "/assets/icons/eye.svg"
                              : "/assets/icons/eye-off.svg"
                          }
                          alt="eye"
                        />
                      </span>
                    </div>
                    {errors.oldPassword && (
                      <span role="alert" className="error_text">
                        {errors.oldPassword.message}
                      </span>
                    )}
                  </div>
                  <div className="form-group">
                    <div className="password">
                      <TextField
                        label="New Password"
                        variant="outlined"
                        name="current-password"
                        className="w-100"
                        type={passwordShown1 ? "text" : "password"}
                        {...register("newPassword", {
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                          minLength: {
                            value: 8,
                            message: "Must be 8 characters",
                          },
                          maxLength: {
                            value: 15,
                            message: "Max 15 characters",
                          },
                          pattern: {
                            value: /^\S+$/,
                            message: "Space not allowed",
                          },
                          validate: (val) => {
                            if (watch("oldPassword") === val) {
                              return "Your old password and new password cannot be same";
                            }
                          },
                        })}
                      />
                      <span
                        className="eye"
                        onClick={() => setPasswordShown1(!passwordShown1)}
                      >
                        <img
                          src={
                            passwordShown1 === true
                              ? "/assets/icons/eye.svg"
                              : "/assets/icons/eye-off.svg"
                          }
                          alt="eye"
                        />
                      </span>
                    </div>
                    {errors.newPassword && (
                      <span role="alert" className="error_text">
                        {errors.newPassword.message}
                      </span>
                    )}
                  </div>{" "}
                  <div className="form-group">
                    <div className="password">
                      <TextField
                        label="Confirm Password"
                        variant="outlined"
                        name="current-password"
                        className="w-100"
                        type={passwordShown ? "text" : "password"}
                        {...register("confirmPassword", {
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                          minLength: {
                            value: 8,
                            message: "Must be 8 characters",
                          },
                          maxLength: {
                            value: 15,
                            message: "Max 15 characters",
                          },
                          pattern: {
                            value: /^\S+$/,
                            message: "Space not allowed",
                          },
                          validate: (val) => {
                            if (watch("newPassword") !== val) {
                              return "Password and Confirm Password does not match";
                            }
                          },
                        })}
                      />
                      <span
                        className="eye"
                        onClick={() => setPasswordShown(!passwordShown)}
                      >
                        <img
                          src={
                            passwordShown === true
                              ? "/assets/icons/eye.svg"
                              : "/assets/icons/eye-off.svg"
                          }
                          alt="eye"
                        />
                      </span>
                    </div>
                    {errors.confirmPassword && (
                      <span role="alert" className="error_text">
                        {errors.confirmPassword.message}
                      </span>
                    )}
                    {derror?.Log && (
                      <span role="alert" className="d-block error_text">
                        {derror.Log}
                      </span>
                    )}
                  </div>
                  <input
                    type="submit"
                    name="submit"
                    className="submit_btn w-10"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default UpdatePassword;
