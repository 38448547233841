import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { GetCallApi, PostCallApi } from "../../Action/Action";
import AssignTable from "../SuperAdmin/AssignUsers/AssignTable";
import { useForm } from "react-hook-form";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import Select from "react-select";
import { customFilter } from "../Components/CustomFilterForReactSelect";

const headCellsEvent = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "firstName",
    label: "First Name",
  },
  {
    id: "lastName",
    label: "Last Name",
  },
  {
    id: "rollname",
    label: "Role Name",
    widthFix: 600,
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "phone",
    label: "Phone",
  },
  {
    id: "state",
    label: "State",
  },
];

const SendTemplateUserList = () => {
  const [userArray, setUserArray] = useState([]);
  // const [dataOfTable, setDataOfTable] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [checkedValue, setCheckValue] = useState(false);
  const [filteredDataOfTable, setFilteredDataOfTable] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [templateId, setTemplateId] = useState("");
  const [err, setErr] = useState();
  const [conList, setConList] = useState([]);
  const [conId, setConId] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [totalRecord, setTotalRecord] = useState();
  const [stateList, setStateList] = useState([]);
  const [roleId, setRoleId] = useState();
  const [stateValue, setStateValue] = useState();

  const loginDetail = useSelector((state) => state.login.LoginDetails);

  const { register, getValues, setValue, handleSubmit } = useForm();

  useEffect(() => {
    GetRoleList();
    GetStateList();

    getTemplateList();
    getConfList();
    setValue("pageNumber", 1);
    setValue("pageSize", 10);
    // eslint-disable-next-line
  }, []);

  const getUserList = async (d) => {
    setOpenCircle(true);
    setFilteredDataOfTable([]);

    let seData = {
      url: `/api/UserMst/GetActiveUserList`,
      body: {
        userId: loginDetail?.userId,
        ...d,
        pageNumber: d.pageNumber ? d.pageNumber : 1,
        pageSize: d.pageSize ? d.pageSize : 10,
      },
    };
    let res = await PostCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.data.success) {
        let data = res.data.data.payload?.map((u, i) => {
          let pn = seData.body.pageNumber - 1;
          return {
            id: pn * seData.body.pageSize + i + 1,
            ...u,
            // id: i + 1,
            rollname: u?.rollname,
            Action: (
              <span className="control_btn d-flex align-items-center">
                <label className="personal_detail_label">
                  <input
                    type="checkbox"
                    className="login_check"
                    name="Remember Me"
                    checked={false}
                    onChange={(e) =>
                      handleSelectUser(e?.target?.checked, u?.userId)
                    }
                  />
                  <span className="checkmark"></span>
                </label>
              </span>
            ),
          };
        });
        // setDataOfTable(data);
        setFilteredDataOfTable(data);
        setTotalRecord(res.data.data.totalRecord);
      }
    }
  };

  const GetRoleList = async () => {
    let res = await GetCallApi({
      url: `/api/Role/RoleList?userId=${loginDetail?.userId}`,
    });
    if (res.status === 200 && res.data.success) {
      let comList = res?.data?.payload?.map((c) => {
        return {
          roleId: c?.roleId,
          roleName: c?.roleName,
          value: c?.roleId,
          label: c?.roleName,
        };
      });
      setRoleList(comList);
    }
  };

  const GetStateList = async () => {
    let seData = {
      url: "/api/Common/GetStateList",
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200 && res.data.success) {
      setStateList(
        res.data.payload?.map((x) => {
          return {
            label: x?.stateName,
            value: x?.stateName,
          };
        })
      );
    }
  };

  const getTemplateList = async () => {
    setOpenCircle(true);
    let seData = {
      url: `/api/template/GetTemplateDropdownList`,
    };
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        setTemplateList(
          res.data.payload?.map((x) => {
            return {
              ...x,
              label: x?.templateTitle,
              value: x?.templateId,
            };
          })
        );
      }
    }
  };

  const getConfList = async () => {
    let seData = {
      url: `/api/Conference/GetAllWithoutTokenConferenceList?isAlphanumericOrder=true`,
    };
    let res = await GetCallApi(seData);
    if (res.status === 200) {
      if (res.data.success) {
        let co = res.data.payload
          ?.filter(
            (x) =>
              x?.conferenceEnddate?.split("T")[0] >=
              new Date()?.toISOString()?.split("T")[0]
          )
          ?.map((x) => {
            return {
              ...x,
              label: x?.conferenceName,
              value: x?.conferenceId,
            };
          });
        setConList(co);
      }
    }
  };

  const handleTemplate = (value) => {
    setTemplateId(value);
  };

  const changetbData = () => {
    let newtb = filteredDataOfTable?.map((pd) => {
      return {
        ...pd,
        add:
          userArray?.filter((x) => x === pd?.userId)?.length > 0 ? true : false,
        Action: (
          <span className="control_btn d-flex align-items-center">
            <label className="personal_detail_label">
              <input
                type="checkbox"
                className="login_check"
                name="Remember Me"
                checked={
                  userArray?.filter((x) => x === pd?.userId)?.length > 0
                    ? true
                    : false
                }
                onChange={(e) =>
                  handleSelectUser(e?.target?.checked, pd?.userId)
                }
              />
              <span className="checkmark"></span>
            </label>
          </span>
        ),
      };
    });
    // setDataOfTable(newtb);
    setFilteredDataOfTable(newtb);
  };

  useEffect(() => {
    if (userArray?.length > 0) {
      changetbData();
    } else {
      changetbData();
    }
    // eslint-disable-next-line
  }, [userArray]);

  const handleSelectUser = (val, uId) => {
    if (val) {
      setUserArray((old) => [...old, uId]);
      setCheckValue(false);
    } else {
      setUserArray((old) => old?.filter((d) => d !== uId));
    }
  };

  const handleMultiCheck = (v, i) => {
    if (v) {
      setUserArray(i?.map((id) => id?.userId));
      setCheckValue(true);
    } else {
      setUserArray([]);
      setCheckValue(false);
    }
  };

  const onSubmit = async () => {
    if (templateId) {
      setErr({ err: "" });
      if (conId) {
        setErr({ con_err: "" });
        const data = {
          templateId: templateId,
          conferenceId: conId,
          userId: userArray,
        };
        let seData = {
          url: "/api/Template/SendEmailByUsers",
          body: data,
        };
        setOpenCircle(true);
        let res = await PostCallApi(seData);
        setOpenCircle(false);
        if (res.status === 200) {
          if (res.data.success) {
            setCheckValue(false);
            setUserArray([]);
            setTemplateId("");
            setConId("");
            toast.success(res.data.message, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            setErr({ err: "" });
          } else {
            setErr({ err: res.data?.message });
            toast.error(res.data.message, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      } else {
        setErr({ con_err: "Please select conference" });
      }
    } else {
      setErr({ err: "Please select template" });
    }
  };

  // Function to filter data based on selected user role
  // const handleUser = (value) => {
  //   setCheckValue(false);
  //   getUserList(value);
  // };

  const getApiData = (e) => {
    const sddata = {
      userName: getValues("userName") ?? "",
      email: getValues("email") ?? "",
      roleId: getValues("roleId") ? parseInt(getValues("roleId")) : 0,
      AssociationLabel: getValues("Labels") ?? "",
      state: getValues("state") ?? "",
      pageNumber: e.page,
      pageSize: e.no,
    };
    setValue("pageNumber", e.page);
    setValue("pageSize", e.no);
    setCheckValue(false);
    getUserList(sddata);
  };

  const handleClearFilter = () => {
    setValue("userName", "");
    setValue("email", "");
    setValue("roleId", "");
    setValue("Labels", "");
    setValue("state", "");
    setValue("pageNumber", 1);
    setValue("pageSize", 10);
    setRoleId();
    setStateValue();
  };

  const handleRole = (e) => {
    setRoleId(e);
    setValue("roleId", e);
  };
  const handleState = (e) => {
    setStateValue(e);
    setValue("state", e);
  };

  return (
    <section className="assign_user">
      <div className="main_wrapper">
        <div className="row">
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<GridExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography style={{ marginLeft: "20px" }}>
                <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                <span className="filter_by">Filter By</span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="row ">
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label"> User Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by User Name"
                      {...register("userName")}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label"> Email </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by Email"
                      {...register("email")}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label"> Role</label>
                    <Select
                      isMulti={false}
                      name="colors"
                      options={roleList}
                      isClearable={true}
                      className="dropdown-single-select"
                      classNamePrefix="select"
                      placeholder="Select Role"
                      filterOption={customFilter}
                      value={roleList?.filter((obj) => obj.value === roleId)}
                      onChange={(e) => handleRole(e?.value)}
                    />
                    <select
                      className="form-select d-none"
                      {...register("roleId")}
                    >
                      <option value="">Select Role</option>
                      {roleList &&
                        roleList?.map((role, i) => (
                          <option key={i} value={role.roleId}>
                            {role.roleName}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                {/* <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label"> Labels</label>
                    <select
                      className="form-select"
                      {...register("Labels", {
                        // onChange: (e) =>
                        //   getUserList(
                        //     getValues("roleId"),
                        //     e.target.value,
                        //     getValues("state")
                        //   ),
                      })}
                    >
                      <option value="">Select Label</option>
                      <option value="Association">Association</option>
                      <option value="LEA">LEA</option>
                      <option value="Industry">Industry</option>
                      <option value="State">State</option>
                    </select>
                  </div>
                </div>
              */}
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label"> State</label>
                    <Select
                      isMulti={false}
                      name="colors"
                      options={stateList}
                      isClearable={true}
                      className="dropdown-single-select"
                      classNamePrefix="select"
                      placeholder="Select State"
                      filterOption={customFilter}
                      value={stateList?.filter(
                        (obj) => obj.value === stateValue
                      )}
                      onChange={(e) => handleState(e?.value)}
                    />
                    <select
                      className="form-select d-none"
                      {...register("state")}
                    >
                      <option value="">Select State</option>
                      {stateList &&
                        stateList?.map((st, i) => (
                          <option value={st?.stateName} key={i}>
                            {st?.stateName}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-12 d-flex justify-content-start mt-auto">
                  <div className="form-group">
                    <Button
                      className="detail_button ms-3"
                      type="button"
                      disabled={openCircle}
                      onClick={
                        () => getApiData({ page: 1, no: 10 })

                        // getUserList({
                        //   userName: getValues("userName") ?? "",
                        //   email: getValues("email") ?? "",
                        //   roleId: getValues("roleId")
                        //     ? parseInt(getValues("roleId"))
                        //     : 0,
                        //   AssociationLabel: getValues("Labels") ?? "",
                        //   state: getValues("state") ?? "",
                        //   pageNumber: getValues("pageNumber") ?? 1,
                        //   pageSize: getValues("pageSize") ?? 20,
                        // })
                      }
                    >
                      {openCircle ? (
                        <CircularProgress
                          color="inherit"
                          sx={{
                            color: "#fff",
                            marginLeft: "0.5rem",
                            height: "23px !important",
                            width: "23px !important",
                          }}
                        />
                      ) : (
                        "Apply Filter"
                      )}
                    </Button>
                    <Button
                      className={`back_button ms-3 ${
                        window.innerWidth > 400 ? "" : "mt-3"
                      }`}
                      onClick={() => handleClearFilter()}
                    >
                      Clear Filter
                    </Button>
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <div className="accordian_card">
            <div className="title mb-3">Send Email Template To User</div>
            {/* <div className="card">
            <div className="card-body"> */}
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label className="form-label">Template</label>
                  <Select
                    isMulti={false}
                    name="colors"
                    options={templateList}
                    isClearable={true}
                    className="dropdown-single-select"
                    classNamePrefix="select"
                    placeholder="Select Template"
                    filterOption={customFilter}
                    value={templateList?.filter(
                      (obj) => obj.value === templateId
                    )}
                    onChange={(e) => handleTemplate(e?.value)}
                  />
                  {/* <select
                    className="form-select"
                    value={templateId}
                    onChange={(e) => handleTemplate(e.target.value)}
                  >
                    <option value="">Select Template</option>
                    {templateList?.map((item, key) => (
                      <option value={item?.templateId} key={key}>
                        {item?.templateTitle?.slice(0, 50)}
                        {item?.templateTitle
                          ? item?.templateTitle?.length > 50 && (
                              <span
                                className="description cr"
                                style={{ cursor: "pointer" }}
                              >
                                ...
                              </span>
                            )
                          : ""}
                      </option>
                    ))}
                  </select> */}
                  {err?.err && (
                    <span role="alert" className="d-block error_text">
                      {err.err}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="form-label">Conference</label>
                  <Select
                    isMulti={false}
                    name="colors"
                    options={conList}
                    isClearable={true}
                    className="dropdown-single-select"
                    classNamePrefix="select"
                    placeholder="Select Conference"
                    filterOption={customFilter}
                    value={conList?.filter((obj) => obj.value === conId)}
                    onChange={(e) => setConId(e?.value)}
                  />

                  {/* <select
                    className="form-select"
                    value={conId}
                    onChange={(e) => setConId(e.target.value)}
                  >
                    <option value="">Select Conference</option>
                    {conList &&
                      conList?.map((con) => (
                        <option value={con.conferenceId} key={con.conferenceId}>
                          {con.conferenceName}
                        </option>
                      ))}
                  </select> */}
                  {err?.con_err && (
                    <span role="alert" className="d-block error_text">
                      {err.con_err}
                    </span>
                  )}
                </div>
              </div>{" "}
              {/* <div className="col-md-3">
              <div className="form-group">
                <label className="form-label">User Role</label>
                <select
                  className="form-select"
                  onChange={(e) => handleUser(e.target.value)}
                >
                  {roleList &&
                    roleList?.map((role, i) => (
                      <option key={i} value={role.roleId}>
                        {role.roleName}
                      </option>
                    ))}
              
                </select>
              </div>
            </div> */}
              <div className="col-md-12 d-flex justify-content-start mt-3">
                <div className="form-group">
                  {userArray?.length > 0 ? (
                    <Button
                      className="detail_button ms-3"
                      type="submit"
                      onClick={handleSubmit(onSubmit)}
                    >
                      {openCircle ? (
                        <CircularProgress
                          color="inherit"
                          sx={{
                            color: "#fff",
                            marginLeft: "0.5rem",
                            height: "23px !important",
                            width: "23px !important",
                          }}
                        />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
              />
              <div className="col-md-12 p-0 mb-4">
                <AssignTable
                  headCells={headCellsEvent}
                  title="User List"
                  action={false}
                  ListData={filteredDataOfTable}
                  dropdown={false}
                  addBtn={false}
                  actionFirst={true}
                  userArray={userArray}
                  setUserArray={setUserArray}
                  handleMultiCheck={handleMultiCheck}
                  checkedValue={checkedValue}
                  setCheckValue={setCheckValue}
                  pageApi={true}
                  pagSize={getValues("pageSize")}
                  pagno={getValues("pageNumber")}
                  getApiData={(e) => getApiData(e)}
                  totalRecord={totalRecord}
                />
              </div>
            </div>
            {/* </div>
          </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SendTemplateUserList;
