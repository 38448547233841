import React, { useEffect, useState } from "react";
import "./index.scss";
// import register_banner from "../../assets/icons/login_banner1.svg";
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Navigation from "../../Shared/Navigation";
import { Config } from "../../Utils/Config";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import logo from "../../assets/images/fig_events.png";
// import eye from "../../assets/icons/eye.svg";
// import eyeOff from "../../assets/icons/eye-off.svg";
import { GetCallApi, PostCallApi } from "../../Action/Action";
import { useDispatch, useSelector } from "react-redux";
import { resetMenu, RegisterData } from "../../reducers/menu";
// import RegisterExhibitorPayment from "../../Modules/user/RegisterExhibitorPayment";
// import { GetCallApi } from "../../Action/Action.jsx";
import Select from "react-select";
import { customFilter } from "../../Modules/Components/CustomFilterForReactSelect";

function Register() {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm();

  const [openCircle, setOpenCircle] = useState(false);
  const [userType, setUserType] = useState("");
  // const [payPage, setPayPage] = useState();
  const [derror, setDerror] = useState();
  // const [priceDetail, setPriceDetail] = useState();
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [amList, setAmList] = useState([]);
  const [selectAm, setSelectAm] = useState();
  const [inputValue, setInputValue] = useState("");

  let dispatch = useDispatch();

  const cmpData = useSelector((state) => state.headerMenu?.company);
  const preregiData = useSelector((state) => state.headerMenu?.register);

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };
  const togglePasswordVisiblity1 = () => {
    setPasswordShown1(!passwordShown1);
  };
  let nav = useNavigate();

  useEffect(() => {
    document.title = "FIG Events | Register";
    // getAmt();
    setValue("firstName", preregiData?.firstName);
    setValue("lastName", preregiData?.lastName);
    setValue("email", preregiData?.email);
    setValue("phone", preregiData?.phone);
    setValue("password", preregiData?.password);
    setValue("confirmPassword", preregiData?.confirmPassword);
    setValue("UserType", preregiData?.UserType);
    setValue("check", true);
    setUserType(preregiData?.UserType ? preregiData?.UserType : "");
    setSelectAm(preregiData?.selectAm ? preregiData?.selectAm : "");
    // eslint-disable-next-line
  }, [preregiData]);
  // const getAmt = async () => {
  //   let seData = {
  //     url: `/api/Common/GetCommonPricingList`,
  //   };
  //   let res = await GetCallApi(seData);
  //   if (res?.status === 200) {
  //     if (res?.data.success) {
  //       let prde = res.data.payload[0];
  //       setPriceDetail(prde);
  //     }
  //   }
  // };

  const onSubmit = (data) => {
    let is_success = true;
    if (data?.UserType === "Exhibitor") {
      if (cmpData?.length === 0) {
        setDerror({
          req: "Before registration you have to create your company",
        });
        dispatch(
          RegisterData({
            ...data,
            AssociationMemberId: selectAm?.value,
            selectAm: selectAm,
          })
        );
        is_success = false;
      }
    }
    if (is_success) {
      setDerror({ req: "" });

      setDerror({ Log: "" });

      setOpenCircle(true);
      axios
        .post(
          `${Config.API_HOST_URL_live}/api/UserMst/CreateUser`,
          {
            ...data,
            AssociationMemberId: selectAm?.value,
            // lstRoleId: data?.UserType === "Exhibitor"? 4:5
            // priceId: priceDetail?.priceId,
            // Amount: priceDetail?.price,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "*/*",
            },
          }
        )
        .then(async (res) => {
          if (res.status === 200) {
            if (res.data.success) {
              if (data?.UserType === "Exhibitor") {
                let comData = {
                  ...cmpData,
                  createdby: res.data?.payload?.userId,
                  companyType: "Primary",
                };
                let seData = {
                  url: "/api/CompanyRegistration/CreateCompanyRegistrationWithoutToken",
                  body: comData,
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Accept: "*/*",
                  },
                };
                let res1 = await PostCallApi(seData);
                let sendData = {
                  url: `/api/CompanyRegistration/CreateCompanyUserAllocation`,
                  body: {
                    companyId: res1?.data?.payload?.companyId,
                    createdBy: res.data?.payload?.userId,
                    userId: [res.data?.payload?.userId],
                  },
                  headers: {
                    Authorization: `Bearer ${res.data?.payload?.accessToken}`,
                  },
                };
                await PostCallApi(sendData);
                seData = res1; //for remove error
              }
              // if (res.status === 200) {
              //   if (res.data.success) {
              //     toast.success(res.data.message, {
              //       position: "top-right",
              //       autoClose: 1500,
              //       hideProgressBar: true,
              //       closeOnClick: true,
              //       pauseOnHover: true,
              //       draggable: true,
              //       progress: undefined,
              //       theme: "dark",
              //     });
              //     dispatch(resetMenu());
              //     reset();
              //     setTimeout(function () {
              //       nav(-1);
              //     }, 2000);
              //     setDerror({ er_msg: "" });
              //   } else {
              //     setDerror({ er_msg: res.data.message });
              //   }
              // }
              dispatch(resetMenu());
              setOpenCircle(false);
              toast.success(res.data.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
              // let loginDeatil = {
              //   ...res.data?.payload,
              //   authenticate: true,
              // };
              // dispatch(LoginUser(loginDeatil));
              reset();
              showReadMore(
                "Registration Successful",
                "We have sent confirmation email, please confirm your email to proceed further.",
                {
                  ...data,
                  ...res.data.payload,
                  //  bookingPrice: priceDetail?.price
                }
              );
            } else {
              setOpenCircle(false);
              setDerror({ Log: res.data?.message });
            }
          } else {
            setOpenCircle(false);
          }
        })
        .catch((e) => {
          setOpenCircle(false);
          console.log(e);
        });
    }
  };
  const showReadMore = (title, des) => {
    Swal.fire({
      allowOutsideClick: false,
      position: "center",
      width: "50%",
      icon: "success",
      title: title,
      text: des,
      confirmButtonText: "OK",
      confirmButtonColor: "#31B680",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // if (data?.userType === "Exhibitor") {
        //   if (data.bookingPrice !== 0) {
        //     setPayPage(data);
        //     localStorage.setItem("usREG", JSON.stringify(data));
        //   } else {
        //     nav("/login", { replace: true });
        //   }
        // } else {
        nav("/login", { replace: true });
        // }
      }
    });
  };

  const getAMList = async (e, name) => {
    let is_success = true;
    let email = e?.split("@")[1];
    setAmList([]);
    if (email?.length < 3 || email === undefined) {
      is_success = false;
    }
    if (is_success) {
      let res = await GetCallApi({
        url: `/api/AssociationMembers/GetAssociationMemberListUsingDomain?email=${email}${
          name ? `&search=${name}` : ""
        }`,
      });
      if (res?.status === 200 && res?.data?.success) {
        let assoMem = res?.data?.payload?.map((x) => {
          let vl = x?.name + " (" + x?.type + ") ";
          let as = x?.associationName ? " - " + x?.associationName : "";
          return {
            value: x?.associationMembersId,
            label: vl + as,
          };
        });
        setAmList(assoMem);
      }
    }
  };
  const handleChangeAm = (e) => {
    setSelectAm(e);
  };
  const handleInputChange = (newValue) => {
    setInputValue(newValue);
    if (newValue?.length > 2) {
      getAMList(getValues("email"), newValue);
    } else {
      getAMList(getValues("email"), "");
    }
  };

  const handleUserType = (e) => {
    setUserType(e);
    setValue("UserType", e);
  };

  return (
    <>
      <Navigation />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {/* {payPage ? (
        <RegisterExhibitorPayment companyData={payPage} />
      ) : ( */}
      <section className="register_page">
        <div className="row m-0">
          <div className="col-xl-6 col-lg-6 col-md-12 col-12 p-0 text-center border-right image_sec left_main">
            <div className="inner_box left">
              <div className="content">
                <h1>Create an Account</h1>
                <p>
                  Welcome to FIG Conference Planner! We are thrilled that you
                  are joining our platform! As a member you will play a crucial
                  role in shaping the future of K12 Nutrition
                </p>
              </div>
              <img
                src={"/assets/icons/login_banner1.svg"}
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-12 p-0 right_main">
            <div className="inner_box right">
              <img src={logo} className="img-fluid logo" alt="logo" />
              <form
                className="d-flex flex-column"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="row m-0">
                  <div className="col-xl-6 col-lg-12 col-md-6 col-12">
                    <div className="form-group mb-3">
                      <TextField
                        id="outlined-basic"
                        label="First Name"
                        variant="outlined"
                        className="w-100"
                        placeholder="Enter your first name"
                        {...register("firstName", {
                          required: "This field is required",
                          pattern: {
                            value: /^[^\s]+(\s+[^\s]+)*$/,
                            message: "Starting and Ending Space not allowed",
                          },
                        })}
                      />
                      {errors.firstName && (
                        <span role="alert" className="error_text">
                          {errors.firstName.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-12 col-md-6 col-12">
                    <div className="form-group mb-3">
                      <TextField
                        id="outlined-basic"
                        label="Last Name"
                        variant="outlined"
                        className="w-100"
                        placeholder="Enter your last name"
                        {...register("lastName", {
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                          pattern: {
                            value: /^[^\s]+(\s+[^\s]+)*$/,
                            message: "Starting and Ending Space not allowed",
                          },
                        })}
                      />
                      {errors.lastName && (
                        <span role="alert" className="error_text">
                          {errors.lastName.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-12 col-md-6 col-12">
                    <div className="form-group mb-3">
                      <TextField
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        className="w-100"
                        placeholder="Enter your email ID"
                        {...register("email", {
                          required: "This field is required",
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: "Please Enter Valid Email",
                          },
                          onChange: (e) => getAMList(e?.target.value),
                        })}
                      />
                      {errors.email && (
                        <span role="alert" className="error_text">
                          {errors.email.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-12 col-md-6 col-12">
                    <div className="form-group mb-3">
                      <TextField
                        id="outlined-basic"
                        label="Phone"
                        variant="outlined"
                        className="w-100"
                        placeholder="Enter your Number"
                        {...register("phone", {
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                          pattern: {
                            value:
                              /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                            message: "Enter valid phone number",
                          },
                        })}
                      />
                      {errors.phone && (
                        <span role="alert" className="error_text">
                          {errors.phone.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-12 col-md-6 col-12">
                    <div className="form-group mb-3 ">
                      <div className="password">
                        <TextField
                          id="outlined-basic"
                          label="Password"
                          variant="outlined"
                          className="w-100 "
                          placeholder="Enter your Password"
                          type={passwordShown ? "text" : "password"}
                          {...register("password", {
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                            minLength: {
                              value: 8,
                              message: "Must be 8 characters",
                            },
                            maxLength: {
                              value: 15,
                              message: "Max 15 characters",
                            },
                            pattern: {
                              value: /^\S+$/,
                              message: "Space not allowed",
                            },
                          })}
                        />
                        <span className="eye" onClick={togglePasswordVisiblity}>
                          <img
                            src={
                              passwordShown === true
                                ? "/assets/icons/eye.svg"
                                : "/assets/icons/eye-off.svg"
                            }
                            alt="eye"
                          />
                        </span>
                      </div>
                      {errors.password && (
                        <span role="alert" className="error_text">
                          {errors.password.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-12 col-md-6 col-12">
                    <div className="form-group mb-3 ">
                      <div className="password">
                        <TextField
                          id="outlined-basic"
                          label="Confirm password"
                          variant="outlined"
                          className="w-100 "
                          type={passwordShown1 ? "text" : "password"}
                          placeholder="Enter Confirm password"
                          {...register("confirmPassword", {
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                            minLength: {
                              value: 8,
                              message: "Must be 8 characters",
                            },
                            maxLength: {
                              value: 15,
                              message: "Max 15 characters",
                            },
                            pattern: {
                              value: /^\S+$/,
                              message: "Space not allowed",
                            },
                            validate: (val) => {
                              if (watch("password") !== val) {
                                return "Your passwords do no match";
                              }
                            },
                          })}
                        />
                        <span
                          className="eye"
                          onClick={togglePasswordVisiblity1}
                        >
                          <img
                            src={
                              passwordShown1 === true
                                ? "/assets/icons/eye.svg"
                                : "/assets/icons/eye-off.svg"
                            }
                            alt="eye"
                          />
                        </span>
                      </div>
                      {errors.confirmPassword && (
                        <span role="alert" className="error_text">
                          {errors.confirmPassword.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-12 col-md-6 col-12">
                    <div className="form-group mb-3">
                      <Select
                        isMulti={false}
                        name="colors"
                        options={[
                          { label: "Attendee", value: "User" },
                          { label: "Exhibitor", value: "Exhibitor" },
                        ]}
                        isClearable={true}
                        className="company-multi-select"
                        classNamePrefix="select"
                        placeholder="Select User Type"
                        filterOption={customFilter}
                        value={[
                          { label: "Attendee", value: "User" },
                          { label: "Exhibitor", value: "Exhibitor" },
                        ]?.filter((obj) => obj.value === userType)}
                        onChange={(e) => handleUserType(e?.value)}
                      />
                      <select
                        className="form-select d-none"
                        {...register("UserType", {
                          required: "This field is required",
                          onChange: (e) => setUserType(e.target.value),
                        })}
                      >
                        <option value="">Select User Type</option>
                        <option value="Exhibitor">Exhibitor</option>
                        <option value="User">Attendee</option>
                      </select>
                      {errors.UserType && (
                        <span role="alert" className="error_text">
                          {errors.UserType.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-12 col-md-6 col-12">
                    <div className="form-group mb-3">
                      <Select
                        isMulti={false}
                        name="colors"
                        options={amList}
                        isClearable={true}
                        className="company-multi-select"
                        classNamePrefix="select"
                        placeholder="Select LEA/State/Industry"
                        filterOption={customFilter}
                        inputValue={inputValue}
                        onInputChange={handleInputChange}
                        value={amList?.filter(
                          (obj) => obj.value === selectAm?.value
                        )}
                        onChange={handleChangeAm}
                      />
                    </div>
                  </div>
                  {/* <div className="col-xl-6 col-lg-12 col-md-6 col-12">
                      <div className="form-group mb-3">
                        {priceDetail?.price ? (
                          <>
                            {userType === "Exhibitor"
                              ? `Fees $${priceDetail?.price}/${priceDetail?.duration}`
                              : ""}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div> */}
                  {userType === "Exhibitor" ? (
                    <div className="col-xl-6 col-lg-12 col-md-6 col-12">
                      <div className="">
                        <Button
                          className="back_button w-100"
                          style={{ height: "40px", marginBottom: "20px" }}
                        >
                          <Link
                            to="/exhibitor-company-create"
                            style={{
                              textDecoration: "none",
                              color: "black",
                              fontSize: "16px",
                            }}
                          >
                            Create Company
                          </Link>
                        </Button>
                        {derror?.req && (
                          <span role="alert" className="error_text">
                            {derror?.req}
                          </span>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="links mb-3 justify-content-start">
                  <FormControlLabel
                    className="me-2"
                    control={<Checkbox defaultChecked />}
                    label="I agree to Terms & Conditions and "
                    {...register("check", {
                      required: {
                        value: true,
                        message: "You must agree with the terms and conditions",
                      },
                    })}
                  />{" "}
                  <Link className="form-label" to={"/privacy-policy"}>
                    Privacy Policy{" "}
                  </Link>{" "}
                </div>
                {errors.check && (
                  <span role="alert" className="w-100 ms-3 error_text">
                    {errors.check.message}
                  </span>
                )}
                {derror?.Log && (
                  <span role="alert" className="d-block error_text text-center">
                    {derror.Log}
                  </span>
                )}
                <input
                  type="submit"
                  name="submit"
                  className="submit_btn w-10"
                  value="Register"
                />
                {/* <button
                    onClick={() =>
                      showReadMore(
                        "Registration Successful",
                        "We have sent confirmation email, please confirm your email to proceed further.",
                        {
                          bookingPrice: 150000,
                          userId: "2d34604d-db7d-4674-bae4-dd30f1de4053",
                          email: "mailto:bhautikvidhan+1@yopmail.com",
                          name: "bhautik vidhan",
                          userType: "Exhibitor",
                        }
                      )
                    }
                    className="submit_btn w-10"
                    value="Register"
                  >
                    sb
                  </button> */}

                <h6>
                  Already have an account? <Link to="/login">LOGIN</Link>
                </h6>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* )} */}
    </>
  );
}

export default Register;
