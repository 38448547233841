import React, { Suspense } from "react";
import OneWorldSyncTable from "../OneWorldSyncTable/index";
import { CircularProgress } from "@mui/material";
import TableHeader from "../../../../TableHeader.json"

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const SyncList = (props) => {
  const { apiCall, ApiCallFuntion } = props;

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <OneWorldSyncTable
          title="Global Data Source"
          headCells={TableHeader?.GlobalDataSourceHeader}
          action={true}
          actionFirst={true}
          apiCall={apiCall}
          ApiCallFuntion={ApiCallFuntion}
          pageApi={true}
        />
      </Suspense>
    </>
  );
};

export default SyncList;
