import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import Cookies from "universal-cookie";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import "./index.scss";
import uuid from "react-uuid";
import Swal from "sweetalert2";
import Select from "react-select";
import { useSelector } from "react-redux";
import { customFilter } from "../../../Components/CustomFilterForReactSelect";

function QuestionsforNonFigUsers(props) {
  const {
    GetUsDetails,
    setReqCall,
    productList,
    asList,
    chatEx,
    exhiId,
    venderList,
    conferenceId,
  } = props;
  const { id } = useParams();
  const exhibitorId = id || exhiId;

  const [userDetails, setUserDetails] = useState();
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handlePrev = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <CreateUserDetail
            handleNext={() => handleNext()}
            forBack={forBack}
            setUserDetails={setUserDetails}
          />
        );
      case 1:
        return (
          <CreateQuestionAnswer
            userDetails={userDetails}
            productList={productList}
            asList={asList}
            forBack={forBack}
            chatEx={chatEx}
            venderList={venderList}
            conferenceId={conferenceId}
            handleNext={() => handleNext()}
            handlePrev={() => handlePrev()}
          />
        );

      default:
        return "";
    }
  };

  const steps = ["User Information", "Answer Questions"];

  const CreateUserDetail = (props) => {
    const { setUserDetails, handleNext, forBack } = props;
    const [openCircle, setOpenCircle] = useState(false);
    const LogDetail = useSelector((state) => state.login.LoginDetails);

    const {
      register,
      handleSubmit,
      setValue,
      formState: { errors },
    } = useForm();

    useEffect(() => {
      if (LogDetail?.authenticate) {
        setValue("firstName", LogDetail?.firstName);
        setValue("lastName", LogDetail?.lastName);
        setValue("email", LogDetail?.email);
        setValue("phone", LogDetail?.phone);
        setValue("userId", LogDetail?.userId);
        setValue("isExistsUser", true);
      }
    }, [LogDetail]);
    const onSubmit = async (data) => {
      setOpenCircle(true);
      if (LogDetail?.authenticate) {
        sendData(data);
      } else {
        let res = await PostCallApi({
          url: `/api/Questions/CheckEmailWithNonFigUser`,
          body: {
            email: data?.email,
          },
        });
        setOpenCircle(false);
        if (res?.status === 200) {
          if (res?.data?.success) {
            sendData(data);
          } else {
            Swal.fire({
              position: "center",
              width: window?.innerWidth > 768 ? "50%" : "90%",
              title: res?.data?.message,
              showCancelButton: true,
              confirmButtonText: "Yes",
              confirmButtonColor: "#80c242",
            }).then((value) => {
              // console.log(value);
              if (value?.isConfirmed) {
                sendData({
                  ...data,
                  isExistsUser: true,
                  userId: res?.data?.payload?.userId,
                });
              }
            });
          }
        }
      }
    };

    const sendData = (data) => {
      setUserDetails({
        ...data,
        isExistsUser: data?.isExistsUser ? true : false,
      });
      handleNext();
    };
    return (
      <>
        <div className="title mb-3">User Information</div>
        <h5 className=" mb-3">
          After Completing both step your details send to exhibitor other wise
          data will not send to exhibitor
        </h5>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">
                  First Name
                  <span className="red-color"> *</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter first name"
                  {...register("firstName", {
                    required: "This field is required",
                    pattern: {
                      value: /^[^\s]+(\s+[^\s]+)*$/,
                      message: "Starting and Ending Space not allowed",
                    },
                    maxLength: {
                      value: 250,
                      message: "Max character 250 allowed",
                    },
                  })}
                />
                {errors.firstName && (
                  <span role="alert" className="error_text">
                    {errors.firstName.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">
                  Last Name
                  <span className="red-color"> *</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter last name"
                  {...register("lastName", {
                    required: "This field is required",
                    pattern: {
                      value: /^[^\s]+(\s+[^\s]+)*$/,
                      message: "Starting and Ending Space not allowed",
                    },
                    maxLength: {
                      value: 250,
                      message: "Max character 250 allowed",
                    },
                  })}
                />
                {errors.lastName && (
                  <span role="alert" className="error_text">
                    {errors.lastName.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">
                  Email
                  <span className="red-color"> *</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter email"
                  disabled={LogDetail?.email ? true : false}
                  {...register("email", {
                    required: "This field is required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Please Enter Valid Email",
                    },
                  })}
                />
                {errors.email && (
                  <span role="alert" className="error_text">
                    {errors.email.message}
                  </span>
                )}
              </div>
            </div>{" "}
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">
                  Phone
                  {/* <span className="red-color"> *</span> */}
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter phone"
                  {...register("phone", {
                    // required: "This field is required",
                    pattern: {
                      value:
                        /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                      message: "Enter valid phone number",
                    },
                  })}
                />
                {errors.phone && (
                  <span role="alert" className="error_text">
                    {errors.phone.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-12 d-flex justify-content-end mt-3">
              <div className="form-group d-flex gap-3">
                <Button className="detail_button" onClick={() => forBack()}>
                  Cancel
                </Button>
                <Button className="detail_button" type="submit">
                  {openCircle ? (
                    <CircularProgress
                      color="inherit"
                      sx={{
                        color: "#fff",
                        marginLeft: "0.5rem",
                        height: "23px !important",
                        width: "23px !important",
                      }}
                    />
                  ) : (
                    "Next"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  };

  const CreateQuestionAnswer = (props) => {
    const {
      userDetails,
      productList,
      asList,
      forBack,
      chatEx,
      // venderList,
      conferenceId,
    } = props;
    const [openCircle, setOpenCircle] = useState(false);
    const [conList, setConList] = useState([]);
    const [conId, setConId] = useState();
    const [prdSelect, setPrdSelect] = useState([]);
    const [derror, setDerror] = useState();
    const [selectAS, setSelectAS] = useState();
    const LogDetail = useSelector((state) => state.login.LoginDetails);
    const nav = useNavigate();
    const {
      register,
      handleSubmit,
      setValue,
      control,
      setError,
      clearErrors,
      formState: { errors },
    } = useForm();
    const { fields: fieldArray1 } = useFieldArray({
      control,
      name: "questionAnswerDetails",
    });
    const cookies = new Cookies();

    useEffect(() => {
      GetAllQuestionList();
      GetConList();
      cookies.set("createdBy", uuid());
    }, []);

    const handleChangePrdMulti = (e) => {
      setPrdSelect(Array.isArray(e) ? e.map((x) => x.value) : []);
    };

    const GetConList = async () => {
      let res = await GetCallApi({
        url: `/api/Conference/GetAllExternalConferenceList`,
      });
      if (res?.status === 200) {
        if (res?.data?.success) {
          let confList = res?.data?.payload?.map((x) => {
            return {
              ...x,
              value: x?.conferenceId,
              label: x?.conferenceName,
            };
          });
          setConList([...(confList ?? []), { value: "Other", label: "Other" }]);
          setTimeout(() => {
            if (conferenceId) {
              handleConferenceSelect(conferenceId);
            }
          }, 500);
        }
      }
    };

    const GetAllQuestionList = async () => {
      let seData = {
        // url: `/api/Questions/GetAllQuestionListWithoutToken?userId=${exhibitorId}`,
        url: `/api/Questions/GetAllQuestionListWithoutToken?companyId=${exhibitorId}`,
      };
      let res = await GetCallApi(seData);

      if (res.status === 200) {
        if (res.data.success) {
          let comList = res?.data?.payload?.map((c, i) => {
            return {
              id: i + 1,
              ...c,
            };
          });
          if (comList?.length > 0) {
            setValue("questionAnswerDetails", comList);
          } else {
            cookies.set(exhibitorId, exhibitorId);
            // GetUsDetails();
            // setReqCall(false);
          }
        }
      }
    };

    const onSubmit = async (data) => {
      let is_success = true;
      if (LogDetail?.authenticate) {
        if (asList?.length > 0 && !data?.associationMembersId) {
          is_success = false;
          setError("associationMembersId", {
            type: "custom",
            message: "This field is required",
          });
        }
      }
      if (prdSelect?.length === 0) {
        is_success = false;
        setDerror({ err: "Please select one product." });
      }
      if (is_success) {
        let UID = cookies.get("createdBy") ? cookies.get("createdBy") : uuid();
        let com = await GetCallApi({
          url: `/api/CompanyRegistration/GetCompanyUserAllocationListByCompanyIdWithoutToken?companyId=${exhibitorId}`,
        });
        let userList = com?.data?.payload;
        let seData = {
          url: `/api/Questions/CreateUserQuestionAnswer`,
          body: {
            ...data,
            ...userDetails,
            // userId: id,
            exhibitorsId:
              userList?.length > 0 ? userList[0]?.userId : exhibitorId,
            companyId: exhibitorId,
            isChatWithExhibitor: chatEx,
            createdBy: LogDetail?.userId ? LogDetail?.userId : UID,
            conferenceId:
              data?.conferenceId === "Other" ? null : data?.conferenceId,
            ProductIds: prdSelect,
            questionAnswerDetails: data?.questionAnswerDetails?.map((x) => {
              if (x?.questionType === "Multiple Choice") {
                return { ...x, answer: x?.answer?.toString() };
              }
              return x;
            }),
          },
        };
        setOpenCircle(true);
        let res = await PostCallApi(seData);
        setOpenCircle(false);
        if (res?.status === 200 && res?.data?.success) {
          toast.success(res.data.message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          cookies.set(exhibitorId, exhibitorId);
          GetUsDetails();
          if (chatEx) {
            if (LogDetail?.userId) {
              if (userList?.length > 0) {
                startChat(userList[0]?.userId);
              } else {
                setReqCall(false);
              }
            } else {
              if (userList?.length > 0) {
                sessionStorage.setItem("newExhibitor", userList[0]?.userId);
                sessionStorage.setItem("email", userDetails?.email);

                nav(`/login`, { replace: true });
              } else {
                setReqCall(false);
              }
            }
          } else {
            setReqCall(false);
          }
        } else {
          toast.error(res?.data.message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      }
    };
    const startChat = async (vn) => {
      let stChat = {
        userIdOne: LogDetail?.userId,
        userIdSecond: vn,
        createdBy: LogDetail?.userId,
        isDeleted: false,
      };
      let response = await PostCallApi({
        url: `/api/Chat/CreateChat`,
        body: stChat,
      });

      if (response.status === 200) {
        if (response.data.success) {
          nav(`/messages/${response.data.payload?.chatId}`, { replace: true });
        }
      }
    };
    const handleChangeDis = (e) => {
      setValue("associationMembersId", e?.value ? e?.value : "");
      setSelectAS(e);
      if (!e) {
        setError("associationMembersId", {
          type: "custom",
          message: "This field is required",
        });
      } else {
        clearErrors("associationMembersId");
      }
    };

    const handleConferenceSelect = (e) => {
      setConId(e);
      setValue("conferenceId", e);
    };

    return (
      <>
        <div className="title mb-3">Answer Questions</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className={`col-md-6`}>
              <div className="form-group">
                <label className="form-label">
                  Conference
                  <span className="red-color"> *</span>
                </label>
                <Select
                  isMulti={false}
                  name="colors"
                  options={conList}
                  isClearable={true}
                  className="dropdown-single-select"
                  classNamePrefix="select"
                  placeholder="Select Conference"
                  filterOption={customFilter}
                  value={conList?.filter((obj) => obj.value === conId)}
                  onChange={(e) => handleConferenceSelect(e?.value)}
                />
                <select
                  type="text"
                  className="form-select d-none"
                  {...register("conferenceId", {
                    required: "This field is required",
                    onChange: (e) => setConId(e?.target.value),
                  })}
                >
                  <option value="">Select Conference</option>
                  {conList &&
                    conList?.map((x) => (
                      <option value={x?.conferenceId} key={x?.conferenceId}>
                        {x?.conferenceName}
                      </option>
                    ))}
                  <option value="Other">Other</option>
                </select>
                {errors.conferenceId && (
                  <span role="alert" className="error_text">
                    {errors.conferenceId.message}
                  </span>
                )}
              </div>
            </div>
            {conId === "Other" ? (
              <div className={`col-md-6`}>
                <div className="form-group">
                  <label className="form-label">
                    Other Extrenal Conference
                    <span className="red-color"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter other extrenal conference"
                    {...register("otherExtrenalConference", {
                      required: "This field is required",
                      pattern: {
                        value: /^[^\s]+(\s+[^\s]+)*$/,
                        message: "Starting and Ending Space not allowed",
                      },
                      maxLength: {
                        value: 250,
                        message: "Max character 250 allowed",
                      },
                    })}
                  />
                  {errors.otherExtrenalConference && (
                    <span role="alert" className="error_text">
                      {errors.otherExtrenalConference.message}
                    </span>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            {LogDetail?.authenticate && asList?.length > 0 ? (
              <div className={`col-md-6`}>
                <div className="form-group">
                  <label className="form-label">
                    District or Organization Name
                    <span className="red-color"> *</span>
                  </label>
                  <Select
                    isMulti={false}
                    name="colors"
                    options={asList?.map((x) => {
                      return {
                        value: x?.associationMembersId,
                        label: x?.name + " - " + x?.type,
                      };
                    })}
                    isClearable={true}
                    className="dropdown-single-select"
                    classNamePrefix="select"
                    placeholder="Select District or Organization Name"
                    filterOption={customFilter}
                    value={asList
                      ?.map((x) => {
                        return {
                          value: x?.associationMembersId,
                          label: x?.name + " - " + x?.type,
                        };
                      })
                      ?.filter((obj) => obj.value === selectAS?.value)}
                    onChange={handleChangeDis}
                  />
                  {/* <select
                    type="text"
                    className="form-select"
                    {...register("associationMembersId", {
                      required: "This field is required",
                    })}
                  >
                    <option value="">
                      Select District or Organization Name
                    </option>
                    {asList &&
                      asList?.map((con) => (
                        <option
                          value={con.associationMembersId}
                          key={con.associationMembersId}
                        >
                          {con.name} - {con?.type}
                        </option>
                      ))}
                  </select> */}
                  {errors.associationMembersId && (
                    <span role="alert" className="error_text">
                      {errors.associationMembersId.message}
                    </span>
                  )}
                </div>
              </div>
            ) : (
              <div className={`col-md-6`}>
                <div className="form-group">
                  <label className="form-label">
                    District or Organization Name
                    <span className="red-color"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter  District or Organization Name"
                    {...register("otherAssociationMembersName", {
                      required: "This field is required",
                      pattern: {
                        value: /^[^\s]+(\s+[^\s]+)*$/,
                        message: "Starting and Ending Space not allowed",
                      },
                      maxLength: {
                        value: 250,
                        message: "Max character 250 allowed",
                      },
                    })}
                  />
                  {errors.otherAssociationMembersName && (
                    <span role="alert" className="error_text">
                      {errors.otherAssociationMembersName.message}
                    </span>
                  )}
                </div>
              </div>
            )}
            <div className={`col-md-6`}>
              <div className="form-group">
                <label className="form-label">Select Product</label>
                <Select
                  isMulti
                  name="colors"
                  options={productList}
                  className="company-multi-select"
                  classNamePrefix="select"
                  placeholder="Product"
                  filterOption={customFilter}
                  value={productList?.filter((obj) =>
                    prdSelect?.includes(obj.value)
                  )}
                  onChange={handleChangePrdMulti}
                />
                {derror?.err && (
                  <span role="alert" className="error_text">
                    {derror?.err}
                  </span>
                )}
              </div>
            </div>

            {fieldArray1?.map((item, index) => (
              <div
                className={`col-md-${item?.questionType === "Rating" ? 12 : 6}`}
                key={index}
              >
                <div className="form-group">
                  <label className="form-label">
                    {index + 1}) {item?.questionTitle}
                    {item?.isRequired ? (
                      <span className="red-color"> *</span>
                    ) : (
                      ""
                    )}
                  </label>
                  {item?.questionType === "Text" ? (
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Answer"
                      {...register(`questionAnswerDetails.${index}.answer`, {
                        required: {
                          value: item?.isRequired,
                          message: "This field is required",
                        },
                        pattern: {
                          value: /^[^\s]+(\s+[^\s]+)*$/,
                          message: "Starting and Ending Space not allowed",
                        },
                        maxLength: {
                          value: 200,
                          message: "Max character 200 allowed",
                        },
                      })}
                    />
                  ) : item?.questionType === "BigText" ? (
                    <textarea
                      rows={3}
                      type="text"
                      className="form-control"
                      placeholder="Enter Answer"
                      {...register(`questionAnswerDetails.${index}.answer`, {
                        required: {
                          value: item?.isRequired,
                          message: "This field is required",
                        },
                        pattern: {
                          value: /^[^\s]+(\s+[^\s]+)*$/,
                          message: "Starting and Ending Space not allowed",
                        },
                        maxLength: {
                          value: 500,
                          message: "Max character 500 allowed",
                        },
                      })}
                    />
                  ) : item?.questionType === "Listbox" ? (
                    <select
                      type="text"
                      className="form-select"
                      {...register(`questionAnswerDetails.${index}.answer`, {
                        required: {
                          value: item?.isRequired,
                          message: "This field is required",
                        },
                      })}
                    >
                      <option value="">Select Value</option>
                      {item?.optionList?.map((x, i) => (
                        <option value={x?.option} key={i}>
                          {x?.option}
                        </option>
                      ))}
                    </select>
                  ) : item?.questionType === "Rating" ? (
                    <div className="d-flex">
                      <div
                        className=" my-auto me-3"
                        style={{ maxWidth: "35%", width: "fit-content" }}
                      >
                        <div className="text-end">
                          {item?.optionList[0]?.option}
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="d-flex gap-5">
                          {[...Array(5).keys()]?.map((x) => (
                            <div key={x}>
                              <label
                                htmlFor={`TrueFalse${x}`}
                                className="w-100"
                              >
                                {x + 1}
                              </label>
                              <input
                                type="radio"
                                id={`TrueFalse${x}`}
                                className=""
                                name="TrueFalse"
                                value={x + 1}
                                {...register(
                                  `questionAnswerDetails.${index}.answer`,
                                  {
                                    required: {
                                      value: item?.isRequired,
                                      message: "This field is required",
                                    },
                                  }
                                )}
                              />{" "}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div
                        className=" my-auto ms-3"
                        style={{ maxWidth: "35%", width: "fit-content" }}
                      >
                        <div>{item?.optionList[1]?.option}</div>
                      </div>
                      {/* <input
                        type="radio"
                        id="TrueFalse1"
                        className=""
                        name="TrueFalse"
                        value={true}
                        {...register(`questionAnswerDetails.${index}.answer`, {
                          required: {
                            value: item?.isRequired,
                            message: "This field is required",
                          },
                        })}
                      />{" "}
                      <label htmlFor="TrueFalse1" className="mx-2">
                        True
                      </label>
                      <input
                        type="radio"
                        id="TrueFalse2"
                        name="TrueFalse"
                        className=""
                        value={false}
                        {...register(`questionAnswerDetails.${index}.answer`, {
                          required: {
                            value: item?.isRequired,
                            message: "This field is required",
                          },
                        })}
                      />
                      <label htmlFor="TrueFalse2" className="mx-2">
                        False
                      </label> */}
                    </div>
                  ) : item?.questionType === "Radio" ? (
                    <div>
                      {item?.optionList?.map((opt, i) => (
                        <div key={opt?.optionId}>
                          <input
                            type="radio"
                            id={`TrueFalse${i}`}
                            className=""
                            name="TrueFalse"
                            value={opt?.option}
                            {...register(
                              `questionAnswerDetails.${index}.answer`,
                              {
                                required: {
                                  value: item?.isRequired,
                                  message: "This field is required",
                                },
                              }
                            )}
                          />{" "}
                          <label htmlFor={`TrueFalse${i}`} className="ms-2">
                            {opt?.option}
                          </label>
                        </div>
                      ))}
                    </div>
                  ) : item?.questionType === "Multiple Choice" ? (
                    <div>
                      {item?.optionList?.map((x, i) => (
                        <React.Fragment key={i}>
                          <input
                            type="checkBox"
                            id={`check${x?.option}`}
                            className=""
                            value={x?.option}
                            {...register(
                              `questionAnswerDetails.${index}.answer`,
                              {
                                required: {
                                  value: item?.isRequired,
                                  message: "This field is required",
                                },
                              }
                            )}
                          />{" "}
                          <label htmlFor={`check${x?.option}`} className="mx-2">
                            {x?.option}
                          </label>
                        </React.Fragment>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                  {errors.questionAnswerDetails?.[index]?.answer && (
                    <span role="alert" className="error_text">
                      {errors.questionAnswerDetails?.[index]?.answer.message}
                    </span>
                  )}
                </div>
              </div>
            ))}
            <div className="col-md-12 d-flex justify-content-end mt-3">
              <div className="form-group d-flex gap-3">
                <Button className="detail_button" onClick={() => forBack()}>
                  Cancel
                </Button>
                <Button
                  className="detail_button"
                  type="submit"
                  disabled={openCircle}
                >
                  {openCircle ? (
                    <CircularProgress
                      color="inherit"
                      sx={{
                        color: "#fff",
                        marginLeft: "0.5rem",
                        height: "23px !important",
                        width: "23px !important",
                      }}
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  };
  const forBack = () => {
    Swal.fire({
      position: "center",
      width: window?.innerWidth > 768 ? "50%" : "90%",
      title: "Are you sure want to cancel request call ?",
      text: "All the details will discard after close.",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#ff5151",
    }).then((value) => {
      // console.log(value);
      if (value?.isConfirmed) {
        setReqCall(false);
      }
    });
  };
  return (
    <>
      <section className="QuestionsforNonFigUsers">
        <Box sx={{ width: "100%" }}>
          <div className="main_wrapper mb-5">
            <div className="card-body">
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                className="stepper_box_label"
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
          </div>
          <hr />
          <div className="main_wrapper ">
            <div className="card">
              <div className="card-body">{getStepContent(activeStep)}</div>
            </div>
          </div>
        </Box>
        {/* <div className="main_wrapper">
          <div className="card">
            <div className="card-body">
           
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
}

export default QuestionsforNonFigUsers;
