import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Backdrop, CircularProgress, Button } from "@mui/material";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Select from "react-select";
import { customFilter } from "../../../../Components/CustomFilterForReactSelect";

function PushNotificationCreate() {
  const [openCircle, setOpenCircle] = useState(false);
  const { id } = useParams();
  const nav = useNavigate();
  const LogDetails = useSelector((state) => state.login.LoginDetails);
  const [conList, setConList] = useState([]);
  const [conId, setConId] = useState();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });

  const handleFormChange = watch(
    () => ["title", "description"],
    () => {
      if (!isValid) {
        console.log("Form is invalid:", errors);
      }
    },
    { disabled: true }
  );

  const onSubmit = async (data) => {
    if (data?.conferenceId !== "00000000-0000-0000-0000-000000000000") {
      SaveData(data);
    } else {
      Swal.fire({
        position: "center",
        width: "50%",
        title: "Are you sure you don't want to select event?",
        confirmButtonText: "Yes",
        confirmButtonColor: "#80c242",
        showCancelButton: true,
      }).then((value) => {
        if (value?.isConfirmed) {
          SaveData(data);
        }
      });
    }
  };
  const SaveData = async (data) => {
    let seData = {
      url: "/api/PublicNotification/CreatePublicNotification",
      body: {
        ...data,
        createdBy: LogDetails?.userId,
        conferenceId: data?.conferenceId
          ? data?.conferenceId
          : "00000000-0000-0000-0000-000000000000",
        eventName: data?.conferenceId
          ? data?.conferenceId !== "00000000-0000-0000-0000-000000000000"
            ? conList?.find((x) => x?.conferenceId === data?.conferenceId)
                ?.conferenceName
            : ""
          : "",
      },
    };
    setOpenCircle(true);
    let res = await PostCallApi(seData);
    setOpenCircle(false);

    if (res?.status === 200) {
      if (res.data.success) {
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        reset();
        nav(-1);
      } else {
        toast.error(res.data.message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } else {
      toast.error(res.data.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  useEffect(() => {
    document.title = "FIG Events | Push Notification";
    if (id) {
      getInfo();
    } else {
      getConfList();
    }
    // eslint-disable-next-line
  }, [id]);

  const getConfList = async (name) => {
    let seData = {
      url: `/api/Conference/GetAllWithoutTokenConferenceList?isAlphanumericOrder=true`,
    };
    let res = await GetCallApi(seData);
    if (res.status === 200) {
      if (res.data.success) {
        let co = res.data.payload?.map((x) => {
          return {
            ...x,
            label: x?.conferenceName,
            value: x?.conferenceId,
          };
        });
        setConList(co);
        if (name) {
          setTimeout(() => {
            setValue("conferenceId", name);
          }, 200);
        }
      }
    }
  };

  const getInfo = async () => {
    let seData = {
      url: `/api/PublicNotification/GetPublicNotificationById?publicNotificationId=${id}`,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        let abt = res.data.payload;
        setValue("publicNotificationId", id);
        setValue("title", abt?.title);
        setValue("description", abt?.description);
        setConId(abt?.conferenceId);
        getConfList(abt?.conferenceId);
      }
    }
  };
  const handleConferenceSelect = (e) => {
    setConId(e);
    setValue("conferenceId", e);
  };

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: () => 9999 }} open={openCircle}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="create_event_class">
        <div className="main_wrapper">
          <div className="title mb-3">
            {" "}
            {id ? "Edit" : "Create"} Push Notification
          </div>
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)} className="form_box">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">Event Name</label>
                      <Select
                        isMulti={false}
                        name="colors"
                        options={conList}
                        isClearable={true}
                        className="dropdown-single-select"
                        classNamePrefix="select"
                        placeholder="Select Conference"
                        filterOption={customFilter}
                        value={conList?.filter((obj) => obj.value === conId)}
                        onChange={(e) => handleConferenceSelect(e?.value)}
                      />
                      <select
                        type="text"
                        className="form-select d-none"
                        {...register("conferenceId")}
                      >
                        <option value="00000000-0000-0000-0000-000000000000">
                          Select Event
                        </option>
                        {conList &&
                          conList?.map((con) => (
                            <option
                              value={con.conferenceId}
                              // value={con.conferenceName}
                              key={con.conferenceId}
                            >
                              {con.conferenceName}
                            </option>
                          ))}
                      </select>
                      {errors.conferenceId && (
                        <span role="alert" className="error_text">
                          {errors.conferenceId.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Title
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Title"
                        onChange={handleFormChange}
                        {...register("title", {
                          required: "This field is required",
                          pattern: {
                            value: /^[^\s]+(\s+[^\s]+)*$/,
                            message: "Starting and Ending Space not allowed",
                          },
                          maxLength: {
                            value: 250,
                            message: "Max character 250 allowed",
                          },
                        })}
                      />
                      {errors.title && (
                        <span role="alert" className="error_text">
                          {errors.title.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="form-label">
                        Description
                        <span className="red-color"> *</span>
                      </label>
                      <textarea
                        type="text"
                        rows={5}
                        onChange={handleFormChange}
                        placeholder="Enter Description"
                        className="form-control"
                        {...register("description", {
                          required: "This field is required",
                          pattern: {
                            value: /^[^\s]+(\s+[^\s]+)*$/,
                            message: "Starting and Ending Space not allowed",
                          },
                        })}
                      />
                      {errors.description && (
                        <span role="alert" className="error_text">
                          {errors.description.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12 d-flex justify-content-end mt-3">
                    <div className="form-group">
                      <Button className="back_button" onClick={() => nav(-1)}>
                        Back
                      </Button>

                      {openCircle ? (
                        <Button className="detail_button ms-3">
                          <CircularProgress
                            color="inherit"
                            sx={{
                              color: "#fff",
                              marginLeft: "0.5rem",
                              height: "23px !important",
                              width: "23px !important",
                            }}
                          />
                        </Button>
                      ) : (
                        <Button
                          className="detail_button ms-3"
                          type="submit"
                          disabled={!isValid}
                        >
                          Send
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PushNotificationCreate;
