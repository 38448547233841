import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import "./index.scss";
import { useLocation, useParams } from "react-router-dom";
import Axios from "../../../Utils/AxiosConfig";
import { useSelector } from "react-redux";
// import Tooltip from "../../../Modules/Components/Reusable/Tooltip";
import DetailShowPopup from "../../../Modules/Components/Reusable/DetailShowPopup";
// import door from "../../../assets/icons/door.svg";
import { Config } from "../../../Utils/Config";

function ConferenceUserDragableBoxBook(props) {
  const [open, setOpen] = React.useState(false);
  const [comDl, setComDl] = React.useState(false);
  const handleOpen = (detail) => {
    setOpen(true);
    setSboothId();

    setComDl(detail);
  };
  const { evD, sboothId, canEdit, setSboothId } = props;
  // const [loading, setLoading] = useState(false);

  // const [selectSeat, setSelectSeat] = useState([]);
  const [deltaPositionSave, setDeltaPositionSave] = useState([]);
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  // const logDetail = useSelector((state) => state.login.LoginDetails);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const { id, name } = useParams();
  const { state } = useLocation();
  const conId = id || state?.conferenceId;
  const [confId, setConFId] = useState();
  useEffect(() => {
    if (conId) {
      setConFId(conId);
    }
    // eslint-disable-next-line
  }, [conId]);
  useEffect(() => {
    getBox();
    // eslint-disable-next-line
  }, [confId]);

  const getBox = async () => {
    setDeltaPositionSave();
    if (confId) {
      Axios.get(
        // `/api/EventBooths/GetEventBoothsByEventID?eventId=${id}`,
        `/api/ConferenceBooths/GetConferenceBoothsStatusByConferenceIdWithoutToken?conferenceId=${confId}`,
        {
          headers,
        }
      )
        .then((res) => {
          if (res.status === 200) {
            if (res.data.success) {
              let pn = res.data.payload?.map((p) => {
                return {
                  ...p,
                  ...p.exhibitor,
                  ...p.exhibitor?.exhibitorCompanyDetails,
                  sequenceNumber: p?.sequenceNumber,
                  value: p?.eventBoothsId,
                  label: p?.seatEventNumber,
                  x: p?.positionX,
                  y: p?.positionY,
                  width: p?.width,
                  height: p?.hight,
                  status: p?.status,
                  oneExhibitorList: p?.exhibitor,
                  exhibitorList: p?.exhibitor,
                };
              });
              setDeltaPositionSave(pn);
            }
          }
        })
        .catch((e) => console.log(e));
    }
  };

  // useEffect(() => {
  //   if (sboothId) {
  //     setTimeout(() => {
  //     }, 3000);
  //   }
  //   // eslint-disable-next-line
  // }, [sboothId]);
  return (
    <>
      <div className="editdrop_new">
        <div className="control_btn"></div>
        <div
          className="box"
          style={{
            height: evD?.height ? evD?.height - 290 : "680px",
            width: "100%",
            position: "relative",
            overflow: "auto",
            padding: "0",
          }}
        >
          <div
            style={{
              height: "100%",
              width: evD?.width ? evD?.width - 150 : "1770px",
              padding: "10px",
              zIndex: "999",
              position: "relative",
            }}
          >
            {deltaPositionSave &&
              deltaPositionSave?.map((drag, k) => (
                <Draggable
                  key={k}
                  bounds="parent"
                  handle=".child"
                  position={{
                    x: drag.x,
                    y: drag.y,
                  }}
                  onStart={() => false}
                >
                  {drag?.type === "wall" ? (
                    <div
                      className={`box_small wall`}
                      style={{
                        width: `${drag?.width ? drag.width : "28"}px`,
                        height: `${drag?.height ? drag.height : "28"}px`,
                        background: drag?.type === "wall" ? "black" : "",
                      }}
                    ></div>
                  ) : drag?.type === "door" ? (
                    <div
                      className={`box_small door`}
                      style={{
                        width: `${drag?.width ? drag.width : "28"}px`,
                        height: `${drag?.height ? drag.height : "28"}px`,
                      }}
                    >
                      <img
                        src={"/assets/icons/door.svg"}
                        alt=""
                        style={{
                          width: `100%`,
                          height: `100%`,
                        }}
                      />
                    </div>
                  ) : drag?.status === "Approve" ? (
                    <div
                      className={`child box_small ${
                        drag?.value === sboothId
                          ? "blink_me booked"
                          : drag?.status === "Approve"
                          ? "booked"
                          : drag?.status === "InProgress"
                          ? "my_booked"
                          : ""
                      }`}
                      style={{
                        width: `${drag?.width ? drag.width : "26"}px`,
                        height: `${drag?.height ? drag.height : "26"}px`,
                        backgroundImage: drag?.companyLogo
                          ? `url(${Config.API_HOST_URL_live}${drag?.companyLogo})`
                          : `url(${Config.API_HOST_URL_live}${drag?.exhibitorProfileImage})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                      onClick={() => handleOpen(drag)}
                      onTouchStart={() => handleOpen(drag)}
                    >
                      <div>{drag?.label}</div>
                    </div>
                  ) : drag?.status === "InProgress" ? (
                    <div
                      className={`child box_small
                         my_booked
                        
                      `}
                      style={{
                        width: `${drag?.width ? drag.width : "26"}px`,
                        height: `${drag?.height ? drag.height : "26"}px`,
                      }}
                    >
                      <div>{drag?.label}</div>
                    </div>
                  ) : (
                    <div
                      className={`child box_small availabel `}
                      style={{
                        width: `${drag?.width ? drag.width : "28"}px`,
                        height: `${drag?.height ? drag.height : "28"}px`,
                      }}
                    >
                      <div>{drag?.label}</div>
                    </div>
                  )}
                </Draggable>
              ))}
          </div>
        </div>
      </div>
      <DetailShowPopup
        openbox={open}
        closebox={setOpen}
        title="Company Details"
        item={comDl}
        canEdit={canEdit}
        gotoBtn={true}
        allBooth={deltaPositionSave}
        getBox={getBox}
        hideButton={name}
        evD={evD}
      />
    </>
  );
}

export default ConferenceUserDragableBoxBook;
