import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Button, CircularProgress, IconButton } from "@mui/material";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import CropModel from "../../../../Components/Reusable/CropModel";
import "./index.scss";
import Select from "react-select";
import { customFilter } from "../../../../Components/CustomFilterForReactSelect";
import { ToastError, ToastSuccess } from "../../../../Components/Toast";

function AssociationMemberCreate() {
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [openCircle, setOpenCircle] = React.useState(false);
  const [derror, setDerror] = useState();
  const [stateList, setStateList] = useState([]);
  const [aList, setAList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectAdmin, setSelectAdmin] = useState([]);
  const [associationId, setAssociationId] = useState();
  const [stateValue, setStateValue] = useState();

  let nav = useNavigate();
  let { pathname } = useLocation();

  const LogDetails = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const handleChangeAdmin = (e) => {
    setSelectAdmin(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };
  useEffect(() => {
    // if (userList?.length > 0) {
    if (stateList?.length > 0) {
      // if (aList?.length > 0) {
      if (id) {
        getConfereceData(id);
      }
      // }
    }
    // }
    // eslint-disable-next-line
  }, [id, stateList, aList, userList]);

  useEffect(() => {
    GetStateList();
    GetAssociationList();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (id) {
      GetUserList();
    }
    // eslint-disable-next-line
  }, [id]);

  const getConfereceData = async (id) => {
    setOpenCircle(true);
    let seData = {
      url: `/api/AssociationMembers/GetAssociationMembersByAssociationMembersId?associationMembersId=${id}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res.data.success) {
        let useDe = res.data.payload?.am;
        setValue("AssociationMembersId", id);
        setValue("associationName", res?.data?.payload?.associationName);
        setValue("AssociationId", useDe?.associationId);
        setAssociationId(useDe?.associationId);
        setValue("memberNumber", useDe?.memberNumber);
        setValue("Name", useDe?.name);
        setValue("Address", useDe?.address);
        setValue("Type", useDe?.type);
        setValue("TShirtSize", useDe?.tshirtSize);
        setValue("mealRequirements", useDe?.mealRequirements);
        setValue("Email", useDe?.email);
        setValue("Phone", useDe?.phone);
        setValue("DisplayImage", useDe?.profilePicture);
        setValue("DisplayImageUrl", useDe?.profilePicture);
        setValue("City", useDe?.city);
        setValue("State", useDe?.state);
        setValue("Zip", useDe?.zip);
        setValue("Accreditations", useDe?.accreditations?.toString());

        let useId = res.data.payload?.associationMemberAdminList;
        if (useId?.length > 0) {
          setSelectAdmin(useId?.map((x) => x?.userId));
        }
        // if (useId) {
        //   setValue("adminId", useId);
        // }
      }
    }
  };

  const onSubmit = async (data) => {
    setOpenCircle(true);
    let EData = {
      ...data,
      AssociationMemberAdminList: selectAdmin?.length > 0 ? selectAdmin : [],
      profilePicture: data?.DisplayImage,
      CreatedBy: LogDetails?.userId,
      Type:
        pathname?.split("/")[1] === "state-agencies"
          ? "State Agency"
          : pathname?.split("/")[1] === "lea-members"
          ? "LEA"
          : "Industry",
    };

    let seData = {
      url: `/api/AssociationMembers/CreateAssociationMembers`,
      body: EData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let res = await PostCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200 && res.data.success) {
      reset();
      ToastSuccess(res.data.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      nav(-1);
    } else {
      setDerror({ err: res.data?.message });

      ToastError(res.data.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const GetStateList = async () => {
    let seData = {
      url: "/api/Common/GetStateList",
      headers: headers,
    };

    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        setStateList(
          res.data.payload?.map((x) => {
            return {
              ...x,
              label: x?.stateName,
              value: x?.stateName,
            };
          })
        );
      }
    }
  };

  const GetAssociationList = async () => {
    let seData = {
      url: `/api/Association/GetAllAssociationList?userId=${LogDetails?.userId}`,
      headers: headers,
    };

    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        setAList(
          res.data.payload?.map((x) => {
            return {
              ...x,
              label: x?.name,
              value: x?.associationId,
            };
          })
        );
      }
    }
  };

  const GetUserList = async () => {
    setOpenCircle(true);
    let seData = {
      url: `/api/Association/GetAllAssociationMembersTypeAllocationListByAssociationMembersId?associationMembersId=${id}`,
    };
    let res = await GetCallApi(seData);

    if (res.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        let comList = res?.data?.payload?.map((c) => {
          return {
            value: c?.userId,
            label: c?.userName,
          };
        });
        setUserList(comList);
      }
    }
  };

  // crop code start
  const [src, setSrc] = useState(null);
  const [filename, setFileName] = useState(null);
  const [croppedImageUrl] = useState(null);
  const [openCropModal, setCropModal] = useState(false);
  const setCroppedImageUrlCallback = useCallback(
    (url) => {
      if (url) {
        setValue("DisplayImage", url);
        setValue("DisplayImageUrl", url.name);
        // handleProImg(url);
        // setCroppedImageUrl(URL.createObjectURL(url));
        setSrc(null);
      } else {
        setSrc(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl]
  );

  const handleFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setFileName(e.target.files[0].name);
      reader.addEventListener(
        "load",
        () => {
          setSrc(reader.result);
          setCropModal(true);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
    }
  };
  // crop code end
  const handleAssociation = (e) => {
    setAssociationId(e);
    setValue("AssociationId", e);
  };

  const handleState = (e) => {
    setStateValue(e);
    setValue("State", e);
  };

  return (
    <>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => 9999 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <form onSubmit={handleSubmit(onSubmit)} className="form_box mt-3">
        <div className="main_wrapper">
          <div className="card">
            <div className="card-body">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12 mb-4">
                    <div className="title">
                      {id ? "Edit" : "Create"}{" "}
                      {id
                        ? pathname?.split("/")[1] === "state-agencies"
                          ? "State Agency"
                          : pathname?.split("/")[1] === "lea-members"
                          ? "LEA Member"
                          : "Industry Member"
                        : pathname === "/state-agencies/create"
                        ? "State Agency"
                        : pathname === "/lea-members/create"
                        ? "LEA Member"
                        : "Industry Member"}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Name
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Name"
                        {...register("Name", {
                          required: "This field is required",
                          pattern: {
                            value: /^[^\s]+(\s+[^\s]+)*$/,
                            message: "Starting and Ending Space not allowed",
                          },
                          maxLength: {
                            value: 250,
                            message: "Max 250 characters",
                          },
                        })}
                      />
                      {errors.Name && (
                        <span role="alert" className="error_text">
                          {errors.Name.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        District/Organization
                        <span className="red-color"> *</span>
                      </label>
                      <span>
                        {LogDetails?.roleId === 1 ? (
                          <>
                            <Select
                              isMulti={false}
                              name="colors"
                              options={aList}
                              isClearable={true}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select District/Organization"
                              filterOption={customFilter}
                              value={aList?.filter(
                                (obj) => obj.value === associationId
                              )}
                              onChange={(e) => handleAssociation(e?.value)}
                            />
                            <select
                              type="text"
                              className="form-select d-none"
                              {...register("AssociationId", {
                                required: "This field is required",
                              })}
                            >
                              <option value="">
                                Select District/Organization
                              </option>

                              {aList &&
                                aList?.map((st, i) => (
                                  <option value={st?.associationId} key={i}>
                                    {st?.name}
                                  </option>
                                ))}
                            </select>
                          </>
                        ) : id ? (
                          <input
                            type="text"
                            className="form-select"
                            disabled={id ? true : false}
                            {...register("associationName", {
                              required: "This field is required",
                            })}
                          ></input>
                        ) : (
                          <>
                            <Select
                              isMulti={false}
                              name="colors"
                              options={aList}
                              isClearable={true}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select District/Organization"
                              filterOption={customFilter}
                              value={aList?.filter(
                                (obj) => obj.value === associationId
                              )}
                              onChange={(e) => handleAssociation(e?.value)}
                            />
                            <select
                              type="text"
                              className="form-select d-none"
                              {...register("AssociationId", {
                                required: "This field is required",
                              })}
                            >
                              <option value="">
                                Select District/Organization
                              </option>

                              {aList &&
                                aList?.map((st, i) => (
                                  <option value={st?.associationId} key={i}>
                                    {st?.name}
                                  </option>
                                ))}
                            </select>
                          </>
                        )}
                      </span>
                      {errors.AssociationId && (
                        <span role="alert" className="error_text">
                          {errors.AssociationId.message}
                        </span>
                      )}
                    </div>
                  </div>{" "}
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Profile Picture
                        <span className="red-color"> *</span>
                      </label>
                      <div className="d-flex upload_btn_sec">
                        <input
                          type="text"
                          className="form-control"
                          readOnly
                          placeholder="No file choose"
                          {...register(`DisplayImageUrl`, {
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                            // onChange: (e) => handleFile(e),
                          })}
                        />
                        <IconButton
                          component="label"
                          className="upload_btn m-0"
                        >
                          Choose File
                          <input
                            id="upload"
                            type="file"
                            accept="image/*"
                            className="form-control"
                            hidden
                            onChange={(e) => handleFile(e)}
                          />
                        </IconButton>
                      </div>
                      {/* crop code start */}
                      {src && (
                        <CropModel
                          src={src}
                          filename={filename}
                          setCroppedImageUrl={setCroppedImageUrlCallback}
                          openCropModal={openCropModal}
                          setCropModal={setCropModal}
                        />
                      )}
                      {/* crop code end */}
                      {errors.DisplayImageUrl && (
                        <span role="alert" className="error_text">
                          {errors.DisplayImageUrl.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Member Number
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Member Number"
                        {...register("memberNumber", {
                          required: "This field is required",
                          pattern: {
                            value: /^[0-9+-]+$/,
                            message: "Enter valid number",
                          },
                          maxLength: {
                            value: 100,
                            message: "Max 100 characters",
                          },
                        })}
                      />
                      {errors.memberNumber && (
                        <span role="alert" className="error_text">
                          {errors.memberNumber.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Phone
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Phone"
                        {...register("Phone", {
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                          pattern: {
                            value:
                              /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                            message: "Enter valid phone number",
                          },
                          maxLength: {
                            value: 20,
                            message: "Max 20 characters",
                          },
                        })}
                      />
                      {errors.Phone && (
                        <span role="alert" className="error_text">
                          {errors.Phone.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Email
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Email"
                        {...register("Email", {
                          required: "This field is required",
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: "Please Enter Valid Email",
                          },
                          maxLength: {
                            value: 150,
                            message: "Max 150 characters",
                          },
                        })}
                      />
                      {errors.Email && (
                        <span role="alert" className="error_text">
                          {errors.Email.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        {" "}
                        Address
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Address"
                        {...register("Address", {
                          required: "This field is required",
                          pattern: {
                            value: /^[^\s]+(\s+[^\s]+)*$/,
                            message: "Starting and Ending Space not allowed",
                          },
                          maxLength: {
                            value: 1000,
                            message: "Max 1000 characters",
                          },
                        })}
                      />
                      {errors.Address && (
                        <span role="alert" className="error_text">
                          {errors.Address.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        {" "}
                        City
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter City"
                        {...register("City", {
                          required: "This field is required",
                          pattern: {
                            value: /^[^\s]+(\s+[^\s]+)*$/,
                            message: "Starting and Ending Space not allowed",
                          },
                          maxLength: {
                            value: 100,
                            message: "Max 100 characters",
                          },
                        })}
                      />
                      {errors.City && (
                        <span role="alert" className="error_text">
                          {errors.City.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        {" "}
                        State
                        <span className="red-color"> *</span>
                      </label>
                      <Select
                        isMulti={false}
                        name="colors"
                        options={stateList}
                        isClearable={true}
                        className="dropdown-single-select"
                        classNamePrefix="select"
                        placeholder="Select State"
                        filterOption={customFilter}
                        value={stateList?.filter(
                          (obj) => obj.value === stateValue
                        )}
                        onChange={(e) => handleState(e?.value)}
                      />
                      <select
                        type="text"
                        className="form-select d-none"
                        placeholder="Enter State"
                        {...register("State", {
                          required: "This field is required",
                        })}
                      >
                        <option value="">Select State</option>

                        {stateList &&
                          stateList?.map((st, i) => (
                            <option value={st?.stateName} key={i}>
                              {st?.stateName}
                            </option>
                          ))}
                      </select>
                      {errors.State && (
                        <span role="alert" className="error_text">
                          {errors.State.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        {" "}
                        Zip
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Zip"
                        {...register("Zip", {
                          required: "This field is required",
                          pattern: {
                            value: /^[^\s]+(\s+[^\s]+)*$/,
                            message: "Starting and Ending Space not allowed",
                          },
                          maxLength: {
                            value: 20,
                            message: "Max 20 characters",
                          },
                        })}
                      />
                      {errors.Zip && (
                        <span role="alert" className="error_text">
                          {errors.Zip.message}
                        </span>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Accreditations
                        <span className="red-color"> *</span>
                      </label>
                      <div>
                        <input
                          type="radio"
                          value={"true"}
                          {...register("Accreditations", {
                            required: "This field is required",
                          })}
                        />{" "}
                        True
                      </div>
                      <div>
                        <input
                          type="radio"
                          value={"false"}
                          {...register("Accreditations", {
                            required: "This field is required",
                          })}
                        />{" "}
                        False
                      </div>

                      {errors.Accreditations && (
                        <span role="alert" className="error_text">
                          {errors.Accreditations.message}
                        </span>
                      )}
                    </div>
                  </div> */}
                  {/* <div className="col-md-4 d-none">
                    <div className="form-group">
                      <label className="form-label">
                        Type
                        <span className="red-color"> *</span>
                      </label>
                      <div>
                        <input
                          type="radio"
                          value={"LEA"}
                          {...register("Type", {
                            required: "This field is required",
                          })}
                        />{" "}
                        LEA
                      </div>
                      <div>
                        <input
                          type="radio"
                          value={"Industry"}
                          {...register("Type", {
                            required: "This field is required",
                          })}
                        />{" "}
                        Industry
                      </div>
                      <div>
                        <input
                          type="radio"
                          value={"State Agency"}
                          {...register("Type", {
                            required: "This field is required",
                          })}
                        />{" "}
                        State Agency
                      </div>
                      {errors.Type && (
                        <span role="alert" className="error_text">
                          {errors.Type.message}
                        </span>
                      )}
                    </div>
                  </div> */}
                  {/* <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        T-Shirt Size
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter T-Shirt Size"
                        {...register("TShirtSize", {
                          required: "This field is required",
                          pattern: {
                            value: /^[^\s]+(\s+[^\s]+)*$/,
                            message: "Starting and Ending Space not allowed",
                          },
                          maxLength: {
                            value: 50,
                            message: "Max 50 characters",
                          },
                        })}
                      />
                      {errors.TShirtSize && (
                        <span role="alert" className="error_text">
                          {errors.TShirtSize.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Meal Requirements
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Meal Requirements"
                        {...register("mealRequirements", {
                          required: "This field is required",
                          pattern: {
                            value: /^[^\s]+(\s+[^\s]+)*$/,
                            message: "Starting and Ending Space not allowed",
                          },
                          maxLength: {
                            value: 100,
                            message: "Max 100 characters",
                          },
                        })}
                      />
                      {errors.mealRequirements && (
                        <span role="alert" className="error_text">
                          {errors.mealRequirements.message}
                        </span>
                      )}
                    </div>
                  </div> */}
                  {id ? (
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          {" "}
                          Admin
                          <span className="red-color"> *</span>
                        </label>
                        <Select
                          isMulti
                          name="colors"
                          options={userList}
                          className="company-multi-select"
                          classNamePrefix="select"
                          placeholder="Select Admin"
                          filterOption={customFilter}
                          value={userList?.filter((obj) =>
                            selectAdmin?.includes(obj.value)
                          )}
                          onChange={handleChangeAdmin}
                        />
                        {/* <select
                          type="text"
                          className="form-select"
                          {...register("adminId", {
                            required: "This field is required",
                          })}
                        >
                          <option value="">Select Admin</option>
                          {userList &&
                            userList?.map((u) => (
                              <option value={u?.value} key={u?.value}>
                                {u?.label}
                              </option>
                            ))}
                        </select> */}
                        {errors.adminId && (
                          <span role="alert" className="error_text">
                            {errors.adminId.message}
                          </span>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="col-md-12 d-flex justify-content-end mt-3">
                    <div className="form-group">
                      <Button className="back_button" onClick={() => nav(-1)}>
                        Cancel
                      </Button>
                      <Button
                        className="detail_button ms-3"
                        type="submit"
                        disabled={openCircle}
                      >
                        {openCircle ? (
                          <CircularProgress
                            color="inherit"
                            sx={{
                              color: "#fff",
                              marginLeft: "0.5rem",
                              height: "23px !important",
                              width: "23px !important",
                            }}
                          />
                        ) : id ? (
                          "Update"
                        ) : (
                          "Save"
                        )}
                      </Button>
                      {derror?.err && (
                        <span role="alert" className="d-block error_text">
                          {derror.err}
                        </span>
                      )}
                    </div>
                  </div>
                  <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AssociationMemberCreate;
