import React, { Suspense, useEffect, useState } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import WebMenu from "../../Shared/WebMenu";
import CountdownTimer from "../../Modules/Components/CountDown/CountdownTimer";
import { Button, CircularProgress } from "@mui/material";
import ImgPopup from "../../Modules/Components/ImgPopUp";
import WebFooter from "../../Shared/WebFooter";
import { Config } from "../../Utils/Config";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { MdArrowOutward } from "react-icons/md";
import Carousel from "react-material-ui-carousel";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaArrowLeft } from "react-icons/fa6";
import { GetCallApi } from "../../Action/Action";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import figEventMockup from "../../assets/images/figEventMockup.png";
import events from "../../assets/images/events.png";
import explore from "../../assets/images/explore.png";
import inquiryCall from "../../assets/images/inquiryCall.png";
import NotFound from "../../assets/images/Image_not_available.jpg";
import HomeConferenceList from "./ConferenceList";

const calander = "/assets/icons/calendar.svg";
const group = "/assets/icons/group.svg";
const tracking = "/assets/icons/tracking.svg";
const imageDots = "/assets/icons/imageDots.svg";
const layer = "/assets/icons/layer.svg";
const pattern = "/assets/icons/pattern.svg";
const pattern02 = "/assets/icons/pattern02.svg";
const chat = "/assets/images/chat.png";
const CheckIn = "/assets/images/CheckIn.png";
const community = "../../assets/images/community.png";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function WebsiteHome() {
  const [isLoading, setIsLoading] = useState(true);
  const [openCircle, setOpenCircle] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [glList, setGlList] = useState([]);
  const [abtInfo, setAbtInfo] = useState();

  const NOW_IN_MS = new Date().getTime();
  const LogDetails = useSelector((state) => state.login.LoginDetails);

  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    document.title = "FIG Events ";

    setIsLoading(true);
    setTimeout(() => {
      getEventList("conference");
      getAboutInfo();
      GetGlList();
    }, 200);
    // eslint-disable-next-line
  }, []);

  const box = Array.from({ length: 1 }, (_, index) => (
    <div key={index} className="skeleton-box"></div>
  ));

  const getEventList = async () => {
    setOpenCircle(!openCircle);
    let seData = {
      url: `/api/Conference/GetAllWithoutTokenConferenceList?isAlphanumericOrder=false`,
    };
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    setIsLoading(false);
    if (res.status === 200) {
      if (res.data.success) {
        let cl = res.data.payload?.filter((x) => x.status === "Approved");
        var offset = new Date().getTimezoneOffset();
        var date1 = new Date();
        date1.setMinutes(date1.getMinutes() + offset);
        var easternTimeOffset = -240;
        date1.setMinutes(date1.getMinutes() + easternTimeOffset);
        cl = cl.map((x) => {
          // let date = convert(x?.conferenceStartdate?.split("T")[0]);
          let date = x?.conferenceStartdate;
          let std = new Date(date);
          // std.setMinutes(std.getMinutes() + offset);
          std.setMinutes(std.getMinutes());
          // std.setMinutes(std.getMinutes() + easternTimeOffset);
          // const diffTime = Math.abs(std - date1);
          const diffTime = Math.abs(std - new Date());
          // const diffTime = Math.abs(new Date(date) - new Date());
          return {
            ...x,
            diffTime: new Date(date) > new Date() ? NOW_IN_MS + diffTime : 0,
          };
        });
        setEventList(cl);
      } else {
        setOpenCircle(false);
        setIsLoading(false);
      }
    }
  };
  // function convert(str) {
  //   var date = new Date(str?.replace(/-/g, "/")),
  //     mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  //     day = ("0" + date.getDate()).slice(-2);
  //   return [date.getFullYear(), mnth, day].join("/");
  // }
  const GetGlList = async () => {
    let seData = {
      url: `/api/Gallery/GetAllGallery`,
      headers: {},
    };
    let res = await GetCallApi(seData);

    if (res.status === 200) {
      if (res.data.success) {
        let comList = res?.data?.payload?.map((c, i) => {
          return {
            no: i + 1,
            ...c,
            image: c?.image ? `${Config.API_HOST_URL_live}${c.image}` : "",
          };
        });
        setGlList(comList);
      } else {
        setIsLoading(false);
      }
    }
  };
  const getAboutInfo = async () => {
    let seData = {
      url: "/api/AboutUs/GetAboutUsInformation",
      headers: {},
    };
    let res = await GetCallApi(seData);
    if (res.status === 200) {
      if (res.data.success) {
        let abt = res.data.payload;
        setAbtInfo(abt);
      } else {
        setOpenCircle(false);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <WebMenu />
        <section
          className={`slider ${
            isLoading ? "load" : eventList?.length > 0 ? "" : "d-none"
          }`}
        >
          {isLoading ? (
            <div className="slide_contain">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-12 left_slide">
                    <div className="skeleton-loader">
                      <div className="skeleton-line skeleton-title mb-4"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                    </div>
                    <div className="skeleton-loader inner-loader-rounded mt-5">
                      <div className="skeleton-round"></div>
                      <div className="skeleton-round"></div>
                      <div className="skeleton-round"></div>
                      <div className="skeleton-round"></div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 right_slide">
                    <div className="skeleton-loader mb-3">{box}</div>
                  </div>
                </div>
              </div>
            </div>
          ) : eventList?.length > 0 ? (
            <Carousel
              NavButton={({ onClick, style, next, prev }) => {
                return isLoading ? (
                  <Button className="navigation_btn">
                    <div className="skeleton-loader">
                      <div className="skeleton-banner-arrow"></div>
                    </div>
                  </Button>
                ) : (
                  <Button
                    onClick={onClick}
                    className="navigation_btn skeleton-banner-arrow"
                    style={style}
                  >
                    {next && <FaArrowRightLong />}
                    {prev && <FaArrowLeft />}
                  </Button>
                );
              }}
            >
              {eventList?.map((cd, i) => (
                <div key={i} className="slide_contain">
                  <img src={imageDots} className="img-fluid imageDots" alt="" />
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-5 col-md-12">
                        <div className="left_slide">
                          {cd?.conferenceName ? (
                            <h1>{cd.conferenceName.slice(0, 19)}</h1>
                          ) : null}
                          {cd?.description ? (
                            <p>
                              {cd?.description &&
                              cd.description.length > 150 ? (
                                <>
                                  {cd.description.slice(0, 150)}
                                  <span
                                    data-tooltip-id="description-tooltip"
                                    data-tooltip-content={cd.description}
                                  >
                                    ...
                                  </span>
                                  <Tooltip
                                    id="description-tooltip"
                                    place="top"
                                    type="dark"
                                    effect="solid"
                                  />
                                </>
                              ) : (
                                cd.description
                              )}
                            </p>
                          ) : null}

                          <CountdownTimer
                            targetDate={cd?.diffTime}
                            expire={
                              new Date(cd?.conferenceEnddate) >= time
                              // cd?.conferenceEnddate >= new Date()?.toISOString()
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-12">
                        <div className="right_slide">
                          <img
                            src={
                              cd?.coverImage
                                ? `${Config.API_HOST_URL_live}${cd?.coverImage}`
                                : NotFound
                            }
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = NotFound;
                            }}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          ) : (
            ""
          )}
        </section>
        <HomeConferenceList NavHide={true} />
        <section className="keyFeature">
          <div className="container">
            <div className="keyFeature_title">
              <h2>Key Features of Our Event App</h2>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="d-flex flex-column gap-3 h-100 left">
                  <div className="keyFeatureBox">
                    <img src={community} className="img-fluid" alt="" />
                    <h4>Connect with the Community</h4>
                    <p>
                      Engage with a dynamic community. Network with like-minded
                      professionals, share insights, and build meaningful
                      connections effortlessly.
                    </p>
                  </div>
                  <div className="keyFeatureBox">
                    <img src={events} className="img-fluid" alt="" />
                    <h4>Schedule Your Sessions and Events</h4>
                    <p>
                      Optimize your event experience. Schedule sessions and
                      events in advance to ensure you don’t miss any crucial
                      activities.
                    </p>
                  </div>
                  <div className="keyFeatureBox">
                    <img src={chat} className="img-fluid" alt="" />
                    <h4>Real-Time Chat Functionality</h4>
                    <p>
                      Maintain seamless communication with attendees, speakers,
                      and exhibitors. Our integrated chat feature facilitates
                      real-time interactions with all participants.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 my-3 my-md-0">
                <img src={figEventMockup} className="img-fluid" alt="" />
              </div>
              <div className="col-md-4">
                <div className="d-flex flex-column gap-3 h-100 right">
                  <div className="keyFeatureBox">
                    <img src={CheckIn} className="img-fluid" alt="" />
                    <h4>Effortless Session Check-In</h4>
                    <p>
                      Enhance your event participation. Use our convenient scan
                      feature to quickly and easily check in to sessions.
                    </p>
                  </div>
                  <div className="keyFeatureBox">
                    <img src={explore} className="img-fluid" alt="" />
                    <h4>Explore Exhibitors and Products</h4>
                    <p>
                      Discover a diverse range of exhibitors and their products.
                      Access detailed information to make informed decisions and
                      maximize your event experience.
                    </p>
                  </div>
                  <div className="keyFeatureBox">
                    <img src={inquiryCall} className="img-fluid" alt="" />
                    <h4>Request a Product Inquiry Call</h4>
                    <p>
                      Need more information? Simply request a call from our
                      support team or exhibitors to get your questions answered
                      promptly.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="about_sec" id="about">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 right_sec">
                {isLoading ? (
                  <div className="skeleton-loader">{box}</div>
                ) : (
                  <img
                    src={
                      abtInfo?.image
                        ? `${Config.API_HOST_URL_live}${abtInfo?.image}`
                        : "/assets/images/about_img.jpg"
                    }
                    className="img-fluid"
                    alt="about"
                    width={616}
                    height={616}
                  />
                )}
                {isLoading ? (
                  <div className="skeleton-loader">{box}</div>
                ) : (
                  <img
                    src={
                      abtInfo?.image2
                        ? `${Config.API_HOST_URL_live}${abtInfo?.image2}`
                        : // : aboutimg
                          "/assets/images/about-img02.png"
                    }
                    alt="About"
                    className="small-img"
                  />
                )}
              </div>
              <div className="col-lg-6 col-md-12 left_sec">
                <div className="contain">
                  <img src={pattern} alt="pattern" className="pattern-img" />
                  {isLoading ? (
                    <div className="skeleton-loader">
                      <h5>About Us</h5>
                      <div className="skeleton-line skeleton-title mb-4"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                    </div>
                  ) : (
                    <>
                      <h5>About Us</h5>
                      <h2>{abtInfo?.title}</h2>
                      <p>{abtInfo?.description}</p>
                      <Link
                        to="https://www.swank12.com"
                        target="_blank"
                        className="theme-btn"
                      >
                        Know More
                      </Link>
                    </>
                  )}
                  <img src={pattern02} alt="pattern" className="pattern-img1" />
                </div>
              </div>
            </div>

            <div className="row aboutMoreCard"></div>
          </div>
        </section>
        <section className="WhyChooseus">
          <div className="container">
            <div className="whyChooseus_title">
              <h5>Why Choose us</h5>
              <h2>Why you should choose us?</h2>
              <p>
                Conference Planner (CP) unites thousands of school nutrition
                program operators, industry partners, and allied organizations
                for an unparalleled educational and networking experience.
                Integrated
              </p>
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                <div className="whyChooseusBox">
                  <div>
                    <div className="icon blue">
                      <img src={calander} className="img-fluid" alt="" />
                    </div>
                  </div>
                  <div className="content">
                    <h4>Personalize Your Schedule</h4>
                    <p>
                      Enhance your conference experience by tailoring your
                      schedule to fit your unique interests and needs. Select
                      the sessions that matter most to you, ensuring you make
                      the most of your time with us.
                      {/* Browse and add events or classes to your personal
                      schedule. Stay organized and make the most of your
                      conference experience by planning your itinerary in
                      advance. */}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                <div className="whyChooseusBox">
                  <div>
                    <div className="icon red">
                      <img src={layer} className="img-fluid" alt="" />
                    </div>
                  </div>
                  <div className="content">
                    <h4>Earn Continuing Education Units</h4>
                    <p>
                      Advance your professional development by earning
                      Continuing Education Units (CEUs) through our
                      comprehensive programs. Stay ahead in your field and
                      effortlessly meet your certification requirements.
                      {/* Attend classes and sessions to earn CEU certificates. The
                      app automatically tracks your attendance and provides
                      certification for completed sessions. */}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                <div className="whyChooseusBox">
                  <div>
                    <div className="icon black">
                      <img src={tracking} className="img-fluid" alt="" />
                    </div>
                  </div>
                  <div className="content">
                    <h4>Manage Your CRM by Lead Tracking and Status </h4>
                    {/* <h4>Earn Continuing Education Units</h4> */}
                    <p>
                      Streamline your CRM management with our sophisticated lead
                      tracking and status features. Stay organized, nurture
                      leads effectively, and optimize your sales process for
                      better results.
                      {/* Attend classes and sessions to earn CEU certificates. The
                      app automatically tracks your attendance and provides
                      certification for completed sessions. */}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                <div className="whyChooseusBox">
                  <div>
                    <div className="icon green">
                      <img src={group} className="img-fluid" alt="" />
                    </div>
                  </div>
                  <div className="content">
                    <h4>Chat with Vendors, Exhibitors, and Attendees</h4>
                    <p>
                      In-person Chat with Vendors, Exhibitors, and Attendees
                      Foster meaningful connections through direct, in-person
                      conversations with vendors, exhibitors, and fellow
                      attendees. Gain valuable insights and build lasting
                      relationships at our events.
                      {/* Attend classes and sessions to earn CEU certificates. The
                      app automatically tracks your attendance and provides
                      certification for completed sessions. */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className={`exhibitors ${isLoading ? "load" : ""}`}
          id="exhibitors"
        >
          <div className="container">
            <div className="exhibitors_title">
              {/* <img src={title_icon1} className="img-fluid" alt="" /> */}
              <h5>Exhibitors</h5>
            </div>
            <h2>Advantages of Exhibiting At FIG Event</h2>
            <p>
              Known as the Conference Planner of the Year, the Conference
              Planner (CP) unites thousands of school nutrition program
              operators, industry partners, and allied organizations for an
              unparalleled educational and networking experience every July.
            </p>
            {/* <div className="card">
              <div className="card-body"> */}
            <div className="row">
              <div className="col-md-6 left_sec">
                <h5 className="mb-3">The benefits of exhibiting include:</h5>
                <div className="exhibing-accordian">
                  {isLoading ? (
                    <div className="skeleton-loader">
                      <h1>Advantages of Exhibiting At FIG Event</h1>
                      <span className="mb-3">
                        The benefits of exhibiting include:
                      </span>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                    </div>
                  ) : (
                    <>
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<MdArrowOutward />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <p>Raise Awareness</p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <p className="mb-0">
                            Exhibiting at industry events is a good way to raise
                            your profile and generate brand awareness. As well
                            as taking a stand at an event, there are usually
                            other advertising and sponsorship opportunities.
                            These opportunities can include branded goody bags,
                            branded seating or even sponsoring the event itself.
                          </p>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion className="mt-20">
                        <AccordionSummary
                          expandIcon={<MdArrowOutward />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <p>Meet In Person</p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <p className="mb-0">
                            Meeting face-to-face with potential customers is a
                            great way to start building relationships.
                          </p>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion className="mt-20">
                        <AccordionSummary
                          expandIcon={<MdArrowOutward />}
                          aria-controls="panel3-content"
                          id="panel3-header"
                        >
                          <p>Networking</p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <p className="mb-0">
                            Trade shows are a great way to meet potential new
                            customers, suppliers and to learn more about your
                            competitors.
                          </p>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion className="mt-20">
                        <AccordionSummary
                          expandIcon={<MdArrowOutward />}
                          aria-controls="panel3-content"
                          id="panel3-header"
                        >
                          <p>Launch new product</p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <p className="mb-0">
                            Trade shows are a good place to introduce a new
                            product or service. Being able to explain your
                            offering in person and answer questions is ideal if
                            your product is innovative.
                          </p>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion className="mt-20">
                        <AccordionSummary
                          expandIcon={<MdArrowOutward />}
                          aria-controls="panel4-content"
                          id="panel4header"
                        >
                          <p>Build your database</p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <p className="mb-0">
                            Meeting with potential customers at an exhibition
                            helps you to start building your marketing lists and
                            generate qualified sales leads.
                          </p>
                        </AccordionDetails>
                      </Accordion>
                    </>
                  )}
                </div>
              </div>
              <div className="col-md-6 right_sec">
                {isLoading ? (
                  <div className="skeleton-loader">{box}</div>
                ) : (
                  <img
                    src={"/assets/images/exhibitors_side.jpg"}
                    className="img-fluid"
                    alt="about"
                  />
                )}
              </div>
            </div>
          </div>
          {/* </div>
          </div> */}
        </section>
        <section className="gallery" id="gallery">
          <div className="container">
            <div className="gallery_title">
              <h5>Gallery</h5>
            </div>
            <h2>Event and Conference Photos Gallery</h2>
            <p>
              Known as the Conference Planner of the Year, the Conference
              Planner (CP) unites thousands of school nutrition program
              operators, industry partners, and allied organizations for an
              unparalleled educational and networking experience every July.
            </p>
            <div className="gallryPics">
              <div className="row">
                {isLoading ? (
                  <>
                    <div className="d-flex gap-3">
                      <div className="skeleton-loader">{box}</div>
                      <div className="skeleton-loader">{box}</div>
                      <div className="skeleton-loader">{box}</div>
                      <div className="skeleton-loader">{box}</div>
                    </div>
                  </>
                ) : (
                  glList?.map((ga, i) => (
                    <div className="col-md-3" key={i}>
                      <div className="img_box">
                        {i % 2 === 0 ? (
                          <h5 className="mb-30">
                            {ga?.title ? ga?.title : "Gallery Images"}
                          </h5>
                        ) : (
                          ""
                        )}
                        <ImgPopup img={ga.image} className="img-fluid" />
                        {i % 2 !== 0 ? (
                          <h5 className="mt-30">
                            {ga?.title ? ga?.title : "Gallery Images"}
                          </h5>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </section>
        {/* <section className="hotel_travel_information">
          <div className="container">
            <div className="HT_information_title">
              <img src={title_icon} className="img-fluid" alt="" />
              <h2>Hotel & Travel Information</h2>
            </div>
            <div className="row">
              <div className="col-md-12 mx-auto left_sec">
                <div className="contain">
                  <h1>Hotel Information</h1>
                  <p>
                    When planning your visit to Denver, it’s always best to plan
                    ahead. For information on Denver's attractions and
                    interactive maps of the city, visit www.denver.org.
                  </p>
                  <Button className="detail_button">Find a Hotel</Button>
                </div>
                <img src={'/assets/icons/hotelinfo.jpg'} className="img-fluid" alt="about" />
              </div>
              <div className="col-md-12 mx-auto left_sec">
                <img src={location} className="img-fluid" alt="about" />
                <div className="contain ps-5">
                  <h1>Location</h1>
                  <p>
                    With 300 days of sunshine, a walkable downtown, thriving
                    arts and culture, an award-winning culinary scene and the
                    Rocky Mountains as a backdrop, Denver offers an affordable
                    mix of urban sophistication and outdoor adventure.
                  </p>
                  <Button className="detail_button">
                    Get Travel Information
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="registration" id="pricing">
          <div className="container">
            <div className="registration_title">
              <img src={title_icon1} className="img-fluid" alt="" />
              <h2>Registration</h2>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 m-auto left_sec">
                    <div className="contain">
                      <h1>Pricing</h1>
                      <p>
                        CP 2023 registration fees include access to all general
                        and education sessions, the exhibit hall, culinary
                        demonstrations and the closing event at the Convention
                        Center. Conference registration is required to secure a
                        reservation within discounted CP hotel block.
                      </p>
                      <Button className="detail_button">
                        See More Registration Information
                      </Button>
                    </div>
                  </div>
                  <div className="col-md-6 right_sec">
                    <img src={'/assets/images/pricing.jpg'} className="img-fluid" alt="pricing" />
                  </div>
                </div>
              </div>
            </div>
            <div className="key_dates_title">
              <h2>Key Dates</h2>
            </div>
            <div className="year_box">
              <div className="year_title">
                <h2>2023</h2>
              </div>
              <div className="row">
                {month?.map((month, i) => (
                  <div className="col-md-3" key={i}>
                    <div className="month_box">
                      <h4>{month.name}</h4>
                      <p>{month.text}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="future_meetings">
          <div className="container">
            <div className="key_dates_title">
              <h2>Future Meetings</h2>
              <table className="table-border table_box">
                <thead>
                  <tr>
                    <th>Location</th>
                    <th>Dates</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.map((data, i) => (
                    <tr key={i}>
                      <td>{data.location}</td>
                      <td>{data.dates}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section> */}
        <section className={`register_rarly ${isLoading ? "load" : ""}`}>
          <div className="container">
            {isLoading ? (
              <div className="col-md-5">
                <div className="skeleton-loader">
                  <div className="skeleton-line skeleton-title mb-4"></div>
                  <div className="skeleton-line"></div>
                  <div className="skeleton-line"></div>
                  <div className="skeleton-line"></div>
                </div>
              </div>
            ) : (
              <>
                <div className="registerRarly_title">
                  <h2>Register Early and Save!</h2>
                </div>
                <div className="registerRarly_content">
                  <p>
                    Registering early will give you access to choose the best
                    place for your booth selection. We approve the booth
                    selection based on the first come first serve manner. so if
                    you register early you place your booth on the right place
                    of the floor plan.
                  </p>
                </div>
                {LogDetails?.authenticate ? (
                  ""
                ) : (
                  <Link
                    to={"/register"}
                    style={{ textDecoration: "none" }}
                    className="theme-btn"
                  >
                    Register Now
                  </Link>
                )}
              </>
            )}
          </div>
        </section>
        <WebFooter />
      </Suspense>
    </>
  );
}

export default WebsiteHome;
