import React from "react";
import { Config } from "../../../../Utils/Config";
import NotFound from "../../../../assets/images/Image_not_available.jpg";
import "./index.scss";
import { Link } from "react-router-dom";

function GridViewList({ listItem, key }) {
  return (
    <div className="col-lg-3 col-md-4 mb-4 " key={key}>
      <div className="card p-0 ">
        <div className="card-body">
          <div className="grid-view-img-set">
            <img
              src={
                listItem?.speakerImage
                  ? `${Config.API_HOST_URL_live}${listItem?.speakerImage}`
                  : NotFound
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = NotFound;
              }}
              className="img-fluid"
              alt=""
            />
            {listItem?.SpeakerTotalNumberOfVideo !== 0 && (
              <div className="img_text ">
                <span>{listItem?.SpeakerTotalNumberOfVideo} video</span>
              </div>
            )}
          </div>

          <div className="grid-view-infos">
            <h5>{listItem?.speakerName ?? "speaker name"}</h5>
            <p>
              {listItem?.speechesAndTopics?.slice(0, 85)}
              {listItem?.speechesAndTopics
                ? listItem?.speechesAndTopics?.length > 85 && (
                    <span
                      className="description cr"
                      style={{ cursor: "pointer" }}
                    >
                      ...
                    </span>
                  )
                : ""}
            </p>
          </div>
          <Link
            className="theme_btn"
            to={`/speaker-public-profile`}
            state={{ speakerId: listItem?.speakerId }}
          >
            View More
          </Link>
        </div>
      </div>
    </div>
  );
}

export default React.memo(GridViewList);
