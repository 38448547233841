import React, { Suspense } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/fig_events.png";
import "react-tabs/style/react-tabs.css";
import CRMHeader from "../Shared/CRMHeader";
import { MdContacts } from "react-icons/md";
import { FaRegStar } from "react-icons/fa";
import { BsWindow } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";
import { BsTrash3 } from "react-icons/bs";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { RxCross2 } from "react-icons/rx";
import { Button, CircularProgress } from "@mui/material";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { FaAngleLeft } from "react-icons/fa6";
import { TfiImport } from "react-icons/tfi";
import { TfiExport } from "react-icons/tfi";
import { CiSettings } from "react-icons/ci";
import { LuArchive } from "react-icons/lu";
import { LuBook } from "react-icons/lu";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

function InvoicePreview() {
  const [openSide, setOpenSide] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  return (
    <>
      <CRMHeader />
      <Suspense fallback={renderLoader()}>
        <section className="contactlist-section">
          <div className="container-fluid">
            <div className="row">
              <div
                className={`${
                  openSide ? "" : "hide_side"
                } col-lg-2 col-md-4 contact-border`}
              >
                <div className="cotact-left-section">
                  <Link to="" className="primary-btn btn-border w-100">
                    Create
                  </Link>
                  <div className="menu-group">
                    <ul>
                      <li>
                        <Link className="nav-link">
                          <MdContacts /> All Invoices
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <FaRegStar /> Sent
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <BsWindow /> Archived
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <FaRegEdit /> Pending
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <BsTrash3 /> Deleted
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="separator separator-light"></div>
                  <div className="menu-group">
                    <ul>
                      <li className="menu-title">Manage</li>
                      <li>
                        <Link className="nav-link">
                          <TfiExport /> Manage Invoices
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <TfiImport /> Recurring Invoices
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <BsWindow /> Manage Contacts
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <BsWindow /> Saved Templates
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="separator separator-light"></div>
                  <div className="menu-group">
                    <ul>
                      <li className="menu-title">Info</li>
                      <li>
                        <Link className="nav-link">
                          <MdContacts /> Business Info
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <BsWindow /> Tax Info
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="separator separator-light"></div>
                </div>
                <div className="contactapp-fixednav">
                  <div className="menu-group">
                    <ul>
                      <li>
                        <Link>
                          <CiSettings />
                        </Link>
                      </li>
                      <li>
                        <Link>
                          <LuArchive />
                        </Link>
                      </li>
                      <li>
                        <Link>
                          <LuBook />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  openSide ? "col-lg-10 col-md-8" : "col-lg-12"
                } contact-right-side`}
              >
                <div className="togglebar">
                  <div
                    className="sidebar-togglable"
                    onClick={() => setOpenSide(!openSide)}
                  >
                    <MdKeyboardArrowLeft />
                  </div>
                </div>
                <div className="top-contact">
                  <div className="invoice-title">
                    <Link to="/create-invoice" className="left-arrow">
                      <FaAngleLeft />
                    </Link>
                    <div className="v-separator"></div>
                    <Link to="/create-invoice">
                      <h3>Template Preview</h3>
                    </Link>
                  </div>
                  <div className="invoice-options-wrap">
                    <Link to="/create-invoice">Start with Template</Link>
                  </div>
                </div>
                <div className="row preview-invoice">
                  <div className="col-lg-9 offset-lg-1">
                    <div className="invoice-section">
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="logo">
                            <img
                              src={logo}
                              style={{
                                height: "150px",
                              }}
                              alt="Confrence Planner"
                              title="Confrence Planner"
                            />
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="d-flex align-items-center gap-3 justify-content-end">
                            <h2>Invoice</h2>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-5">
                        <div className="col-lg-4">
                          <div className="address-wrap">
                            <h6>Hencework</h6>
                            <p>4747, Pearl Street</p>
                            <p>Rainy Day Drive, </p>
                            <p>Washington DC 42156</p>
                            <p>jampack_01@hencework.com</p>
                          </div>
                        </div>
                        <div className="col-lg-8 text-end">
                          <div className="invoice-details">
                            <ul>
                              <li>
                                <span className="primarylight-color">
                                  Invoice No*
                                </span>
                                <span className="invoice-text">0001</span>
                              </li>
                              <li>
                                <span className="primarylight-color">
                                  Invoice Date*
                                </span>
                                <span className="invoice-text">24/08/2020</span>
                              </li>
                              <li>
                                <span className="primarylight-color">
                                  Due Date*
                                </span>
                                <span className="invoice-text">
                                  Due on receipt
                                </span>
                              </li>
                              <li>
                                <span className="primarylight-color">
                                  Customer No
                                </span>
                                <span className="invoice-text">321456</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="separator separator-light"></div>
                      <div className="row">
                        <div className="col-xxl-3 mb-xxl-0 mb-4">
                          <h6 className="text-uppercase">Billed To</h6>
                          <div className="Billto-wrap">
                            <h6>Supernova consultant</h6>
                            <p>4747, Pearl Street</p>
                            <p>Rainy day Drive</p>
                            <p>Washington DC 42341</p>
                            <p>jampack_01@hencework.com</p>
                          </div>
                        </div>
                      </div>
                      <div className="table-wrap mt-5">
                        <div className="invoice-table-wrap">
                          <table className="table table-bordered invoice-table">
                            <thead className="thead-primary">
                              <tr>
                                <th>Item</th>
                                <th>Quantity</th>
                                <th>Price</th>
                                <th>Discount</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="w-70">
                                  <h6>Redesiging of agencyclick.com</h6>
                                  <p className="mb-0 primarylight-color">
                                    This is my project description. if the line
                                    do not filt like the sentence is to big the
                                    area will start getting bigger
                                  </p>
                                </td>
                                <td className="text-end ">8</td>
                                <td className="text-end">60.00</td>
                                <td className="text-end">5%</td>
                                <td className="text-end">$420.5</td>
                              </tr>
                              <tr>
                                <td className="w-70">
                                  <h6>Re-branding</h6>
                                </td>
                                <td className="text-end ">1</td>
                                <td className="text-end">150.00</td>
                                <td className="text-end">0%</td>
                                <td className="text-end">$140.5</td>
                              </tr>
                              <tr>
                                <td className="w-70">
                                  <h6>Social media marketing</h6>
                                </td>
                                <td className="text-end ">20</td>
                                <td className="text-end">30.00</td>
                                <td className="text-end">5%</td>
                                <td className="text-end">$540.5</td>
                              </tr>
                              <tr>
                                <td
                                  colSpan="2"
                                  rowSpan="4"
                                  className="border-0"
                                ></td>
                                <td colSpan="2" className="primarylight-color">
                                  Subtotal
                                </td>
                                <td className="text-end text-dark">$1101.0</td>
                              </tr>
                              <tr>
                                <td colSpan="2" className="primarylight-color">
                                  Item Discount
                                </td>
                                <td className="text-end">$10.0</td>
                              </tr>
                              <tr>
                                <td colSpan="2" className="primarylight-color">
                                  Extra Discount
                                </td>
                                <td className="text-end">$0</td>
                              </tr>
                              <tr className="border-0">
                                <td colSpan="2" className="text-dark border">
                                  Total
                                </td>
                                <td className="text-end text-dark border">
                                  $1101.0
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row mt-5">
                        <div className="col-lg-5">
                          <h6>Note to client</h6>
                          <p className="primarylight-color">
                            thank you for choosing Hencework for design
                            services. If you need more assistance in future here
                            is your discount coupon for future jobs. Just call
                            us and mention the coupon code:
                            &quot;10-springhnc&quot;
                          </p>
                        </div>
                        <div className="col-lg-7 text-lg-end mt-3">
                          <h5 className="mt-lg-7">Katherine Zeta Jones</h5>
                          <p>Co-founder, Hencework</p>
                        </div>
                      </div>
                      <div className="separator separator-light"></div>
                      <div className="row term-text">
                        <div className="col-md-12">
                          <h6>Terms &amp; Conditions</h6>
                          <ul className="ps-3">
                            <li className="primarylight-color">
                              {" "}
                              Please pay within 15 days from the date of
                              invoice, overdue interest @ 14% will be charged on
                              delayed payments.
                            </li>
                            <li className="primarylight-color">
                              {" "}
                              Please quote invoice number when remitting funds.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>

      {/* Add deal popup */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="deal-popup"
      >
        <Box sx={style}>
          <div className="modal-head">
            <h5>Billed To</h5>
            <Link onClick={handleClose}>
              <RxCross2 />
            </Link>
          </div>
          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">Company Name</label>
                    <input
                      className="form-control task-name"
                      type="text"
                      placeholder="Supernova Consultants"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">Email ID</label>
                    <input
                      className="form-control task-name"
                      type="text"
                      placeholder="thompson_peter@super.co"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">Address Line One</label>
                    <input
                      className="form-control task-name"
                      type="text"
                      placeholder="Sycamore Street"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">Address Line Two</label>
                    <input
                      className="form-control task-name"
                      type="text"
                      placeholder="San Antonio Valley, CA 34668"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <Button
              type="submit"
              className="secondary-btn me-2"
              onClick={handleClose}
            >
              Discard
            </Button>
            <Button type="submit" className="primary-btn">
              Add
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default InvoicePreview;
