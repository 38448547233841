import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import QRCode from "react-qr-code";
import { Link, useNavigate } from "react-router-dom";
import "./index.scss";
import { useSelector } from "react-redux";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import ProductCard from "./Card";
import NotFound from "../../../assets/images/Image_not_available.jpg";
import { Config } from "../../../Utils/Config";
import DeletePopup from "../../Components/Reusable/DeletePopup";
import {
  Backdrop,
  Button,
  CircularProgress,
  // IconButton,
  Tooltip,
} from "@mui/material";
import { VscAdd, VscEdit } from "react-icons/vsc";
import ImgPopup from "../../Components/ImgPopUp";
// import { LoginUser } from "../../../reducers/login";
import { toast } from "react-toastify";
import NewLIstTable from "../../Components/Reusable/NewLIstTable";
import Axios from "../../../Utils/AxiosConfig";
// import AOS from "aos";
// import "aos/dist/aos.css";
import whitelogo from "../../../assets/images/fig_events.png";
import SyncList from "./OneWorldSyncTable/SyncList";
import { IoMdRefreshCircle } from "react-icons/io";
import speckerProfileBanner from "../../../assets/images/speckerProfileBanner.png";
import { MdVerified } from "react-icons/md";
import TableHeader from "../../../TableHeader.json";
import Select from "react-select";
import { customFilter } from "../../Components/CustomFilterForReactSelect";

function ExhibitorProfile() {
  // const { id } = useParams();
  const [exDeatils, setExDetails] = useState([]);
  const [venderList, setVenderList] = useState([]);
  const [dataOfTable1, setDataOfTable1] = useState([]);
  // const [usDetails, setUsDetails] = useState();
  // const [derror, setDerror] = useState();
  const [openCircle, setOpenCircle] = useState(false);
  const [qrSave, setQRSave] = useState(false);
  const [companyId, setCompanyId] = useState();

  const qrRef = useRef();
  let deleteInfo = useSelector((state) => state.Common?.DeleteData);
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  let nav = useNavigate();

  const exportAsImage = async (el, imageFileName) => {
    const canvas = await html2canvas(qrRef.current);
    const image = canvas.toDataURL("image/png", 1.0);
    downloadQRCode(image, imageFileName);
  };
  const downloadQRCode = async (blob, fileName) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
    setQRSave(false);
    setOpenCircle(false);
  };
  useEffect(() => {
    document.title = "FIG Events | Company Store";
    GetCompanyRegistrationList();

    // GeVendorList();

    // eslint-disable-next-line
  }, [deleteInfo]);

  // const GeVendorList = async () => {
  //   let seData = {
  //     url: `/api/ExhibitorVendor/GetAllExhibitorVendorList?userId=${LogDetail?.userId}`,
  //     headers: headers,
  //   };
  //   let res = await GetCallApi(seData);
  //   if (res?.status === 200 && res.data.success) {
  //     let sv = res.data.payload?.map((x, i) => {
  //       return {
  //         id: i + 1,
  //         ...x,
  //         ...x?.ev,
  //         image: x?.ev?.image ? (
  //           <ImgPopup
  //             img={`${Config.API_HOST_URL_live}${x?.ev.image}`}
  //             addClass={"h_fix"}
  //             hide_icon={true}
  //           />
  //         ) : (
  //           ""
  //         ),
  //         Action: (
  //           <div className="d-flex align-items-center justify-content-center">
  //             <Link
  //               to={`/vendor-edit/${x?.ev?.salesRepresentativesId}`}
  //               className="btn_edit me-2 d-flex align-items-center"
  //             >
  //               <VscEdit />
  //             </Link>
  //             <DeletePopup
  //               title={"Delete Team Member"}
  //               text={x?.ev?.firstName + " " + x?.ev?.lastName}
  //               url={`/api/ExhibitorVendor/DeleteExhibitorVendor?exhibitorVendorId=${x?.ev?.salesRepresentativesId}`}
  //             />
  //           </div>
  //         ),
  //       };
  //     });
  //     setVenderList(sv);
  //   }
  // };
  // const GetUsDetails = async (id) => {
  //   let seData = {
  //     url: `/api/UserMst/GetExhibitorsAllInformation?userId=${id}&loginUserId=${LogDetail?.userId}`,
  //     headers: headers,
  //   };
  //   setOpenCircle(true);
  //   let res = await GetCallApi(seData);
  //   setOpenCircle(false);
  //   if (res?.status === 200) {
  //     if (res.data.success) {
  //       setUsDetails(res.data.payload);
  //       let sv = res.data.payload?.productList?.map((x) => {
  //         return {
  //           ...x,
  //           cardImg: x.productImage
  //             ? x?.productImage
  //             : x?.multipleImages?.[0]?.imagePath
  //             ? x?.multipleImages?.[0]?.imagePath
  //             : NotFound,
  //           cardTitle: x.productName,
  //           des: x?.shortDescription,
  //           ed: (
  //             <>
  //               {LogDetail?.userId ? (
  //                 <div className="d-flex align-items-center justify-content-center">
  //                   <Link
  //                     to={`/product-edit/${x?.exhibitorProductId}`}
  //                     className="btn_edit me-2 d-flex align-items-center"
  //                   >
  //                     <img src={"/assets/icons/edit_img.svg"} alt="" />
  //                   </Link>
  //                   {res.data.payload?.length === 1 ? (
  //                     ""
  //                   ) : (
  //                     <DeletePopup
  //                       title={"Delete Product"}
  //                       text={x?.productName}
  //                       url={`/api/ExhibitorProduct/DeleteExhibitorProduct?exhibitorProductId=${x?.exhibitorProductId}`}
  //                     />
  //                   )}
  //                 </div>
  //               ) : (
  //                 ""
  //               )}
  //             </>
  //           ),
  //         };
  //       });
  //       setExDetails(sv);
  //     }
  //   }
  // };

  // let dispatch = useDispatch();
  // const UpadteStatusComplete = async () => {
  //   let is_success = true;
  //   setDerror({ err: "" });

  //   if (exDeatils?.length < 1) {
  //     setDerror({
  //       err: "For completing profile you have to add minimum one product.",
  //     });
  //     is_success = false;
  //   }
  //   if (is_success) {
  //     let seData = {
  //       url: `/api/UserMst/UserProfileCompleteStatus?userId=${LogDetail?.userId}&status=true`,
  //       body: {},
  //       headers: headers,
  //     };
  //     let res = await PostCallApi(seData);
  //     if (res?.status === 200) {
  //       if (res.data.success) {
  //         toast.success("Your Profile Completed Successfully", {
  //           position: "top-right",
  //           autoClose: 1500,
  //           hideProgressBar: true,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "dark",
  //         });
  //         dispatch(
  //           LoginUser({
  //             ...LogDetail,
  //             isCompleteProfile: res.data.payload.isCompleteProfile,
  //           })
  //         );

  //         // setUsDetails(res.data.payload);
  //       }
  //     }
  //   }
  // };

  const getcompanyDetail = async (id) => {
    setExDetails([]);
    setCompanyId();
    setVenderList([]);
    setOpenCircle(true);
    let res = await GetCallApi({
      url: `/api/CompanyRegistration/GetCompanyDetailsWithoutToken?companyId=${id}&userId=${LogDetail?.userId}`,
    });
    setOpenCircle(false);
    if (res?.status === 200 && res?.data.success) {
      let com = res?.data?.payload;
      setCompanyId({ ...com, value: id, label: com?.companyName });
      let sv = res.data.payload?.productList?.map((x) => {
        return {
          ...x,
          cardImg: x.productImage
            ? x?.productImage
            : x?.multipleImages?.[0]?.imagePath
            ? x?.multipleImages?.[0]?.imagePath
            : NotFound,
          cardTitle: x.productName,
          des: x?.shortDescription,
          ed: (
            <>
              {LogDetail?.userId ? (
                <div className="d-flex align-items-center justify-content-center">
                  <Link
                    to={`/product-edit/${x?.exhibitorProductId}`}
                    className="btn_edit me-2 d-flex align-items-center"
                  >
                    <img src={"/assets/icons/edit_img.svg"} alt="" />
                  </Link>
                  {res.data.payload?.length === 1 ? (
                    ""
                  ) : (
                    <DeletePopup
                      title={"Delete Product"}
                      text={x?.productName}
                      url={`/api/ExhibitorProduct/DeleteExhibitorProduct?exhibitorProductId=${x?.exhibitorProductId}`}
                    />
                  )}
                </div>
              ) : (
                ""
              )}
            </>
          ),
        };
      });
      setExDetails(sv);
      let vn = res.data.payload?.vendorList?.map((x, i) => {
        return {
          id: i + 1,
          ...x,
          companyName: com?.companyName,
          image: x?.image ? (
            <ImgPopup
              img={`${Config.API_HOST_URL_live}${x.image}`}
              addClass={"h_fix"}
              hide_icon={true}
            />
          ) : (
            ""
          ),
          Action: (
            <div className="d-flex align-items-center justify-content-center">
              <Link
                to={`/vendor-edit/${x?.exhibitorVendorId}`}
                className="btn_edit me-2 d-flex align-items-center"
              >
                <VscEdit />
              </Link>
              <DeletePopup
                title={"Delete Team Member"}
                text={x?.name}
                url={`/api/ExhibitorVendor/DeleteExhibitorVendor?exhibitorVendorId=${x?.exhibitorVendorId}`}
              />
            </div>
          ),
        };
      });
      setVenderList(vn);
    }
  };
  const [prdSearchField, setPrdSearchField] = useState("");
  const formattedSearchResults = exDeatils?.filter((user) => {
    if (prdSearchField) {
      return user.cardTitle
        ?.toLowerCase()
        .includes(prdSearchField?.toLowerCase());
    } else return user;
  });

  if (LogDetail?.roleName === "User") {
    nav("/attendee-dashboard", { replace: true });
  }
  if (LogDetail?.roleName === "Event Organizer") {
    nav("/home", { replace: true });
  }

  const GetCompanyRegistrationList = async () => {
    let seData = {
      url:
        LogDetail?.roleId === 1
          ? `/api/CompanyRegistration/GetAllCompanyRegistration?userId=${LogDetail?.userId}`
          : `/api/CompanyRegistration/GetCompanyUserAllocationListByUserId?userId=${LogDetail?.userId}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        let comList = res?.data?.payload?.map((c, i) => {
          return {
            id: i + 1,
            ...c,
            value: c?.companyId,
            label: c?.companyName,
            color:
              c.isAppoved === "Approved"
                ? "approv"
                : c.isAppoved === "Rejected"
                ? "reject"
                : "created",
            Action: (
              <>
                <Link
                  className="btn_edit me-3 d-flex align-items-center"
                  to={`/company/edit/${c?.companyId}`}
                >
                  <VscEdit />
                </Link>
                {LogDetail?.roleId === 1 ? (
                  <>
                    {c?.isAppoved === "Approved" ||
                    c.isAppoved === "Rejected" ? (
                      ""
                    ) : (
                      <>
                        <Link
                          className="btn_edit me-3 d-flex align-items-center"
                          onClick={() => reqApprove(c?.companyId, "Approved")}
                        >
                          <img src={"/assets/icons/check.svg"} alt="" />
                        </Link>
                        <Link
                          className="btn_dlt d-flex align-items-center me-3"
                          onClick={() => reqApprove(c?.companyId, "Rejected")}
                        >
                          <img src={"/assets/icons/close.svg"} alt="" />
                        </Link>
                      </>
                    )}
                  </>
                ) : (
                  ""
                )}
                <DeletePopup
                  title={"Delete Company"}
                  text={c?.companyName}
                  url={`/api/CompanyRegistration/DeleteCompanyRegistration?companyId=${c?.companyId}`}
                />
              </>
            ),
          };
        });
        setDataOfTable1(comList);
        getcompanyDetail(comList[0]?.value);
      } else {
        setDataOfTable1([]);
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };
  const reqApprove = (comId, ac) => {
    let comD = {
      companyId: comId,
      isApproved: ac,
      createdBy: LogDetail?.userId,
    };
    Axios.post("/api/CompanyRegistration/SendCompanyApproval", comD, {
      headers,
    })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            let msg = res.data.message;
            GetCompanyRegistrationList();
            toast.success(msg, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }
        }
      })
      .catch((e) => console.log(e));
  };
  useEffect(() => {
    // AOS.init({
    //   duration: 700,
    //   easing: "ease-out-cubic",
    //   once: true,
    // });
    // window.addEventListener("load", function () {
    //   AOS.init();
    //   // Remove AOS attributes after animations are done
    //   setTimeout(function () {
    //     document.querySelectorAll("[data-aos]").forEach(function (el) {
    //       el.removeAttribute("data-aos");
    //     });
    //   }, 1000); // Adjust the timeout according to your animation duration
    // });
  }, []);

  const userInLike = async (p) => {
    let seData = {
      url: "/api/ExhibitorProduct/CreateProductUserInteraction",
      body: {
        ExhibitorProductId: p,
        userId: LogDetail?.userId,
        status: "Like",
      },
    };
    let res = await PostCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        getcompanyDetail(companyId?.value);
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  };
  useEffect(() => {
    if (qrSave) {
      exportAsImage(1, LogDetail?.userId);
    }
    // eslint-disable-next-line
  }, [qrSave]);
  const saveQR = () => {
    setQRSave(true);
    setOpenCircle(true);
  };

  const handleRefresh = () => {
    getcompanyDetail(companyId?.value);
  };

  const handleChangeCompany = (val) => {
    getcompanyDetail(val);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="exhibitors_pro">
        <img
          src={
            companyId?.coverImage
              ? Config.API_HOST_URL_live + companyId?.coverImage
              : speckerProfileBanner
          }
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = speckerProfileBanner;
          }}
          className="profile_images_pic img-fluid w-100"
          alt="Speaker Profile"
        />

        <div className="container-fluid">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 col-12 index-9 aos-init aos-animate">
                <div className="profile_images">
                  <img
                    src={
                      companyId?.profilePhoto
                        ? Config.API_HOST_URL_live + companyId?.profilePhoto
                        : NotFound
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = NotFound;
                    }}
                  />
                </div>
              </div>
              <div className="col-xl-10 col-lg-9 col-md-8 col-sm-12 col-12 aos-init aos-animate">
                <div className="right-image">
                  <div className="content">
                    <h1>
                      {companyId?.companyName}
                      {companyId?.isExhibitorSubscriptionExists ? (
                        <>
                          (Pro)
                          <Tooltip
                            title={"Subcription Purchased"}
                            placement="bottom"
                            arrow
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  backgroundColor: "#80c242",
                                  "& .MuiTooltip-arrow": {
                                    color: "#80c242",
                                  },
                                },
                              },
                            }}
                          >
                            <span>
                              <MdVerified color="#80c242" fontSize={30} />
                            </span>
                          </Tooltip>
                        </>
                      ) : (
                        ""
                      )}
                    </h1>{" "}
                    <h2>
                      {companyId?.contactName ? companyId?.contactName : ""}{" "}
                    </h2>
                    <p>
                      {companyId?.address1 ? "Address: " : ""}
                      {companyId?.address1} {companyId?.address1 ? ", " : ""}
                      {companyId?.address2 ? companyId?.address2 : ""}
                      {companyId?.address12 ? ", " : ""}
                      {companyId?.city}
                      {companyId?.city ? ", " : ""}
                      {companyId?.state}
                      {companyId?.zip ? " - " : ""}
                      {companyId?.zip ? companyId?.zip : ""}
                    </p>
                    {/* <h2>
                      {usDetails?.firstName ? usDetails?.firstName : ""}{" "}
                      {usDetails?.lastName ? usDetails?.lastName : ""}
                    </h2>{" "}
                    <h3>{usDetails?.userTitle}</h3>
                    <p className="mb-3" style={{ whiteSpace: "pre-line" }}>
                      {usDetails?.userDescription
                        ? usDetails?.userDescription?.slice(0, 500)
                        : ""}
                      {usDetails?.userDescription
                        ? usDetails?.userDescription?.length > 500 && (
                            <span
                              className="description cr"
                              style={{ cursor: "pointer" }}
                            >
                              ...
                            </span>
                          )
                        : ""}
                    </p> */}
                    {/* {LogDetail?.userId && !LogDetail?.isCompleteProfile ? (
                      <>
                        <IconButton
                          className="dashboard_btn background me-3"
                          onClick={UpadteStatusComplete}
                        >
                          Complete Profile
                        </IconButton>
                        <Link
                          className="dashboard_btn background me-3 mb-3"
                          to={"/vendor-create"}
                          style={{ textDecoration: "none" }}
                        >
                          Enter Team Member Info
                        </Link>
                      </>
                    ) : ( */}
                    {companyId ? (
                      <>
                        <Link
                          className="dashboard_btn background me-3 mb-3"
                          // className="dashboard_btn me-3 mb-3"
                          target="_blank"
                          to={
                            Config.API_HOST_URL +
                            "/exhibitor-profile/" +
                            companyId?.value
                          }
                          style={{ textDecoration: "none" }}
                        >
                          Public View
                        </Link>
                        <Link
                          className="dashboard_btn background me-3 mb-3"
                          to={`/company/edit/${companyId?.value}`}
                          // onClick={handleEdit}
                          style={{ textDecoration: "none" }}
                        >
                          Profile Edit
                        </Link>
                      </>
                    ) : (
                      ""
                    )}
                    {/* )} */}
                    {/* {derror?.err && (
                      <span
                        role="alert"
                        className="d-block mb-3 error_text"
                        style={{ fontSize: "20px" }}
                      >
                        {derror.err}
                      </span>
                    )} */}
                  </div>
                  <div className="rightInfo d-flex gap-2">
                    <div className="">
                      <Select
                        isMulti={false}
                        name="colors"
                        options={dataOfTable1}
                        isClearable={true}
                        className="dropdown-single-select"
                        classNamePrefix="select"
                        placeholder="Select Company"
                        filterOption={customFilter}
                        value={dataOfTable1?.filter(
                          (obj) => obj.value === companyId?.value
                        )}
                        onChange={(e) => handleChangeCompany(e?.value)}
                      />
                    </div>
                    {companyId ? (
                      <div
                        className="user_detail_QR"
                        style={{ width: qrSave ? "100%" : "" }}
                      >
                        {qrSave ? (
                          <>
                            <div
                              className="qr-card"
                              style={{
                                background:
                                  "linear-gradient(90.24deg, #18BAD4 2.7%, #31B680 54.38%, #80C242 98.4%)",
                                // borderRadius: "20px",
                                width: "65%",
                                margin: "auto",
                              }}
                              ref={qrRef}
                            >
                              <div
                                className="logo"
                                style={{
                                  textAlign: "center",
                                  padding: "1rem 0",
                                }}
                              >
                                <img
                                  src={whitelogo}
                                  alt=""
                                  style={{
                                    width: "30%",
                                    background: "white",
                                    padding: "1rem",
                                    borderRadius: "16px",
                                  }}
                                />
                              </div>
                              <div
                                className="qr_box"
                                style={{
                                  textAlign: "center",
                                  backgroundColor: "#fff",
                                  padding: "2rem 0",
                                }}
                              >
                                <QRCode
                                  value={
                                    Config.API_HOST_URL +
                                    "/exhibitor-profile/" +
                                    companyId?.value
                                  }
                                />
                              </div>
                              <div className="info" style={{ padding: "1rem" }}>
                                <h1
                                  style={{
                                    color: "#fff",
                                    fontSize: "26px",
                                    fontWeight: "600",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  {companyId?.companyName
                                    ? companyId?.companyName
                                    : ""}

                                  {/* <span
                                  style={{
                                    color: "#fff",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    marginLeft: "5px",
                                  }}
                                >
                                  ({usDetails?.userType})
                                </span> */}
                                </h1>
                                <h1
                                  style={{
                                    color: "#fff",
                                    fontSize: "26px",
                                    fontWeight: "600",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  {companyId?.contactName}
                                </h1>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="qr_box">
                            <QRCode
                              size={100}
                              value={
                                Config.API_HOST_URL +
                                "/exhibitor-profile/" +
                                companyId?.value
                              }
                            />
                          </div>
                        )}

                        <button
                          type="button"
                          className="dashboard_btn background"
                          onClick={(e) => saveQR(e, LogDetail?.userId)}
                        >
                          Download QR Code
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="exhibitor_profile">
        <div className="main_wrapper">
          <div className="exhibitor_main_box mb-4">
            {/* <div className="exhibitor_main_box mb-4" data-aos="zoom-in"> */}
            <div className="d-flex justify-content-between align-items-center mb-4 flex-column flex-md-row">
              <h4 className="title m-0">Product List</h4>
              <div className="d-flex align-items-center">
                <div className=" search_sec ms-auto">
                  <div className="search_input w-100">
                    <img src={"/assets/icons/search_icon.svg"} alt="" />
                    <input
                      onChange={(e) => {
                        setPrdSearchField(e.target.value);
                      }}
                      type="text"
                      className="form-control"
                      placeholder="Type your search here"
                    />
                  </div>
                </div>
                <Link
                  className="dashboard_btn background ms-3"
                  to={`/product-create`}
                  style={{ textDecoration: "none" }}
                >
                  <VscAdd className="me-2" />
                  Add
                </Link>
                {openCircle ? (
                  <Button
                    className="dashboard_btn background ms-3"
                    style={{ textDecoration: "none", height: "45px" }}
                  >
                    <CircularProgress
                      sx={{
                        color: "white",
                        height: "23px !important",
                        width: "23px !important",
                      }}
                    />
                  </Button>
                ) : (
                  <Button
                    className="dashboard_btn background ms-3"
                    type="submit"
                    style={{ textDecoration: "none", height: "45px" }}
                    onClick={handleRefresh}
                  >
                    <IoMdRefreshCircle fontSize={30} />
                  </Button>
                )}
              </div>
            </div>
            <div className="row">
              <ProductCard
                data={formattedSearchResults}
                userInLike={userInLike}
              />
            </div>
          </div>
          <div className="new_card p-0 mb-4">
            <SyncList
              companyList={dataOfTable1}
              apiCall={true}
              ApiCallFuntion={handleRefresh}
            />
          </div>
          <div className="new_card p-0 mb-4">
            <NewLIstTable
              addressLink={`/vendor-create`}
              exportBtn={false}
              title="Team Members List"
              headCells={TableHeader?.TeamMemberListHeader}
              action={true}
              ListData={venderList}
              addBtn={true}
            />
          </div>
          <div className="new_card p-0">
            <NewLIstTable
              addressLink={`/company/create`}
              exportBtn={false}
              title="Company List"
              headCells={TableHeader?.CompanyListHeader}
              action={true}
              ListData={dataOfTable1}
              addBtn={true}
            />
          </div>
        </div>
      </section>
      {/* <Company /> */}
    </>
  );
}

export default ExhibitorProfile;
