import React, { useState } from "react";
import { useParams } from "react-router-dom";
import WebMenu from "../../../Shared/WebMenu";
import "./index.scss";
import CreatingNutritiousAndFunSchoolLunches from "./Component/Creating-Nutritious-And-Fun-School-Lunches";

function Viewblogs() {
  let { id } = useParams();
  const [activeBlog] = useState(id);

  return (
    <>
      <WebMenu />
      {activeBlog === "creating-nutritious-and-fun-school-lunches" ? (
        <CreatingNutritiousAndFunSchoolLunches />
      ) : (
        ""
      )}
    </>
  );
}
export default React.memo(Viewblogs);
