import React from "react";
import "./index.scss";
import logo from "../../../../assets/images/fig_events.png";
import { Link } from "react-router-dom";
import { LuInbox } from "react-icons/lu";
import { MdNotificationAdd } from "react-icons/md";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { MdDashboard } from "react-icons/md";
import Badge from "@mui/material/Badge";
import { MdLeaderboard } from "react-icons/md";
import { RiContactsBookLine } from "react-icons/ri";
import { LuListTodo } from "react-icons/lu";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { RiArrowDropDownLine } from "react-icons/ri";
import { LuCornerDownRight } from "react-icons/lu";
import { CiSettings } from "react-icons/ci";

const avatar_img = "/assets/images/avatar.jpg";

function CRMHeader() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const [anchorEl5, setAnchorEl5] = React.useState(null);
  const [anchorEl6, setAnchorEl6] = React.useState(null);
  const open = Boolean(anchorEl);
  const openContact = Boolean(anchorEl1);
  const openScrumboard = Boolean(anchorEl2);
  const openInvoice = Boolean(anchorEl3);
  const openTodo = Boolean(anchorEl4);
  const openSerach = Boolean(anchorEl5);
  const openNotification = Boolean(anchorEl6);

  const handleClickContact = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClickScrumboard = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClickInvoice = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClickTodo = (event) => {
    setAnchorEl4(event.currentTarget);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickSerach = (event) => {
    setAnchorEl5(event.currentTarget);
  };
  const handleClickNotification = (event) => {
    setAnchorEl6(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseContact = () => {
    setAnchorEl1(null);
  };
  const handleCloseScrumboard = () => {
    setAnchorEl2(null);
  };
  const handleCloseInvoice = () => {
    setAnchorEl3(null);
  };
  const handleCloseTodo = () => {
    setAnchorEl4(null);
  };
  const handleCloseSerach = () => {
    setAnchorEl5(null);
  };
  const handleCloseNotification = () => {
    setAnchorEl6(null);
  };

  return (
    <div className="crmmenu">
      <div className="container-fluid">
        <div className="top-header">
          <div className="left-header">
            <div className="logo">
              <img
                src={logo}
                alt="logo"
                style={{
                  height: "70px",
                }}
              />
            </div>
            <Link
              className="menu-link"
              id="serach-menu"
              aria-controls={open ? "serach-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClickSerach}
            >
              <input
                type="search"
                placeholder="Search..."
                className="form-control w-auto"
              />
            </Link>
          </div>
          <div className="right-header">
            <Link className="inbox-icon">
              <Badge badgeContent={4} color="secondary">
                <LuInbox color="action" />
              </Badge>
            </Link>
            <Link
              className="inbox-icon"
              id="notification-menu"
              aria-controls={open ? "notification-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClickNotification}
            >
              <Badge color="primary" variant="dot">
                <MdNotificationAdd />
              </Badge>
            </Link>
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                //sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar sx={{ width: 34, height: 34 }}></Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={handleClose}>
                <Avatar /> Profile
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Avatar /> My account
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <PersonAdd fontSize="small" />
                </ListItemIcon>
                Add another account
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </div>
        </div>
        <div className="bottom-header">
          <nav className="menu">
            <ul>
              <li>
                <Link to={"/dashboard"} className="menu-link">
                  {" "}
                  <MdDashboard /> Dashboard
                </Link>
              </li>
              <li>
                <Link
                  className="menu-link"
                  id="scrumboard-menu"
                  aria-controls={open ? "scrumboard-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClickScrumboard}
                >
                  <MdLeaderboard /> Scrumboard <RiArrowDropDownLine />
                </Link>
              </li>
              <li>
                <Link
                  className="menu-link"
                  id="contact-menu"
                  aria-controls={open ? "contact-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClickContact}
                >
                  <RiContactsBookLine /> Contact <RiArrowDropDownLine />
                </Link>
              </li>
              <li>
                <Link
                  className="menu-link"
                  id="todo-menu"
                  aria-controls={open ? "todo-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClickTodo}
                >
                  <LuListTodo /> Todo <RiArrowDropDownLine />
                </Link>
              </li>
              <li>
                <Link
                  className="menu-link"
                  id="invoice-menu"
                  aria-controls={open ? "invoice-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClickInvoice}
                >
                  <LiaFileInvoiceSolid /> Invoice <RiArrowDropDownLine />
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <Menu
        id="scrumboard-menu"
        anchorEl={anchorEl2}
        open={openScrumboard}
        onClose={handleCloseScrumboard}
        MenuListProps={{
          "aria-labelledby": "scrumboard-menu",
        }}
      >
        <MenuItem>
          <Link to={`/projects-board`} className="dropdown-link">
            {" "}
            All Boards
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to={`/pipeline`} className="dropdown-link">
            {" "}
            Pipeline Kanban
          </Link>
        </MenuItem>
      </Menu>
      <Menu
        id="contact-menu"
        anchorEl={anchorEl1}
        open={openContact}
        onClose={handleCloseContact}
        MenuListProps={{
          "aria-labelledby": "contact-menu",
        }}
      >
        <MenuItem>
          <Link to={`/contact-list`} className="dropdown-link">
            {" "}
            Contact List
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to={`/contact-card`} className="dropdown-link">
            {" "}
            Contact Card
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to={`/contact-edit`} className="dropdown-link">
            Edit Contact
          </Link>
        </MenuItem>
      </Menu>
      <Menu
        id="invoice-menu"
        anchorEl={anchorEl3}
        open={openInvoice}
        onClose={handleCloseInvoice}
        MenuListProps={{
          "aria-labelledby": "invoice-menu",
        }}
      >
        <MenuItem>
          <Link to={`/invoice-list`} className="dropdown-link">
            {" "}
            Invoice List
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to={`/create-invoice`} className="dropdown-link">
            Create Invoice
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to={`/invoice-preview`} className="dropdown-link">
            Invoice Preview
          </Link>
        </MenuItem>
      </Menu>
      <Menu
        id="todo-menu"
        anchorEl={anchorEl4}
        open={openTodo}
        onClose={handleCloseTodo}
        MenuListProps={{
          "aria-labelledby": "todo-menu",
        }}
      >
        <MenuItem>
          <Link to={`/task-list`} className="dropdown-link">
            {" "}
            TaskList
          </Link>
        </MenuItem>
      </Menu>

      {/* Search bar Menu */}
      <Menu
        id="serach-menu"
        anchorEl={anchorEl5}
        open={openSerach}
        onClose={handleCloseSerach}
        MenuListProps={{
          "aria-labelledby": "serach-menu",
        }}
        className="search-bar"
      >
        <li className="search-header">
          <h6>Recent Search</h6>
          <div className="dropdown-item bg-transparent">
            <Link className="badge rounded-pill badge-soft-secondary">
              Grunt
            </Link>
            <Link className="badge rounded-pill badge-soft-secondary">
              Node JS
            </Link>
            <Link className="badge rounded-pill badge-soft-secondary">
              SCSS
            </Link>
          </div>
        </li>
        <li className="dropdown-divider"></li>
        <li className="help-block">
          <h6>Help</h6>
          <Link className="help-text">
            <div className="bg-round">
              <LuCornerDownRight />
            </div>
            <p>How to setup theme?</p>
          </Link>
          <Link className="help-text">
            <div className="bg-round">
              <LuCornerDownRight />
            </div>
            <p>View detail documentation</p>
          </Link>
        </li>
        <li className="dropdown-divider"></li>
        <li className="help-block">
          <h6>Users</h6>
          <Link className="help-text">
            <div className="bg-image">
              <img src={avatar_img} alt="profil" />
            </div>
            <p className="ml-2">Sarah Jone</p>
          </Link>
          <Link className="help-text">
            <div className="bg-image">
              <img src={avatar_img} alt="profil" />
            </div>
            <p className="ml-2">Joe Jackson</p>
          </Link>
          <Link className="help-text">
            <div className="bg-image">
              <img src={avatar_img} alt="profil" />
            </div>
            <p className="ml-2">Maria Richard</p>
          </Link>
        </li>
        <li className="search-footer">
          <Link>Search all</Link>
        </li>
      </Menu>

      {/* Notification Menu */}
      <Menu
        id="notification-menu"
        anchorEl={anchorEl6}
        open={openNotification}
        onClose={handleCloseNotification}
        MenuListProps={{
          "aria-labelledby": "notification-menu",
        }}
        className="notification-box"
      >
        <li className="notification-header">
          <h6>Notification</h6>
          <Link>
            <CiSettings />
          </Link>
        </li>
        <li className="dropdown-divider"></li>
        <li className="notification-block">
          <div className="image">
            <img src={avatar_img} alt="profile" />
          </div>
          <div className="notification-contact">
            <h6>Morgan Freeman accepted your invitation to join the team</h6>
            <div className="notifications-info">
              <span className="badge badge-soft-success">Collaboration</span>
              <div className="notifications-time">Today, 10:14 PM</div>
            </div>
          </div>
        </li>
        <li className="notification-block">
          <div className="image green-wrap">
            <LuInbox />
          </div>
          <div className="notification-contact">
            <h6>New message received from Alan Rickman</h6>
            <div className="notifications-info">
              <div className="notifications-time">Today, 7:51 AM</div>
            </div>
          </div>
        </li>
        <li className="notification-block">
          <div className="image">
            <img src={avatar_img} alt="profile" />
          </div>
          <div className="notification-contact">
            <h6>Morgan Freeman accepted your invitation to join the team</h6>
            <div className="notifications-info">
              <div className="notifications-time">Today, 10:14 PM</div>
            </div>
          </div>
        </li>
        <li className="notification-block">
          <div className="image">
            <img src={avatar_img} alt="profile" />
          </div>
          <div className="notification-contact">
            <h6>Morgan Freeman accepted your invitation to join the team</h6>
            <div className="notifications-info">
              <div className="notifications-time">Today, 10:14 PM</div>
            </div>
          </div>
        </li>
        <li className="notification-block">
          <div className="image">
            <img src={avatar_img} alt="profile" />
          </div>
          <div className="notification-contact">
            <h6>Morgan Freeman accepted your invitation to join the team</h6>
            <div className="notifications-info">
              <span className="badge badge-soft-success">Collaboration</span>
              <div className="notifications-time">Today, 10:14 PM</div>
            </div>
          </div>
        </li>
        <li className="notification-footer">
          <Link>View all notification</Link>
        </li>
      </Menu>
    </div>
  );
}

export default React.memo(CRMHeader);
