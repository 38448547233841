import React, { useEffect, useState } from "react";
import { Backdrop, Box, Button, CircularProgress, Modal } from "@mui/material";
import "./index.scss";
import Select from "react-select";
import { BsPlusSquareFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
import { toast } from "react-toastify";
import { customFilter } from "../../../Components/CustomFilterForReactSelect";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};
function AddEO(props) {
  const { conId } = props;
  const [distList, setDistList] = useState([]);
  const [selectDistList, setSelectDistList] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setDistList([]);
    setSelectDistList([]);
  };
  const logindetail = useSelector((state) => state.login.LoginDetails);

  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    if (open) {
      setDistList([]);
      setSelectDistList([]);
      getDistrictList();
    }
    // eslint-disable-next-line
  }, [open]);
  useEffect(() => {
    if (conId) {
      if (distList?.length > 0) {
        getAssignDistrictList();
      }
    }
    // eslint-disable-next-line
  }, [conId, distList]);
  const getDistrictList = async () => {
    let res = await GetCallApi({
      url: `/api/EventOrganizerAllocation/GetAllEventOrganizerList`,
      headers: headers,
    });

    if (res.status === 200) {
      if (res.data.success) {
        const dlist = res.data.payload?.map((sd) => {
          return {
            value: sd?.userId,
            label: sd?.firstName + " " + sd?.lastName,
          };
        });
        setDistList(dlist?.length > 0 ? dlist : []);
      }
    }
  };
  const getAssignDistrictList = async () => {
    setOpenCircle(true);
    let res = await GetCallApi({
      url: `/api/EventOrganizerAllocation/GetAllEventOrganizerListByConferenceId?conferenceId=${conId}`,
      headers: headers,
    });
    setOpenCircle(false);

    if (res.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        const dlist = res.data.payload?.map((sd) => sd?.eventOrganizerId);
        setSelectDistList(dlist?.length > 0 ? dlist : []);
      } else {
        setOpenCircle(false);
        setSelectDistList([]);
      }
    }
  };
  const onSubmit = async () => {
    let distData = {
      EventOrganizerId: selectDistList,
      ConferenceId: conId,
      CreatedBy: logindetail?.userId,
    };
    setOpenCircle(true);
    let url = `/api/EventOrganizerAllocation/EventOragnizerAllocationToConference`;
    let NewData = {
      url: url,
      body: distData,
      headers: headers,
    };
    let res = await PostCallApi(NewData);

    setOpenCircle(false);
    if (res?.status === 200) {
      if (res.data.success) {
        handleClose();
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast.error(res.data.message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } else {
      toast.error(res.data.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const handleDistrictMulti = (e) => {
    setSelectDistList(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  return (
    <>
      <Backdrop open={openCircle} sx={{ color: "#fff", zIndex: () => 9999 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Button className=" " onClick={handleOpen}> */}
      <BsPlusSquareFill
        fontSize={24}
        color="#73c04d"
        className="me-3"
        style={{ cursor: "pointer" }}
        onClick={handleOpen}
      />
      {/* </Button> */}
      {open && (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="district_toggle_img_Show"
          >
            <Box sx={style} className="delete_data_box">
              <form>
                <div className="modal-header">
                  <h4>Select Event Organizer</h4>
                </div>
                <div className="modal-body">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="form-label">
                        Event Organizer Name{" "}
                      </label>
                      <Select
                        isMulti
                        name="type"
                        options={distList}
                        // className="form-select"
                        className="course-caretory-multi-select f-14-400"
                        placeholder="Select Event Organizer"
                        classNamePrefix="select"
                        filterOption={customFilter}
                        value={distList?.filter((obj) =>
                          selectDistList?.includes(obj.value)
                        )}
                        onChange={handleDistrictMulti}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  {/* {selectDistList?.length > 0 ? ( */}
                  <Button className="detail_button" onClick={onSubmit}>
                    Save
                  </Button>
                  {/* ) : (
                    ""
                  )} */}
                  <Button className="ms-2 back_button" onClick={handleClose}>
                    Cancel
                  </Button>
                </div>
              </form>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}

export default React.memo(AddEO);
