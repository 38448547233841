import React from "react";
import { Button } from "@mui/material";
import { VscSignIn } from "react-icons/vsc";
import "./index.scss";
import { useNavigate } from "react-router-dom";

function LogMainPop(props) {
  let nav = useNavigate();
  return (
    <>
      <Button
        onClick={() => nav("/login", { replace: true })}
        className={`ms-auto log_txt`}
      >
        {props?.btnName === "Login/Register" ? (
          <VscSignIn className="login_icon" />
        ) : (
          ""
        )}
        {props?.btnName}
      </Button>
    </>
  );
}

export default React.memo(LogMainPop);
