import React, { Suspense, lazy, useEffect, useState } from "react";
import registerConference from "../../../assets/images/registerConference.png";
import { SlLocationPin } from "react-icons/sl";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { Button, CircularProgress } from "@mui/material";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import { Config } from "../../../Utils/Config";
import NotFound from "../../../assets/images/Image_not_available.jpg";
import { Link } from "react-router-dom";

const logo = "/assets/images/fig_events.png";

const WebMenu = lazy(() => import("../../../Shared/WebMenu"));
const WebFooter = lazy(() => import("../../../Shared/WebFooter"));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function HomeConferenceList(props) {
  const { NavHide } = props;
  const [apply, setApply] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [conferenceList, setConferenceList] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const NOW_IN_MS = new Date().getTime();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();
  const box = Array.from({ length: 1 }, (_, index) => (
    <div key={index} className="skeleton-box"></div>
  ));
  useEffect(() => {
    document.title = "FIG Events | Conference";

    setIsLoading(true);
    setTimeout(() => {
      getConList();
    }, 200);
    // eslint-disable-next-line
  }, []);

  function convert(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("/");
  }

  const getConList = async () => {
    setOpenCircle(!openCircle);
    let seData = {
      url: `/api/Conference/GetAllWithoutTokenConferenceList?isAlphanumericOrder=false`,
    };
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    setIsLoading(false);
    if (res.status === 200) {
      if (res.data.success) {
        let cl = res.data.payload?.filter((x) => x.status === "Approved");
        var offset = new Date().getTimezoneOffset();
        var date1 = new Date();
        date1.setMinutes(date1.getMinutes() + offset);
        var easternTimeOffset = -240;
        date1.setMinutes(date1.getMinutes() + easternTimeOffset);
        cl = cl.map((x) => {
          let date = convert(x?.conferenceStartdate?.split("T")[0]);
          let std = new Date(date);
          // std.setMinutes(std.getMinutes() + offset);
          std.setMinutes(std.getMinutes());
          // std.setMinutes(std.getMinutes() + easternTimeOffset);
          // const diffTime = Math.abs(std - date1);
          const diffTime = Math.abs(std - new Date());
          // const diffTime = Math.abs(new Date(date) - new Date());
          return {
            ...x,
            diffTime: new Date(date) > new Date() ? NOW_IN_MS + diffTime : 0,
          };
        });
        setConferenceList(cl);
      } else {
        setOpenCircle(false);
        setIsLoading(false);
      }
    }
  };

  const Apply = () => {
    setApply(true);
  };

  const Close = () => {
    setApply(false);
  };
  const onSubmit = async (data) => {
    let passData = {
      ...data,
    };

    let apiUrl = {
      url: `/api/Inquiry/CreateInquiry`,
      body: passData,
    };

    const resp = await PostCallApi(apiUrl);
    if (resp?.status === 200) {
      if (resp?.data?.success) {
        Close();
        reset();
        setTimeout(() => {
          toast.success(resp?.data.message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }, 500);
      } else {
        toast.error(resp?.data.message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } else {
      toast.error(resp?.data.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  return (
    <>
      <Suspense fallback={renderLoader()}>
        {!NavHide && <WebMenu />}
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <section className="conferences_sec" id="conference">
          <div className="container">
            <div className="conferences_title">
              <div className="d-flex justify-content-between align-items-center">
                <h2>Featured Conference</h2>
                {NavHide && (
                  <Link
                    to={"/conference"}
                    style={{ height: "fit-content" }}
                    className="detail_button"
                  >
                    View All Conference
                  </Link>
                )}
              </div>
              <p>Stay Informed and Save the Date!</p>
            </div>
            <div className="row conferences_card">
              {isLoading ? (
                <>
                  <div className="col-lg-3 col-md-4 mb-4">
                    <div className="skeleton-loader mb-3">{box}</div>
                    <div className="skeleton-loader">
                      <div className="skeleton-line skeleton-title"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 mb-4">
                    <div className="skeleton-loader mb-3">{box}</div>
                    <div className="skeleton-loader">
                      <div className="skeleton-line skeleton-title"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 mb-4">
                    <div className="skeleton-loader mb-3">{box}</div>
                    <div className="skeleton-loader">
                      <div className="skeleton-line skeleton-title"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 mb-4">
                    <div className="skeleton-loader mb-3">{box}</div>
                    <div className="skeleton-loader">
                      <div className="skeleton-line skeleton-title"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                    </div>
                  </div>
                </>
              ) : (
                conferenceList
                  ?.slice(0, NavHide ? 5 : conferenceList?.length)
                  ?.map((cd, i) => (
                    <div className="col-lg-3 col-md-4 mb-4" key={i}>
                      <div className="card p-0 overflow-hidden">
                        <div className="card-body">
                          <Link
                            to={`/conference-detail`}
                            state={{ conferenceId: cd?.conferenceId }}
                            className="text-decoration-none"
                          >
                            <div className="img-set fadeIn-bottom">
                              <img
                                src={
                                  cd?.displayImage
                                    ? `${Config.API_HOST_URL_live}${cd?.displayImage}`
                                    : NotFound
                                }
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = NotFound;
                                }}
                                className="img-fluid "
                                alt=""
                              />
                            </div>
                            <div className="infos">
                              <h5>{cd?.conferenceName}</h5>
                              <div className="d-flex align-items-center justify-content-between mb-2">
                                <div className="location-block">
                                  <SlLocationPin className="icon" />{" "}
                                  <p>{cd?.conferenceAddress}</p>
                                </div>
                                <div className="datetext">
                                  {new Date(
                                    cd?.conferenceStartdate
                                  ).toLocaleDateString("en-US", {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  })}
                                </div>
                              </div>
                              <p>
                                {cd?.description?.length > 150 ? (
                                  <>{cd?.description?.slice(0, 150)}...</>
                                ) : (
                                  cd?.description
                                )}{" "}
                              </p>
                              <Button type="text" className="content-title">
                                View More
                              </Button>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))
              )}
              <div className="col-lg-3 col-md-4 mb-4">
                <div className="card p-0 overflow-hidden">
                  <div className="card-body">
                    {/* <Link className="text-decoration-none">
                    </Link> */}
                    <div className="img-set fadeIn-bottom">
                      <img
                        src={registerConference}
                        className="img-fluid "
                        alt=""
                      />
                    </div>
                    <div className="infos1">
                      <h5>Your Conference is Up Next!</h5>
                      <Button
                        type="text"
                        className="content-title"
                        onClick={() => Apply()}
                      >
                        Inquire Now
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className={`apply_now_back ${apply ? "active" : ""}`}>
          <button className="close" onClick={Close}>
            +
          </button>
          <div className="apply_now">
            <img
              src={logo}
              className="img-fluid mb-3"
              style={{ height: "70px" }}
            />
            <h3>Launch Your Conference: Build and Manage with Our App</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-6 mb-3">
                  <input
                    type="text"
                    placeholder="First Name *"
                    className="form-control"
                    {...register("firstName", {
                      required: "This field is required",
                      pattern: {
                        value: /^[^\s]+(\s+[^\s]+)*$/,
                        message: "Starting and Ending Space not allowed",
                      },
                    })}
                  />
                  {errors?.firstName && (
                    <span role="alert" className="error_text">
                      {errors?.firstName?.message}
                    </span>
                  )}
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 mb-3">
                  <input
                    type="text"
                    placeholder="Last Name"
                    className="form-control"
                    {...register("lastName", {
                      pattern: {
                        value: /^[^\s]+(\s+[^\s]+)*$/,
                        message: "Starting and Ending Space not allowed",
                      },
                    })}
                  />
                  {errors?.lastName && (
                    <span role="alert" className="error_text">
                      {errors?.lastName?.message}
                    </span>
                  )}
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 mb-3">
                  <input
                    type="email"
                    placeholder="Email *"
                    className="form-control"
                    {...register("email", {
                      required: "This field is required",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Please Enter Valid Email",
                      },
                    })}
                  />
                  {errors?.email && (
                    <span role="alert" className="error_text">
                      {errors?.email?.message}
                    </span>
                  )}
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                  <input
                    type="text"
                    placeholder="Phone *"
                    className="form-control"
                    {...register("phone", {
                      required: "This field is required",
                      pattern: {
                        value:
                          /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                        message: "Enter valid phone number",
                      },
                    })}
                  />
                  {errors?.phone && (
                    <span role="alert" className="error_text">
                      {errors?.phone?.message}
                    </span>
                  )}
                </div>

                <div className="col-xl-6 col-lg-6 col-md-12 mb-3">
                  <input
                    type="text"
                    placeholder="School District Name"
                    className="form-control"
                    {...register("schoolDistrictName", {
                      pattern: {
                        value: /^[^\s]+(\s+[^\s]+)*$/,
                        message: "Starting and Ending Space not allowed",
                      },
                    })}
                  />
                  {errors?.schoolDistrictName && (
                    <span role="alert" className="error_text">
                      {errors?.schoolDistrictName?.message}
                    </span>
                  )}
                </div>
                <div className="col-md-12 mb-3">
                  <textarea
                    className="form-control"
                    placeholder="Type your message here..."
                    name=""
                    id=""
                    rows={5}
                    {...register("description", {
                      pattern: {
                        value: /^[^\s]+(\s+[^\s]+)*$/,
                        message: "Starting and Ending Space not allowed",
                      },
                    })}
                  ></textarea>
                  {errors?.description && (
                    <span role="alert" className="error_text">
                      {errors?.description?.message}
                    </span>
                  )}
                </div>
                <div className="col-md-12">
                  <button type="submit" className="theme_btn">
                    Send
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {!NavHide && <WebFooter />}
      </Suspense>
    </>
  );
}

export default HomeConferenceList;
