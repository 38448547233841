import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import "./index.scss";
import { Backdrop, CircularProgress, IconButton } from "@mui/material";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
import CropModel from "../../../Components/Reusable/CropModel";

function About() {
  const [derror, setDerror] = useState();
  const [openCircle, setOpenCircle] = React.useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const token = useSelector((state) => state.login.LoginDetails?.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const onSubmit = async (data) => {
    setDerror({ err: "" });
    let seData = {
      url: "/api/AboutUs/CreateAboutUs",
      body: data,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    setOpenCircle(true);
    let res = await PostCallApi(seData);
    setOpenCircle(false);

    if (res?.status === 200) {
      if (res.data.success) {
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        reset();
        setDerror({ err: "" });
        getAboutInfo();
      } else {
        setDerror({ err: res.data?.message });
      }
    }
  };
  useEffect(() => {
    getAboutInfo();
    document.title = "FIG Events | About";
    // eslint-disable-next-line
  }, []);

  const getAboutInfo = async () => {
    let seData = {
      url: "/api/AboutUs/GetAboutUsInformation",
      headers: headers,
    };
    let res = await GetCallApi(seData);
    if (res.status === 200) {
      if (res.data.success) {
        let abt = res.data.payload;
        setValue("aboutUsId", abt?.aboutUsId);
        setValue("Title", abt?.title);
        setValue("description", abt?.description);
        setValue("image", abt?.image);
        setValue("CoverImage1", abt?.image);
        setValue("image2", abt?.image2);
        setValue("CoverImage2", abt?.image2);
      }
    }
  };

  // crop code start
  const [src, setSrc] = useState(null);
  const [filename, setFileName] = useState(null);
  const [croppedImageUrl] = useState(null);
  const [openCropModal, setCropModal] = useState(false);
  const setCroppedImageUrlCallback = useCallback(
    (url) => {
      if (url) {
        setValue("image", url);
        setValue("CoverImage1", url.name);
        setSrc(null);
      } else {
        setSrc(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl]
  );
  const handleFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setFileName(e.target.files[0].name);
      reader.addEventListener(
        "load",
        () => {
          setSrc(reader.result);
          setCropModal(true);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
    }
  };
  const [src1, setSrc1] = useState(null);
  const [filename1, setFileName1] = useState(null);
  const [croppedImageUrl1] = useState(null);
  const [openCropModal1, setCropModal1] = useState(false);
  const setCroppedImageUrlCallback1 = useCallback(
    (url) => {
      if (url) {
        setValue("image2", url);
        setValue("CoverImage2", url.name);
        setSrc1(null);
      } else {
        setSrc1(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl1]
  );
  const handleFile1 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setFileName1(e.target.files[0].name);
      reader.addEventListener(
        "load",
        () => {
          setSrc1(reader.result);
          setCropModal1(true);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
    }
  };

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: () => 9999 }} open={openCircle}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="create_event_class">
        <div className="main_wrapper">
          <div className="title mb-3">About</div>
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)} className="form_box">
                <div className="row">
                  {/* <div className="col-md-12">
                    <div className="title mb-3">About</div>
                  </div> */}
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        {" "}
                        Title
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter  title"
                        {...register("Title", {
                          required: "This field is required",
                        })}
                      />
                      {errors.Title && (
                        <span role="alert" className="error_text">
                          {errors.Title.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Image
                        <span className="red-color"> *</span>
                      </label>
                      <div className="d-flex upload_btn_sec">
                        <input
                          type="text"
                          className="form-control"
                          readOnly
                          placeholder="No file choose"
                          {...register(`CoverImage1`, {
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                            // onChange: (e) => handleFile(e),
                          })}
                        />
                        <IconButton
                          component="label"
                          className="upload_btn m-0"
                        >
                          Choose File
                          <input
                            id="upload"
                            type="file"
                            accept="image/*"
                            className="form-control"
                            hidden
                            onChange={(e) => handleFile(e)}
                          />
                        </IconButton>
                      </div>
                      {/* crop code start */}
                      {src && (
                        <CropModel
                          src={src}
                          filename={filename}
                          setCroppedImageUrl={setCroppedImageUrlCallback}
                          openCropModal={openCropModal}
                          setCropModal={setCropModal}
                        />
                      )}
                      {/* crop code end */}
                      {errors.CoverImage1 && (
                        <span role="alert" className="error_text">
                          {errors.CoverImage1.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Image 2<span className="red-color"> *</span>
                      </label>
                      <div className="d-flex upload_btn_sec">
                        <input
                          type="text"
                          className="form-control"
                          readOnly
                          placeholder="No file choose"
                          {...register(`CoverImage2`, {
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                            // onChange: (e) => handleFile(e),
                          })}
                        />
                        <IconButton
                          component="label"
                          className="upload_btn m-0"
                        >
                          Choose File
                          <input
                            id="upload"
                            type="file"
                            accept="image/*"
                            className="form-control"
                            hidden
                            onChange={(e) => handleFile1(e)}
                          />
                        </IconButton>
                      </div>
                      {/* crop code start */}
                      {src1 && (
                        <CropModel
                          src={src1}
                          filename={filename1}
                          setCroppedImageUrl={setCroppedImageUrlCallback1}
                          openCropModal={openCropModal1}
                          setCropModal={setCropModal1}
                        />
                      )}
                      {/* crop code end */}
                      {errors.CoverImage2 && (
                        <span role="alert" className="error_text">
                          {errors.CoverImage2.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Description
                        <span className="red-color"> *</span>
                      </label>
                      <textarea
                        rows={5}
                        type="text"
                        className="form-control"
                        placeholder="Enter description"
                        {...register("description", {
                          required: "This field is required",
                        })}
                      />
                      {errors.description && (
                        <span role="alert" className="error_text">
                          {errors.description.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 d-flex justify-content-end mt-3">
                    <div className="form-group">
                      <Button className="detail_button" type="submit">
                        Update
                      </Button>

                      {derror?.err && (
                        <span role="alert" className="d-block error_text">
                          {derror.err}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
