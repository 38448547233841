import React, { Suspense } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import Config from "../../../Utils/Config";
import "react-tabs/style/react-tabs.css";
import CRMHeader from "../Shared/CRMHeader";
import { BsWindow } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FaStar } from "react-icons/fa";
import Badge from "@mui/material/Badge";
import { GoDotFill } from "react-icons/go";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Button, CircularProgress } from "@mui/material";
import { FiLink } from "react-icons/fi";
import { RxCross1 } from "react-icons/rx";
import Divider from "@mui/material/Divider";
import { BiSolidEdit } from "react-icons/bi";
import { BiUser } from "react-icons/bi";
import { TbPaperclip } from "react-icons/tb";
import { GoTag } from "react-icons/go";
import { FiCalendar } from "react-icons/fi";
import { IoBookmarkOutline } from "react-icons/io5";
import { LuRepeat } from "react-icons/lu";
import { CgPocket } from "react-icons/cg";
import { FiArchive } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import Alert from "@mui/material/Alert";
import { FiEdit2 } from "react-icons/fi";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { FaCirclePlus } from "react-icons/fa6";
import { FiPlusSquare } from "react-icons/fi";
import { RiFileExcel2Line } from "react-icons/ri";
import Chip from "@mui/material/Chip";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import { CiSettings } from "react-icons/ci";
import { LuArchive } from "react-icons/lu";
import { LuBook } from "react-icons/lu";
import { FiLayout } from "react-icons/fi";
import { FaListUl } from "react-icons/fa6";

const avatar_img = "/assets/images/avatar.jpg";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function TaskList() {
  const [openSide, setOpenSide] = React.useState(true);
  const [openCloseSide, setOpenCloseSide] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open = Boolean(anchorEl);
  const openAction = Boolean(anchorEl1);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleActionClick = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleActionClose = () => {
    setAnchorEl1(null);
  };
  const handleDelete = () => {
    console.info("You clicked the delete icon.");
  };

  return (
    <>
      <CRMHeader />
      <Suspense fallback={renderLoader()}>
        <section className="contactlist-section">
          <div className="container-fluid">
            <div className="row">
              <div
                className={`${
                  openSide ? "" : "hide_side"
                } col-lg-2 col-md-4 contact-border`}
              >
                <div className="cotact-left-section">
                  <Link to="" className="primary-btn btn-border">
                    Add Task
                  </Link>
                  <div className="menu-group">
                    <ul>
                      <li>
                        <Link className="nav-link">
                          <FiLayout /> Overview
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <FaListUl /> My Tasks
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <BsWindow /> Files
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <FaRegEdit /> Activity
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="separator separator-light"></div>
                  <div className="menu-group">
                    <ul>
                      <li>
                        <Link className="nav-link">
                          <span className="badge rounded-pill badge-danger badge-indicator"></span>{" "}
                          Urgent
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <span className="badge rounded-pill badge-gold badge-indicator"></span>{" "}
                          High
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <span className="badge rounded-pill badge-yellow badge-indicator"></span>{" "}
                          Medium
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <span className="badge rounded-pill text-bg-danger badge-indicator"></span>{" "}
                          Low
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="separator separator-light"></div>
                </div>
                <div className="contactapp-fixednav">
                  <div className="menu-group">
                    <ul>
                      <li>
                        <Link>
                          <CiSettings />
                        </Link>
                      </li>
                      <li>
                        <Link>
                          <LuArchive />
                        </Link>
                      </li>
                      <li>
                        <Link>
                          <LuBook />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  openSide ? "col-lg-10 col-md-8" : "col-lg-12"
                } contact-right-side`}
              >
                <div className="togglebar">
                  <div
                    className="sidebar-togglable"
                    onClick={() => setOpenSide(!openSide)}
                  >
                    <MdKeyboardArrowLeft />
                  </div>
                </div>
                <div className="top-contact">
                  <div className="title-text">
                    <h3>Tasks</h3>
                    <select className="form-select me-auto" defaultValue="all">
                      <option value="all">All Task</option>
                      <option value="1">Add New Contact</option>
                      <option value="2">Add New Department</option>
                      <option value="3">Add Category</option>
                      <option value="4">Add New Tag</option>
                    </select>
                  </div>
                  <div className="right-bottom-contact">
                    <input
                      type="search"
                      placeholder="Search"
                      className="form-control w-auto"
                    />
                  </div>
                </div>
                <div className="row">
                  <div
                    className={`${
                      openCloseSide ? "col-lg-8" : "col-lg-10"
                    } task-list-section`}
                  >
                    <div className="bottom-contact">
                      <div className="left-bottom-contact">
                        <div className="d-flex gap-3 align-items-center">
                          <select
                            className="form-select me-auto"
                            defaultValue="Bulk"
                          >
                            <option value="Edit">Edit</option>
                            <option value="Bulk">Bulk action</option>
                            <option value="Move">Move to trash</option>
                          </select>
                          <button type="text" className="apply-btn">
                            Apply
                          </button>
                          <select
                            className="form-select w-auto me-auto"
                            defaultValue="1"
                          >
                            <option value="1">Sort by Date</option>
                            <option value="2">Date Edited</option>
                            <option value="3">Recently Added</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="accordian-section">
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <h5>Recently Assigned</h5>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="card-body">
                            <ul className="advance-list">
                              <li className="advance-list-item">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="d-flex align-items-center">
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="customCheckTodo1"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="customCheckTodo1"
                                      ></label>
                                    </div>
                                    <div>
                                      <span className="todo-star">
                                        <FaStar />
                                      </span>
                                      <Badge
                                        color="error"
                                        variant="dot"
                                        className="badge-icon"
                                      ></Badge>
                                      <span className="box-text">
                                        Video conference with Canada Team
                                      </span>
                                      <span className="badge rounded-pill text-bg-primary">
                                        <GoDotFill className="me-1" />
                                        Hight
                                      </span>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-shrink-0 align-items-center ms-3">
                                    <span className="todo-time d-lg-inline-block d-none primarysky-color me-3">
                                      Tomorrow
                                    </span>
                                    <span>
                                      <img
                                        src={avatar_img}
                                        alt="user"
                                        className="avatar-img"
                                      />
                                    </span>
                                    <span className="badge rounded-pill text-bg-info ms-3 d-md-inline-block d-none">
                                      Calls
                                    </span>
                                    <IconButton
                                      aria-label="more"
                                      id="long-button"
                                      aria-controls={
                                        open ? "long-menu" : undefined
                                      }
                                      aria-expanded={open ? "true" : undefined}
                                      aria-haspopup="true"
                                      onClick={handleClick}
                                      className="icon-btn"
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </li>
                              <li className="advance-list-item">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="d-flex align-items-center">
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="customCheckTodo1"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="customCheckTodo1"
                                      ></label>
                                    </div>
                                    <div>
                                      <span className="todo-star">
                                        <FaStar />
                                      </span>
                                      <Badge
                                        color="error"
                                        variant="dot"
                                        className="badge-icon "
                                      ></Badge>
                                      <span className="box-text">
                                        Client objective meeting
                                      </span>
                                      <span className="badge rounded-pill text-bg-primary">
                                        <GoDotFill className="me-1" />
                                        Hight
                                      </span>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-shrink-0 align-items-center ms-3">
                                    <span className="todo-time d-lg-inline-block d-none primaryred-color me-3">
                                      Yesyerday
                                    </span>
                                    <span>
                                      <img
                                        src={avatar_img}
                                        alt="user"
                                        className="avatar-img"
                                      />
                                    </span>
                                    <span className="badge rounded-pill text-bg-danger ms-3 d-md-inline-block d-none">
                                      Conferences
                                    </span>
                                    <IconButton
                                      aria-label="more"
                                      id="long-button"
                                      aria-controls={
                                        open ? "long-menu" : undefined
                                      }
                                      aria-expanded={open ? "true" : undefined}
                                      aria-haspopup="true"
                                      onClick={handleClick}
                                      className="icon-btn"
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </li>
                              <li className="advance-list-item">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="d-flex align-items-center">
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="customCheckTodo1"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="customCheckTodo1"
                                      ></label>
                                    </div>
                                    <div>
                                      <span className="todo-star">
                                        <FaStar />
                                      </span>
                                      <Badge
                                        color="error"
                                        variant="dot"
                                        className="badge-icon"
                                      ></Badge>
                                      <span className="box-text">
                                        Send revised proposal to Mr. Dow Jones
                                      </span>
                                      <span className="badge rounded-pill text-bg-yellow">
                                        <GoDotFill className="me-1" />
                                        Low
                                      </span>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-shrink-0 align-items-center ms-3">
                                    <span className="todo-time d-lg-inline-block d-none primarysky-color me-3">
                                      Tomorrow
                                    </span>
                                    <span>
                                      <img
                                        src={avatar_img}
                                        alt="user"
                                        className="avatar-img"
                                      />
                                    </span>
                                    <span className="badge rounded-pill text-bg-secondary ms-3 d-md-inline-block d-none">
                                      Calls
                                    </span>
                                    <IconButton
                                      aria-label="more"
                                      id="long-button"
                                      aria-controls={
                                        open ? "long-menu" : undefined
                                      }
                                      aria-expanded={open ? "true" : undefined}
                                      aria-haspopup="true"
                                      onClick={handleClick}
                                      className="icon-btn"
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </li>
                              <li className="advance-list-item">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="d-flex align-items-center">
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="customCheckTodo1"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="customCheckTodo1"
                                      ></label>
                                    </div>
                                    <div>
                                      <span className="todo-star">
                                        <FaStar />
                                      </span>
                                      <Badge
                                        color="error"
                                        variant="dot"
                                        className="badge-icon "
                                      ></Badge>
                                      <span className="box-text">
                                        Target market trend analysis on the go
                                      </span>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-shrink-0 align-items-center ms-3">
                                    <span className="todo-time d-lg-inline-block d-none primarylight-color me-3">
                                      Saturday
                                    </span>
                                    <span>
                                      <img
                                        src={avatar_img}
                                        alt="user"
                                        className="avatar-img"
                                      />
                                    </span>
                                    <span className="badge rounded-pill text-bg-warning ms-3 d-md-inline-block d-none">
                                      Project
                                    </span>
                                    <IconButton
                                      aria-label="more"
                                      id="long-button"
                                      aria-controls={
                                        open ? "long-menu" : undefined
                                      }
                                      aria-expanded={open ? "true" : undefined}
                                      aria-haspopup="true"
                                      onClick={handleClick}
                                      className="icon-btn"
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <h5>Yesterday</h5>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="card-body">
                            <ul className="advance-list">
                              <li className="advance-list-item">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="d-flex align-items-center">
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="customCheckTodo1"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="customCheckTodo1"
                                      ></label>
                                    </div>
                                    <div>
                                      <span className="todo-star">
                                        <FaStar />
                                      </span>
                                      <Badge
                                        color="error"
                                        variant="dot"
                                        className="badge-icon"
                                      ></Badge>
                                      <span className="box-text">
                                        Fix tooltip word wrap/break rules
                                      </span>
                                      <span className="badge rounded-pill text-bg-primary">
                                        <GoDotFill className="me-1" />
                                        Hight
                                      </span>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-shrink-0 align-items-center ms-3">
                                    <span className="todo-time d-lg-inline-block d-none primarysky-color me-3">
                                      4 Days ago
                                    </span>
                                    <span>
                                      <img
                                        src={avatar_img}
                                        alt="user"
                                        className="avatar-img"
                                      />
                                    </span>
                                    <span className="badge rounded-pill text-bg-warning ms-3 d-md-inline-block d-none">
                                      Project
                                    </span>
                                    <IconButton
                                      aria-label="more"
                                      id="long-button"
                                      aria-controls={
                                        open ? "long-menu" : undefined
                                      }
                                      aria-expanded={open ? "true" : undefined}
                                      aria-haspopup="true"
                                      onClick={handleClick}
                                      className="icon-btn"
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </li>
                              <li className="advance-list-item">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="d-flex align-items-center">
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="customCheckTodo1"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="customCheckTodo1"
                                      ></label>
                                    </div>
                                    <div>
                                      <span className="todo-star">
                                        <FaStar />
                                      </span>
                                      <Badge
                                        color="error"
                                        variant="dot"
                                        className="badge-icon "
                                      ></Badge>
                                      <span className="box-text">
                                        Client objective meeting
                                      </span>
                                      <span className="badge rounded-pill text-bg-primary">
                                        <GoDotFill className="me-1" />
                                        Hight
                                      </span>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-shrink-0 align-items-center ms-3">
                                    <span className="todo-time d-lg-inline-block d-none primaryred-color me-3">
                                      Yesyerday
                                    </span>
                                    <span>
                                      <img
                                        src={avatar_img}
                                        alt="user"
                                        className="avatar-img"
                                      />
                                    </span>
                                    <span className="badge rounded-pill text-bg-danger ms-3 d-md-inline-block d-none">
                                      Conferences
                                    </span>
                                    <IconButton
                                      aria-label="more"
                                      id="long-button"
                                      aria-controls={
                                        open ? "long-menu" : undefined
                                      }
                                      aria-expanded={open ? "true" : undefined}
                                      aria-haspopup="true"
                                      onClick={handleClick}
                                      className="icon-btn"
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </li>
                              <li className="advance-list-item">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="d-flex align-items-center">
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="customCheckTodo1"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="customCheckTodo1"
                                      ></label>
                                    </div>
                                    <div>
                                      <span className="todo-star">
                                        <FaStar />
                                      </span>
                                      <Badge
                                        color="error"
                                        variant="dot"
                                        className="badge-icon"
                                      ></Badge>
                                      <span className="box-text">
                                        Send revised proposal to Mr. Dow Jones
                                      </span>
                                      <span className="badge rounded-pill text-bg-yellow">
                                        <GoDotFill className="me-1" />
                                        Low
                                      </span>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-shrink-0 align-items-center ms-3">
                                    <span className="todo-time d-lg-inline-block d-none primarysky-color me-3">
                                      Tomorrow
                                    </span>
                                    <span>
                                      <img
                                        src={avatar_img}
                                        alt="user"
                                        className="avatar-img"
                                      />
                                    </span>
                                    <span className="badge rounded-pill text-bg-secondary ms-3 d-md-inline-block d-none">
                                      Calls
                                    </span>
                                    <IconButton
                                      aria-label="more"
                                      id="long-button"
                                      aria-controls={
                                        open ? "long-menu" : undefined
                                      }
                                      aria-expanded={open ? "true" : undefined}
                                      aria-haspopup="true"
                                      onClick={handleClick}
                                      className="icon-btn"
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </li>
                              <li className="advance-list-item">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="d-flex align-items-center">
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="customCheckTodo1"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="customCheckTodo1"
                                      ></label>
                                    </div>
                                    <div>
                                      <span className="todo-star">
                                        <FaStar />
                                      </span>
                                      <Badge
                                        color="error"
                                        variant="dot"
                                        className="badge-icon "
                                      ></Badge>
                                      <span className="box-text">
                                        Target market trend analysis on the go
                                      </span>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-shrink-0 align-items-center ms-3">
                                    <span className="todo-time d-lg-inline-block d-none primarylight-color me-3">
                                      Saturday
                                    </span>
                                    <span>
                                      <img
                                        src={avatar_img}
                                        alt="user"
                                        className="avatar-img"
                                      />
                                    </span>
                                    <span className="badge rounded-pill text-bg-warning ms-3 d-md-inline-block d-none">
                                      Project
                                    </span>
                                    <IconButton
                                      aria-label="more"
                                      id="long-button"
                                      aria-controls={
                                        open ? "long-menu" : undefined
                                      }
                                      aria-expanded={open ? "true" : undefined}
                                      aria-haspopup="true"
                                      onClick={handleClick}
                                      className="icon-btn"
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                        >
                          <h5>15 July, 20</h5>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="card-body">
                            <ul className="advance-list">
                              <li className="advance-list-item">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="d-flex align-items-center">
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="customCheckTodo1"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="customCheckTodo1"
                                      ></label>
                                    </div>
                                    <div>
                                      <span className="todo-star">
                                        <FaStar />
                                      </span>
                                      <Badge
                                        color="error"
                                        variant="dot"
                                        className="badge-icon"
                                      ></Badge>
                                      <span className="box-text">
                                        Fix tooltip word wrap/break rules
                                      </span>
                                      <span className="badge rounded-pill text-bg-primary">
                                        <GoDotFill className="me-1" />
                                        Hight
                                      </span>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-shrink-0 align-items-center ms-3">
                                    <span className="todo-time d-lg-inline-block d-none primarysky-color me-3">
                                      4 Days ago
                                    </span>
                                    <span>
                                      <img
                                        src={avatar_img}
                                        alt="user"
                                        className="avatar-img"
                                      />
                                    </span>
                                    <span className="badge rounded-pill text-bg-warning ms-3 d-md-inline-block d-none">
                                      Project
                                    </span>
                                    <IconButton
                                      aria-label="more"
                                      id="long-button"
                                      aria-controls={
                                        open ? "long-menu" : undefined
                                      }
                                      aria-expanded={open ? "true" : undefined}
                                      aria-haspopup="true"
                                      onClick={handleClick}
                                      className="icon-btn"
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </li>
                              <li className="advance-list-item">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="d-flex align-items-center">
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="customCheckTodo1"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="customCheckTodo1"
                                      ></label>
                                    </div>
                                    <div>
                                      <span className="todo-star">
                                        <FaStar />
                                      </span>
                                      <Badge
                                        color="error"
                                        variant="dot"
                                        className="badge-icon "
                                      ></Badge>
                                      <span className="box-text">
                                        Client objective meeting
                                      </span>
                                      <span className="badge rounded-pill text-bg-primary">
                                        <GoDotFill className="me-1" />
                                        Hight
                                      </span>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-shrink-0 align-items-center ms-3">
                                    <span className="todo-time d-lg-inline-block d-none primaryred-color me-3">
                                      Yesyerday
                                    </span>
                                    <span>
                                      <img
                                        src={avatar_img}
                                        alt="user"
                                        className="avatar-img"
                                      />
                                    </span>
                                    <span className="badge rounded-pill text-bg-danger ms-3 d-md-inline-block d-none">
                                      Conferences
                                    </span>
                                    <IconButton
                                      aria-label="more"
                                      id="long-button"
                                      aria-controls={
                                        open ? "long-menu" : undefined
                                      }
                                      aria-expanded={open ? "true" : undefined}
                                      aria-haspopup="true"
                                      onClick={handleClick}
                                      className="icon-btn"
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </li>
                              <li className="advance-list-item">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="d-flex align-items-center">
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="customCheckTodo1"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="customCheckTodo1"
                                      ></label>
                                    </div>
                                    <div>
                                      <span className="todo-star">
                                        <FaStar />
                                      </span>
                                      <Badge
                                        color="error"
                                        variant="dot"
                                        className="badge-icon"
                                      ></Badge>
                                      <span className="box-text">
                                        Send revised proposal to Mr. Dow Jones
                                      </span>
                                      <span className="badge rounded-pill text-bg-yellow">
                                        <GoDotFill className="me-1" />
                                        Low
                                      </span>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-shrink-0 align-items-center ms-3">
                                    <span className="todo-time d-lg-inline-block d-none primarysky-color me-3">
                                      Tomorrow
                                    </span>
                                    <span>
                                      <img
                                        src={avatar_img}
                                        alt="user"
                                        className="avatar-img"
                                      />
                                    </span>
                                    <span className="badge rounded-pill text-bg-secondary ms-3 d-md-inline-block d-none">
                                      Calls
                                    </span>
                                    <IconButton
                                      aria-label="more"
                                      id="long-button"
                                      aria-controls={
                                        open ? "long-menu" : undefined
                                      }
                                      aria-expanded={open ? "true" : undefined}
                                      aria-haspopup="true"
                                      onClick={handleClick}
                                      className="icon-btn"
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </li>
                              <li className="advance-list-item">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="d-flex align-items-center">
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="customCheckTodo1"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="customCheckTodo1"
                                      ></label>
                                    </div>
                                    <div>
                                      <span className="todo-star">
                                        <FaStar />
                                      </span>
                                      <Badge
                                        color="error"
                                        variant="dot"
                                        className="badge-icon "
                                      ></Badge>
                                      <span className="box-text">
                                        Target market trend analysis on the go
                                      </span>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-shrink-0 align-items-center ms-3">
                                    <span className="todo-time d-lg-inline-block d-none primarylight-color me-3">
                                      Saturday
                                    </span>
                                    <span>
                                      <img
                                        src={avatar_img}
                                        alt="user"
                                        className="avatar-img"
                                      />
                                    </span>
                                    <span className="badge rounded-pill text-bg-warning ms-3 d-md-inline-block d-none">
                                      Project
                                    </span>
                                    <IconButton
                                      aria-label="more"
                                      id="long-button"
                                      aria-controls={
                                        open ? "long-menu" : undefined
                                      }
                                      aria-expanded={open ? "true" : undefined}
                                      aria-haspopup="true"
                                      onClick={handleClick}
                                      className="icon-btn"
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                  <div
                    className={`${openCloseSide ? "" : "hide_side"} col-lg-4`}
                  >
                    <div className="task-info">
                      <div className="task-header">
                        <div className="task-header-left">
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox defaultChecked />}
                              label="Mark as completed"
                            />
                          </FormGroup>
                          <Button type="text" className="copy-btn">
                            <FiLink className="me-1" /> Copy Link
                          </Button>
                        </div>
                        <div className="task-header-right">
                          <span className="todo-star">
                            <FaStar />
                          </span>
                          <IconButton
                            aria-label="more"
                            id="task-button"
                            aria-controls={open ? "task-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={handleActionClick}
                            className="icon-btn"
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            onClick={() => setOpenCloseSide(!openCloseSide)}
                          >
                            {" "}
                            <RxCross1 />{" "}
                          </IconButton>
                        </div>
                      </div>
                      <div className="task-detail-body">
                        <Alert severity="success">
                          {" "}
                          This task is private for Jampack Team
                        </Alert>
                        <h4 className="pt-4">
                          Framworking Building <FiEdit2 />
                        </h4>
                        <p>
                          Instant rebuilding of assets during development{" "}
                          <FiEdit2 />
                        </p>
                        <div className="mt-3 avatar-img">
                          <AvatarGroup max={4}>
                            <Avatar
                              alt="Remy Sharp"
                              src="/static/images/avatar/1.jpg"
                            />
                            <Avatar
                              alt="Travis Howard"
                              src="/static/images/avatar/2.jpg"
                            />
                            <Avatar
                              alt="Cindy Baker"
                              src="/static/images/avatar/3.jpg"
                            />
                            <Avatar
                              alt="Agnes Walker"
                              src="/static/images/avatar/4.jpg"
                            />
                            <Avatar
                              alt="Trevor Henderson"
                              src="/static/images/avatar/5.jpg"
                            />
                          </AvatarGroup>
                        </div>
                        <form className="row">
                          <div className="col-md-6">
                            <div className="datetext">
                              Due Date <span className="line-date"></span>
                            </div>
                            <input
                              className="form-control"
                              type="date"
                              name="single-date"
                            />
                          </div>
                          <div className="col-md-6">
                            <div className="datetext">
                              Status <span className="line-date"></span>
                            </div>
                            <Button
                              aria-label="more"
                              id="long-menu"
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={handleClick}
                              className="progress-btn"
                            >
                              In Progress <IoIosArrowDown className="ml-2" />
                            </Button>
                            {/* <div className="form-group">
                              <select name="" className="progress-btn">
                                <option value="In Progress" selected>In Progress</option>
                                <option value="In Progress">In Progress</option>
                                <option value="In Progress">In Progress</option>
                                <option value="In Progress">In Progress</option>
                              </select>
                            </div> */}
                          </div>
                          <div className="col-md-12">
                            <div className="datetext">
                              Label <span className="line-label"></span>
                            </div>
                            <div className="chip-block">
                              <Chip
                                label="Framework"
                                variant="outlined"
                                onDelete={handleDelete}
                                className="me-2"
                              />
                              <Chip
                                label="Html"
                                variant="outlined"
                                onDelete={handleDelete}
                              />
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Add Chips"
                              />
                            </div>
                          </div>
                        </form>
                        <div className="tab-section">
                          <Tabs aria-label="Basic tabs1" defaultValue={0}>
                            <TabList>
                              <Tab>Checklist</Tab>
                              <Tab>Comments</Tab>
                              <Tab>Files</Tab>
                              <Tab>Activity</Tab>
                            </TabList>
                            <TabPanel value={0}>
                              <div className="tab-content">
                                <div className="checklist-headers">
                                  <h5>Checklist</h5>
                                  <Link to="" className="plus-icon">
                                    {" "}
                                    <FaCirclePlus />{" "}
                                  </Link>
                                </div>
                                <div className="hk-checklist">
                                  <FormGroup className="from-group">
                                    <FormControlLabel
                                      control={<Checkbox defaultChecked />}
                                      label="Video conference with canada Team"
                                    />
                                    <Link to="" className="primarylight-color">
                                      <MdDeleteOutline />
                                    </Link>
                                  </FormGroup>
                                  <FormGroup className="from-group">
                                    <FormControlLabel
                                      control={<Checkbox defaultChecked />}
                                      label="Client objective meeting"
                                    />
                                    <Link to="" className="primarylight-color">
                                      <MdDeleteOutline />
                                    </Link>
                                  </FormGroup>
                                  <FormGroup className="from-group">
                                    <FormControlLabel
                                      control={<Checkbox defaultChecked />}
                                      label="Upgrade dependency on resources"
                                    />
                                    <Link to="" className="primarylight-color">
                                      <MdDeleteOutline />
                                    </Link>
                                  </FormGroup>
                                  <FormGroup className="from-group">
                                    <FormControlLabel
                                      control={<Checkbox />}
                                      label="Add new Item"
                                    />
                                    <Link to="" className="primarylight-color">
                                      <MdDeleteOutline />
                                    </Link>
                                  </FormGroup>
                                  <Link to={""} className="primarysky-color">
                                    <FiPlusSquare className="me-2" /> New Item
                                  </Link>
                                </div>
                                <div className="team-task-content">
                                  <h5>Canada team task</h5>
                                  <div className="d-flex gap-2">
                                    <Link to="" className="primarylightcolor">
                                      <FiEdit2 />
                                    </Link>
                                    <Link to="" className="primarylightcolor">
                                      <MdDeleteOutline />
                                    </Link>
                                  </div>
                                </div>
                                <div className="hk-checklist">
                                  <FormGroup className="from-group">
                                    <FormControlLabel
                                      control={<Checkbox defaultChecked />}
                                      label="Video conference with canada Team"
                                    />
                                    <Link to="" className="primarylight-color">
                                      <MdDeleteOutline />
                                    </Link>
                                  </FormGroup>
                                  <FormGroup className="from-group">
                                    <FormControlLabel
                                      control={<Checkbox defaultChecked />}
                                      label="Client objective meeting"
                                    />
                                    <Link to="" className="primarylight-color">
                                      <MdDeleteOutline />
                                    </Link>
                                  </FormGroup>
                                  <FormGroup className="from-group">
                                    <FormControlLabel
                                      control={<Checkbox defaultChecked />}
                                      label="Upgrade dependency on resources"
                                    />
                                    <Link to="" className="primarylight-color">
                                      <MdDeleteOutline />
                                    </Link>
                                  </FormGroup>
                                  <FormGroup className="from-group">
                                    <FormControlLabel
                                      control={<Checkbox />}
                                      label="Add new Item"
                                    />
                                    <Link to="" className="primarylight-color">
                                      <MdDeleteOutline />
                                    </Link>
                                  </FormGroup>
                                  <Link to={""} className="primarysky-color">
                                    <FiPlusSquare className="me-2" /> New Item
                                  </Link>
                                </div>
                                <div className="team-task-content mt-2">
                                  <h5>Notes</h5>
                                  <Link to="" className="plus-icon">
                                    {" "}
                                    <FaCirclePlus />{" "}
                                  </Link>
                                </div>
                                <div className="bg-box">
                                  <div className="bg-header">
                                    <div className="d-flex gap-3">
                                      <div className="image">
                                        <img src={avatar_img} alt="" />
                                      </div>
                                      <div>
                                        <h5>Martin Luther</h5>
                                        <p>9 Apr, 20, 7:14 AM</p>
                                      </div>
                                    </div>
                                    <IconButton
                                      aria-label="more"
                                      id="long-button"
                                      aria-controls={
                                        open ? "long-menu" : undefined
                                      }
                                      aria-expanded={open ? "true" : undefined}
                                      aria-haspopup="true"
                                      onClick={handleClick}
                                      className="icon-btn"
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </div>
                                  <div className="bg-content">
                                    <p className="primarylight-color">
                                      @
                                      <Link to="" className="primarysky-color">
                                        Charlie Darvin
                                      </Link>{" "}
                                      From there, you can run grunt compile,
                                      grunt migrate and grunt test to compile
                                      your contracts, deploy those contracts to
                                      the network, and run their associated unit
                                      tests.
                                    </p>
                                  </div>
                                </div>
                                <div className="bg-box">
                                  <div className="bg-header">
                                    <div className="d-flex gap-3">
                                      <div className="image">
                                        <img src={avatar_img} alt="" />
                                      </div>
                                      <div>
                                        <h5>Katherine Jones</h5>
                                        <p>8 Apr, 20, 5:30 PM</p>
                                      </div>
                                    </div>
                                    <IconButton
                                      aria-label="more"
                                      id="long-button"
                                      aria-controls={
                                        open ? "long-menu" : undefined
                                      }
                                      aria-expanded={open ? "true" : undefined}
                                      aria-haspopup="true"
                                      onClick={handleClick}
                                      className="icon-btn"
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </div>
                                  <div className="bg-content">
                                    <p className="primarylight-color">
                                      @
                                      <Link to="" className="primarysky-color">
                                        Martin Luther
                                      </Link>{" "}
                                      Viscosity ratio for &quot;Appear
                                      view&quot; link text is 3.7:1 which is
                                      less
                                    </p>
                                  </div>
                                </div>
                                <Link to="" className="btn btn-light w-100">
                                  View more
                                </Link>
                              </div>
                            </TabPanel>
                            <TabPanel value={1}>
                              <div className="tab-content">
                                <div className="checklist-headers">
                                  <h5>3 Responses</h5>
                                  <Link to="" className="plus-icon">
                                    {" "}
                                    <FaCirclePlus />{" "}
                                  </Link>
                                </div>
                                <div className="comment-block">
                                  <div className="d-flex gap-3">
                                    <div className="image">
                                      <img src={avatar_img} alt="" />
                                    </div>
                                    <div>
                                      <h5>
                                        Martin Luther{" "}
                                        <span className="badge  text-bg-info ms-3 d-md-inline-block d-none">
                                          Manager
                                        </span>
                                      </h5>
                                      <p className="primarylight-color mt-3">
                                        @
                                        <Link
                                          to=""
                                          className="primarysky-color"
                                        >
                                          Charlie Darvin
                                        </Link>{" "}
                                        From there, you can run grunt compile,
                                        grunt migrate and grunt test to compile
                                        your contracts, deploy those contracts
                                        to the network, and run their associated
                                        unit tests.
                                      </p>
                                      <div className="comment-action-wrap mt-3">
                                        <span>3 hours ago</span>
                                        <span className="comment-dot-sep">
                                          ●
                                        </span>
                                        <a href="/task-list">Reply</a>
                                        <span className="comment-dot-sep">
                                          ●
                                        </span>
                                        <a href="/task-list">Like</a>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="separator separator-light"></div>
                                  <div className="d-flex gap-3">
                                    <div className="image">
                                      <img src={avatar_img} alt="" />
                                    </div>
                                    <div>
                                      <h5>Katherine Jones</h5>
                                      <p className="primarylight-color mt-3">
                                        Dynamically beautiful work done by @
                                        <Link
                                          to=""
                                          className="primarysky-color"
                                        >
                                          Ashton Kutcher
                                        </Link>
                                      </p>
                                      <div className="comment-action-wrap mt-3">
                                        <span>3 hours ago</span>
                                        <span className="comment-dot-sep">
                                          ●
                                        </span>
                                        <a href="/task-list">Reply</a>
                                        <span className="comment-dot-sep">
                                          ●
                                        </span>
                                        <a href="/task-list">Like</a>
                                      </div>
                                      <div className="d-flex gap-3 mt-3">
                                        <div className="image">
                                          <img src={avatar_img} alt="" />
                                        </div>
                                        <div>
                                          <h5>
                                            Ashton Kutche{" "}
                                            <span className="badge badge-gold ms-3 d-md-inline-block d-none">
                                              Designer
                                            </span>
                                          </h5>
                                          <p className="primarylight-color mt-3">
                                            @
                                            <Link
                                              to=""
                                              className="primarysky-color"
                                            >
                                              Katherine Jones
                                            </Link>{" "}
                                            Thank you :)
                                          </p>
                                          <div className="comment-action-wrap mt-3">
                                            <span>3 hours ago</span>
                                            <span className="comment-dot-sep">
                                              ●
                                            </span>
                                            <a href="/task-list">Reply</a>
                                            <span className="comment-dot-sep">
                                              ●
                                            </span>
                                            <a href="/task-list">Like</a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="separator separator-light"></div>
                                  <form>
                                    <div className="form-group">
                                      <label className="form-label">
                                        Add Comment
                                      </label>
                                      <textarea
                                        className="form-control"
                                        rows="5"
                                      ></textarea>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                      <button className="primary-btn">
                                        Send
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </TabPanel>
                            <TabPanel value={2}>
                              <div className="tab-content">
                                <div className="row pb-4">
                                  <div className="col-sm">
                                    <form
                                      action="#"
                                      className="dropzone dz-clickable"
                                      id="remove_link"
                                    >
                                      <div className="dz-default dz-message">
                                        <button
                                          className="dz-button"
                                          type="button"
                                        >
                                          Drop files here to upload
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                                <h5>Shared files</h5>
                                <div className="tab-section">
                                  <Accordion defaultExpanded>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panela-content"
                                      id="panela-header"
                                    >
                                      <h5 className="primarysky-color">
                                        Yesterday
                                      </h5>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <ul className="sh-files">
                                        <li>
                                          <div className="d-flex gap-3">
                                            <div className="bg-sm-box">
                                              <RiFileExcel2Line />
                                            </div>
                                            <div>
                                              <h6>website_content.exl</h6>
                                              <span className="primarylight-color">
                                                2,635 KB
                                              </span>
                                            </div>
                                          </div>
                                          <div className="d-flex gap-3">
                                            <div className="image">
                                              <img src={avatar_img} alt="" />
                                            </div>
                                            <IconButton
                                              aria-label="more"
                                              id="long-button"
                                              aria-controls={
                                                open ? "long-menu" : undefined
                                              }
                                              aria-expanded={
                                                open ? "true" : undefined
                                              }
                                              aria-haspopup="true"
                                              onClick={handleClick}
                                              className="icon-btn"
                                            >
                                              <MoreVertIcon />
                                            </IconButton>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="d-flex gap-3">
                                            <div className="bg-sm-box">
                                              <RiFileExcel2Line />
                                            </div>
                                            <div>
                                              <h6>website_content.exl</h6>
                                              <span className="primarylight-color">
                                                2,635 KB
                                              </span>
                                            </div>
                                          </div>
                                          <div className="d-flex gap-3">
                                            <div className="image">
                                              <img src={avatar_img} alt="" />
                                            </div>
                                            <IconButton
                                              aria-label="more"
                                              id="long-button"
                                              aria-controls={
                                                open ? "long-menu" : undefined
                                              }
                                              aria-expanded={
                                                open ? "true" : undefined
                                              }
                                              aria-haspopup="true"
                                              onClick={handleClick}
                                              className="icon-btn"
                                            >
                                              <MoreVertIcon />
                                            </IconButton>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="d-flex gap-3">
                                            <div className="bg-sm-box">
                                              <RiFileExcel2Line />
                                            </div>
                                            <div>
                                              <h6>website_content.exl</h6>
                                              <span className="primarylight-color">
                                                2,635 KB
                                              </span>
                                            </div>
                                          </div>
                                          <div className="d-flex gap-3">
                                            <div className="image">
                                              <img src={avatar_img} alt="" />
                                            </div>
                                            <IconButton
                                              aria-label="more"
                                              id="long-button"
                                              aria-controls={
                                                open ? "long-menu" : undefined
                                              }
                                              aria-expanded={
                                                open ? "true" : undefined
                                              }
                                              aria-haspopup="true"
                                              onClick={handleClick}
                                              className="icon-btn"
                                            >
                                              <MoreVertIcon />
                                            </IconButton>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="d-flex gap-3">
                                            <div className="bg-sm-box">
                                              <RiFileExcel2Line />
                                            </div>
                                            <div>
                                              <h6>website_content.exl</h6>
                                              <span className="primarylight-color">
                                                2,635 KB
                                              </span>
                                            </div>
                                          </div>
                                          <div className="d-flex gap-3">
                                            <div className="image">
                                              <img src={avatar_img} alt="" />
                                            </div>
                                            <IconButton
                                              aria-label="more"
                                              id="long-button"
                                              aria-controls={
                                                open ? "long-menu" : undefined
                                              }
                                              aria-expanded={
                                                open ? "true" : undefined
                                              }
                                              aria-haspopup="true"
                                              onClick={handleClick}
                                              className="icon-btn"
                                            >
                                              <MoreVertIcon />
                                            </IconButton>
                                          </div>
                                        </li>
                                      </ul>
                                    </AccordionDetails>
                                  </Accordion>
                                  <div className="separator separator-light"></div>
                                  <Accordion defaultExpanded>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panela-content"
                                      id="panela-header"
                                    >
                                      <h5 className="primarysky-color">
                                        23 April
                                      </h5>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <ul className="sh-files">
                                        <li>
                                          <div className="d-flex gap-3">
                                            <div className="bg-sm-box">
                                              <RiFileExcel2Line />
                                            </div>
                                            <div>
                                              <h6>website_content.exl</h6>
                                              <span className="primarylight-color">
                                                2,635 KB
                                              </span>
                                            </div>
                                          </div>
                                          <div className="d-flex gap-3">
                                            <div className="image">
                                              <img src={avatar_img} alt="" />
                                            </div>
                                            <IconButton
                                              aria-label="more"
                                              id="long-button"
                                              aria-controls={
                                                open ? "long-menu" : undefined
                                              }
                                              aria-expanded={
                                                open ? "true" : undefined
                                              }
                                              aria-haspopup="true"
                                              onClick={handleClick}
                                              className="icon-btn"
                                            >
                                              <MoreVertIcon />
                                            </IconButton>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="d-flex gap-3">
                                            <div className="bg-sm-box">
                                              <RiFileExcel2Line />
                                            </div>
                                            <div>
                                              <h6>website_content.exl</h6>
                                              <span className="primarylight-color">
                                                2,635 KB
                                              </span>
                                            </div>
                                          </div>
                                          <div className="d-flex gap-3">
                                            <div className="image">
                                              <img src={avatar_img} alt="" />
                                            </div>
                                            <IconButton
                                              aria-label="more"
                                              id="long-button"
                                              aria-controls={
                                                open ? "long-menu" : undefined
                                              }
                                              aria-expanded={
                                                open ? "true" : undefined
                                              }
                                              aria-haspopup="true"
                                              onClick={handleClick}
                                              className="icon-btn"
                                            >
                                              <MoreVertIcon />
                                            </IconButton>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="d-flex gap-3">
                                            <div className="bg-sm-box">
                                              <RiFileExcel2Line />
                                            </div>
                                            <div>
                                              <h6>website_content.exl</h6>
                                              <span className="primarylight-color">
                                                2,635 KB
                                              </span>
                                            </div>
                                          </div>
                                          <div className="d-flex gap-3">
                                            <div className="image">
                                              <img src={avatar_img} alt="" />
                                            </div>
                                            <IconButton
                                              aria-label="more"
                                              id="long-button"
                                              aria-controls={
                                                open ? "long-menu" : undefined
                                              }
                                              aria-expanded={
                                                open ? "true" : undefined
                                              }
                                              aria-haspopup="true"
                                              onClick={handleClick}
                                              className="icon-btn"
                                            >
                                              <MoreVertIcon />
                                            </IconButton>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="d-flex gap-3">
                                            <div className="bg-sm-box">
                                              <RiFileExcel2Line />
                                            </div>
                                            <div>
                                              <h6>website_content.exl</h6>
                                              <span className="primarylight-color">
                                                2,635 KB
                                              </span>
                                            </div>
                                          </div>
                                          <div className="d-flex gap-3">
                                            <div className="image">
                                              <img src={avatar_img} alt="" />
                                            </div>
                                            <IconButton
                                              aria-label="more"
                                              id="long-button"
                                              aria-controls={
                                                open ? "long-menu" : undefined
                                              }
                                              aria-expanded={
                                                open ? "true" : undefined
                                              }
                                              aria-haspopup="true"
                                              onClick={handleClick}
                                              className="icon-btn"
                                            >
                                              <MoreVertIcon />
                                            </IconButton>
                                          </div>
                                        </li>
                                      </ul>
                                    </AccordionDetails>
                                  </Accordion>
                                </div>
                              </div>
                            </TabPanel>
                            <TabPanel value={3}>
                              <div className="tab-content">
                                <h5>Latest activity</h5>
                                <div className="tab-section">
                                  <Accordion defaultExpanded>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panela-content"
                                      id="panela-header"
                                    >
                                      <h5 className="primarysky-color">
                                        Today
                                      </h5>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <ul className="sh-files">
                                        <li>
                                          <div className="d-flex gap-3">
                                            <div className="image">
                                              <img src={avatar_img} alt="" />
                                            </div>
                                            <div>
                                              <p className="primarylight-color">
                                                <span className="text-dark">
                                                  Hencework
                                                </span>{" "}
                                                on Documentation link is working
                                                now -{" "}
                                                <Link className="link-url">
                                                  <u>
                                                    ttps://hencework.com/theme/jampa
                                                  </u>
                                                </Link>
                                              </p>
                                              <span className="primarylight-color">
                                                Oct 15, 2021, 12:34 PM
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="d-flex gap-3">
                                            <div className="image">
                                              <img src={avatar_img} alt="" />
                                            </div>
                                            <div>
                                              <p className="primarylight-color">
                                                <span className="text-dark">
                                                  Morgan Fregman
                                                </span>{" "}
                                                completed react conversion of{" "}
                                                <Link className="link-url">
                                                  <u>components</u>
                                                </Link>
                                              </p>
                                              <span className="primarylight-color">
                                                Sep 16, 2021, 4:54 PM
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="d-flex gap-3">
                                            <div className="image">
                                              <img src={avatar_img} alt="" />
                                            </div>
                                            <div>
                                              <p className="primarylight-color">
                                                <span className="text-dark">
                                                  Jimmy Carry
                                                </span>{" "}
                                                completed side bar menu on{" "}
                                                <Link className="link-url">
                                                  <u>elements</u>
                                                </Link>
                                              </p>
                                              <span className="primarylight-color">
                                                Sep 10, 2021, 10:13 AM
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="d-flex gap-3">
                                            <div className="image">
                                              <img src={avatar_img} alt="" />
                                            </div>
                                            <div>
                                              <p className="primarylight-color">
                                                <span className="text-dark">
                                                  Charlie Chaplin
                                                </span>{" "}
                                                deleted empty cards on{" "}
                                                <Link className="link-url">
                                                  <u>completed</u>
                                                </Link>
                                              </p>
                                              <span className="primarylight-color">
                                                Sep 10, 2021, 10:13 AM
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </AccordionDetails>
                                  </Accordion>
                                  <div className="separator separator-light"></div>
                                  <Accordion defaultExpanded>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panela-content"
                                      id="panela-header"
                                    >
                                      <h5 className="primarysky-color">
                                        Yesterday
                                      </h5>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <ul className="sh-files">
                                        <li>
                                          <div className="d-flex gap-3">
                                            <div className="image">
                                              <img src={avatar_img} alt="" />
                                            </div>
                                            <div>
                                              <p className="primarylight-color">
                                                <span className="text-dark">
                                                  Hencework
                                                </span>{" "}
                                                on Documentation link is working
                                                now -{" "}
                                                <Link className="link-url">
                                                  <u>
                                                    ttps://hencework.com/theme/jampa
                                                  </u>
                                                </Link>
                                              </p>
                                              <span className="primarylight-color">
                                                Oct 15, 2021, 12:34 PM
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="d-flex gap-3">
                                            <div className="image">
                                              <img src={avatar_img} alt="" />
                                            </div>
                                            <div>
                                              <p className="primarylight-color">
                                                <span className="text-dark">
                                                  Morgan Fregman
                                                </span>{" "}
                                                completed react conversion of{" "}
                                                <Link className="link-url">
                                                  <u>components</u>
                                                </Link>
                                              </p>
                                              <span className="primarylight-color">
                                                Sep 16, 2021, 4:54 PM
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="d-flex gap-3">
                                            <div className="image">
                                              <img src={avatar_img} alt="" />
                                            </div>
                                            <div>
                                              <p className="primarylight-color">
                                                <span className="text-dark">
                                                  Jimmy Carry
                                                </span>{" "}
                                                completed side bar menu on{" "}
                                                <Link className="link-url">
                                                  <u>elements</u>
                                                </Link>
                                              </p>
                                              <span className="primarylight-color">
                                                Sep 10, 2021, 10:13 AM
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="d-flex gap-3">
                                            <div className="image">
                                              <img src={avatar_img} alt="" />
                                            </div>
                                            <div>
                                              <p className="primarylight-color">
                                                <span className="text-dark">
                                                  Charlie Chaplin
                                                </span>{" "}
                                                deleted empty cards on{" "}
                                                <Link className="link-url">
                                                  <u>completed</u>
                                                </Link>
                                              </p>
                                              <span className="primarylight-color">
                                                Sep 10, 2021, 10:13 AM
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </AccordionDetails>
                                  </Accordion>
                                </div>
                              </div>
                            </TabPanel>
                          </Tabs>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem>
          <Link
            className="dropdown-link"
            onClick={() => setOpenCloseSide(!openCloseSide)}
          >
            {" "}
            Edit Task
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            className="dropdown-link"
            onClick={() => setOpenCloseSide(!openCloseSide)}
          >
            {" "}
            Edit View
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to={``} className="dropdown-link">
            Edit Delete{" "}
          </Link>
        </MenuItem>
      </Menu>
      <Menu
        id="task-menu"
        MenuListProps={{
          "aria-labelledby": "task-button",
        }}
        anchorEl={anchorEl1}
        open={openAction}
        onClose={handleActionClose}
        className="task-menu"
      >
        <MenuItem className="primarylight-color">Action</MenuItem>
        <MenuItem>
          <Link to={``} className="dropdown-link">
            <BiSolidEdit /> Assign to
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to={``} className="dropdown-link">
            <BiUser /> Attach files
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to={``} className="dropdown-link">
            <TbPaperclip /> Apply Labels{" "}
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to={``} className="dropdown-link">
            <GoTag /> Set Due Date
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to={``} className="dropdown-link">
            <FiCalendar /> Follow Task
          </Link>
        </MenuItem>
        <Divider />
        <MenuItem>
          <Link to={``} className="dropdown-link">
            <IoBookmarkOutline /> Set as Top Priority{" "}
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to={``} className="dropdown-link">
            <LuRepeat /> Change Status{" "}
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to={``} className="dropdown-link">
            {" "}
            <CgPocket /> Save as Template{" "}
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to={``} className="dropdown-link">
            <FiArchive /> Move to archive{" "}
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to={``} className="dropdown-link">
            <MdDeleteOutline /> Delete{" "}
          </Link>
        </MenuItem>
      </Menu>
    </>
  );
}

export default TaskList;
