import React, { Suspense, lazy } from "react";
import "./index.scss";
import { CircularProgress } from "@mui/material";

const WebMenu = lazy(() => import("../../../Shared/WebMenu"));
const WebFooter = lazy(() => import("../../../Shared/WebFooter"));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function PrivacyPolicy() {
  return (
    <>
      <Suspense fallback={renderLoader()}>
        <WebMenu />
        <div className="banner-img">
          <img
            src={"/assets/images/Privacy-Policy.png"}
            className="img-fluid"
            alt=""
          />
        </div>
        <section className="privacy_policy_top">
          <div className="container">
            <div className="row">
              <div className="col-md-12 hed">
                <p>
                  Protecting your private information is our priority. This
                  Statement of Privacy applies to FIG K12, and Swan Solutions,
                  LLC and governs data collection and usage. For the purposes of
                  this Privacy Policy, unless otherwise noted, all references to
                  Swan Solutions, LLC include https://figk-12.com and FIG K-12.
                  The FIG K-12 application is a professional development
                  application. By using the FIG K-12 application, you consent to
                  the data practices described in this statement.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="privacy_policy point1">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-6 col-md-12 col-sm-12 m-auto left_sec">
                <div className="title_text">
                  <h1>1 - Collection Of Your Personal Information</h1>
                </div>
                <p>
                  In order to better provide you with products and services
                  offered, FIG K-12 may collect personally identifiable
                  information, such as your:
                </p>
                <ul className="ul_list">
                  <li className="description">First and Last Name</li>
                  <li className="description">Mailing Address</li>
                  <li className="description">E-mail Address</li>
                  <li className="description">Phone Number</li>
                  <li className="description">Employer</li>
                  <li className="description">Job Title</li>
                </ul>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 right_sec">
                <div className="img_box">
                  <img
                    src={"/assets/images/point1.png"}
                    className="img-fluid"
                    alt="about"
                  />
                </div>
              </div>
              <div className="col-md-12 center_sec">
                <p>
                  Please keep in mind that if you directly disclose personally
                  identifiable information or personally sensitive data through
                  FIG K-12&apos;s public message boards, this information may be
                  collected and used by others.
                </p>
                <p>
                  We do not collect any personal information about you unless
                  you voluntarily provide it to us. However, you may be required
                  to provide certain personal information to us when you elect
                  to use certain products or services. These may include: (a)
                  registering for an account; (b) entering a sweepstakes or
                  contest sponsored by us or one of our partners; (c) signing up
                  for special offers from selected third parties; (d) sending us
                  an email message; (e) submitting your credit card or other
                  payment information when ordering and purchasing products and
                  services. To wit, we will use your information for, but not
                  limited to, communicating with you in relation to services
                  and/or products you have requested from us. We also may gather
                  additional personal or non-personal information in the future.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="privacy_policy point2">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 left_sec">
                <div className="img_box">
                  <img
                    src={"/assets/images/point2.png"}
                    className="img-fluid"
                    alt="about"
                  />
                </div>
              </div>
              <div className="col-xl-8 col-lg-6 col-md-12 col-sm-12 m-auto right_sec">
                <div className="title_text">
                  <h1>2 - Use Of Your Personal Information</h1>
                </div>
                <p>
                  FIG K-12 collects and uses your personal information to
                  operate and deliver the services you have requested.
                </p>
                <p>
                  FIG K-12 may also use your personally identifiable information
                  to inform you of other products or services available from FIG
                  K-12 and its affiliates.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="privacy_policy point3">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-6 col-md-12 col-sm-12 m-auto left_sec">
                <div className="title_text">
                  <h1>3 - Sharing Information With Third Parties</h1>
                </div>
                <p>
                  FIG K-12 does not sell, rent or lease its customer lists to
                  third parties.
                </p>
                <p>
                  FIG K-12 may share data with trusted partners to help perform
                  statistical analysis, send you email or postal mail, provide
                  customer support, or arrange for deliveries. All such third
                  parties are prohibited from using your personal information
                  except to provide these services to FIG K-12, and they are
                  required to maintain the confidentiality of your information.
                </p>
                <p>
                  FIG K-12 may disclose your personal information, without
                  notice, if required to do so by law or in the good faith
                  belief that such action is necessary to: (a) conform to the
                  edicts of the law or comply with legal process served on FIG
                  K-12 or the site; (b) protect and defend the rights or
                  property of FIG K-12; and/or (c) act under exigent
                  circumstances to protect the personal safety of users of FIG
                  K-12, or the public.
                </p>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 right_sec">
                <div className="img_box">
                  <img
                    src={"/assets/images/point3.png"}
                    className="img-fluid"
                    alt="about"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="privacy_policy point4">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 left_sec">
                <div className="img_box">
                  <img
                    src={"/assets/images/point4.png"}
                    className="img-fluid"
                    alt="about"
                  />
                </div>
              </div>
              <div className="col-xl-8 col-lg-6 col-md-12 col-sm-12 m-auto right_sec">
                <div className="title_text">
                  <h1>4 - Security Of Your Personal Information</h1>
                </div>
                <p>
                  FIG K-12 secures your personal information from unauthorized
                  access, use, or disclosure. FIG K-12 uses the following
                  methods for this purpose:
                </p>
                <ul className="ul_list">
                  <li className="description">SSL Protocol</li>
                </ul>
                <p>
                  When personal information (such as a credit card number) is
                  transmitted to other websites, it is protected through the use
                  of encryption, such as the Secure Sockets Layer (SSL)
                  protocol.
                </p>
              </div>
              <div className="col-md-12 center_sec">
                <p>
                  We strive to take appropriate security measures to protect
                  against unauthorized access to or alteration of your personal
                  information. Unfortunately, no data transmission over the
                  Internet or any wireless network can be guaranteed to be 100%
                  secure. As a result, while we strive to protect your personal
                  information, you acknowledge that: (a) there are security and
                  privacy limitations inherent to the Internet which are beyond
                  our control; and (b) security, integrity, and privacy of any
                  and all information and data exchanged between you and us
                  through this Site cannot be guaranteed.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="privacy_policy point5">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-6 col-md-12 col-sm-12 m-auto left_sec">
                <div className="title_text">
                  <h1>5 - Right To Deletion</h1>
                </div>
                <p>
                  Subject to certain exceptions set out below, on receipt of a
                  verifiable request from you, we will:
                </p>
                <ul className="ul_list">
                  <li className="description">
                    Delete your personal information from our records
                  </li>
                  <li className="description">
                    Direct any service providers to delete your personal
                    information from their records.
                  </li>
                </ul>
                <p>
                  Please note that we may not be able to comply with requests to
                  delete your personal information if it is necessary to:
                </p>
                <ul className="ul_list mb-0">
                  <li className="description">
                    Complete the transaction for which the personal information
                    was collected, fulfill theterms of a written warranty or
                    product recall conducted in accordance with federallaw,
                    provide a good or service requested by you, or reasonably
                    anticipated within thecontext of our ongoing business
                    relationship with you, or otherwise perform a
                    contractbetween you and us.
                  </li>
                </ul>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 right_sec">
                <div className="img_box">
                  <img
                    src={"/assets/images/point5.png"}
                    className="img-fluid"
                    alt="about"
                  />
                </div>
              </div>
              <div className="col-md-12 center_sec">
                <ul className="ul_list">
                  <li className="description">
                    Detect security incidents, protect against malicious,
                    deceptive, fraudulent, or illegalactivity, or prosecute
                    those responsible for that activity.
                  </li>
                  <li className="description">
                    Debug to identify and repair errors that impair existing
                    intended functionality.
                  </li>
                  <li className="description">
                    Exercise free speech, ensure the right of another consumer
                    to exercise his or her right of free speech, or exercise
                    another right provided for by law.
                  </li>
                  <li className="description">
                    Comply with the California Electronic Communications Privacy
                    Act.
                  </li>
                  <li className="description">
                    Engage in public or peer-reviewed scientific, historical, or
                    statistical research in thepublic interest that adheres to
                    all other applicable ethics and privacy laws, when
                    ourdeletion of the information is likely to render
                    impossible or seriously impair theachievement of such
                    research, provided we have obtained your informed consent.
                  </li>
                  <li className="description">
                    Enable solely internal uses that are reasonably aligned with
                    your expectations based on your relationship with us.
                  </li>
                  <li className="description">
                    Comply with an existing legal obligation.
                  </li>
                  <li className="description">
                    Otherwise use your personal information, internally, in a
                    lawful manner that iscompatible with the context in which
                    you provided the information.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="privacy_policy point6">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 left_sec">
                <div className="img_box">
                  <img
                    src={"/assets/images/point6.png"}
                    className="img-fluid"
                    alt="about"
                  />
                </div>
              </div>
              <div className="col-xl-8 col-lg-6 col-md-12 col-sm-12 m-auto right_sec">
                <div className="title_text">
                  <h1>6 - Children Under Thirteen</h1>
                </div>
                <p>
                  FIG K-12 does not knowingly collect personally identifiable
                  information from children under the age of thirteen. If you
                  are under the age of thirteen, you must ask your parent or
                  guardian for permission to use this application.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="privacy_policy point7">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-6 col-md-12 col-sm-12 m-auto left_sec">
                <div className="title_text">
                  <h1>7 - E-Mail Communications</h1>
                </div>
                <p>
                  From time to time, FIG K-12 may contact you via email for the
                  purpose of providingannouncements, promotional offers, alerts,
                  confirmations, surveys, and/or other generalcommunication. In
                  order to improve our Services, we may receive a notification
                  when you open anemail from FIG K-12 or click on a link
                  therein.
                </p>
                <p>
                  If you would like to stop receiving marketing or promotional
                  communications via email from FIG K-12, you may opt out of
                  such communications by clicking the UNSUBSCRIBE button.
                </p>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 right_sec">
                <div className="img_box">
                  <img
                    src={"/assets/images/point7.png"}
                    className="img-fluid"
                    alt="about"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="privacy_policy point8">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 m-auto left_sec">
                <div className="title_text">
                  <h1>8 - External Data Storage Sites</h1>
                </div>
                <p>
                  We may store your data on servers provided by third party
                  hosting vendors with whom we have contracted.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="privacy_policy point9">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 left_sec">
                <div className="img_box">
                  <img
                    src={"/assets/images/point9.png"}
                    className="img-fluid"
                    alt="about"
                  />
                </div>
              </div>
              <div className="col-xl-8 col-lg-6 col-md-12 col-sm-12 m-auto right_sec">
                <div className="title_text">
                  <h1>9 - Changes To This Statement</h1>
                </div>
                <p>
                  FIG K-12 reserves the right to change this Privacy Policy from
                  time to time. We will notify you about significant changes in
                  the way we treat personal information by sending a notice to
                  the primary email address specified in your account, by
                  placing a prominent notice on our application, and/or by
                  updating any privacy information. Your continued use of the
                  application and/or Services available after such modifications
                  will constitute your: (a) acknowledgment of the modified
                  Privacy Policy; and (b) agreement to abide and be bound by
                  that Policy.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="privacy_policy point10">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-6 col-md-12 col-sm-12 m-auto left_sec">
                <div className="title_text">
                  <h1>10 - Contact Information</h1>
                </div>
                <p>
                  FIG K-12 welcomes your questions or comments regarding this
                  Statement of Privacy. If you believe that FIG K-12 has not
                  adhered to this Statement, please contact FIG K-12 at:
                </p>
                <ul className="ul_list">
                  <li className="description">
                    Swan Solutions, LLC, 5189 Stewart Street, Milton, Florida
                    32570
                  </li>
                  <li className="description">becki@swansolutionsllc.com</li>
                  <li className="description">7708539289</li>
                </ul>
                <p>Effective as of April 10, 2023</p>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 right_sec">
                <div className="img_box">
                  <img
                    src={"/assets/icons/point10.svg"}
                    className="img-fluid"
                    alt="about"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <WebFooter />
      </Suspense>
    </>
  );
}

export default PrivacyPolicy;
