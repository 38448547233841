import React from "react";
import { Link } from "react-router-dom";
import "./index.scss";

function Blogbox(props) {
  return (
    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
      <div className="blogitem">
        <div className="blog_img">
          <picture>
            <source media="(min-width:465px)" srcSet={props.image} />
            <img
              loading="lazy"
              width={localStorage.getItem("windowWidth") < 500 ? "509" : "256"}
              height={localStorage.getItem("windowWidth") < 500 ? "296" : "148"}
              src={props.image}
              alt={props.alt}
              title={props.cap}
            />
          </picture>
        </div>
        <div className="blog_content">
          <h3>{props.title}</h3>
          <p>{props.description}</p>
          <Link to={`/blog/${props.to}`}>
            <span className="me-auto">{props.date}</span>
            Read More{" "}
          </Link>
        </div>
      </div>
    </div>
  );
}
export default React.memo(Blogbox);
