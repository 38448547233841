import React, { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Tooltip,
  Typography,
  Button,
} from "@mui/material";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import { Link } from "react-router-dom";
import PaymentTable from "../../../../user/PaymentHistory/PaymentTable";
import { IoReceiptOutline } from "react-icons/io5";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { useForm } from "react-hook-form";
import { ToastError } from "../../../../Components/Toast";
import { customFilter } from "../../../../Components/CustomFilterForReactSelect";
import Select from "react-select";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const headCellsEvent = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "associationName",
    label: "Association Name",
  },
  {
    id: "userName",
    label: "User Name",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "phone",
    label: "Phone",
  },
  {
    id: "paymentAmount",
    label: "Payment Amount",
  },
  {
    id: "paymentStatus",
    label: "Payment Status",
  },
  {
    id: "paymentMode",
    label: "Payment Type",
  },
  {
    id: "orderNumber",
    label: "Order Number",
  },
  {
    id: "paymentReference",
    label: "Payment Reference",
  },
  {
    id: "date",
    label: "Payment Date",
  },
  {
    id: "dueDate",
    label: "Due Date",
  },
];

function AssociationProductPaymentHistory() {
  const [dataOfTable, setDataOfTable] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [filterPage, setFilterPage] = useState(false);
  const [asList, setASList] = useState([]);
  const [asId, setASId] = useState();
  const [paymentStatus, setPaymentStatus] = useState();
  const [paymentType, setPaymentType] = useState();

  const LogDetails = useSelector((state) => state.login.LoginDetails);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    document.title = "FIG Events | Association Product Payment History";
    getAssoList();
    // eslint-disable-next-line
  }, []);

  const getAssoList = async () => {
    let seData = {
      url: `/api/Association/GetAssociationListForFliter?userId=${LogDetails?.userId}`,
    };
    let res = await GetCallApi(seData);
    if (res.status === 200) {
      if (res.data.success) {
        let data = res.data.payload?.map((x) => {
          return {
            ...x,
            value: x?.associationId,
            label: x?.name,
          };
        });
        setASList(data);
      }
    }
  };

  const GetPaymentList = async (data) => {
    setDataOfTable([]);
    setOpenCircle(true);
    let res = await PostCallApi({
      url: `/api/AssociationProductPayment/GetAssociationProductPaymentHistory`,
      body: {
        ...data,
        userId: LogDetails?.userId,
      },
    });
    setOpenCircle(false);
    setFilterPage(!filterPage);
    if (res.status === 200 && res.data.success) {
      setOpenCircle(false);
      let comList = res?.data?.payload?.map((c, i) => {
        return {
          ...c,
          id: i + 1,

          phone: c?.phoneNumber,
          groupUserList:
            c?.productList?.length > 0
              ? c?.productList?.map((x, index) => {
                  return {
                    ...x,
                    id: index + 1,
                  };
                })
              : [],
          date: c?.date
            ? new Date(c?.date).toLocaleDateString("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              })
            : "",
          dueDate:
            c?.paymentMode === "Online"
              ? ""
              : c?.dueDate
              ? new Date(c?.dueDate).toLocaleDateString("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                })
              : "",
          Action: (
            <Tooltip title="View Receipt">
              <Link className="btn_edit" to={`reciept/${c?.paymentId}`}>
                <IoReceiptOutline />
              </Link>
            </Tooltip>
          ),
        };
      });
      setDataOfTable(comList);
    } else {
      ToastError(res.data.message);
    }
  };

  const handleAS = (e) => {
    setASId(e);
    setValue("associationId", e);
  };

  const PaymentStatusOption = [
    { value: "Pending", label: "Pending" },
    { value: "Success", label: "Success" },
  ];

  const handlePaymentStatus = (e) => {
    setPaymentStatus(e);
    setValue("paymentStatus", e);
  };

  const PaymentTypeOption = [
    { value: "Invoice", label: "Invoice" },
    { value: "Online", label: "Online" },
  ];

  const handlePaymentType = (e) => {
    setPaymentType(e);
    setValue("paymentMode", e);
  };

  const handleClearFilter = () => {
    setASId();
    setPaymentStatus();
    setPaymentType();
    setDataOfTable([]);
    reset();
  };

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography style={{ marginLeft: "20px" }}>
                    <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                    <span className="filter_by">Filter By</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <form onSubmit={handleSubmit(GetPaymentList)}>
                    <div className="row" style={{ marginLeft: "20px" }}>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Association</label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={asList}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Association"
                            filterOption={customFilter}
                            value={asList?.filter((obj) => obj.value === asId)}
                            onChange={(e) => handleAS(e?.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Order Number</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Order Number"
                            {...register("orderNumber", {
                              pattern: {
                                value: /^[^\s]+(\s+[^\s]+)*$/,
                                message:
                                  "Starting and Ending Space not allowed",
                              },
                              maxLength: {
                                value: 200,
                                message: "Max 200 characters",
                              },
                            })}
                          />
                          {errors.orderNumber && (
                            <span role="alert" className="error_text">
                              {errors.orderNumber.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">
                            Payment Reference
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Payment Reference"
                            {...register("paymentReference", {
                              pattern: {
                                value: /^[^\s]+(\s+[^\s]+)*$/,
                                message:
                                  "Starting and Ending Space not allowed",
                              },
                              maxLength: {
                                value: 200,
                                message: "Max 200 characters",
                              },
                            })}
                          />
                          {errors.paymentReference && (
                            <span role="alert" className="error_text">
                              {errors.paymentReference.message}
                            </span>
                          )}
                        </div>
                      </div>{" "}
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Payment Status</label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={PaymentStatusOption}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Payment Status"
                            filterOption={customFilter}
                            value={PaymentStatusOption?.filter(
                              (obj) => obj.value === paymentStatus
                            )}
                            onChange={(e) => handlePaymentStatus(e?.value)}
                          />
                        </div>
                      </div>{" "}
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Payment Date</label>
                          <input
                            type="date"
                            className="form-control"
                            {...register("paymentDate", {
                              valueAsDate: true,
                            })}
                          />
                          {errors.paymentDate && (
                            <span role="alert" className="error_text">
                              {errors.paymentDate.message}
                            </span>
                          )}
                        </div>
                      </div>{" "}
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Payment Type</label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={PaymentTypeOption}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Payment Type"
                            filterOption={customFilter}
                            value={PaymentTypeOption?.filter(
                              (obj) => obj.value === paymentType
                            )}
                            onChange={(e) => handlePaymentType(e?.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 d-flex justify-content-start mt-auto">
                        <div className="form-group">
                          <Button
                            className="detail_button ms-3"
                            type="submit"
                            disabled={openCircle ? true : false}
                          >
                            {openCircle ? (
                              <CircularProgress
                                color="inherit"
                                sx={{
                                  color: "#fff",
                                  marginLeft: "0.5rem",
                                  height: "23px !important",
                                  width: "23px !important",
                                }}
                              />
                            ) : (
                              "Apply Filter"
                            )}
                          </Button>
                          <Button
                            className={`back_button ms-3 ${
                              window.innerWidth > 400 ? "" : "mt-3"
                            }`}
                            onClick={() => handleClearFilter()}
                          >
                            Clear Filter
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                </AccordionDetails>
              </Accordion>
              <div className="col-md-12 accordian_card p-0 mb-4">
                <PaymentTable
                  title="Association Product Payment History"
                  action={true}
                  headCells={headCellsEvent}
                  ListData={dataOfTable}
                  dropdown={false}
                  addBtn={false}
                  showUserListInTable={true}
                  fileName={"Association Product Payment History"}
                  filterPage={filterPage}
                  exportDataList={true}
                  innerProduct={true}
                  subHeadCells={[
                    {
                      id: "id",
                      label: "id",
                    },
                    {
                      id: "productName",
                      label: "Product Name",
                    },
                    {
                      id: "quntity",
                      label: "Quantity",
                    },
                    {
                      id: "price",
                      label: "Price",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default AssociationProductPaymentHistory;
