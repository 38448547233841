import React, { Suspense, lazy, useEffect, useState } from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  CircularProgress,
  Typography,
} from "@mui/material";
import NewLIstTable from "../../Components/Reusable/NewLIstTable/index.jsx";
import { GetCallApi } from "../../../Action/Action.jsx";
import { ToastError } from "../../Components/Toast/index.jsx";
import { customFilter } from "../../Components/CustomFilterForReactSelect/index.jsx";
import Select from "react-select";
import { GridExpandMoreIcon } from "@mui/x-data-grid";

const headCellsBlock = [
  { id: "id", label: "#" },
  { id: "postType", label: "Post Type" },
  { id: "PostCUN", label: "Post By" },
  { id: "RepoUN", label: "Report User Name" },
  { id: "RepoURCAt", label: "Report Reason Category" },
  { id: "RepoUR", label: "Report Reason", widthFix: 600 },
  { id: "deletePostReason", label: "Delete Post Reason", widthFix: 400 },
];
const PostDeletePop = lazy(() => import("./PostDeletePop.jsx"));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function BlockReportList() {
  const [dataOfTable1, setDataOfTable1] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [blCatList, setBlCatList] = useState([]);
  const [blCat, setBlCat] = useState();
  const [filterPage, setFilterPage] = useState(false);

  let deleteInfo = useSelector((state) => state.Common?.DeleteData);

  useEffect(() => {
    document.title = "FIG Events | Block & Report";
    GetAllBlockCategoryList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    GetBlockList();
    // eslint-disable-next-line
  }, [deleteInfo, blCat]);

  const GetAllBlockCategoryList = async () => {
    let seData = {
      url: `/api/BlockPostCategory/GetAllBlockPostCategoryList`,
    };
    let res = await GetCallApi(seData);

    if (res.status === 200 && res.data.success) {
      let comList = res?.data?.payload
        ?.filter((x) => x?.isActive === true)
        ?.map((c, i) => {
          return {
            id: i + 1,
            ...c,
            value: c?.blockPostCategoryName,
            label: c?.blockPostCategoryName,
          };
        });
      setBlCatList(comList);
    }
  };

  const GetBlockList = async () => {
    setOpenCircle(true);
    let res = await GetCallApi({
      url: `/api/BlockReportPost/GetBlockReportPostList?blockCategories=${
        blCat ? blCat : ""
      }`,
    });
    setOpenCircle(false);
    setFilterPage(!filterPage);

    if (res.status === 200 && res.data.success) {
      setOpenCircle(false);
      let comList = res?.data?.payload?.map((item, i) => {
        return {
          id: i + 1,
          ...item,

          PostCUN:
            item?.communityPostCreatedBy === "" ||
            item?.communityPostCreatedBy === null
              ? ""
              : item?.communityPostCreatedBy,

          RepoUN:
            item?.requestedByUser === "" || item?.requestedByUser === null
              ? ""
              : item?.requestedByUser,
          RepoURCAt:
            item?.blockCategories === "" || item?.blockCategories === null
              ? ""
              : item.blockCategories,
          RepoUR:
            item?.blockingReason === "" || item?.blockingReason === null
              ? ""
              : item?.blockingReason,
          Action: item?.isDeleted ? (
            "Deleted Already"
          ) : (
            <PostDeletePop
              text={item?.communityPostCreatedBy}
              postBlockReportId={item?.postBlockReportId}
            />
          ),
        };
      });
      setDataOfTable1(comList);
    } else {
      ToastError(res?.data?.message);
    }
  };

  const handleBlockCategory = (e) => {
    setBlCat(e);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography style={{ marginLeft: "20px" }}>
                    <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                    <span className="filter_by">Filter By</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className="d-flex flex-wrap gap-4 mb-3"
                    style={{ marginLeft: "20px" }}
                  >
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="form-label">Block Category</label>
                        <Select
                          isMulti={false}
                          name="colors"
                          options={blCatList}
                          isClearable={true}
                          className="dropdown-single-select"
                          classNamePrefix="select"
                          placeholder="Select Block Category"
                          filterOption={customFilter}
                          value={blCatList?.filter(
                            (obj) => obj.value === blCat
                          )}
                          onChange={(e) => handleBlockCategory(e?.value)}
                        />
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>

              <div className="col-md-12 accordian_card p-0 mb-4">
                <NewLIstTable
                  headCells={headCellsBlock}
                  title="Block & Report List"
                  action={true}
                  ListData={dataOfTable1}
                  filterPage={filterPage}
                />
              </div>
            </div>
            {/* <div className="card mb-3">
              <div className="card-body">
              </div>
            </div> */}
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default BlockReportList;
