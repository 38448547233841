import React from "react";
import "./index.scss";
import Sidebar from "./sidebar";
import { FaArrowRightLong } from "react-icons/fa6";

import ShowFloorBox from "./ShowFloorBox";
import Navigation from "../../Shared/Navigation";

function ConferenceUserFloorPlan(props) {
  const { companyData, handleNext, setCompanyData, hideNav } = props;
  const [sboothId, setSboothId] = React.useState();

  return (
    <>
      {hideNav ? "" : <Navigation />}
      <section className="floor_plan_view_page">
        <Sidebar
          content=".floor_plan_view_page"
          behaviour={"hidden"}
          position={"left"}
          setSboothId={setSboothId}
          sboothId={sboothId}
        />

        <div className={`open_btn ${hideNav ? "top" : ""}`}>
          <button
            className="my-auto tg_btn"
            onClick={() => {
              Sidebar.toggle(".floor_plan_view_page");
            }}
          >
            <FaArrowRightLong fontSize={20} />
          </button>
        </div>
        <ShowFloorBox
          companyData={companyData}
          setCompanyData={setCompanyData}
          handleNext={handleNext}
          sboothId={sboothId}
          setSboothId={setSboothId}
        />
      </section>
    </>
  );
}

export default ConferenceUserFloorPlan;
