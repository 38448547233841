import React, { useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import "./AccessManagement.scss";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Axios from "../../../../Utils/AxiosConfig";
import { ToastContainer, toast } from "react-toastify";
import CheckboxTree from "react-checkbox-tree";
import Select from "react-select";
import { customFilter } from "../../../Components/CustomFilterForReactSelect";

function AccesssManagement() {
  const [checkBoxVal, setCheckBoxVal] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [roleList, setRoleList] = useState();
  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  const [open, setOpen] = useState(false);
  const [derror, setDerror] = useState();
  const [mlist, setMlist] = useState([]);

  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const LogUserId = useSelector((state) => state.login.LoginDetails.userId);
  const { handleSubmit, setValue, reset } = useForm();
  // const { fields } = useFieldArray({
  //   control,
  //   name: "check",
  // });

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const handleRole = (e) => {
    setRoleName(e);
    setDerror({ rol: "" });
  };
  useEffect(() => {
    if (roleName) {
      reset({ check: [] });
      GetMenuList(roleName);
    } else {
      handlecancel();
    }
    // eslint-disable-next-line
  }, [roleName]);
  const onSubmit = () => {
    if (roleName) {
      let ch = mlist?.filter((x) => x?.children?.length > 0);

      let meSeList = [];

      ch?.forEach((x) => {
        const filteredChildren = x.children?.filter(
          (c) => checked?.includes(c?.value?.toString()) ?? false // Use nullish coalescing
        );

        if (filteredChildren?.length > 0) {
          meSeList.push({
            ...x,
            children: filteredChildren,
          });
        }
      });

      meSeList = [
        ...(meSeList?.map((x) => x?.menuId) ?? []),
        ...(checked?.map((x) => parseInt(x)) ?? []),
      ];
      // return false;
      if (meSeList?.length >= 1) {
        const acc = {
          menuId: meSeList,
          // menuId: Menu?.map((m) => m?.menuid),
          roleId: parseInt(roleName),
          createdBy: LogUserId,
        };
        setOpen(true);
        let url = `/api/RoleMenuAllocation/CreateRoleMenuAllocation`;

        Axios.post(url, acc).then((response) => {
          if (response?.status === 200) {
            if (response.data.success) {
              setOpen(false);
              toast.success(response.data.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
              handlecancel();
            } else {
              setOpen(false);
              setCheckBoxVal(false);
            }
          }
        });
        setDerror({ rol: "" });
      } else {
        setDerror({ rol: "Select minimun one menu" });
      }
    } else {
      setDerror({ rol: "Please Select Role" });
    }
  };
  useEffect(() => {
    getRoleList();
    document.title = "FIG Events | Menu Access";

    // eslint-disable-next-line
  }, []);
  const getRoleList = () => {
    let url = `/api/Role/RoleList?userId=${LogUserId}`;
    Axios.get(url, { headers })
      .then((response) => {
        if (response.data.success) {
          const data = response?.data.payload?.map((item) => {
            return {
              RoleId: item?.roleId,
              RoleName: item?.roleName,
              value: item?.roleId,
              label: item?.roleName,
            };
          });
          setRoleList(data);
          setCheckBoxVal(checkBoxVal);
        }
      })
      .catch((error) => console.log(error));
  };

  const GetMenuList = (e) => {
    setOpen(true);
    let url = `/api/RoleMenuAllocation/GetAllRoleMenuAllocationData?roleId=${e}`;
    Axios.get(url, { headers })
      .then((response) => {
        if (response?.status === 200) {
          if (response.data.success) {
            setCheckBoxVal(false);
            setOpen(false);
            const data = response?.data.payload?.map((item) => {
              return {
                menuid: item?.menuId,
                menuName: item?.menuName,
                ParentMenuName: item?.parentMenuName,
                menuType: item?.menuType === 0 ? "Main Menu" : "Sub Menu",
                url: item?.url,
                description: item?.menuDescription,
                isAssigned: item?.isAssigned,
              };
            });
            setValue("check", data);
            let allMainCh = true;

            data?.map((as) => {
              if (as?.isAssigned === false) {
                return (allMainCh = false);
              }
              return 0;
            });
            if (data?.length > 0) {
              if (allMainCh) {
                setCheckBoxVal(true);
              }
            }
            let nr = response?.data.payload
              ?.filter((x) => x?.parentMenuId === 0)
              ?.map((me) => {
                let child = response?.data.payload
                  ?.filter((d) => d?.parentMenuId === me?.menuId)
                  ?.map((c) => {
                    return {
                      ...c,
                      value: c?.menuId,
                      label: c?.menuName + ` (${me?.menuDescription})`,
                    };
                  });
                if (child?.length > 0) {
                  return {
                    ...me,
                    value: me?.menuId,
                    label: me?.menuName + ` (${me?.menuDescription})`,
                    children: child,
                  };
                }
                return {
                  ...me,
                  value: me?.menuId,
                  label: me?.menuName + ` (${me?.menuDescription})`,
                };
              });
            setMlist(nr);
            let ch = nr
              ?.filter((x) => x?.children?.length > 0)
              ?.map((c) => c?.children?.filter((a) => a?.isAssigned === true));
            ch = ch
              ?.filter((x) => x?.length > 0)
              ?.map((c) => c?.map((v) => v?.menuId));
            ch = ch.toString()?.split(",");
            let pr = nr
              ?.filter((x) => x?.children === undefined)
              ?.filter((x) => x?.isAssigned === true)
              ?.map((x) => x?.menuId);
            pr = pr.toString()?.split(",");
            let sendArr = [...ch, ...pr];

            setChecked(sendArr);
          } else {
            setOpen(false);
          }
        } else {
          setOpen(false);
        }
      })
      .catch((e) => {
        setOpen(false);
        console.log(e);
      });
  };
  const handlecancel = () => {
    setRoleName("");
    setCheckBoxVal(false);
    reset({ check: [] });
    setMlist([]);
    setChecked([]);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="menu_access py-4">
        <div className="main_wrapper">
          <div className="form_box">
            <div className="col-md-12">
              <div className="title mb-3">Menu Access</div>
            </div>
            <div className=" access_pannel mt-3">
              <div className="row new_card mb-4">
                <div className="col-md-6">
                  <div
                    className={`form-group col-lg-10 col-md-12 access_role_select_box ${
                      roleName ? "" : "pb-5"
                    }`}
                  >
                    <label className="form-label">
                      Select Role Name
                      <span className="red-color"> *</span>
                    </label>

                    <Select
                      isMulti={false}
                      name="colors"
                      options={roleList}
                      isClearable={true}
                      className="dropdown-single-select"
                      classNamePrefix="select"
                      placeholder="Select Role"
                      filterOption={customFilter}
                      value={roleList?.filter((obj) => obj.value === roleName)}
                      onChange={(e) => handleRole(e?.value)}
                    />
                    {/* <select
                      className={`form-select  ${roleName ? "" : "add"}`}
                      aria-label="Default select example"
                      value={roleName}
                      onChange={(e) => handleRole(e.target.value)}
                    >
                      <option value=""> Select Role Name</option>
                      {roleList &&
                        roleList?.map((data, i) => (
                          <option key={i} value={data?.RoleId}>
                            {data?.RoleName}
                          </option>
                        ))}
                    </select> */}
                    {derror?.rol && (
                      <span role="alert" className="error_text">
                        {derror?.rol}
                      </span>
                    )}
                  </div>
                </div>
                <div className="add_family">
                  <CheckboxTree
                    nodes={mlist}
                    checked={checked}
                    expanded={expanded}
                    onCheck={(checked) => setChecked(checked)}
                    onExpand={(expanded) => setExpanded(expanded)}
                  />
                </div>
                <div className="form-group col-md-12 d-flex mt-5 px-3 ">
                  <Button
                    className="detail_button me-3"
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Submit
                  </Button>
                  <Button className="back_button" onClick={handlecancel}>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default React.memo(AccesssManagement);
