import React, { useEffect, useState } from "react";
import "./index.scss";
import { useForm } from "react-hook-form";
import {
  Backdrop,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { GetCallApi, PostCallApi } from "../../../../../../Action/Action";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import EditAssociationUserPayment from "./EditPayment";
import { AttendeeRegistrationConference } from "../../../../../../reducers/Common";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { customFilter } from "../../../../../Components/CustomFilterForReactSelect";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F6FC",
  },
  padding: "5px",
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f6f6f6",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F1F6FC",
  },
  // hide last border
  //   "&:last-child td, &:last-child th": {
  //     border: 0,
  //   },
}));

function EditAssociationRegisterPay() {
  const { id, invoiceId } = useParams();
  const [dataOfAssociation, setDataOfAssociation] = useState();
  const [asList, setAsList] = useState([]);

  const [subtotal, setSubTotal] = useState("");

  const [invoiceData, setInvoiceData] = useState();
  const [paymentD, setPaymentD] = useState();
  const [payDetail, setPayDetail] = useState();
  const [openCircle, setOpenCircle] = useState(false);
  const [conPrice, setConPrice] = useState();
  const [associationMembersId, setAssociationMembersId] = useState();
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  let dispatch = useDispatch();
  let nav = useNavigate();

  useEffect(() => {
    if (LogDetail) {
      setValue("FirstName", LogDetail?.firstName);
      setValue("LastName", LogDetail?.lastName);
      setValue("email", LogDetail?.email);
      setValue("phone", LogDetail?.phone);
    }
    getAssoData();
    GetAssociationMemberList();
    // eslint-disable-next-line
  }, []);

  const getAssoData = async () => {
    let seData = {
      url: `/api/Association/GetAssociationByAssociationId?associationId=${id}`,
      headers: headers,
    };

    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        let useDe = res.data.payload;
        setDataOfAssociation(useDe);
        setValue("associationId", id);
        setValue("name", useDe?.name);
        setValue("PaymentRemittanceAddress", useDe?.paymentRemittanceAddress);
        setValue("PaymentCity", useDe?.paymentCity);
        setValue("PaymentState", useDe?.paymentState);
        setValue("PaymentZip", useDe?.paymentZip);
      }
    }
  };

  useEffect(() => {
    if (dataOfAssociation) {
      if (invoiceId) {
        getInvoiceData(invoiceId);
      }
    }
    // eslint-disable-next-line
  }, [invoiceId, dataOfAssociation]);
  const getInvoiceData = async (ind) => {
    let res = await GetCallApi({
      url: `/api/AssociationPayment/GetAssociationInvoiceDataByAssociationInvoiceId?associationInvoiceId=${ind}`,
    });
    if (res.status === 200) {
      if (res.data.success) {
        let useDe = res.data.payload?.ai;
        let asData = res.data.payload;
        setInvoiceData(useDe);
        setValue(
          "associationMembershipsTypesId",
          useDe?.associationMembershipsTypesId
        );
        setValue("associationInvoiceId", useDe?.associationInvoiceId);
        setValue("associationMemberId", asData?.associationMemberId);
        setAssociationMembersId(asData?.associationMemberId);
        setValue("companyName", asData?.companyName);
        if (useDe?.associationMembershipsTypesId) {
          let mt = dataOfAssociation?.associationMembershipsTypes
            ?.map((x, i) => {
              if (
                x?.associationMembershipsTypesId ===
                useDe?.associationMembershipsTypesId
              ) {
                return i + 1;
              }
              return null;
            })
            ?.filter((x) => x !== null)[0];
          setValue("orType", mt.toString());
          handleChnagePrice(mt);
        }
      }
    }
  };

  const GetAssociationMemberList = async () => {
    let seData = {
      url: `/api/AssociationMembers/GetAllAssociationMembersList?userId=${LogDetail?.userId}`,
      headers: headers,
    };

    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        let asm = res.data.payload
          ?.filter((y) => y?.associationId === id)
          ?.map((x) => {
            let ty = x?.type ? " - " + x?.type : "";
            let paid = x?.isUserPayForCompany ? " (Already Paid)" : "";
            return {
              ...x,
              value: x?.associationMembersId,
              label: x?.name + ty + paid,
            };
          });
        // asm = [asm[0]];
        setAsList(asm);
        if (asm?.length === 1) {
          // setAscId(asm[0]?.associationMembersId);
        }
      }
    }
  };

  const onSubmit = async (data) => {
    let is_success = true;

    if (!data?.orType) {
      is_success = false;
      Swal.fire({
        position: "center",
        width: "50%",
        title: "Member type is required",
        confirmButtonText: "Close",
        confirmButtonColor: "#E64942",
      });
    }
    if (is_success) {
      let sed = {
        ...dataOfAssociation,
        ...data,
        subtotal: subtotal,
        organizationOrAttendeeType:
          dataOfAssociation?.associationMembershipsTypes?.filter(
            (x, i) => i + 1 === parseInt(data?.orType)
          )[0]?.name,
        associationMembershipsTypesId:
          dataOfAssociation?.associationMembershipsTypes?.filter(
            (x, i) => i + 1 === parseInt(data?.orType)
          )[0]?.associationMembershipsTypesId,
        amount: conPrice,
        totalPrice: subtotal,
        createdBy: LogDetail?.userId,
      };
      if (parseInt(subtotal) === 0) {
        setOpenCircle(true);
        let seData = {
          url: `/api/AssociationPayment/AssociationPaymentProcess`,
          body: sed,
          headers: headers,
        };
        let res = await PostCallApi(seData);
        setOpenCircle(false);
        if (res?.status === 200) {
          if (res?.data.isPaid) {
            // let rv = res.data.payload;
            nav(`/association-list`, {
              replace: true,
            });
            handleReset();

            toast.success(res.data.message, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            // rv = rev; //added for remove comment
          } else {
            toast.error(res.data.message, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      } else {
        setPayDetail({
          ...sed,
        });
        setPaymentD(true);
        dispatch(
          AttendeeRegistrationConference({
            ...sed,
            ...invoiceData,
          })
        );
      }
    }
  };

  const handleReset = () => {
    reset();
    setDataOfAssociation();
    setPaymentD();
  };

  const handleChnagePrice = (n) => {
    // console.log(n, d);
    let pr = dataOfAssociation?.associationMembershipsTypes?.filter(
      (x, i) => i + 1 === parseInt(n)
    )[0]?.cost;
    setConPrice(pr);
    setSubTotal(pr);
  };

  const handleAssociationMember = (e) => {
    if (e) {
      let val = asList?.find((x) => x?.associationMembersId === e);
      if (val?.isUserPayForCompany) {
        setValue("associationMemberId", "");
        Swal.fire({
          position: "center",
          width: "50%",
          title: `Please select another company ${val?.name} already paid for membership`,
          confirmButtonText: "Close",
          confirmButtonColor: "#E64942",
        });
        setAssociationMembersId();
      } else {
        setAssociationMembersId(e);
        setValue("associationMemberId", e);
      }
    } else {
      setValue("associationMemberId", e);
    }
  };

  return (
    <section className="Register_Conference_Form ">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="main_wrapper">
        <div className="card">
          <div className="card-body">
            {paymentD ? (
              <EditAssociationUserPayment
                companyData={payDetail}
                handleReset={handleReset}
              />
            ) : (
              <>
                <div className="title">Pay Fee&apos;s of Association</div>
                <form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Association Name</label>
                        <input
                          className="form-control"
                          {...register("name")}
                          disabled
                        ></input>
                        {errors.associationMemberId && (
                          <span role="alert" className="error_text">
                            {errors.associationMemberId.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          MemberShip Type
                          {/* Organization or Attendee Type */}
                          <span className="red-color"> *</span>
                        </label>
                        {dataOfAssociation?.associationMembershipsTypes?.map(
                          (pr, i) => (
                            <div key={i + 1}>
                              <input
                                type="radio"
                                value={i + 1}
                                {...register("orType", {
                                  required: "This field is required",
                                  onChange: (e) =>
                                    handleChnagePrice(e.target.value, pr.cost),
                                })}
                              />{" "}
                              {pr?.title}
                            </div>
                          )
                        )}

                        {errors.orType && (
                          <span role="alert" className="error_text">
                            {errors.orType.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Company/Industry
                          <span className="red-color"> *</span>
                        </label>
                        {asList?.length > 0 ? (
                          <>
                            <Select
                              isMulti={false}
                              name="colors"
                              options={asList}
                              isClearable={true}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select Company/Industry"
                              filterOption={customFilter}
                              value={asList?.filter(
                                (obj) => obj.value === associationMembersId
                              )}
                              onChange={(e) =>
                                handleAssociationMember(e?.value)
                              }
                            />
                            <select
                              className="form-select d-none"
                              {...register("associationMemberId", {
                                required: "This field is required",
                              })}
                            >
                              <option value="">Select Company/Industry</option>
                              {asList &&
                                asList?.map((con) => (
                                  <option
                                    value={con.associationMembersId}
                                    key={con.associationMembersId}
                                  >
                                    {con.name} - {con?.type}
                                  </option>
                                ))}
                            </select>

                            {errors.associationMemberId && (
                              <span role="alert" className="error_text">
                                {errors.associationMemberId.message}
                              </span>
                            )}
                          </>
                        ) : (
                          <>
                            <input
                              className="form-control"
                              placeholder="Enter company name"
                              {...register("companyName", {
                                required: "This field is required",
                                pattern: {
                                  value: /^[^\s]+(\s+[^\s]+)*$/,
                                  message:
                                    "Starting and Ending Space not allowed",
                                },
                                maxLength: {
                                  value: 250,
                                  message: "Max character 250 allowed",
                                },
                              })}
                            ></input>
                            {errors.companyName && (
                              <span role="alert" className="error_text">
                                {errors.companyName.message}
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter First Name"
                          {...register("FirstName", {
                            pattern: {
                              value: /^[^\s]+(\s+[^\s]+)*$/,
                              message: "Starting and Ending Space not allowed",
                            },
                          })}
                          disabled
                        />
                        {errors.FirstName && (
                          <span role="alert" className="error_text">
                            {errors.FirstName.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Last Name"
                          {...register("LastName", {
                            pattern: {
                              value: /^[^\s]+(\s+[^\s]+)*$/,
                              message: "Starting and Ending Space not allowed",
                            },
                          })}
                          disabled
                        />
                        {errors.LastName && (
                          <span role="alert" className="error_text">
                            {errors.LastName.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Phone/Mobile
                          <span className="red-color"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Phone/Mobile"
                          {...register("phone", {
                            required: "This field is required",
                            pattern: {
                              value:
                                /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                              message: "Enter valid phone number",
                            },
                          })}
                          disabled
                        />
                        {errors.phone && (
                          <span role="alert" className="error_text">
                            {errors.phone.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Email
                          <span className="red-color"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Email"
                          {...register("email", {
                            required: "This field is required",
                            pattern: {
                              value: /\S+@\S+\.\S+/,
                              message: "Please Enter Valid Email",
                            },
                          })}
                          disabled
                        />
                        {errors.email && (
                          <span role="alert" className="error_text">
                            {errors.email.message}
                          </span>
                        )}
                      </div>
                    </div>
                    {dataOfAssociation && conPrice > -1 ? (
                      <div className="table-wrap mt-5">
                        <Table className="table-bordered Conference-table">
                          <TableHead className="thead-primary">
                            <StyledTableRow>
                              <StyledTableCell className="txt_st">
                                Item
                              </StyledTableCell>

                              <StyledTableCell className="txt_st">
                                Line Total
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableHead>
                          <TableBody>
                            <StyledTableRow>
                              <StyledTableCell>
                                {dataOfAssociation?.name}
                              </StyledTableCell>
                              <StyledTableCell>${conPrice}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledTableCell
                                style={{
                                  backgroundColor: "white",
                                  textAlign: "right",
                                }}
                              >
                                <b>Total</b>
                              </StyledTableCell>
                              <StyledTableCell
                                style={{ backgroundColor: "white" }}
                              >
                                <b>${subtotal}</b>
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="col-md-12 mt-3">
                      <Button type="submit" className="detail_button">
                        Submit
                      </Button>
                    </div>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default EditAssociationRegisterPay;
