export const validateImage = (file) => {
  const validVideoAudioExtensions = /\.(jpg|jpeg|png|webp)$/i;

  return validVideoAudioExtensions.test(file);
};

// const handleExcelFile = (e) => {
//   let file = e.target?.files[0];
//   if (file) {
//     if (file.name.match(/\.(xlsx)$/i)) {
//       setValue("DisplayImageUrl", file.name);
//       setValue("AttendeeList", file);
//     } else {
//       Swal.fire({
//         position: "center",
//         width: "50%",
//         title: "Please Select Only .xlsx File",
//         confirmButtonText: "Close",
//         confirmButtonColor: "#E64942",
//       });
//     }
//   }
// };
