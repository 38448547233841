// import React from "react";
// import { Box, IconButton, Modal } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
// import "./index.scss";
// import { Config } from "../../../../../Utils/Config";

// const style = {
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     width: 600,
//     background: "#ffffff",
//     display: "flex",
//     justifyContent: "center",
//     flexDirection: "column",
// };
// function MultiImgPopUp(props) {
//     const { imgAr } = props;

//     const [open, setOpen] = React.useState(false);
//     const handleClose = () => setOpen(false);
//     const handleOpen = () => {
//         if (imgAr?.length > 0) {
//             setOpen(true);
//         }
//     };

//     return (
//         <>
//             <button
//                 className="btn_Img_show"
//                 onClick={handleOpen}
//                 type="button"
//                 style={{ backgroundColor: "transparent", border: 0 }}
//             >
//                 <img
//                     src={`${Config?.API_HOST_URL_live}${imgAr[0]}` ? `${Config?.API_HOST_URL_live}${imgAr[0]}` : `${props.img}`}
//                     alt="Product"
//                     height={30}
//                     width={30}
//                     onError={({ currentTarget }) => {
//                         currentTarget.onerror = null; // prevents looping
//                         currentTarget.src = props.img;
//                     }}
//                 />
//                 {imgAr?.length > 1 ? ` ${imgAr?.length - 1}+` : ""}
//             </button>
//             {open && (
//                 <>
//                     <Modal
//                         open={open}
//                         onClose={handleClose}
//                         aria-labelledby="modal-modal-title"
//                         aria-describedby="modal-modal-description"
//                         className="toggle_img_Show"
//                     >
//                         <Box sx={style} className="delete_data_box">
//                             <IconButton
//                                 aria-label="close"
//                                 onClick={handleClose}
//                                 sx={{
//                                     position: "absolute",
//                                     right: 8,
//                                     top: 8,
//                                     // color: (theme) => theme.palette.grey[500],
//                                     bgcolor: "#48953e",
//                                     color: "#fff",
//                                 }}
//                             >
//                                 <CloseIcon />
//                             </IconButton>
//                             <div className="col-md-12">
//                                 <div className="row m-0">
//                                     {imgAr &&
//                                         imgAr?.map((im, i) => (
//                                             <div className="col-md-4 mb-3" key={i}>
//                                                 <div className="box">
//                                                     <img
//                                                         src={`${Config?.API_HOST_URL_live}${im}` ? `${Config?.API_HOST_URL_live}${im}` :  `${props.img}`}
//                                                         alt="Product"
//                                                         className="img-fluid"
//                                                         onError={({ currentTarget }) => {
//                                                             currentTarget.onerror = null; // prevents looping
//                                                             currentTarget.src = props.img;
//                                                         }}
//                                                     />
//                                                 </div>
//                                             </div>
//                                         ))}
//                                 </div>
//                             </div>
//                         </Box>
//                     </Modal>
//                 </>
//             )}
//         </>
//     );
// }

// export default React.memo(MultiImgPopUp);

import React from "react";
import { Box, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./index.scss";
const default_img = "/assets/images/default_profile.jpg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};
function MultiImgPopUp(props) {
  const { imgAr } = props;

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    if (imgAr?.length > 0) {
      setOpen(true);
    }
  };

  return (
    <>
      <button
        className="btn_Img_show"
        onClick={handleOpen}
        type="button"
        style={{ backgroundColor: "transparent", border: 0 }}
      >
        <img
          src={imgAr[0] ? imgAr[0] : default_img}
          alt="Product"
          height={30}
          width={30}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = default_img;
          }}
        />
        {imgAr?.length > 1 ? ` ${imgAr?.length - 1}+` : ""}
      </button>
      {open && (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="toggle_img_Show"
          >
            <Box sx={style} className="delete_data_box">
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  // color: (theme) => theme.palette.grey[500],
                  bgcolor: "#48953e",
                  color: "#fff",
                }}
              >
                <CloseIcon />
              </IconButton>
              <div className="col-md-12">
                <div className="row m-0">
                  {imgAr &&
                    imgAr?.map((im, i) => (
                      <div className="col-md-4 mb-3" key={i}>
                        <div className="box">
                          <img
                            src={im ? im : default_img}
                            alt="Product"
                            className="img-fluid"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = default_img;
                            }}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}

export default React.memo(MultiImgPopUp);
