import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { GetCallApi } from "../../Action/Action";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import { GoEye } from "react-icons/go";

const defaultStyles = {
  transition: "all 0.5s ease-in-out",
  height: "100%",
  width: 0,
  position: "absolute",
  zIndex: 1,
  backgroundColor: "#FFFFFF",
  overflowX: "hidden",
};

const expectedBehaviours = ["overlay", "hidden"];
const expectedPositions = ["left", "right"];

const invalidBehaviourProp = new Error(
  `Sidebar component : behaviour prop must be one of ${expectedBehaviours.toString()}`
);
const invalidPositionProp = new Error(
  `Sidebar component : position prop must be one of ${expectedPositions.toString()}`
);

const computeStylesFromState = (
  isSidebarOpen,
  content,
  behaviour,
  position
) => {
  const additionalStyles = {};
  additionalStyles[position] = "0px";
  additionalStyles.width = !isSidebarOpen
    ? "0px"
    : window.innerWidth > 480
    ? "350px"
    : "250px";
  const contentNode = document.querySelector(content);
  if (contentNode && behaviour === "hidden") {
    // contentNode.style.marginLeft = !isSidebarOpen
    //   ? "0px"
    //   : `${position === "right" ? "-" : ""}15%`;
  }
  return { ...defaultStyles, ...additionalStyles };
};

const Sidebar = ({ behaviour, content, position, setSboothId }) => {
  const [isOpen, setOpenState] = React.useState(true);
  const openSidebar = () => {
    setOpenState(true);
  };

  const toggleSidebar = () => {
    setOpenState(!isOpen);
  };

  const closeSidebar = () => {
    setOpenState(false);
  };

  Sidebar.open = openSidebar;
  Sidebar.close = closeSidebar;
  Sidebar.toggle = toggleSidebar;

  const computedStylesForState = React.useMemo(
    () => computeStylesFromState(isOpen, content, behaviour, position),
    [isOpen, content, behaviour, position]
  );

  React.useEffect(() => {
    if (!expectedBehaviours.includes(behaviour)) {
      throw new Error(invalidBehaviourProp);
    }
    // eslint-disable-next-line
  }, [behaviour]);

  React.useEffect(() => {
    if (!expectedPositions.includes(position)) {
      throw new Error(invalidPositionProp);
    }
    // eslint-disable-next-line
  }, [position]);

  const { id } = useParams();
  const { state } = useLocation();
  const conId = id || state?.conferenceId;
  const [confId, setConFId] = useState();
  React.useEffect(() => {
    if (conId) {
      setConFId(conId);
    }
    // eslint-disable-next-line
  }, [conId]);
  const [deltaPositionSave, setDeltaPositionSave] = useState([]);

  let deleteInfo = useSelector((state) => state.Common?.DeleteData);

  React.useEffect(() => {
    getBoothList();
    // eslint-disable-next-line
  }, [deleteInfo, confId]);
  const getBoothList = async () => {
    setDeltaPositionSave([]);
    if (confId) {
      let seData = {
        url: `/api/ConferenceBooths/GetConferenceBoothsStatusByConferenceIdWithoutToken?conferenceId=${confId}`,
      };
      let res = await GetCallApi(seData);
      if (res.status === 200 && res.data.success) {
        let pn = res.data.payload
          ?.filter((x) => x?.status === "Approve")
          ?.map((p) => {
            return {
              ...p,
              ...p.exhibitor,
              ...p.exhibitor?.exhibitorCompanyDetails,
            };
          });
        setDeltaPositionSave(pn);
      }
    }
  };

  const [searchField, setSearchField] = useState();
  const searchParam = ["companyName", "exhibitorName", "seatEventNumber"];
  const filterUser = deltaPositionSave?.filter((user) => {
    if (searchField) {
      return searchParam?.some((newItem) => {
        return (
          user[newItem]
            ?.toString()
            ?.toLowerCase()
            ?.indexOf(searchField.toLowerCase()) > -1
        );
      });
    } else return user;
  });

  const handleExhiClick = (ex) => {
    setSboothId(ex?.eventBoothsId);
    if (window.innerWidth < 480) {
      closeSidebar();
    }
  };
  return (
    <>
      <div
        className={`${isOpen ? "sidebar_open" : "sidebar_close"}`}
        style={computedStylesForState}
      >
        {/* <div className="exibitor">Exhibitors</div> */}
        <div className="searc_box">
          <input
            type="search"
            className="form-control"
            placeholder="Search by Name OR Booth No"
            onChange={(e) => setSearchField(e.target.value)}
          />
        </div>
        <div className=" map_legend gap-3 p-3 d-flex">
          <div className="f-15-500">Booth No.</div>
          <div className="f-15-500">Name</div>
          <div className="ms-auto me-3">
            <div className="f-15-500">Link</div>
          </div>
        </div>
        <div className="vender_list_box ">
          {filterUser?.map((ex, i) => (
            <div key={i} className="vender_detail_Box">
              <div className="booth_no">{ex?.seatEventNumber}</div>
              <div className="name f-15-500">
                {/* {ex?.exhibitorName ? ex?.exhibitorName : ""} */}
                {ex?.companyName ? (
                  <div className="d-flex align-items-center text_grey">
                    <img
                      src="/assets/icons/company_logo.svg"
                      style={{ width: "16px" }}
                      alt=""
                    />
                    <div className=" ms-1">{ex?.companyName}</div>
                    {/* <div className="f-14-400 ms-1">{ex?.companyName}</div> */}
                  </div>
                ) : (
                  ex?.exhibitorName
                )}
              </div>
              <div className="ms-auto">
                <Button
                  className="gren_eye_btn"
                  onClick={() => handleExhiClick(ex)}
                >
                  <GoEye />
                </Button>
              </div>
            </div>
          ))}
        </div>
        {/* <div className="map_legend">Map Legend</div> */}
      </div>
    </>
  );
};

export default Sidebar;
