import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { Modal, Button, Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import "./index.scss";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Axios from "../../../../Utils/AxiosConfig";
import { useSelector } from "react-redux";
// import Tooltip from "../../../Components/Reusable/Tooltip";
import DetailShowPopup from "../../../Components/Reusable/DetailShowPopup";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";
import { MdDelete } from "react-icons/md";
// import door from "../../../../assets/icons/door.svg";
import { FaDoorOpen, FaPersonBooth } from "react-icons/fa";
import { GiBrickWall } from "react-icons/gi";
import { toast } from "react-toastify";
import { customFilter } from "../../../Components/CustomFilterForReactSelect";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // height: "50%",
  width: "450px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: 0,
};
function DragableBox(props) {
  const { eventId, canEdit, evD } = props;

  const [deltaPositionSave, setDeltaPositionSave] = useState([]);
  const [derrors, setDerrors] = useState({});
  const [val, setVal] = React.useState("1");
  const handleChangeTab = (event, newValue) => {
    setVal(newValue);
  };
  let nav = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    if (parseInt(data?.noBooth) === 1) {
      const checkData = deltaPositionSave?.filter(
        (x) => x.label.toString() === data?.no?.toString()
      );
      if (checkData.length > 0) {
        setDerrors({ no_err: "This number is already exist." });
      } else {
        setDerrors({ no_err: "" });
        let add = [
          ...deltaPositionSave,
          {
            sequenceNumber: deltaPositionSave.length,
            value: parseInt(data?.no),
            x: deltaPositionSave[deltaPositionSave.length - 1]?.x
              ? deltaPositionSave[deltaPositionSave.length - 1]?.x
              : 0,
            y: deltaPositionSave[deltaPositionSave.length - 1]?.y
              ? deltaPositionSave[deltaPositionSave.length - 1]?.y
              : 0,
            label: data?.no,
            width: data?.width,
            height: data?.height,
            price: data?.price,
          },
        ];
        setDeltaPositionSave(add);
        handleClose();
      }
    } else {
      let newbt = [...Array(parseInt(data?.noBooth)).keys()]?.map((x, i) => {
        let nob = parseInt(data?.no) + i;
        let vl = deltaPositionSave?.filter((b) => b?.value === nob);
        if (vl?.length > 0) {
          return undefined;
        }
        return {
          sequenceNumber: deltaPositionSave.length + i,
          value: nob,
          x: deltaPositionSave[deltaPositionSave.length - 1]?.x
            ? deltaPositionSave[deltaPositionSave.length - 1]?.x
            : 0,
          y: deltaPositionSave[deltaPositionSave.length - 1]?.y
            ? deltaPositionSave[deltaPositionSave.length - 1]?.y
            : 0,
          label: nob,
          width: data?.width,
          height: data?.height,
          price: data?.price,
        };
      });
      newbt = newbt?.filter((x) => x !== undefined);
      let add = [...deltaPositionSave, ...newbt];
      setDeltaPositionSave(add);
      handleClose();
    }
  };
  const handleDrag = (e, ui, drag) => {
    console.log(ui);
    let arr = deltaPositionSave?.map((s1) => {
      if (s1.sequenceNumber === drag?.sequenceNumber) {
        const { x, y } = ui;
        return {
          ...s1,
          x: x,
          y: y,
        };
      }
      return s1;
    });
    // s1 = s1[0];
    // const s = deltaPositionSave?.filter(
    //   (se) => se?.sequenceNumber !== drag?.sequenceNumber
    // );
    // let arr = s;

    // delete drag?.x;
    // delete drag?.y;
    // let select = {
    //   // sequenceNumber: drag?.sequenceNumber,
    //   // value: drag?.value,
    //   // label: drag?.label,
    //   ...drag,
    //   x: s1?.x + ui.deltaX,
    //   y: s1?.y + ui.deltaY,
    // };
    // arr?.splice(drag?.sequenceNumber, 0, select);
    setDeltaPositionSave(arr);
  };
  const [open, setOpen] = useState(false);
  const [comDl, setComDl] = React.useState(false);
  const handleOpen = (detail) => {
    setOpen(true);
    setComDl(detail);
  };

  const handleClose = () => {
    setOpen(false);
    setDerrors({ no_err: "" });

    reset();
  };
  const [openEdit, setOpenEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const [dragMulti, setDragMulti] = useState([]);
  const handleOpenEdit = (d) => {
    setOpenEdit(true);
    setEditData(d);
    setValue("no", d?.label);
    setValue("width", d?.width);
    setValue("height", d?.height);
    setValue("price", d?.price);
    setDragMulti(deltaPositionSave?.map((x) => x?.value));
  };
  console.log(dragMulti, deltaPositionSave);
  const handleCloseEdit = () => {
    setOpenEdit(false);
    setEditData({});
    setDragMulti([]);
    setDerrors({ no_err: "" });
    setVal("1");
    reset();
  };
  const onEdit = (data) => {
    const checkData = deltaPositionSave?.filter(
      (x) => x.label?.toString() === data?.no?.toString()
    );
    if (editData?.label.toString() === data?.no.toString()) {
      let add = {
        // sequenceNumber: editData?.sequenceNumber,
        // value: editData?.value,
        // x: editData?.x,
        // y: editData?.y,
        ...editData,
        label: data?.no,
        width: data?.width,
        height: data?.height,
        price: data?.price,
      };
      const s = deltaPositionSave?.filter(
        (se) => se?.sequenceNumber !== editData?.sequenceNumber
      );
      let arr = s;

      arr?.splice(editData?.sequenceNumber, 0, add);
      setDeltaPositionSave(arr);

      handleCloseEdit();
    } else {
      if (checkData.length > 0) {
        setDerrors({ no_err: "This number is already exist." });
      } else {
        setDerrors({ no_err: "" });
        let add = {
          // sequenceNumber: editData?.sequenceNumber,
          // value: editData?.value,
          // x: editData?.x,
          // y: editData?.y,
          ...editData,
          label: data?.no,
          width: data?.width,
          height: data?.height,
          price: data?.price,
        };
        const s = deltaPositionSave?.filter(
          (se) => se?.sequenceNumber !== editData?.sequenceNumber
        );
        let arr = s;

        arr?.splice(editData?.sequenceNumber, 0, add);
        setDeltaPositionSave(arr);

        handleCloseEdit();
      }
    }
  };
  const onRemove = () => {
    let checkData = deltaPositionSave?.filter((x) =>
      dragMulti.every((obj2) => x.value !== obj2)
    );
    checkData = checkData?.map((d, i) => {
      return {
        ...d,
        sequenceNumber: i,
        // value: d?.value,
        // x: d?.x,
        // y: d?.y,
        // label: d?.label,
        // width: d?.width,
        // height: d?.height,
      };
    });
    setDeltaPositionSave(checkData);
    handleCloseEdit();
  };
  const handleChangedragMulti = (e) => {
    setDragMulti(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  let location = useLocation();
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const loginDetail = useSelector((state) => state.login.LoginDetails);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const { id } = useParams();
  useEffect(() => {
    document.title = "FIG Events | Floor Plan";
    if (id) {
      getseatNumberList(id);
    } else if (eventId) {
      getseatNumberList(eventId);
    }
    // eslint-disable-next-line
  }, [id, eventId]);

  const getseatNumberList = (eid) => {
    let url =
      location?.pathname?.split("/")[2] === "view"
        ? `/api/ConferenceBooths/GetConferenecBoothsStatusByConferenceId`
        : `/api/ConferenceBooths/GetConferenceoothsByConferenceId`;
    Axios.get(`${url}?conferenceId=${eid}`, {
      headers,
    })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            if (location?.pathname?.split("/")[2] === "view") {
              let pn = res.data.payload?.map((p) => {
                return {
                  ...p,
                  ...p.exhibitor,
                  sequenceNumber: p?.sequenceNumber,
                  value: p?.eventBoothsId,
                  label: p?.seatEventNumber,
                  x: p?.positionX,
                  y: p?.positionY,
                  width: p?.width,
                  height: p?.hight,
                  status: p?.status,
                };
              });
              setDeltaPositionSave(pn);
            } else {
              let pn = res.data.payload?.map((p) => {
                return {
                  ...p,
                  ...p.exhibitor,
                  sequenceNumber: p?.sequenceNumber,
                  value: p?.eventBoothsId,
                  label: p?.seatEventNumber,
                  x: p?.positionX,
                  y: p?.positionY,
                  width: p?.width,
                  height: p?.hight,
                };
              });
              setDeltaPositionSave(pn);
            }
          }
        }
      })
      .catch((e) => console.log(e));
  };
  const onSaveFloor = () => {
    let seatData = {
      ConferenceId: id ? id : eventId,
      createdBy: loginDetail?.userId,
      seatNumbers: deltaPositionSave?.map((sn) => {
        let n = typeof sn.value;
        if (n === "number") {
          return {
            sequenceNumber: sn.sequenceNumber,
            seatEventNumber: sn?.label?.toString(),
            positionX: sn?.x,
            positionY: sn?.y,
            width: parseFloat(sn?.width),
            hight: parseFloat(sn?.height),
            type: sn?.type ? sn?.type : "booth",
            price: evD?.priceType === "SinglePrice" ? 0 : parseFloat(sn?.price),
          };
        } else {
          return {
            eventBoothsId: sn?.value,
            sequenceNumber: sn.sequenceNumber,
            seatEventNumber: sn?.label?.toString(),
            positionX: sn?.x,
            positionY: sn?.y,
            width: parseFloat(sn?.width),
            hight: parseFloat(sn?.height),
            type: sn?.type ? sn?.type : "booth",
            price: evD?.priceType === "SinglePrice" ? 0 : parseFloat(sn?.price),
          };
        }
      }),
    };
    Axios.post("/api/ConferenceBooths/CreateConferenceBooths", seatData, {
      headers,
    })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            toast.success(res.data.message, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            nav("/conference-list", { replace: true });
            getseatNumberList(eventId ? eventId : id);
          } else {
            toast.error(res.data.message, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      })
      .catch((e) => console.log(e));
  };
  const onResize = (event, { size }, drag) => {
    // const onResize = (event, { node, size, handle }, drag) => {
    let ad = deltaPositionSave?.map((x) => {
      if (x.sequenceNumber === drag.sequenceNumber) {
        return { ...x, width: size.width, height: size.height };
      }
      return x;
    });
    setDeltaPositionSave(ad);
    // { width: size.width, height: size.height }
  };
  const addWall = (val) => {
    let add = [
      ...deltaPositionSave,
      {
        sequenceNumber: deltaPositionSave.length,
        value: deltaPositionSave.length,
        x: deltaPositionSave[deltaPositionSave.length - 1]?.x
          ? deltaPositionSave[deltaPositionSave.length - 1]?.x
          : 0,
        y: deltaPositionSave[deltaPositionSave.length - 1]?.y
          ? deltaPositionSave[deltaPositionSave.length - 1]?.y
          : 0,
        label: "",
        type: val,
        width: val === "door" ? 100 : 20,
        height: 100,
      },
    ];
    setDeltaPositionSave(add);
  };
  const removeWall = (d) => {
    let checkData = deltaPositionSave?.filter(
      (x) => x.sequenceNumber !== d?.sequenceNumber
    );
    setDeltaPositionSave(checkData);
  };
  return (
    <>
      <div className="editdrop">
        {location?.pathname?.split("/")[2] === "view" ? (
          ""
        ) : canEdit ? (
          <div className="control_btn">
            <Button className="add_btn White_button save" onClick={onSaveFloor}>
              {" "}
              Save
            </Button>
            <Button className="add_btn White_button ms-2" onClick={handleOpen}>
              <FaPersonBooth />
            </Button>
            <Button
              className="add_btn White_button ms-2"
              onClick={() => addWall("wall")}
            >
              <GiBrickWall />
            </Button>{" "}
            <Button
              className="add_btn White_button ms-2"
              onClick={() => addWall("door")}
            >
              <FaDoorOpen />
            </Button>{" "}
          </div>
        ) : (
          ""
        )}
        <div
          className="box"
          style={{
            height: evD?.height ? evD?.height - 290 : "680px",
            // height: "680px",
            width: "100%",
            position: "relative",
            overflow: "auto",
            padding: "0",
          }}
        >
          <div
            style={{
              height: "100%",
              width: evD?.width ? evD?.width - 150 : "1770px",
              // width: "1770px",
              padding: "10px",
              zIndex: "999",
              position: "relative",
              // display: "flex",
              // flexWrap: "wrap",
            }}
          >
            {location?.pathname?.split("/")[2] === "view" ? (
              <>
                {deltaPositionSave &&
                  deltaPositionSave?.map((drag, k) => (
                    <Draggable
                      key={k}
                      bounds="parent"
                      handle=".child"
                      position={{
                        x: drag.x,
                        y: drag.y,
                      }}
                      onStart={() => false}
                      onDrag={(e, ui) => handleDrag(e, ui, drag)}
                    >
                      {drag?.type === "wall" ? (
                        <div
                          className={`box_small_edit ${
                            drag?.type === "wall" ? "" : ""
                          }`}
                          style={{
                            width: `${drag?.width ? drag.width : "28"}px`,
                            height: `${drag?.height ? drag.height : "28"}px`,
                            background: drag?.type === "wall" ? "black" : "",
                          }}
                        ></div>
                      ) : drag?.type === "door" ? (
                        <div
                          className={`box_small_edit door`}
                          style={{
                            width: `${drag?.width ? drag.width : "28"}px`,
                            height: `${drag?.height ? drag.height : "28"}px`,
                          }}
                        >
                          <img
                            src={"/assets/icons/door.svg"}
                            alt=""
                            style={{
                              width: `100%`,
                              height: `100%`,
                            }}
                          />
                        </div>
                      ) : (
                        <div
                          className={` box_small_edit  ${
                            drag?.status === "Approve"
                              ? "booked"
                              : drag?.status === "InProgress"
                              ? "my_booked"
                              : "availabel"
                          }`}
                          style={{
                            width: `${drag?.width ? drag.width : "28"}px`,
                            height: `${drag?.height ? drag.height : "28"}px`,
                          }}
                        >
                          <>
                            {drag?.status === "InProgress" ? (
                              <div className="align-items-center d-flex h-100 justify-content-center w-100">
                                {drag?.label}
                              </div>
                            ) : drag?.status === "Approve" ? (
                              <>
                                <div
                                  className="align-items-center d-flex h-100 justify-content-center w-100"
                                  onClick={() => handleOpen(drag)}
                                >
                                  {drag?.label}
                                </div>
                                {/* <Tooltip comDl={drag} /> */}
                              </>
                            ) : (
                              <div>{drag?.label}</div>
                            )}
                          </>

                          {/* <div onClick={(e) => handleRemove(drag)}>e</div> */}
                        </div>
                      )}
                    </Draggable>
                  ))}
                <DetailShowPopup
                  openbox={open}
                  closebox={setOpen}
                  title="Company Details"
                  comDl={comDl}
                />
              </>
            ) : canEdit ? (
              <>
                {deltaPositionSave &&
                  deltaPositionSave?.map((drag, k) => (
                    <Draggable
                      key={k}
                      bounds="parent"
                      handle=".child"
                      position={{
                        x: drag.x,
                        y: drag.y,
                      }}
                      onStart={() => true}
                      onDrag={(e, ui) => handleDrag(e, ui, drag)}
                    >
                      {drag?.type === "wall" ? (
                        <Resizable
                          className="  box_small_edit "
                          width={drag?.width ? drag.width : 28}
                          height={drag?.height ? drag.height : 28}
                          // handle={".save"}
                          // style={{
                          //   width: `${drag?.width ? drag.width : 28}`,
                          //   height: `${drag?.height ? drag.height : 28}`,
                          // }}

                          onResize={(e, data) => onResize(e, data, drag)}
                        >
                          <div
                            className={`box_small_edit ${
                              drag?.type === "wall" ? "" : ""
                            }`}
                            style={{
                              width: `${drag?.width ? drag.width : "28"}px`,
                              height: `${drag?.height ? drag.height : "28"}px`,
                              background: drag?.type === "wall" ? "black" : "",
                            }}
                          >
                            <div className="child" style={{ color: "white" }}>
                              W
                            </div>
                            <div
                              className="delete"
                              onClick={() => removeWall(drag)}
                            >
                              <MdDelete color="red" fontSize={24} />
                            </div>
                          </div>
                        </Resizable>
                      ) : drag?.type === "door" ? (
                        <Resizable
                          className="  box_small_edit "
                          width={drag?.width ? drag.width : 28}
                          height={drag?.height ? drag.height : 28}
                          onResize={(e, data) => onResize(e, data, drag)}
                        >
                          <div
                            className={`box_small_edit door`}
                            style={{
                              width: `${drag?.width ? drag.width : "28"}px`,
                              height: `${drag?.height ? drag.height : "28"}px`,
                            }}
                          >
                            <img
                              src={"/assets/icons/door.svg"}
                              className="child"
                              alt=""
                              style={{
                                width: `100%`,
                                height: `100%`,
                              }}
                            />

                            <div
                              className="delete"
                              onClick={() => removeWall(drag)}
                            >
                              <MdDelete color="red" fontSize={24} />
                            </div>
                          </div>
                        </Resizable>
                      ) : (
                        <div
                          className={`box_small_edit child`}
                          style={{
                            width: `${drag?.width ? drag.width : "28"}px`,
                            height: `${drag?.height ? drag.height : "28"}px`,
                            background: drag?.type === "wall" ? "black" : "",
                          }}
                        >
                          {/* <div
                          className=" child box_small_edit"
                          style={{
                            width: `${drag?.width ? drag.width : "28"}px`,
                            height: `${drag?.height ? drag.height : "28"}px`,
                          }}
                        > */}
                          {location?.pathname?.split("/")[2] === "view" ? (
                            <>
                              <div>{drag?.label}</div>
                              {/* <Tooltip comDl={drag} /> */}
                            </>
                          ) : canEdit ? (
                            <div onDoubleClick={() => handleOpenEdit(drag)}>
                              {drag?.label}
                            </div>
                          ) : (
                            <div>{drag?.label}</div>
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
              </>
            ) : (
              <>
                {deltaPositionSave &&
                  deltaPositionSave?.map((drag, k) => (
                    <Draggable
                      key={k}
                      bounds="parent"
                      handle=".child"
                      position={{
                        x: drag.x,
                        y: drag.y,
                      }}
                      onStart={() => true}
                      onDrag={(e, ui) => handleDrag(e, ui, drag)}
                    >
                      <div
                        className=" child box_small_edit"
                        style={{
                          width: `${drag?.width ? drag.width : "28"}px`,
                          height: `${drag?.height ? drag.height : "28"}px`,
                        }}
                      >
                        <div>{drag?.label}</div>
                        {/* <Tooltip comDl={drag} /> */}
                      </div>
                    </Draggable>
                  ))}
              </>
            )}
          </div>
        </div>
      </div>
      {open && location?.pathname?.split("/")[2] !== "view" && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="popup_box_add_drag"
        >
          <Box sx={style} className={`main_box `}>
            <div className="col-md-12 title popup mt-0">Add Drag Box</div>
            <form className="form_details" onSubmit={handleSubmit(onSubmit)}>
              <div
                className="row"
                style={{
                  borderBottom: "1px solid #e7e7e7",
                  marginBottom: "20px",
                }}
              >
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">
                      No of Booth&apos;s Want To Create
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter No of Booth's Want To Create"
                      min={1}
                      {...register("noBooth", {
                        required: "This field is required",
                        pattern: {
                          value: /^[0-9+-]+$/,
                          message: "Enter valid number",
                        },
                      })}
                    />
                    {errors.noBooth && (
                      <span role="alert" className="error_text">
                        {errors.noBooth.message}
                      </span>
                    )}
                  </div>
                </div>
                <div
                  className={`${
                    evD?.priceType === "SinglePrice" ? "col-md-12" : "col-md-6"
                  }`}
                >
                  <div className="form-group">
                    <label className="form-label">
                      Booth No
                      <br />
                      (Don&apos;t enter same booth no that will not create multi
                      booth)
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Booth No"
                      min={0}
                      maxLength={4}
                      {...register("no", {
                        required: "This field is required",
                        maxLength: {
                          value: 4,
                          message: "You can enter max 4 numbers",
                        },
                      })}
                    />
                    {errors.no && (
                      <span role="alert" className="error_text">
                        {errors.no.message}
                      </span>
                    )}
                    {derrors?.no_err && (
                      <span role="alert" className="error_text">
                        {derrors?.no_err}
                      </span>
                    )}
                  </div>
                </div>
                {evD?.priceType === "SinglePrice" ? (
                  ""
                ) : (
                  <div className="col-md-6 mt-auto">
                    <div className="form-group">
                      <label className="form-label">Price</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter price"
                        {...register("price", {
                          required: "This field is required",
                          pattern: {
                            value: /^\d+(\.\d{1,2})?$/,
                            message: "Enter valid price",
                          },
                        })}
                      />
                      {errors.price && (
                        <span role="alert" className="error_text">
                          {errors.price.message}
                        </span>
                      )}
                    </div>
                  </div>
                )}
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Width</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter width"
                      min={30}
                      max={evD?.width ? evD?.width - 170 : 1720}
                      {...register("width", {
                        required: "This field is required",
                        pattern: {
                          value: /^[0-9+-]+$/,
                          message: "Enter valid width",
                        },
                      })}
                    />
                    {errors.width && (
                      <span role="alert" className="error_text">
                        {errors.width.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Height</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter height"
                      min={30}
                      max={evD?.height ? evD?.height - 290 : 660}
                      {...register("height", {
                        required: "This field is required",
                        pattern: {
                          value: /^[0-9+-]+$/,
                          message: "Enter valid height",
                        },
                      })}
                    />
                    {errors.height && (
                      <span role="alert" className="error_text">
                        {errors.height.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-end">
                  <Button
                    className="back_button me-3"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </Button>
                  <input
                    type="submit"
                    value="Submit"
                    className="detail_button"
                  />
                </div>
              </div>
            </form>
          </Box>
        </Modal>
      )}
      {openEdit && location?.pathname?.split("/")[2] !== "view" && (
        <Modal
          open={openEdit}
          onClose={handleCloseEdit}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="popup_box_add_drag"
        >
          <Box sx={style} className={`main_box `}>
            <TabContext value={val}>
              <Box className="tab_group mb-3">
                <TabList
                  onChange={handleChangeTab}
                  aria-label="lab API tabs example"
                  className="tab_label"
                >
                  <Tab label="Edit" value="1" className="tab_1" />
                  <Tab label="Remove" value="2" className="tab_3" />
                </TabList>
              </Box>
              <TabPanel value="1" className="tab_panel" style={{ padding: 0 }}>
                {/* <div className="title">Edit Drag Box</div> */}
                <form className="form_details" onSubmit={handleSubmit(onEdit)}>
                  <div className="row">
                    <div
                      className={`${
                        evD?.priceType === "SinglePrice"
                          ? "col-md-12"
                          : "col-md-6"
                      }`}
                    >
                      <div className="form-group">
                        <label className="form-label">Booth No</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Booth no"
                          min={0}
                          maxLength={4}
                          {...register("no", {
                            required: "This field is required",
                            maxLength: {
                              value: 4,
                              message: "You can enter max 4 numbers",
                            },
                            valueAsNumber: true,
                          })}
                        />
                        {errors.no && (
                          <span role="alert" className="error_text">
                            {errors.no.message}
                          </span>
                        )}
                        {derrors?.no_err && (
                          <span role="alert" className="error_text">
                            {derrors?.no_err}
                          </span>
                        )}
                      </div>
                    </div>

                    {evD?.priceType === "SinglePrice" ? (
                      ""
                    ) : (
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">Price</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter price"
                            {...register("price", {
                              required: "This field is required",
                              pattern: {
                                value: /^\d+(\.\d{1,2})?$/,
                                message: "Enter valid price",
                              },
                            })}
                          />
                          {errors.price && (
                            <span role="alert" className="error_text">
                              {errors.price.message}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Width</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter width"
                          min={30}
                          max={evD?.width ? evD?.width - 170 : 1720}
                          {...register("width", {
                            required: "This field is required",
                            pattern: {
                              value: /^[0-9+-]+$/,
                              message: "Enter valid Width",
                            },
                          })}
                        />
                        {errors.width && (
                          <span role="alert" className="error_text">
                            {errors.width.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Height</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter height"
                          min={30}
                          max={evD?.height ? evD?.height - 290 : 660}
                          {...register("height", {
                            required: "This field is required",
                            pattern: {
                              value: /^[0-9+-]+$/,
                              message: "Enter valid height",
                            },
                          })}
                        />
                        {errors.height && (
                          <span role="alert" className="error_text">
                            {errors.height.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="d-flex">
                      <input
                        type="submit"
                        value="Submit"
                        className="detail_button mx-auto"
                      />
                    </div>
                  </div>
                </form>
              </TabPanel>
              <TabPanel value="2" className="tab_panel" style={{ padding: 0 }}>
                <form className="form_details">
                  <div className="form-group">
                    <label className="form-label">No</label>
                    <Select
                      isMulti
                      name="dragval"
                      options={deltaPositionSave
                        ?.filter((x) => x?.type !== "wall")
                        .filter((x) => x?.type !== "door")}
                      className="drag-value-multi-select"
                      classNamePrefix="select"
                      filterOption={customFilter}
                      value={deltaPositionSave?.filter((obj) =>
                        dragMulti?.includes(obj.value)
                      )}
                      // value={othSchSelect ? othSchSelect : null}
                      onChange={handleChangedragMulti}
                      formatOptionLabel={(drag) => (
                        <div className="country-option">
                          <span>{drag.label}</span>
                        </div>
                      )}
                    />
                  </div>
                  <div className="form-group">
                    <div className="d-flex">
                      <input
                        type="button"
                        onClick={onRemove}
                        value="Submit"
                        className="detail_button mx-auto"
                      />
                    </div>
                  </div>
                </form>
              </TabPanel>
            </TabContext>
          </Box>
        </Modal>
      )}
    </>
  );
}

export default DragableBox;

//example for drag

// <Draggable
//   bounds="parent"
//   // defaultPosition={{ x: 0, y: 0 }}
//   // onStart={() => onStart()}
//   // onStop={(e, ui) => onStop(e, ui, 0)}
//   handle=".handle"
//   position={{ x: deltaPositionSave[0].x, y: deltaPositionSave[0].y }}
//   onDrag={(e, ui) => handleDrag(e, ui, 0)}
// >
//   <div className=" handle box_small">
//     112
//     {/* <div className="d-none">
//               x: {deltaPositionSave[0].x.toFixed(0)}, y:{" "}
//               {deltaPositionSave[0].y.toFixed(0)}
//             </div> */}
//   </div>
// </Draggable>;
