import React, { useEffect, useState } from "react";
import "./index.scss";
import { GetCallApi } from "../../../Action/Action";
import { Backdrop, CircularProgress } from "@mui/material";
// import rightTick from "../../../assets/icons/rightTick.svg";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
// import { TbDiscount2 } from "react-icons/tb";

function Subcription() {
  const [openCircle, setOpenCircle] = useState(false);
  const [subList, setSubList] = useState([]);

  useEffect(() => {
    document.title = "FIG Events | Subcription ";
    getSubList();
    // eslint-disable-next-line
  }, []);

  const getSubList = async () => {
    setOpenCircle(true);
    let seData = {
      url: "/api/Subscription/GetAllSubscriptionList",
    };
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res?.data.success) {
        let resVal = res.data.payload
          ?.filter((x) => x?.duration !== "Conference")
          ?.map((c) => {
            return {
              ...c,
              benef: c?.benefits?.split("\n"),
            };
          });
        setSubList(resVal);
      }
    }
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="subscription_page">
        <div className="main_wrapper">
          <div className="title mb-3"> Subscription </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                {subList?.map((sub) => (
                  <div className="col-md-6 col-xl-3 mb-3" key={sub?.priceId}>
                    <div className="sub_card">
                      <div className="d-flex justify-content-between">
                        <p className="heading mb-0">{sub?.subscriptionTitle}</p>
                        <div>
                          {sub?.isDiscountedSubscription ? (
                            <div
                              style={{
                                color: "white",
                                fontWeight: 400,
                                background:
                                  "linear-gradient(86.33deg, #18bad4 1.51%, #31b680 52.02%, #80c242 99.48%)",
                                padding: " 5px 20px",
                                borderRadius: "20px",
                              }}
                            >
                              -
                              {sub?.discountedType === "Percentage"
                                ? sub?.discountedValue + "%"
                                : " " + sub?.discountedValue}{" "}
                              {/* <TbDiscount2 fontSize={25} /> */}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="price_year">
                        <span className="doller_sign me-1">
                          {/* {sub?.isDiscountedSubscription
                            ? "Discounted Price"
                            : "Price"}
                          :{" "} */}

                          <b>
                            {sub?.isDiscountedSubscription
                              ? sub?.price?.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })
                              : sub?.totalSubscriptionPrice?.toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "USD",
                                  }
                                )}{" "}
                          </b>
                          {sub.priceType}
                        </span>
                        <span className="duration">/{sub?.duration}</span>
                        <div>
                          {sub?.isDiscountedSubscription ? (
                            <>
                              Price :{" "}
                              <span
                                style={{
                                  textDecoration: "line-through",
                                }}
                              >
                                {sub?.totalSubscriptionPrice?.toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "USD",
                                  }
                                )}
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="no_at mb-3">
                        {/* Number Of Attendee In Dashboard :{" "} */}
                        <span className="sub_value">{sub?.value}</span> Leads
                      </div>
                      <div className="sub_benefits">
                        <p>Benefits :</p>
                        <div className="row all_details">
                          {sub?.benef?.map((v, i) => {
                            return (
                              <div className="col-12" key={i}>
                                <img
                                  src={"/assets/icons/rightTick.svg"}
                                  alt=""
                                />
                                <span className="ms-3">{v}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="get_start_btn">
                        <Link to={`${sub?.subscriptionId}`}>
                          <Button className="buy_plan_btn w-100">
                            Buy Plan
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Subcription;
