import React, { useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

function UserBio() {
  //   const { usDetails } = props;
  const { id } = useParams();

  const [openCircle, setOpenCircle] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const onSubmit = async (data) => {
    if (id) {
      return false;
    }
    let seData = {
      url: "/api/UserMst/CreateUserBIO",
      body: { ...data, userId: LogDetail?.userId },
    };
    setOpenCircle(true);

    let res = await PostCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res?.data?.success) {
        GetUsDetails(LogDetail?.userId);
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast.error(res.data.message, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } else {
      toast.error(res.data.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  useEffect(() => {
    GetUsDetails(LogDetail?.userId);

    // eslint-disable-next-line
  }, []);
  const GetUsDetails = async (id) => {
    let seData = {
      url: `/api/UserMst/GetAttendeeRoleData?userId=${id}`,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res.data.success) {
        let ud = res.data.payload[0];

        setValue("title", ud?.userTitle);
        setValue("description", ud?.userDescription);
      }
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="title my-3">Bio</div>
      <div className="card">
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)} className="form_box">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">
                    Title
                    {/* <span className="red-color"> *</span> */}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Title"
                    disabled={id ? true : false}
                    {...register("title", {
                      pattern: {
                        value: /^[^\s]+(\s+[^\s]+)*$/,
                        message: "Starting and Ending Space not allowed",
                      },
                      maxLength: {
                        value: 200,
                        message: "Max character 200 allowed",
                      },
                    })}
                  />
                  {errors.title && (
                    <span role="alert" className="error_text">
                      {errors.title.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label className="form-label">
                    description
                    {/* <span className="red-color"> *</span> */}
                  </label>
                  <textarea
                    rows={3}
                    type="text"
                    className="form-control"
                    placeholder="Enter Description"
                    disabled={id ? true : false}
                    {...register("description", {
                      pattern: {
                        value: /^[^\s]+(\s+[^\s]+)*$/,
                        message: "Starting and Ending Space not allowed",
                      },
                      maxLength: {
                        value: 500,
                        message: "Max character 500 allowed",
                      },
                    })}
                  />
                  {errors.description && (
                    <span role="alert" className="error_text">
                      {errors.description.message}
                    </span>
                  )}
                </div>
              </div>
              {!id ? (
                <div className=" col-md-12">
                  <div className="w-auto form-group filter_btn mt-auto">
                    <Button className="detail_button" type="submit">
                      Save
                    </Button>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default UserBio;
