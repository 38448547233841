import React, { Suspense, lazy, useEffect, useState } from "react";
import "./index.scss";
import { Backdrop, CircularProgress } from "@mui/material";
import { GetCallApi } from "../../../../../Action/Action";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { VscEdit } from "react-icons/vsc";

const DeletePopup = lazy(() =>
  import("../../../../Components/Reusable/DeletePopup/index.jsx")
);
const NewLIstTable = lazy(() =>
  import("../../../../Components/Reusable/NewLIstTable")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCells = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "storeName",
    label: "Name",
  },
  {
    id: "associationName",
    label: "Association Name",
  },
  {
    id: "description",
    label: "Description",
    widthFix: 600,
  },
];
function AssociationStore() {
  const [openCircle, setOpenCircle] = useState(false);
  const [dataOfTable, setDataOfTable] = useState([]);
  const { asid } = useParams();

  let deleteInfo = useSelector((state) => state.Common?.DeleteData);

  const Logdetails = useSelector((state) => state.login.LoginDetails);

  useEffect(() => {
    document.title = "FIG Events | Association Store";
    getAssoStoreList();
    // eslint-disable-next-line
  }, [deleteInfo]);
  const getAssoStoreList = async () => {
    setDataOfTable([]);
    let seData = {
      url: `/api/AssociationStore/GetAllAssociationStoreList?associationId=${asid}&userId=${Logdetails?.userId}`,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        let data = res.data.payload?.map((x, i) => {
          return {
            ...x,
            id: i + 1,

            Action: (
              <>
                <Link
                  to={`${x.associationStoreId}/product`}
                  className="btn_edit me-3 d-flex align-items-center"
                >
                  Add Product
                </Link>
                <Link
                  to={`edit/${x.associationStoreId}`}
                  className="btn_edit me-3 d-flex align-items-center"
                >
                  <VscEdit />
                </Link>

                <DeletePopup
                  title={"Delete Association Store"}
                  text={x?.storeName}
                  url={`/api/AssociationStore/DeleteAssociationStore?associationStoreId=${x?.associationStoreId}`}
                />
              </>
            ),
          };
        });
        setDataOfTable(data);
      }
    }
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="association_store_page">
          <div className="main_wrapper">
            <div className="new_card">
              <NewLIstTable
                addressLink={`create`}
                headCells={headCells}
                title="Association Store List"
                action={true}
                ListData={dataOfTable}
                dropdown={false}
                addBtn={true}
                fileName={"Association Store List"}
                exportDataList={false}
              />
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default AssociationStore;
