import React, { Suspense, useEffect, useState } from "react";
import Charts from "../../Components/Reusable/Chart/Charts";
import "./index.scss";
import Highcharts from "highcharts";
import DashboardBox from "../../Components/DashboardBox";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Axios from "../../../Utils/AxiosConfig";
import { Backdrop, CircularProgress, Tooltip } from "@mui/material";
// import { VscEye } from "react-icons/vsc";
// import { MdOutlineEventSeat } from "react-icons/md";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import DeletePopup from "../../Components/Reusable/DeletePopup";
import ImgPopup from "../../Components/ImgPopUp/index.jsx";
import { Config } from "../../../Utils/Config";
import NewLIstTable from "../../Components/Reusable/NewLIstTable/index.jsx";

const calendaruser = "/assets/icons/calendaruser.svg";
const eventschedule = "/assets/icons/event-schedule.svg";
const exhibitor = "/assets/icons/exhibitor.svg";
const floorplan = "/assets/icons/floorplan.svg";
const registerAsAttendee = "/assets/icons/registerAsAttendee.svg";
const editfloor = "/assets/icons/edit-floor.svg";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCellsEvPlanner = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "companycontactName",
    label: "Contact Name",
  },
  {
    id: "companyphoneNumber",
    label: "Phone Number",
  },
  {
    id: "companyemail",
    label: "Email",
  },
];
const headCellsPast = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "evnetName",
    label: "Conference Name",
  },
  {
    id: "contactNumber",
    label: "Contact Number",
  },
  {
    id: "startdate",
    label: "Start Date",
  },
  {
    id: "enddate",
    label: "End Date",
  },
];
const headCellsUser = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "conferenceName",
    label: "Conference Name",
  },
  {
    id: "paymentAmount",
    label: "Payment Amount",
  },
  {
    id: "paymentStatus",
    label: "Payment Status",
  },
  {
    id: "date",
    label: "Register Date",
  },
];
const headCellsConference = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "conferenceName",
    label: "Conference Name",
  },
  {
    id: "startDate",
    label: "Start Date",
  },
  {
    id: "endDate",
    label: "End Date",
  },
];
const headCellsLikeProduct = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "ImgIcon",
    label: "Image",
  },
  {
    id: "productName",
    label: "Product Name",
    widthFix: 600,
  },
  {
    id: "manufacturer",
    label: "Manufacturer",
  },
  {
    id: "exhibitorName",
    label: "Exhibitor Name",
  },
  {
    id: "companyName",
    label: "Company Name",
  },
  {
    id: "userName",
    label: "User Name",
  },
  {
    id: "createdOn",
    label: "Created Date",
  },
];
const headCellsConferenceTable = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "conferenceName",
    label: "Conference Name",
  },
  {
    id: "contactName",
    label: "Contact Name",
  },
  {
    id: "contactNumber",
    label: "Contact Number",
  },
];
function AdminDashboard() {
  const [openCircle, setOpenCircle] = useState(false);

  // let navigate = useNavigate();

  const [dataOfTable, setDataOfTable] = useState([]);
  // const [dataOfTable1, setDataOfTable1] = useState([]);
  const [dataOfTable2, setDataOfTable2] = useState([]);
  const [dataOfTable3, setDataOfTable3] = useState([]);
  const [dataOfTable4, setDataOfTable4] = useState([]);
  const [dataOfTable5, setDataOfTable5] = useState([]);
  const [likeProductList, setLikeProductList] = useState([]);
  const [conferenceTableList, setConferenceTableList] = useState([]);
  const [showConfandPastTable, setShowConfandPastTable] = useState("Default");

  let deleteInfo = useSelector((state) => state.Common?.DeleteData);

  useEffect(() => {
    document.title = "FIG Events | Home";
    if (logindetail?.roleId === 4 || logindetail?.roleName === "User") {
      GetUserList();
      GetCnNotRList();
    } else {
      // GetAllEventsList();
      GetCompanyList("Company");
      GetPastEventList();
      GetChartData();
      GetConferenceTableList();
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    GetLikePrdList();
    // eslint-disable-next-line
  }, [deleteInfo]);

  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const logindetail = useSelector((state) => state.login.LoginDetails);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const GetCompanyList = (key) => {
    setOpenCircle(true);
    Axios.get(
      `/api/Dashboard/GetCompanyORIndividualList?key=${key}&userId=${logindetail?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            let comList1 = res?.data?.payload?.map((c, i) => {
              return {
                id: i + 1,
                companycontactName: c?.contactName,
                companyphoneNumber: c?.phoneNumber,
                companyemail: c?.email,
              };
            });
            setDataOfTable(comList1);
          } else {
            setOpenCircle(false);
          }
        } else {
          setOpenCircle(false);
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  const GetPastEventList = () => {
    setOpenCircle(true);

    Axios.get(
      `/api/Dashboard/GetCompanyUserPastConference?userId=${logindetail?.userId}`,
      {
        headers,
      }
    )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);

            let comList = res?.data?.payload?.map((c, i) => {
              return {
                id: i + 1,
                eventId: c?.eventId,
                evnetName: c?.evnetName,
                contactNumber: c?.contactNumber,
                startdate: c?.startdate
                  ? convert(c?.startdate?.split("T")[0])
                  : "",
                enddate: c?.enddate ? convert(c?.enddate?.split("T")[0]) : "",
              };
            });
            setDataOfTable2(comList);
          } else {
            setOpenCircle(false);
          }
        } else {
          setOpenCircle(false);
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  const GetChartData = () => {
    setOpenCircle(true);
    Axios.get(
      `/api/Dashboard/GetMonthlyAverageEvent?userId=${logindetail?.userId}`,
      {
        headers,
      }
    )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);

            let comList1 = res?.data?.payload;
            setDataOfTable3(comList1);
          } else {
            setOpenCircle(false);
          }
        } else {
          setOpenCircle(false);
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };
  const GetUserList = async () => {
    setOpenCircle(true);
    let seData = {
      url: `/api/UserMst/UserRegisteredHistory?userId=${logindetail?.userId}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        let comList = res?.data?.payload?.map((c, i) => {
          return {
            id: i + 1,
            userName: c?.userName,
            phoneNumber: c?.phoneNumber,
            email: c?.email,
            phone: c?.phoneNumber,
            conferenceName: c?.conferenceName,
            userType: c?.userType,
            paymentAmount: c?.paymentAmount,
            paymentStatus: c?.paymentStatus,
            date: c?.date
              ? new Date(c.date)?.toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })
              : "",
          };
        });
        setDataOfTable4(comList);
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };
  const GetCnNotRList = async () => {
    setOpenCircle(true);
    let seData = {
      url: `/api/UserMst/UserNotRegisteredHistory?userId=${logindetail?.userId}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        let comList = res?.data?.payload?.map((c, i) => {
          return {
            ...c,
            id: i + 1,
            startDate: c?.startDate
              ? new Date(c.startDate).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })
              : "",
            endDate: c?.endDate
              ? new Date(c.endDate).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })
              : "",
            Action: (
              <Link
                className="btn_edit  text"
                to={`/conference-detail`}
                state={{ conferenceId: c?.conferenceId }}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                View Conference
              </Link>
            ),
          };
        });
        setDataOfTable5(comList);
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };
  const GetLikePrdList = async () => {
    setOpenCircle(true);
    let seData = {
      url: `/api/ExhibitorHall/GetProductListByAttendeeId?attendeeId=${logindetail?.userId}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        let comList = res?.data?.payload?.map((c, i) => {
          return {
            ...c,
            id: i + 1,
            ImgIcon: c?.productImage ? (
              <ImgPopup
                img={`${Config.API_HOST_URL_live}${c.productImage}`}
                addClass={"h_fix"}
                hide_icon={true}
              />
            ) : (
              ""
            ),
            createdOn: c?.createdOn
              ? new Date(c?.createdOn)?.toLocaleDateString("en-us", {
                  month: "2-digit",
                  year: "numeric",
                  day: "2-digit",
                })
              : "",
            Action: (
              <DeletePopup
                title={"Remove Like Product"}
                text={c?.productName}
                url={`/api/ExhibitorHall/DeleteProductByAttendeeId?attendeeId=${logindetail?.userId}&productId=${c?.productId}`}
              />
            ),
          };
        });
        setLikeProductList(comList);
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };

  const GetConferenceTableList = async () => {
    setOpenCircle(true);
    setConferenceTableList([]);
    let seData = {
      url: `/api/AttendeeDashboard/GetConferenceList?userId=${logindetail?.userId}`,
      headers: headers,
    };
    let res = await PostCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        let comList = res?.data?.payload?.map((c, i) => {
          return {
            id: i + 1,
            ...c,
            Action: (
              <>
                {c?.isRegister || !c?.isAttendeeRegister ? (
                  ""
                ) : (
                  <Tooltip title="Register as Attendee">
                    <Link
                      className="icon_btn me-2 "
                      to={`/conference-user-register/${c?.conferenceId}`}
                    >
                      <img src={registerAsAttendee} alt="" />
                    </Link>
                  </Tooltip>
                )}
                {c?.isExhiRegister ||
                !c?.isExhibitorRegister ||
                c?.isAllBoothBooked ? (
                  ""
                ) : (
                  <Tooltip title="Register as Exhibitor">
                    <Link
                      className="icon_btn me-2 "
                      to={`/conference-exhibotor-register/${c?.conferenceId}`}
                    >
                      <img src={exhibitor} alt="" />
                    </Link>
                  </Tooltip>
                )}
                <Tooltip title="View Conference Schedule">
                  <Link to={`/attendee-schedule`} className="icon_btn me-2">
                    {/* <Link to={`/conference-calendar`} className="btn_edit me-2"> */}
                    <img src={eventschedule} alt="" />
                  </Link>
                </Tooltip>
                <Tooltip title="My Schedule">
                  <Link to={`/my-schedule`} className="icon_btn me-2">
                    <img src={calendaruser} alt="" />
                  </Link>
                </Tooltip>
                {c?.isExhibitorRegister && (
                  <>
                    <Tooltip title="View Show Floor">
                      <Link
                        to={`/conference-floor-plan`}
                        className="icon_btn me-2"
                        state={{ conferenceId: c?.conferenceId }}
                      >
                        <img src={floorplan} alt="" />
                      </Link>
                    </Tooltip>
                    {c?.allowExit && (
                      <>
                        {/* <button
                          type="button"
                          className="btn_edit me-3 text"
                          onClick={() => handleFlorPlanEdit(c?.eventId)}
                        >
                          Edit Floor
                        </button>  */}
                        <Tooltip title="Edit Floor Plan">
                          <Link
                            to={`/floor-plan/edit/${c?.conferenceId}`}
                            className="icon_btn me-2"
                            // onClick={() => handleFlorPlanEdit(c?.eventId)}
                          >
                            <img src={editfloor} alt="" />
                          </Link>
                        </Tooltip>
                      </>
                    )}
                  </>
                )}
              </>
            ),
          };
        });
        setConferenceTableList(comList);
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };

  function convert(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  let chartOptions = {
    chart: {
      type: "column",
      height: 525 + "px",
    },
    title: {
      text: "Monthly Conference",
      align: "left",
      style: {
        color: "#1A1A1A",
        fontWeight: "600",
        fontSize: "26px",
      },
    },
    accessibility: {
      enabled: false, // Suppresses the warning
    },
    xAxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      crosshair: true,
    },
    yAxis: {
      // max:100,
      allowDecimals: false,
      min: 0,
      title: {
        text: "",
      },
    },
    legend: {
      enabled: true,
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<td style="padding:0"><b>{point.y} </b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Conference",
        data: dataOfTable3,
        color: "#F79153",
      },
    ],
  };

  const handleGetConferenceValue = (val) => {
    setShowConfandPastTable(val);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="admin_dashboard py-4">
          {logindetail?.roleId === 4 || logindetail?.roleName === "User" ? (
            <div className="main_wrapper">
              <div className="row">
                <div className="col-md-12 mb-4">
                  <div className="new_card p-0">
                    <NewLIstTable
                      title="Conference List"
                      action={true}
                      headCells={headCellsConference}
                      ListData={dataOfTable5}
                      dropdown={false}
                      addBtn={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="main_wrapper">
              {logindetail?.roleId === 1 ? (
                <div className="row mb-4">
                  <DashboardBox />
                </div>
              ) : (
                ""
              )}
              <div className="row">
                <div className="col-md-12 mb-4">
                  <div className="new_card p-0">
                    <NewLIstTable
                      title={
                        showConfandPastTable === "Default"
                          ? "Conference List"
                          : "Past Conference"
                      }
                      headCells={
                        showConfandPastTable === "Default"
                          ? headCellsConferenceTable
                          : headCellsPast
                      }
                      action={showConfandPastTable === "Default" ? true : false}
                      ListData={
                        showConfandPastTable === "Default"
                          ? conferenceTableList
                          : dataOfTable2
                      }
                      dropdown={false}
                      btnshow={
                        showConfandPastTable === "Default" ? true : false
                      }
                      exportBtn={false}
                      beforeSearchDropdown={true}
                      beforeSearchDropdownCall={handleGetConferenceValue}
                      beforeSearchDropdownArray={[
                        { value: "Default", label: "Default" },
                        { value: "Past", label: "Past" },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {logindetail?.roleId === 4 || logindetail?.roleName === "User" ? (
            <div className="main_wrapper">
              <div className="row">
                <div className="col-md-12">
                  <NewLIstTable
                    title="Conference Register List"
                    action={false}
                    headCells={headCellsUser}
                    ListData={dataOfTable4}
                    dropdown={false}
                    addBtn={false}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="main_wrapper">
              <div className="row">
                {logindetail?.roleId === 2 ? (
                  ""
                ) : (
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-4">
                    <div className="new_card p-0">
                      <NewLIstTable
                        title=""
                        headCells={headCellsEvPlanner}
                        action={false}
                        ListData={dataOfTable}
                        dropdown={true}
                        Company={GetCompanyList}
                      />
                    </div>
                  </div>
                )}
                <div
                  className={` chart_sec ${
                    logindetail?.roleId === 2
                      ? "col-md-12"
                      : "col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12"
                  }`}
                >
                  {/* <div className="title mb-4">Monthly Average Event</div> */}
                  <div className="card">
                    <div className="card-body">
                      <Charts series={chartOptions} highcharts={Highcharts} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12">
                <div className="new_card p-0">
                  <NewLIstTable
                    title="Like Product List"
                    action={true}
                    headCells={headCellsLikeProduct}
                    ListData={likeProductList}
                    dropdown={false}
                    addBtn={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default AdminDashboard;
