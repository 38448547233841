import React from "react";
// import { useSelector } from "react-redux";
import Navigation from "../Shared/Navigation";

function PublicRoute({ children }) {
  return (
    <>
      <Navigation />
      {children}
    </>
  );
}

export default PublicRoute;
