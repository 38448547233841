import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import "./index.scss";
import { useParams } from "react-router-dom";
import Axios from "../../../../../Utils/AxiosConfig";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DetailShowPopup from "../../../../Components/Reusable/DetailShowPopup";
import { PostCallApi } from "../../../../../Action/Action";
import { Config } from "../../../../../Utils/Config";

function DragableBoxBook(props) {
  const { evD, canEdit, sboothId, setSboothId } = props;
  const [deltaPositionSave, setDeltaPositionSave] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [comDl, setComDl] = React.useState(false);
  const handleOpen = (detail) => {
    setOpen(true);
    setComDl(detail);
  };
  const handleClose = () => {
    setOpen(false);
    setComDl();
  };
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const loginDetail = useSelector((state) => state.login.LoginDetails);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const { id, userId, pId } = useParams();

  useEffect(() => {
    if (id) {
      let eID = id;
      getBoothData(eID);
    }
    // eslint-disable-next-line
  }, [id]);
  const getBoothData = (eID) => {
    setDeltaPositionSave();
    Axios.get(
      `/api/ConferenceBooths/GetConferenecBoothsStatusByConferenceId?conferenceId=${eID}&BoothBookingPaymentId=${pId}`,
      {
        headers,
      }
    )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            let pn = res.data.payload?.map((p) => {
              return {
                ...p,
                ...p.exhibitor,
                ...p.exhibitor?.exhibitorCompanyDetails,
                sequenceNumber: p?.sequenceNumber,
                value: p?.eventBoothsId,
                label: p?.seatEventNumber,
                x: p?.positionX,
                y: p?.positionY,
                width: p?.width,
                height: p?.hight,
                status: p?.status,
                oneExhibitorList: p?.exhibitor,
                exhibitorList: p?.exhibitor,
              };
            });
            setDeltaPositionSave(pn);
          }
        }
      })
      .catch((e) => console.log(e));
  };

  const ApproveRejectBooth = async (uId, st) => {
    let eID = id?.split("&userId=")[0];
    let arData = {
      conferenceBoothId: comDl?.value,
      conferenceId: eID,
      eventBoothsId: comDl?.value,
      boothNumber: comDl?.label,
      exhibitorId: uId,
      eventId: eID,
      status: st ? st : "Approve",
      approveByUserId: loginDetail?.userId,
      userId: loginDetail?.userId,
      BoothBookingPaymentId: pId,
      exhibitorsId: comDl?.exhibitorsId,
      exhibitorName: comDl?.exhibitorName,
      exhibitorEmail: comDl?.emailId,
      cancelByUserName: loginDetail?.firstName + " " + loginDetail?.lastName,
      cancelByUserId: loginDetail?.userId,
    };

    let seData = {
      url:
        st === "Approve"
          ? `/api/ExhibitorBoothAllocation/ApproveRejectConferenceBooths`
          : `/api/ExhibitorBoothAllocation/CancellationConferenceBoothByVendorOrAdmin`,
      body: arData,
      headers: headers,
    };
    let res = await PostCallApi(seData);

    if (res.status === 200) {
      if (res.data.success) {
        // setOpenCircle(false);
        handleClose();

        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        getBoothData(id);
        // setTimeout(function () {
        //   // handleClose();
        //   nav("/event-company-register-list", { replace: true });
        // }, 2000);
      } else {
        // setOpenCircle(false);
        toast.error(res.data.message, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } else {
      // setOpenCircle(false);
      toast.error(res.data.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  useEffect(() => {
    if (sboothId) {
      setTimeout(() => {
        setSboothId();
      }, 3000);
    }
    // eslint-disable-next-line
  }, [sboothId]);
  return (
    <>
      <div className="editdrop_new">
        <div
          className="box"
          style={{
            height: evD?.height ? evD?.height - 290 : "680px",
            width: "100%",
            position: "relative",
            overflow: "auto",
            padding: "0",
          }}
        >
          <div
            style={{
              height: "100%",
              width: evD?.width ? evD?.width - 150 : "1770px",
              padding: "10px",
              zIndex: "999",
              position: "relative",
            }}
          >
            {deltaPositionSave &&
              deltaPositionSave?.map((drag, k) => (
                <Draggable
                  key={k}
                  bounds="parent"
                  handle=".child"
                  position={{
                    x: drag.x,
                    y: drag.y,
                  }}
                  onStart={() => false}
                >
                  {drag?.type === "wall" ? (
                    <div
                      className={`box_small wall`}
                      style={{
                        width: `${drag?.width ? drag.width : "28"}px`,
                        height: `${drag?.height ? drag.height : "28"}px`,
                        background: drag?.type === "wall" ? "black" : "",
                      }}
                    ></div>
                  ) : drag?.type === "door" ? (
                    <div
                      className={`box_small door`}
                      style={{
                        width: `${drag?.width ? drag.width : "28"}px`,
                        height: `${drag?.height ? drag.height : "28"}px`,
                      }}
                    >
                      <img
                        src={"/assets/icons/door.svg"}
                        className="child"
                        alt=""
                        style={{
                          width: `100%`,
                          height: `100%`,
                        }}
                      />
                    </div>
                  ) : drag?.status === "Approve" ||
                    drag?.status === "MyBooking" ? (
                    <div
                      className={`child box_small ${
                        drag?.value === sboothId
                          ? "blink_me booked"
                          : drag?.status === "Approve"
                          ? "booked"
                          : drag?.status === "MyBooking"
                          ? "my_booked"
                          : ""
                      }`}
                      style={{
                        width: `${drag?.width ? drag.width : "28"}px`,
                        height: `${drag?.height ? drag.height : "28"}px`,
                        backgroundImage: drag?.companyLogo
                          ? `url(${Config.API_HOST_URL_live}${drag?.companyLogo})`
                          : `url(${Config.API_HOST_URL_live}${drag?.exhibitor?.exhibitorProfileImage})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                      onClick={() => handleOpen(drag)}
                    >
                      <div>{drag?.label}</div>
                    </div>
                  ) : drag?.exhibitorList?.exhibitorsId === userId ? (
                    <div
                      className={`child box_small selected `}
                      style={{
                        width: `${drag?.width ? drag.width : "28"}px`,
                        height: `${drag?.height ? drag.height : "28"}px`,
                      }}
                      onClick={() => handleOpen(drag)}
                    >
                      <div>{drag?.label}</div>
                    </div>
                  ) : drag?.status === "InProgress" ? (
                    <div
                      className={`child box_small
                         my_booked
                        
                      `}
                      style={{
                        width: `${drag?.width ? drag.width : "26"}px`,
                        height: `${drag?.height ? drag.height : "26"}px`,
                      }}
                    >
                      <div>{drag?.label}</div>
                    </div>
                  ) : (
                    <div
                      className={`child box_small availabel `}
                      style={{
                        width: `${drag?.width ? drag.width : "28"}px`,
                        height: `${drag?.height ? drag.height : "28"}px`,
                      }}
                    >
                      <div>{drag?.label}</div>
                    </div>
                  )}
                </Draggable>
              ))}
          </div>
        </div>
      </div>
      <DetailShowPopup
        openbox={open}
        closebox={setOpen}
        title="Company Details"
        BS={comDl}
        item={comDl}
        canEdit={canEdit}
        gotoBtn={true}
        allBooth={deltaPositionSave}
        getBox={() => getBoothData(id)}
        approvBooth={ApproveRejectBooth}
        evD={evD}
      />
    </>
  );
}

export default DragableBoxBook;
