import React, { useEffect, useRef, useState } from "react";
import logo from "../../../../../../assets/images/fig_events.png";
import "./index.scss";
import jsPDF from "jspdf";
import {
  Backdrop,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F6FC",
  },
  padding: "5px",
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f6f6f6",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F1F6FC",
  },
  // hide last border
  //   "&:last-child td, &:last-child th": {
  //     border: 0,
  //   },
}));
function AssociationUserInvoice(props) {
  const { handleReset, ATRC } = props;
  const [open, setOpen] = useState(false);
  const [downloadPdf, setDownloadpdf] = useState(false);
  const tableRef = useRef(null);
  let Time = new Date();
  let nav = useNavigate();
  const loinDetail = useSelector((state) => state.login.LoginDetails);
  function convertDate(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }
  const handleDownloadPdf = async () => {
    // return false;
    setOpen(true);

    const element = tableRef.current;
    let newPdfDate =
      convertDate(
        Time.toLocaleString("en-us", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")
      ) +
      " " +
      Time.toLocaleTimeString();
    // return false;
    const doc = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
    doc
      .html(element, {
        margin: [20, 10, 20, 10],
        autoPaging: "text",
        html2canvas: { scale: 0.57 },
      })
      .then(async () => {
        let totalPages = doc.internal.pages.length - 1;
        for (let i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          doc.setFontSize(10);
          doc.setTextColor(150);
          doc.text(
            newPdfDate,
            doc.internal.pageSize.width / 2,
            doc.internal.pageSize.getHeight() - 10,
            {
              align: "center",
            }
          );
        }
        doc.save(`invoice.pdf`);
        setOpen(false);
        setDownloadpdf(false);
        nav("/association-list", { replace: true });

        handleReset();
      });
  };
  useEffect(() => {
    if (downloadPdf) {
      handleDownloadPdf();
    }
    // eslint-disable-next-line
  }, [downloadPdf]);
  const back = {
    maxWidth: `${downloadPdf ? "200mm" : "200mm"}`,
    // maxWidth: `${downloadPdf ? "200mm" : "100%"}`,
    width: "100%",
    height: "100%",
    position: "relative",
    margin: "0",
  };
  let newtime = new Date();
  newtime?.setDate(newtime?.getDate() + 30);
  newtime = newtime.toLocaleString("en-us", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  return (
    <>
      <Button
        className="detail_button mb-3"
        onClick={() => setDownloadpdf(true)}
      >
        Download
      </Button>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="preview-invoice-user" ref={tableRef} style={back}>
        <div className="invoice-section">
          <div className="row">
            <div className="col-lg-4">
              <div className="logo">
                <img
                  src={logo}
                  style={{
                    height: "60px",
                  }}
                  alt="Confrence Planner"
                  title="Confrence Planner"
                />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="d-flex align-items-center gap-3 justify-content-end">
                <h2>Invoice</h2>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-6  mb-4">
              <h5 className="text-uppercase">{ATRC?.name}</h5>
              <div className="Billto-wrap">
                <p>{ATRC?.paymentRemittanceAddress}</p>
                <p>
                  {ATRC?.paymentCity +
                    " " +
                    ATRC?.paymentState +
                    " " +
                    ATRC?.paymentZip}
                </p>
              </div>
            </div>
            <div className="col-lg-6 text-end">
              <div className="invoice-details">
                <ul>
                  <li>
                    <span className="primarylight-color">PO Number</span>
                    <span className="invoice-text">
                      {ATRC?.data?.purchaseOrderNumber}
                    </span>
                  </li>
                  <li>
                    <span className="primarylight-color">Invoice Date</span>
                    <span className="invoice-text">
                      {Time.toLocaleString("en-us", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })}
                    </span>
                  </li>
                  <li>
                    <span className="primarylight-color">Due Date</span>
                    <span className="invoice-text">{newtime}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6  mb-4">
              <h5 className="text-uppercase">Billed To</h5>
              <div className="Billto-wrap">
                <p>
                  {ATRC?.associationMemberName
                    ? ATRC?.associationMemberName
                    : ATRC?.companyName}
                </p>
                <p>{ATRC?.data?.name}</p>
                <p>{ATRC?.data?.address1}</p>
                <p>
                  {ATRC?.data?.city +
                    " " +
                    ATRC?.data?.State +
                    " " +
                    ATRC?.data?.zip}
                </p>
                <p>{ATRC?.data?.email}</p>
              </div>
            </div>
          </div>
          <div className="table-wrap mt-5">
            <Table className="table-bordered Conference-table">
              <TableHead className="thead-primary">
                <StyledTableRow>
                  <StyledTableCell
                    className="txt_st"
                    style={{
                      borderTop: "1px solid #808080",
                    }}
                  >
                    Item
                  </StyledTableCell>

                  <StyledTableCell
                    className="txt_st"
                    style={{
                      borderTop: "1px solid #808080",
                    }}
                  >
                    Line Total
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell>
                    {ATRC?.name}
                    <div style={{ maxWidth: "500px" }}>
                      Description: {ATRC?.associationMenbershipTypeDescription}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>${ATRC?.amount}</StyledTableCell>
                </StyledTableRow>
                {ATRC?.discountValue ? (
                  <StyledTableRow>
                    <StyledTableCell
                      style={{
                        backgroundColor: "white",
                        textAlign: "right",
                        borderBottom: "1px solid #808080",
                      }}
                    >
                      Discount
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        backgroundColor: "white",
                        borderBottom: "1px solid #808080",
                      }}
                    >
                      -${ATRC?.discountValue}
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  ""
                )}
                <StyledTableRow>
                  <StyledTableCell
                    style={{
                      backgroundColor: "white",
                      textAlign: "right",
                      borderBottom: "1px solid #808080",
                    }}
                  >
                    <b>Total</b>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      backgroundColor: "white",
                      borderBottom: "1px solid #808080",
                    }}
                  >
                    <b>${ATRC?.subtotal}</b>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </div>
          <div className="table-wrap mt-3">
            <h5>User Details</h5>
            <Table className="table-bordered Conference-table">
              <TableHead className="thead-primary">
                <StyledTableRow>
                  <StyledTableCell
                    className="txt_st"
                    style={{
                      borderTop: "1px solid #808080",
                    }}
                  >
                    User Name
                  </StyledTableCell>
                  <StyledTableCell
                    className="txt_st"
                    style={{
                      borderTop: "1px solid #808080",
                    }}
                  >
                    Email
                  </StyledTableCell>
                  <StyledTableCell
                    className="txt_st"
                    style={{
                      borderTop: "1px solid #808080",
                    }}
                  >
                    Phone
                  </StyledTableCell>
                  <StyledTableCell
                    className="txt_st"
                    style={{
                      borderTop: "1px solid #808080",
                    }}
                  >
                    Price
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell>
                    {loinDetail?.firstName} {loinDetail?.lastName}
                  </StyledTableCell>
                  <StyledTableCell>{loinDetail?.email}</StyledTableCell>
                  <StyledTableCell>{loinDetail?.phone}</StyledTableCell>
                  <StyledTableCell>${ATRC?.subtotal}</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </div>

          <div className="row mt-5">
            <div className="col-lg-12 text-lg-end mt-3">
              <h5 className="mt-lg-7">Becki Swan</h5>
              <p>Co-founder, Fig Events</p>
            </div>
          </div>
          <div className="row term-text">
            <div className="col-md-12">
              <h5>Invoice Instructions</h5>
              <ReactQuill
                value={ATRC?.data?.editorValue}
                readOnly={true}
                theme={"bubble"}
              />
            </div>
          </div>
          {/* <div className="row term-text">
            <div className="col-md-12">
              <h5>Terms &amp; Conditions</h5>
              <ul className="ps-3">
                <li className="primarylight-color">
                  {" "}
                  Please pay within 30 days from the date of invoice, overdue
                  interest @ 14% will be charged on delayed payments.
                </li>
              </ul>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
}

export default AssociationUserInvoice;
