import React, { Suspense } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import CRMHeader from "../Shared/CRMHeader";
import { FaPlus } from "react-icons/fa6";
import phonepay from "../../../assets/images/phonepay-img.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { RxCross2 } from "react-icons/rx";
import { BsUpload } from "react-icons/bs";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Button, CircularProgress } from "@mui/material";
import { LuCircle } from "react-icons/lu";
import { IoCallOutline } from "react-icons/io5";
import { MdMonitor } from "react-icons/md";
import { TbAlertTriangle } from "react-icons/tb";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import kickstarter from "../../../assets/images/kickstarter.png";
import mozilla from "../../../assets/images/mozilla.png";
import propswala from "../../../assets/images/propswala.png";
import icon8 from "../../../assets/images/icon8.png";
import morris from "../../../assets/images/morris.png";
import diamond from "../../../assets/images/diamond.png";
import itf from "../../../assets/images/itf.png";
import intercom from "../../../assets/images/intercom.png";
import figma from "../../../assets/images/figma.png";

const avatar = "/assets/images/avatar-sm.jpg";
const behance_img = "/assets/Images/behance.png";
const city = "/assets/images/city-deals.png";
const codepen = "/assets/images/codepen.png";
const coursera = "/assets/images/coursera-img.png";
const daily = "/assets/images/daily.png";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  maxHeight: "80%",
};

// const data =
//   {
//     "lanes": [
//       {
//         "id": "PLANNED",
//         // "title": "Planned Tasks",
//         // "label": "20/70",
//         // "style": {"width": 280},
//         "cards": [
//           {
//             "id": "Milk",
//             "image": "milk",
//             "title": "Phone pay",
//             "label": "15 mins",
//             "cardStyle": { "width": 300, "maxWidth": 320, "margin": "auto", "marginBottom": 7 },
//             "description": "$5,201",
//           },
//           {
//             "id": "Plan2",
//             "title": "Dispose Garbage",
//             "label": "10 mins",
//             "cardStyle": { "width": 270, "maxWidth": 270, "margin": "auto", "marginBottom": 5 },
//             "description": "Sort out recyclable and waste as needed"
//           },
//           {
//             "id": "Plan3",
//             "title": "Write Blog",
//             "label": "30 mins",
//             "cardStyle": { "width": 270, "maxWidth": 270, "margin": "auto", "marginBottom": 5 },
//             "description": "Can AI make memes?"
//           },
//           {
//             "id": "Plan4",
//             "title": "Pay Rent",
//             "label": "5 mins",
//             "cardStyle": { "width": 270, "maxWidth": 270, "margin": "auto", "marginBottom": 5 },
//             "description": "Transfer to bank account"
//           }
//         ]
//       },
//       {
//         "id": "WIP",
//         // "title": "Work In Progress",
//         // "label": "10/20",
//         // "style": {"width": 280},
//         "cards": [
//           {
//             "id": "Wip1",
//             "title": "Clean House",
//             "label": "30 mins",
//             "cardStyle": { "width": 270, "maxWidth": 270, "margin": "auto", "marginBottom": 5 },
//             "description": "Soap wash and polish floor. Polish windows and doors. Scrap all broken glasses"
//           }
//         ]
//       },
//       {
//         "id": "BLOCKED",
//         // "title": "Blocked",
//         // "label": "0/0",
//         // "style": {"width": 280},
//         "cards": []
//       },
//       {
//         "id": "COMPLETED",
//         // "title": "Completed",
//         // "style": {"width": 280},
//         // "label": "2/5",
//         "cards": [
//           {
//             "id": "Completed1",
//             "title": "Practice Meditation",
//             "label": "15 mins",
//             "cardStyle": { "width": 270, "maxWidth": 270, "margin": "auto", "marginBottom": 5 },
//             "description": "Use Headspace app"
//           },
//           {
//             "id": "Completed2",
//             "title": "Maintain Daily Journal",
//             "label": "15 mins",
//             "cardStyle": { "width": 270, "maxWidth": 270, "margin": "auto", "marginBottom": 5 },
//             "description": "Use Spreadsheet for now"
//           }
//         ]
//       },
//       {
//         "id": "REPEAT",
//         // "title": "Repeat",
//         // "style": {"width": 280},
//         // "label": "1/1",
//         "cards": [
//           {
//             "id": "Repeat1",
//             "title": "Morning Jog",
//             "label": "30 mins",
//             "cardStyle": { "width": 270, "maxWidth": 270, "margin": "auto", "marginBottom": 5 },
//             "description": "Track using fitbit"
//           }
//         ]
//       },
//       // {
//       //   "id": "ARCHIVED",
//       //   "title": "Archived",
//       //   "style": {"width": 280},
//       //   "label": "1/1",
//       //   "cards": [
//       //     {
//       //       "id": "Archived1",
//       //       "title": "Go Trekking",
//       //       "label": "300 mins",
//       //       "cardStyle": { "width": 270, "maxWidth": 270, "margin": "auto", "marginBottom": 5 },
//       //       "description": "Completed 10km on cycle"
//       //     }
//       //   ]
//       // },
//       // {
//       //   "id": "ARCHIVED2",
//       //   "title": "Archived2",
//       //   "style": {"width": 280},
//       //   "label": "1/1",
//       //   "cards": [
//       //     {
//       //       "id": "Archived1",
//       //       "title": "Go Trekking",
//       //       "label": "300 mins",
//       //       "cardStyle": { "width": 270, "maxWidth": 270, "margin": "auto", "marginBottom": 5 },
//       //       "description": "Completed 10km on cycle"
//       //     }
//       //   ]
//       // },
//       // {
//       //   "id": "ARCHIVED3",
//       //   "title": "Archived3",
//       //   "style": {"width": 280},
//       //   "label": "1/1",
//       //   "cards": [
//       //     {
//       //       "id": "Archived1",
//       //       "title": "Go Trekking",
//       //       "label": "300 mins",
//       //       "cardStyle": { "width": 270, "maxWidth": 270, "margin": "auto", "marginBottom": 5 },
//       //       "description": "Completed 10km on cycle"
//       //     }
//       //   ]
//       // }
//     ]
//   }
// const handleDragStart = (cardId, laneId) => {
//   console.log('drag started')
//   console.log(`cardId: ${cardId}`)
//   console.log(`laneId: ${laneId}`)
// }

// const handleDragEnd = (cardId, sourceLaneId, targetLaneId) => {
//   console.log('drag ended')
//   console.log(`cardId: ${cardId}`)
//   console.log(`sourceLaneId: ${sourceLaneId}`)
//   console.log(`targetLaneId: ${targetLaneId}`)
// }

function Pipeline() {
  const [open, setOpen] = React.useState(false);
  const [openlist, setOpenlist] = React.useState(false);
  const [openedit, setOpenedit] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenlist = () => setOpenlist(true);
  const handleCloselist = () => setOpenlist(false);
  const handleOpenedit = () => setOpenedit(true);
  const handleCloseedit = () => setOpenedit(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const open2 = Boolean(anchorEl1);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCardClick = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const handleCardClose = () => {
    setAnchorEl1(null);
  };
  //   const  [boardData,setBoardData] = useState([])
  //   const  [eventBus,setEventBus] = useState([])

  //   useEffect( async()=>{
  //     const response = await getBoard()
  //     setBoardData(response)
  //   },[])

  //  const getBoard = () => {
  //     return new Promise((resolve) => {
  //       resolve(data)
  //     })
  //   }
  //   const  addCard = () => {
  //     eventBus.publish({
  //       type: 'ADD_CARD',
  //       laneId: 'BLOCKED',
  //       card: {
  //         id: 'Ec2Error',
  //         title: 'EC2 Instance Down',
  //         label: '30 mins',
  //         description: 'Main EC2 instance down',
  //       },
  //     })
  //   }

  //   const shouldReceiveNewData = (nextData) => {
  //     console.log('New card has been added')
  //     console.log(nextData)
  //   }

  //   const handleCardAdd = (card, laneId) => {
  //     console.log(`New card added to lane ${laneId}`)
  //     console.dir(card)
  //   }

  return (
    <>
      <CRMHeader />
      <Suspense fallback={renderLoader()}>
        <section className="pipeline-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="pipeline-hedaer">
                  <div className="sales-pipline">
                    <h4>Sales Pipeline</h4>
                    <button
                      type="text"
                      className="deal-btn"
                      onClick={handleOpen}
                    >
                      Add Deal
                    </button>
                  </div>
                  <div className="d-md-flex flex-shrink-0 mx-3 d-none piplne-text">
                    <div className="d-flex align-items-center">
                      <span className="d-md-inline d-none">
                        Pipeline Value:
                      </span>
                      <span className="text-dark fs-5 fw-medium ps-2">
                        $23,706
                      </span>
                    </div>
                    <div className="v-separator"></div>
                    <div className="d-flex align-items-center">
                      <span className="d-md-inline d-none">Total Value:</span>
                      <span className="text-dark fs-5 fw-medium ps-2">
                        $100,000
                      </span>
                    </div>
                  </div>
                  <input
                    type="search"
                    placeholder="Search"
                    className="form-control w-auto"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="pipline-box-section">
                <div className="artical-card">
                  <div className="card-box">
                    <div className="card-header">
                      <h6>Opportunity</h6>
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                        className="icon-btn"
                      >
                        <HiOutlineDotsHorizontal />
                      </IconButton>
                    </div>
                    <div className="card-content">
                      <span>
                        <span className="overall-estimation">$123,453</span>
                        <span className="spipeline-dot-sep">●</span>
                        <span className="lead-count">11 Leads</span>
                      </span>
                    </div>
                    <button
                      type="tetx"
                      className="addcard-btn"
                      onClick={handleOpen}
                    >
                      {" "}
                      <FaPlus /> Add Card
                    </button>
                  </div>
                  <div className="card-inner">
                    <div className="card-inner-block">
                      <div className="image">
                        <img src={phonepay} alt="" />
                      </div>
                      <div className="card-inner-body">
                        <div className="brand-name">Phone pay</div>
                        <div className="price-estimation">$5,201</div>
                        <div className="brand-cat">Chatbot</div>
                        <div className="media">
                          <div className="image">
                            <img src={avatar} alt="" />
                          </div>
                          <p>Yesterday</p>
                        </div>
                      </div>
                      <div
                        className="left-icon bg-red"
                        aria-label="more"
                        id="card-button"
                        aria-controls={open ? "card-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleCardClick}
                      >
                        <FaAngleLeft />
                      </div>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="card-inner-block">
                      <div className="image">
                        <img src={coursera} alt="" />
                      </div>
                      <div className="card-inner-body">
                        <div className="brand-name">Coursera</div>
                        <div className="price-estimation">$6,542</div>
                        <div className="brand-cat">Food Order</div>
                        <div className="media">
                          <div className="image">
                            <img src={avatar} alt="" />
                          </div>
                          <p>2 days</p>
                        </div>
                      </div>
                      <div
                        className="left-icon bg-sky"
                        aria-label="more"
                        id="card-button"
                        aria-controls={open ? "card-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleCardClick}
                      >
                        <FaAngleLeft />
                      </div>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="card-inner-block">
                      <div className="image">
                        <img src={kickstarter} alt="kickstarter" />
                      </div>
                      <div className="card-inner-body">
                        <div className="brand-name">Kickstarter</div>
                        <div className="price-estimation">$9,425</div>
                        <div className="brand-cat">Publishing Co</div>
                        <div className="media">
                          <div className="image">
                            <img src={avatar} alt="" />
                          </div>
                          <p>28 days</p>
                        </div>
                      </div>
                      <div
                        className="left-icon bg-lightcolor"
                        aria-label="more"
                        id="card-button"
                        aria-controls={open ? "card-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleCardClick}
                      >
                        <FaAngleRight />
                      </div>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="card-inner-block">
                      <div className="image">
                        <img src={codepen} alt="" />
                      </div>
                      <div className="card-inner-body">
                        <div className="brand-name">Codepen</div>
                        <div className="price-estimation">$901</div>
                        <div className="brand-cat">Design App</div>
                        <div className="media">
                          <div className="image">
                            <img src={avatar} alt="" />
                          </div>
                          <p>10 days</p>
                        </div>
                      </div>
                      <div
                        className="left-icon bg-yellow"
                        aria-label="more"
                        id="card-button"
                        aria-controls={open ? "card-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleCardClick}
                      >
                        <TbAlertTriangle />
                      </div>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="card-inner-block">
                      <div className="image">
                        <img src={mozilla} alt="" />
                      </div>
                      <div className="card-inner-body">
                        <div className="brand-name">Mozilla Ubisoft</div>
                        <div className="price-estimation">$14,546</div>
                        <div className="brand-cat">Dashboard Template</div>
                        <div className="media">
                          <div className="image">
                            <img src={avatar} alt="" />
                          </div>
                          <p>13 days</p>
                        </div>
                      </div>
                      <div
                        className="left-icon bg-lightcolor"
                        aria-label="more"
                        id="card-button"
                        aria-controls={open ? "card-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleCardClick}
                      >
                        <FaAngleRight />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="artical-card">
                  <div className="card-box">
                    <div className="card-header">
                      <h6>Proposed</h6>
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                        className="icon-btn"
                      >
                        <HiOutlineDotsHorizontal />
                      </IconButton>
                    </div>
                    <div className="card-content">
                      <span>
                        <span className="overall-estimation">$23,456</span>
                        <span className="spipeline-dot-sep">●</span>
                        <span className="lead-count">3 Leads</span>
                      </span>
                    </div>
                    <button
                      type="tetx"
                      className="addcard-btn"
                      onClick={handleOpen}
                    >
                      {" "}
                      <FaPlus /> Add Card
                    </button>
                  </div>
                  <div className="card-inner">
                    <div className="card-inner-block">
                      <div className="image">
                        <img src={propswala} alt="" />
                      </div>
                      <div className="card-inner-body">
                        <div className="brand-name">Propswala</div>
                        <div className="price-estimation">$3,542</div>
                        <div className="brand-cat">Food Order</div>
                        <div className="media">
                          <div className="image">
                            <img src={avatar} alt="" />
                          </div>
                          <p>9 days</p>
                        </div>
                      </div>
                      <div
                        className="left-icon bg-red"
                        aria-label="more"
                        id="card-button"
                        aria-controls={open ? "card-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleCardClick}
                      >
                        <FaAngleLeft />
                      </div>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="card-inner-block">
                      <div className="image">
                        <img src={icon8} alt="" />
                      </div>
                      <div className="card-inner-body">
                        <div className="brand-name">Icons 8</div>
                        <div className="price-estimation">$9,425</div>
                        <div className="brand-cat">Chatbot</div>
                        <div className="media">
                          <div className="image">
                            <img src={avatar} alt="" />
                          </div>
                          <p>a month ago</p>
                        </div>
                      </div>
                      <div
                        className="left-icon bg-lightcolor"
                        aria-label="more"
                        id="card-button"
                        aria-controls={open ? "card-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleCardClick}
                      >
                        <FaAngleRight />
                      </div>
                    </div>
                  </div>
                  <div className="card-inner bg-lightpink">
                    <div className="card-inner-block">
                      <div className="image bg-lightcircle">
                        <img src={behance_img} alt="kickstarter" />
                      </div>
                      <div className="card-inner-body">
                        <div className="brand-name">Behance</div>
                        <div className="price-estimation">$4,560</div>
                        <div className="brand-cat">Food Order</div>
                        <div className="media">
                          <div className="image">
                            <img src={avatar} alt="" />
                          </div>
                          <p>a month ago</p>
                        </div>
                      </div>
                      <div
                        className="left-icon bg-sky"
                        aria-label="more"
                        id="card-button"
                        aria-controls={open ? "card-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleCardClick}
                      >
                        <FaAngleRight />
                      </div>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="card-inner-block">
                      <div className="image">
                        <img src={morris} alt="" />
                      </div>
                      <div className="card-inner-body">
                        <div className="brand-name">Morris Jet</div>
                        <div className="price-estimation">$5,217</div>
                        <div className="brand-cat">Publishing Co</div>
                        <div className="media">
                          <div className="image">
                            <img src={avatar} alt="" />
                          </div>
                          <p>a month ago</p>
                        </div>
                      </div>
                      <div
                        className="left-icon bg-lightcolor"
                        aria-label="more"
                        id="card-button"
                        aria-controls={open ? "card-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleCardClick}
                      >
                        <FaAngleRight />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="artical-card">
                  <div className="card-box">
                    <div className="card-header">
                      <h6>follow up</h6>
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                        className="icon-btn"
                      >
                        <HiOutlineDotsHorizontal />
                      </IconButton>
                    </div>
                    <div className="card-content">
                      <span>
                        <span className="overall-estimation">$5,268</span>
                        <span className="spipeline-dot-sep">●</span>
                        <span className="lead-count">7 Leads</span>
                      </span>
                    </div>
                    <button
                      type="tetx"
                      className="addcard-btn"
                      onClick={handleOpen}
                    >
                      {" "}
                      <FaPlus /> Add Card
                    </button>
                  </div>
                  <div className="card-inner">
                    <div className="card-inner-block">
                      <div className="image-box bg-lightpink">
                        <p className="primaryred-color fontw500">C</p>
                      </div>
                      <div className="card-inner-body">
                        <div className="brand-name">Challenger</div>
                        <div className="price-estimation">$156</div>
                        <div className="brand-cat">Chatbot</div>
                        <div className="media">
                          <div className="image">
                            <img src={avatar} alt="" />
                          </div>
                          <p>a month ago</p>
                        </div>
                      </div>
                      <div
                        className="left-icon bg-lightcolor"
                        aria-label="more"
                        id="card-button"
                        aria-controls={open ? "card-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleCardClick}
                      >
                        <FaAngleRight />
                      </div>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="card-inner-block">
                      <div className="image-box bg-greencircle">
                        <p className="fontw500">A</p>
                      </div>
                      <div className="card-inner-body">
                        <div className="brand-name">Atmanirbhar</div>
                        <div className="price-estimation">$2,456</div>
                        <div className="brand-cat">Food Order</div>
                        <div className="media">
                          <div className="image">
                            <img src={avatar} alt="" />
                          </div>
                          <p>9 days</p>
                        </div>
                      </div>
                      <div
                        className="left-icon bg-sky"
                        aria-label="more"
                        id="card-button"
                        aria-controls={open ? "card-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleCardClick}
                      >
                        <FaAngleRight />
                      </div>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="card-inner-block">
                      <div className="image">
                        <img src={diamond} alt="" />
                      </div>
                      <div className="card-inner-body">
                        <div className="brand-name">Sketch</div>
                        <div className="price-estimation">$1,760</div>
                        <div className="brand-cat">Publishing Co</div>
                        <div className="media">
                          <div className="image">
                            <img src={avatar} alt="" />
                          </div>
                          <p>5 days</p>
                        </div>
                      </div>
                      <div
                        className="left-icon bg-yellow"
                        aria-label="more"
                        id="card-button"
                        aria-controls={open ? "card-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleCardClick}
                      >
                        <TbAlertTriangle />
                      </div>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="card-inner-block">
                      <div className="image">
                        <img src={itf} alt="" />
                      </div>
                      <div className="card-inner-body">
                        <div className="brand-name">ITF</div>
                        <div className="price-estimation">$287</div>
                        <div className="brand-cat">UI/UX Design Agency</div>
                        <div className="media">
                          <div className="image">
                            <img src={avatar} alt="" />
                          </div>
                          <p>15 days</p>
                        </div>
                      </div>
                      <div
                        className="left-icon bg-sky"
                        aria-label="more"
                        id="card-button"
                        aria-controls={open ? "card-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleCardClick}
                      >
                        <FaAngleRight />
                      </div>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="card-inner-block">
                      <div className="image">
                        <img src={city} alt="" />
                      </div>
                      <div className="card-inner-body">
                        <div className="brand-name">City Deals</div>
                        <div className="price-estimation">$12,145</div>
                        <div className="brand-cat">Dashboard Template</div>
                        <div className="media">
                          <div className="image">
                            <img src={avatar} alt="" />
                          </div>
                          <p>24 days</p>
                        </div>
                      </div>
                      <div
                        className="left-icon bg-sky"
                        aria-label="more"
                        id="card-button"
                        aria-controls={open ? "card-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleCardClick}
                      >
                        <FaAngleRight />
                      </div>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="card-inner-block">
                      <div className="image">
                        <img src={daily} alt="" />
                      </div>
                      <div className="card-inner-body">
                        <div className="brand-name">Daily</div>
                        <div className="price-estimation">$7,532</div>
                        <div className="brand-cat">Dating App</div>
                        <div className="media">
                          <div className="image">
                            <img src={avatar} alt="" />
                          </div>
                          <p>18 days</p>
                        </div>
                      </div>
                      <div
                        className="left-icon bg-lightcolor"
                        aria-label="more"
                        id="card-button"
                        aria-controls={open ? "card-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleCardClick}
                      >
                        <FaAngleRight />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="artical-card">
                  <div className="card-box">
                    <div className="card-header">
                      <h6>Conversion</h6>
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                        className="icon-btn"
                      >
                        <HiOutlineDotsHorizontal />
                      </IconButton>
                    </div>
                    <div className="card-content">
                      <span>
                        <span className="overall-estimation">$5,268</span>
                        <span className="spipeline-dot-sep">●</span>
                        <span className="lead-count">7 Leads</span>
                      </span>
                    </div>
                    <button
                      type="tetx"
                      className="addcard-btn"
                      onClick={handleOpen}
                    >
                      {" "}
                      <FaPlus /> Add Card
                    </button>
                  </div>
                  <div className="card-inner">
                    <div className="card-inner-block">
                      <div className="image">
                        <img src={codepen} alt="" />
                      </div>
                      <div className="card-inner-body">
                        <div className="brand-name">Codepen</div>
                        <div className="price-estimation">$7,864</div>
                        <div className="brand-cat">Chatbot</div>
                        <div className="media">
                          <div className="image">
                            <img src={avatar} alt="" />
                          </div>
                          <p>a month ago</p>
                        </div>
                      </div>
                      <div
                        className="left-icon bg-lightcolor"
                        aria-label="more"
                        id="card-button"
                        aria-controls={open ? "card-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleCardClick}
                      >
                        <FaAngleLeft />
                      </div>
                    </div>
                  </div>
                  <div className="card-inner bg-lightgreen">
                    <div className="card-inner-block">
                      <div className="image ">
                        <img src={kickstarter} alt="" />
                      </div>
                      <div className="card-inner-body">
                        <div className="brand-name">Kickstarter</div>
                        <div className="price-estimation">$8,542</div>
                        <div className="brand-cat">Food Order</div>
                        <div className="media">
                          <div className="image">
                            <img src={avatar} alt="" />
                          </div>
                          <p>9 days</p>
                        </div>
                      </div>
                      <div
                        className="left-icon bg-lightcolor"
                        aria-label="more"
                        id="card-button"
                        aria-controls={open ? "card-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleCardClick}
                      >
                        <FaAngleLeft />
                      </div>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="card-inner-block">
                      <div className="image bg-lightcircle">
                        <img src={intercom} alt="" />
                      </div>
                      <div className="card-inner-body">
                        <div className="brand-name">Intercom</div>
                        <div className="price-estimation">$3,791</div>
                        <div className="brand-cat">Publishing Co</div>
                        <div className="media">
                          <div className="image">
                            <img src={avatar} alt="" />
                          </div>
                          <p>a month ago</p>
                        </div>
                      </div>
                      <div
                        className="left-icon bg-lightcolor"
                        aria-label="more"
                        id="card-button"
                        aria-controls={open ? "card-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleCardClick}
                      >
                        <FaAngleLeft />
                      </div>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="card-inner-block">
                      <div className="image bg-lightcircle">
                        <img src={figma} alt="" />
                      </div>
                      <div className="card-inner-body">
                        <div className="brand-name">Figma</div>
                        <div className="price-estimation">$478</div>
                        <div className="brand-cat">Design App</div>
                        <div className="media">
                          <div className="image">
                            <img src={avatar} alt="" />
                          </div>
                          <p>9 days</p>
                        </div>
                      </div>
                      <div
                        className="left-icon bg-red"
                        aria-label="more"
                        id="card-button"
                        aria-controls={open ? "card-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleCardClick}
                      >
                        <FaAngleLeft />
                      </div>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="card-inner-block">
                      <div className="image-box bg-greencircle">
                        <p className="fontw500">H</p>
                      </div>
                      <div className="card-inner-body">
                        <div className="brand-name">Hencework</div>
                        <div className="price-estimation">$987</div>
                        <div className="brand-cat">UI/UX Design Agency</div>
                        <div className="media">
                          <div className="image">
                            <img src={avatar} alt="" />
                          </div>
                          <p>15 days</p>
                        </div>
                      </div>
                      <div
                        className="left-icon bg-lightcolor"
                        aria-label="more"
                        id="card-button"
                        aria-controls={open ? "card-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleCardClick}
                      >
                        <FaAngleRight />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="artical-card">
                  <div className="newcard-box" onClick={handleOpenlist}>
                    <button type="tetx" className="addcard-btn">
                      {" "}
                      <FaPlus /> Add New List
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>

      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open1}
        onClose={handleClose1}
      >
        <MenuItem>
          <Link onClick={handleOpenedit} className="dropdown-link">
            {" "}
            Edit
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to={``} className="dropdown-link">
            {" "}
            Delete
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to={``} className="dropdown-link">
            {" "}
            Clear All
          </Link>
        </MenuItem>
      </Menu>

      <Menu
        id="card-menu"
        MenuListProps={{
          "aria-labelledby": "card-button",
        }}
        anchorEl={anchorEl1}
        open={open2}
        onClose={handleCardClose}
        className="card-dropdown"
      >
        <h6 className="mb-3">Scheduled activity</h6>
        <div className="card-item">
          <div className="circle-icon">
            <LuCircle />
          </div>
          <div className="card-des">
            <h6>Call arranged with James</h6>
            <p className="primaryred-color">Yesterday 4:30 pm</p>
          </div>
          <div className="call-icon bg-pink-circle">
            <IoCallOutline />
          </div>
        </div>
        <div className="card-item">
          <div className="circle-icon">
            <LuCircle />
          </div>
          <div className="card-des">
            <h6>Call arranged with Locus</h6>
            <p className="primaryred-color">21 Jan 20, 12:40 pm</p>
          </div>
          <div className="call-icon bg-light-circle">
            <IoCallOutline />
          </div>
        </div>
        <div className="card-item">
          <div className="circle-icon">
            <LuCircle />
          </div>
          <div className="card-des">
            <h6>Demo arranged with Locus strong</h6>
            <p className="primaryred-color">9 Nov 20, 9:40 am</p>
          </div>
          <div className="call-icon bg-sky-circle">
            <MdMonitor />
          </div>
        </div>
      </Menu>

      {/* Add deal popup */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="deal-popup"
      >
        <Box sx={style}>
          <div className="modal-head">
            <h5>Add New Deal</h5>
            <Link onClick={handleClose}>
              <RxCross2 />
            </Link>
          </div>
          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">Name</label>
                    <input className="form-control task-name" type="text" />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">Start Date</label>
                    <input className="form-control task-name" type="date" />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">End Date</label>
                    <input className="form-control task-name" type="date" />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">Add Deal IN</label>
                    <select className="form-control custom-select">
                      <option selected="">Choose Category</option>
                      <option value="1">Lead IN</option>
                      <option value="2">Opportunity</option>
                      <option value="3">Proposed</option>
                      <option value="4">Follow Up</option>
                      <option value="5">Conversion</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">Note/Description</label>
                    <textarea className="form-control" rows="3"></textarea>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="upload-logo">
                    <div className="dropify-wrapper">
                      <span className="file-icon">
                        <BsUpload />
                      </span>
                      <p>Upload Logo</p>
                      <input type="file" className="dropify-2" />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-inline">
                    <div className="from-group">
                      <label>Set priority :</label>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="female"
                          control={<Radio />}
                          label="High"
                        />
                        <FormControlLabel
                          value="male"
                          control={<Radio />}
                          label="Medium"
                        />
                        <FormControlLabel
                          value="other"
                          control={<Radio />}
                          label="Low"
                        />
                      </RadioGroup>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <Button
              type="submit"
              className="secondary-btn me-2"
              onClick={handleClose}
            >
              Cancle
            </Button>
            <Button type="submit" className="primary-btn">
              Add
            </Button>
          </div>
        </Box>
      </Modal>

      {/* Add new list popup */}
      <Modal
        open={openlist}
        onClose={handleCloselist}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="deal-popup"
      >
        <Box sx={style}>
          <div className="modal-head">
            <h5>Add New List</h5>
            <Link onClick={handleCloselist}>
              <RxCross2 />
            </Link>
          </div>
          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">Name</label>
                    <input className="form-control task-name" type="text" />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <Button
              type="submit"
              className="secondary-btn me-2"
              onClick={handleCloselist}
            >
              Cancle
            </Button>
            <Button type="submit" className="primary-btn">
              Add
            </Button>
          </div>
        </Box>
      </Modal>

      {/* Add new edits popup */}
      <Modal
        open={openedit}
        onClose={handleCloseedit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="deal-popup"
      >
        <Box sx={style}>
          <div className="modal-head">
            <h5>Edit Task List</h5>
            <Link onClick={handleCloseedit}>
              <RxCross2 />
            </Link>
          </div>
          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">Name</label>
                    <input className="form-control task-name" type="text" />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <Button
              type="submit"
              className="secondary-btn me-2"
              onClick={handleCloseedit}
            >
              Cancle
            </Button>
            <Button type="submit" className="primary-btn">
              Add
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default Pipeline;
