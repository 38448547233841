import React, { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Button, Backdrop, CircularProgress } from "@mui/material";
import { VscEdit, VscEye } from "react-icons/vsc";
import NewLIstTable from "../../Components/Reusable/NewLIstTable/index.jsx";
import { GetCallApi } from "../../../Action/Action.jsx";
import { Link } from "react-router-dom";
import "./index.scss";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCellsEvent = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "conferenceName",
    label: "Conference Name",
  },
  {
    id: "userName",
    label: "User Name",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "invoiceEmail",
    label: "Invoice Email",
  },
  {
    id: "poNumber",
    label: "PONumber",
  },
  {
    id: "phone",
    label: "Phone",
  },
  {
    id: "userType",
    label: "User Type",
  },
  {
    id: "amount",
    label: "Amount",
  },
  {
    id: "invoiceStatus",
    label: "Status",
    color: true,
  },
];

function InvoiceList() {
  const [dataOfTable, setDataOfTable] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);

  useEffect(() => {
    document.title = "FIG Events | Conference Approval";
    GetInvoiceList();
    // eslint-disable-next-line
  }, []);

  const token = useSelector((state) => state.login.LoginDetails?.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const GetInvoiceList = async () => {
    setOpenCircle(true);
    let seData = {
      url: `/api/ExhibitorAttendeeInvoice/GetAllExhibitorAttendeeInvoice`,
      headers: headers,
    };
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        let comList = res?.data?.payload?.map((c, i) => {
          return {
            id: i + 1,
            ...c,
            color:
              c?.invoiceStatus === "Approve"
                ? "approv"
                : c?.invoiceStatus === "Reject"
                ? "reject"
                : "created",
            Action: (
              <>
                <Link
                  to={`/invoice-edit/${i + 1}`}
                  className="btn_edit me-3 d-flex align-items-center"
                >
                  <VscEdit />
                </Link>
                <Link
                  to={`/invoice-view/${i + 1}`}
                  className="btn_edit me-3 d-flex align-items-center"
                >
                  <VscEye />
                </Link>
                <Button
                  className="download_btn me-3"
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    justifyContent: "space-between",
                    background:
                      "linear-gradient(90.24deg, #18BAD4 2.7%, #31B680 54.38%, #80C242 98.4%)",
                    padding: "5px 15px",
                    borderRadius: "5px",
                    color: "white",
                    textTransform: "none",
                  }}
                >
                  <div className="text">
                    <span className="ms-2">Resend</span>
                  </div>
                </Button>
              </>
            ),
          };
        });
        setDataOfTable(comList);
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };

  return (
    <>
      <Backdrop open={openCircle} sx={{ color: "#fff", zIndex: () => 9999 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <section className="invoice_list py-4">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12 new_card p-0 mb-4">
                <NewLIstTable
                  headCells={headCellsEvent}
                  title="Invoice List"
                  action={true}
                  ListData={dataOfTable}
                  dropdown={false}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default InvoiceList;
