import React, { Suspense, lazy, useEffect, useState } from "react";
import Charts from "../../Components/Reusable/Chart/Charts";
import "./index.scss";
import Highcharts from "highcharts";
import { useSelector } from "react-redux";
import { Backdrop, CircularProgress, Tooltip } from "@mui/material";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import ImgPopup from "../../Components/ImgPopUp";
import { Config } from "../../../Utils/Config";
import AOS from "aos";
import { Link } from "react-router-dom";
import NewLIstTable from "../../Components/Reusable/NewLIstTable";
import NotFound from "../../../assets/images/Image_not_available.jpg";
// import Swiper from "swiper";

import ImgPopUp from "../../Components/ImgPopUp";
import DeletePopup from "../../Components/Reusable/DeletePopup";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import welcome from "../../../assets/images/Flat-Journalist.png";
// import DragMain from "./DragMain";

const calendaruser = "/assets/icons/calendaruser.svg";
const eventschedule = "/assets/icons/event-schedule.svg";
const exhibitor = "/assets/icons/exhibitor.svg";
const floorplan = "/assets/icons/floorplan.svg";
const registerAsAttendee = "/assets/icons/registerAsAttendee.svg";

const NewListTable = lazy(() =>
  import("../../Components/Reusable/NewLIstTable")
);
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCellsUser = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "userName",
    label: "Name",
  },
  {
    id: "email",
    label: "Contact Email",
  },
  {
    id: "phone",
    label: "Contact Number",
  },
  // {
  //   id: "productName",
  //   label: "Product Name",
  // },
  {
    id: "conferenceName",
    label: "Conference Name",
  },
  {
    id: "associationMembersName",
    label: "Group Name",
  },
  {
    id: "requestACallDate",
    label: "Date",
  },
  // {
  //   id: "productImage",
  //   label: "Product Image",
  // },
];

const headCellsConference = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "conferenceName",
    label: "Conference Name",
  },
  {
    id: "contactName",
    label: "Contact Name",
  },
  {
    id: "contactNumber",
    label: "Contact Number",
  },
];
const headCellsSessionAttended = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "conferenceName",
    label: "Conference Name",
  },
  {
    id: "sessionName",
    label: "Session Name",
  },
  {
    id: "ceuEarned",
    label: "CEU's earned",
  },
  {
    id: "instructorName",
    label: "Instructor Name",
  },
];
const headCellInterestedProducts = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "productName",
    label: "Product Name",
    widthFix: 600,
  },
  {
    id: "productImage",
    label: "Product Image",
  },
  {
    id: "manufacturer",
    label: "Manufacturer",
  },
  {
    id: "exhibitorName",
    label: "Exhibitor Name",
  },
  {
    id: "compnayName",
    label: "Company Name",
  },
  {
    id: "createdOn",
    label: "Created Date",
  },
  // {
  //   id: "phone",
  //   label: "Contact Number",
  // },
];
function ExhibitorDashboard() {
  const [openCircle, setOpenCircle] = useState(false);
  const [closeSug, setCloseSug] = useState(true);
  const [interestedProductList, setInterestedProductList] = useState([]);
  const [likeProductList, setLikeProductList] = useState([]);
  // const [conferenceList, setConferenceList] = useState([]);
  const [viewGraph, setViewGraph] = useState([]);
  const [countList, setCountList] = useState([]);
  const [conferenceTableList, setConferenceTableList] = useState([]);
  const [show, setShow] = useState();
  // const [date, setDate] = useState("All");

  let deleteInfo = useSelector((state) => state.Common?.DeleteData);
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const logindetail = useSelector((state) => state.login.LoginDetails);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    AOS.init({
      duration: 700,
      easing: "ease-out-cubic",
    });
    document.title = "FIG Events | Dashboard";
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    GetLikePrdList();
    GetPrdLikeList();
    GetCount();
    GetProfileViewGraph();
    GetButtonshow();
    GetConferenceTableList();
    // eslint-disable-next-line
  }, [deleteInfo]);

  // useEffect(() => {
  //   if (date === "All") {
  //     GetConferenceList(null);
  //   } else {
  //     GetConferenceList(date);
  //   }
  //   // eslint-disable-next-line
  // }, [date]);

  const GetLikePrdList = async () => {
    setOpenCircle(true);
    let seData = {
      url: `/api/ExhibitorDashboard/GetAttendeeFavoriteProductList?userId=${logindetail?.userId}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        let comList = res?.data?.payload?.attendeeFavoriteProductList?.map(
          (c, i) => {
            let { ...n } = c;
            return {
              id: i + 1,
              ...n,
              requestACallDate: c?.requestACallDate
                ? new Date(
                    c?.requestACallDate?.split("T")[0]
                  ).toLocaleDateString("en-us", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })
                : "",
              productImage: c?.productImage ? (
                <ImgPopup
                  img={`${Config.API_HOST_URL_live}${c.productImage}`}
                  addClass={"h_fix"}
                  hide_icon={true}
                />
              ) : c?.mutipleImages?.length > 0 ? (
                <ImgPopup
                  img={`${Config.API_HOST_URL_live}${c?.mutipleImages?.[0]}`}
                  addClass={"h_fix"}
                  hide_icon={true}
                />
              ) : (
                <ImgPopup img={NotFound} addClass={"h_fix"} hide_icon={true} />
              ),
              Action: "",
              groupUserList:
                c?.productDetails?.length > 0
                  ? c?.productDetails?.map((y, index) => {
                      return {
                        ...y,
                        id: index + 1,
                        profile: y?.productImage ? (
                          <ImgPopup
                            img={`${Config.API_HOST_URL_live}${y?.productImage}`}
                            addClass={"h_fix"}
                            hide_icon={true}
                          />
                        ) : y?.mutipleImages[0] ? (
                          <ImgPopup
                            img={`${Config.API_HOST_URL_live}${y?.mutipleImages[0]}`}
                            addClass={"h_fix"}
                            hide_icon={true}
                          />
                        ) : (
                          <ImgPopup
                            img={NotFound}
                            addClass={"h_fix"}
                            hide_icon={true}
                          />
                        ),
                      };
                    })
                  : [],
            };
          }
        );
        setInterestedProductList(comList);
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };
  const GetPrdLikeList = async () => {
    setOpenCircle(true);
    let seData = {
      url: `/api/ExhibitorDashboard/GetProductLikeList?userId=${logindetail?.userId}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        setLikeProductList(res.data.payload);
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };
  // const GetConferenceList = async (sd) => {
  //   setConferenceList([]);
  //   setOpenCircle(true);
  //   let seData = {
  //     url: `/api/ExhibitorDashboard/GetConferenceList`,
  //     body: { userId: logindetail?.userId, dateFilter: sd },
  //     headers: headers,
  //   };
  //   let res = await PostCallApi(seData);

  //   if (res?.status === 200) {
  //     if (res.data.success) {
  //       setOpenCircle(false);
  //       let comList = res?.data?.payload?.map((c, i) => {
  //         return {
  //           id: i + 1,
  //           ...c,
  //           eventStartDate: c?.eventStartDate
  //             ? new Date(c?.eventStartDate).toLocaleDateString("en-us", {
  //                 year: "2-digit",
  //                 month: "2-digit",
  //                 day: "2-digit",
  //               })
  //             : "",
  //         };
  //       });
  //       setConferenceList(comList);
  //     } else {
  //       setOpenCircle(false);
  //     }
  //   } else {
  //     setOpenCircle(false);
  //   }
  // };
  const GetCount = async () => {
    setOpenCircle(true);
    let seData = {
      url: `/api/ExhibitorDashboard/GetStatisticsCount?userId=${logindetail?.userId}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        setCountList(res.data.payload);
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };
  const GetProfileViewGraph = async () => {
    setOpenCircle(true);
    let seData = {
      url: `/api/ExhibitorDashboard/GetExhibitorProfileViewList?userId=${logindetail?.userId}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        let vg = res.data.payload?.map((x) => {
          return {
            ...x,
            date: x?.date
              ? new Date(x?.date).toLocaleDateString("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                })
              : "",
          };
        });
        setViewGraph(vg);
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };

  const GetConferenceTableList = async () => {
    setOpenCircle(true);
    setConferenceTableList([]);
    let seData = {
      url: `/api/AttendeeDashboard/GetConferenceList?userId=${logindetail?.userId}`,
      headers: headers,
    };
    let res = await PostCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        let comList = res?.data?.payload?.map((c, i) => {
          return {
            id: i + 1,
            ...c,
            Action: (
              <>
                {c?.isRegister || !c?.isAttendeeRegister ? (
                  ""
                ) : (
                  <Tooltip title="Register as Attendee">
                    <Link
                      className="icon_btn me-2 "
                      to={`/conference-user-register/${c?.conferenceId}`}
                    >
                      <img src={registerAsAttendee} alt="" />
                    </Link>
                  </Tooltip>
                )}
                {c?.isExhiRegister ||
                !c?.isExhibitorRegister ||
                c?.isAllBoothBooked ? (
                  ""
                ) : (
                  <Tooltip title="Register as Exhibitor">
                    <Link
                      className="icon_btn me-2 "
                      to={`/conference-exhibotor-register/${c?.conferenceId}`}
                    >
                      <img src={exhibitor} alt="" />
                    </Link>
                  </Tooltip>
                )}
                <Tooltip title="View Conference Schedule">
                  <Link to={`/attendee-schedule`} className="icon_btn me-2">
                    {/* <Link to={`/conference-calendar`} className="btn_edit me-2"> */}
                    <img src={eventschedule} alt="" />
                  </Link>
                </Tooltip>
                <Tooltip title="My Schedule">
                  <Link to={`/my-schedule`} className="icon_btn me-2">
                    <img src={calendaruser} alt="" />
                  </Link>
                </Tooltip>
                {c?.isExhibitorRegister && (
                  <Tooltip title="View Show Floor">
                    <Link
                      to={`/conference-floor-plan`}
                      className="icon_btn me-2"
                      state={{ conferenceId: c?.conferenceId }}
                    >
                      <img src={floorplan} alt="" />
                    </Link>
                  </Tooltip>
                )}
              </>
            ),
          };
        });
        setConferenceTableList(comList);
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };

  let lineChartOptions = {
    chart: {
      type: "spline",
      height: 400 + "px",
    },
    title: {
      text: null,
    },
    xAxis: {
      categories: viewGraph?.length > 0 ? viewGraph?.map((x) => x.date) : [],
      // categories: [
      //   "Fig",
      //   "Green Onion",
      //   "Fig events",
      //   "Swan Scoring",
      //   "Tracking",
      //   "POS",
      //   "Swan Scoring",
      //   "Fig Communication",
      // ],
      crosshair: true,
    },
    yAxis: {
      // max:100,
      allowDecimals: false,
      min: 0,
      title: {
        text: "",
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<td style="padding:0"><b>{point.y} </b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Conference",
        // data: dataOfTable3,
        data: viewGraph?.length > 0 ? viewGraph?.map((x) => x.count) : [],
        // data: [20, 15, 10, 5, 13, 20, 7, 15],
        marker: {
          enabled: false, // Set marker to false to remove it
        },
        color: "#3CD856",
      },
    ],
  };
  let barChartOptions = {
    chart: {
      type: "column",
      height: 400 + "px",
    },
    title: {
      text: null,
    },
    xAxis: {
      categories: likeProductList?.map((x) => x?.productName),
      crosshair: true,
      accessibility: {
        description: "Countries",
      },
    },
    yAxis: {
      // max:100,
      allowDecimals: false,
      min: 0,
      title: {
        text: "",
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      valueSuffix: "",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Likes",
        data: likeProductList?.map((x) => x.count),
        // data: likeProductList,
        // data: [20, 15, 10, 5, 13, 20, 7, 15],
        marker: {
          enabled: false, // Set marker to false to remove it
        },
        color: "#18BAD4",
      },
    ],
  };
  // const headCellsConferenceSearch = [
  //   "conferenceName",
  //   "eventAddress",
  //   "description",
  // ];
  // const [searchField, setSearchField] = useState("");
  // const filterConference = conferenceList?.filter((user) => {
  //   if (searchField) {
  //     let state = false;
  //     headCellsConferenceSearch?.forEach((property) => {
  //       let value = String(user[property]);
  //       if (typeof value === "string") {
  //         if (value?.toLowerCase()?.includes(searchField.toLowerCase())) {
  //           state = true;
  //         }
  //       }
  //     });
  //     return state;
  //   } else return user;
  // });

  const GetButtonshow = async () => {
    setOpenCircle(true);
    let Data = {
      url: `/api/ExhibitorSubscription/ExhibitorSubscriptionExistsByUserId?userId=${logindetail?.userId}`,
      headers: headers,
    };
    let resp = await GetCallApi(Data);

    if (resp?.status === 200) {
      if (resp.data.success) {
        setOpenCircle(false);
        setShow(resp.data.success);
      } else {
        setOpenCircle(false);
        setShow(resp.data.success);
      }
    } else {
      setOpenCircle(false);
    }
  };

  // Attendee Dashboard Funcations

  const [attendeeInterestedProductList, setAttendeeInterestedProductList] =
    useState([]);
  const [attendedCEUEarned, setAttendedCEUEarned] = useState([]);
  const [conferenceCEUEarned, setConferenceCEUEarned] = useState([]);
  const [seAt, setSeAt] = useState([]);
  const [announcementsDetails, setAnnouncementsDetails] = useState();
  const [speakerId, setSpeakerId] = useState();

  useEffect(() => {
    document.title = "FIG Events | Dashboard";
    GetInterestedProducts();
    GetAttendedCEUEarned();
    GetConferenceCEUEarnedList();
    GetAnnouncementsDetails();
    getSessionAttend();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    GetInterestedProducts();
    // eslint-disable-next-line
  }, [deleteInfo]);

  let highchartSession = {
    chart: {
      type: "line",
      height: 160,
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    color: ["#3CD856"],
    xAxis: {
      categories: seAt?.length > 0 ? seAt?.map((x) => x?.monthName) : [],
      // categories: [
      //   "Jan",
      //   "Feb",
      //   "Mar",
      //   "Apr",
      //   "May",
      //   "Jun",
      //   "Jul",
      //   "Aug",
      //   "Sep",
      //   "Oct",
      //   "Nov",
      //   "Dec",
      // ],
    },
    yAxis: {
      allowDecimals: false,
      title: {
        text: "",
      },
      min: 0,
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: false,
        },
        enableMouseTracking: false,
        background: "#3CD856",
      },
    },
    series: [
      {
        name: "Reggane",
        data: seAt?.length > 0 ? seAt?.map((x) => x?.counts) : [],
        showInLegend: false,
        color: "#3CD856",
      },
    ],
  };
  let highchartCEU = {
    chart: {
      height: "500",
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    colors: ["#18BAD4"],
    xAxis: {
      categories:
        conferenceCEUEarned?.length > 0
          ? conferenceCEUEarned?.map((x) => x.conferenceName)
          : [],
    },
    yAxis: {
      title: {
        text: "",
      },
      min: 0,
    },
    series: [
      {
        type: "column",
        name: "CEU",
        borderRadius: 5,
        colorByPoint: true,
        data:
          conferenceCEUEarned?.length > 0
            ? conferenceCEUEarned?.map((x) => x.ceuEarned)
            : [],
        // data: [55, 35, 23, 35, 15, 52, 28, 22],
        showInLegend: false,
      },
    ],
  };
  const GetInterestedProducts = async () => {
    setOpenCircle(true);
    let seData = {
      url: `/api/AttendeeDashboard/GetAttendeeInterestedProductList?userId=${logindetail?.userId}`,
      headers: headers,
    };
    let res = await PostCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        let comList = res?.data?.payload?.map((c, i) => {
          return {
            id: i + 1,
            ...c,
            createdOn: c?.createdOn
              ? new Date(c?.createdOn).toLocaleDateString("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                })
              : "",
            productImage: c?.productImage ? (
              <ImgPopUp
                img={`${Config.API_HOST_URL_live}${c.productImage}`}
                addClass={"h_fix"}
                hide_icon={true}
              />
            ) : c?.mutipleImages?.length > 0 ? (
              <ImgPopUp
                img={`${Config.API_HOST_URL_live}${c?.mutipleImages?.[0]}`}
                addClass={"h_fix"}
                hide_icon={true}
              />
            ) : (
              ""
            ),
            Action: (
              <>
                {/* <Link className="me-2">
                  <img src={download} alt="icon" />
                </Link> */}
                <Link
                  to={`/exhibitor-product-detail/${c?.productId}`}
                  className="btn_edit me-2"
                >
                  View
                </Link>
                <DeletePopup
                  title={"Delete Product"}
                  text={c?.productName}
                  url={`/api/AttendeeDashboard/DeleteProductFromInterestedProductList?attendeeFavoriteProductId=${c?.attendeeFavoriteProductId}`}
                />
              </>
            ),
          };
        });
        setAttendeeInterestedProductList(comList);
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };
  const GetAttendedCEUEarned = async () => {
    setOpenCircle(true);
    let seData = {
      url: `/api/AttendeeDashboard/GetSessionAttendedCEUEarnedList?userId=${logindetail?.userId}`,
      headers: headers,
    };
    let res = await PostCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        let comList = res?.data?.payload?.map((c, i) => {
          return {
            id: i + 1,
            ...c,
            instructorName: (
              <select
                value={
                  c?.speakerList[0]?.speakerId
                    ? c?.speakerList[0]?.speakerId
                    : ""
                }
                onChange={(e) => handleSpeaker(e.target.value, i)}
                className="form-select"
              >
                {c?.speakerList?.map((x) => (
                  <option key={x?.speakerId} value={x?.speakerId}>
                    {x?.speakerName}
                  </option>
                ))}
              </select>
            ),
            Action: (
              <>
                <Link
                  className="btn_edit"
                  to={`/speaker-public-profile`}
                  state={{
                    speakerId: c?.speakerList[0]?.speakerId
                      ? c?.speakerList[0]?.speakerId
                      : "",
                  }}
                >
                  Contact Instructor
                </Link>
              </>
            ),
          };
        });
        setAttendedCEUEarned(comList);
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };

  const handleSpeaker = (value, index) => {
    setSpeakerId({ value: value, index: index });
  };

  useEffect(() => {
    if (speakerId) {
      ChangeDataOfEarnCEu();
    }
    // eslint-disable-next-line
  }, [speakerId]);

  const ChangeDataOfEarnCEu = () => {
    let comList = attendedCEUEarned?.map((c, i) => {
      return {
        ...c,
        instructorName: (
          <select
            value={
              speakerId?.index === i
                ? speakerId?.value
                : c?.speakerList[0]?.speakerId
                ? c?.speakerList[0]?.speakerId
                : ""
            }
            className="form-select"
            onChange={(e) => handleSpeaker(e.target.value, i)}
          >
            {c?.speakerList?.map((x) => (
              <option key={x?.speakerId} value={x?.speakerId}>
                {x?.speakerName}
              </option>
            ))}
          </select>
        ),
        Action: (
          <>
            <Link
              className="btn_edit"
              to={`/speaker-public-profile`}
              state={{
                speakerId:
                  speakerId?.index === i
                    ? speakerId?.value
                    : c?.speakerList[0]?.speakerId
                    ? c?.speakerList[0]?.speakerId
                    : "",
              }}
            >
              Contact Instructor
            </Link>
          </>
        ),
      };
    });
    setAttendedCEUEarned(comList);
  };

  const GetConferenceCEUEarnedList = async () => {
    setOpenCircle(true);
    let seData = {
      url: `/api/AttendeeDashboard/GetConferenceCEUEarnedList?userId=${logindetail?.userId}`,
      headers: headers,
    };
    let res = await PostCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        setConferenceCEUEarned(res.data.payload);
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };

  const GetAnnouncementsDetails = async () => {
    setOpenCircle(true);
    let state = "true";
    let seData = {
      url: `/api/EventPromotion/GetAllEventPromotionList?isActiveEventPromotion=${state}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        setAnnouncementsDetails(
          res.data.payload?.filter((x) => x?.type === "Web")
        );
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };
  const getSessionAttend = async () => {
    let seData = {
      url: `/api/AttendeeDashboard/GetSessionAttendedList?userId=${logindetail?.userId}`,
      body: {},
      headers: headers,
    };
    let res = await PostCallApi(seData);
    if (res?.status === 200) {
      if (res?.data?.success) {
        setSeAt(res?.data?.payload);
      }
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        {/* <DragMain /> */}
        <section className="exhibitor_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <div
                className="col-md-12 exhibitor_title mb-3"
                data-aos="fade-up"
              >
                <h1>Dashboard</h1>
                {closeSug ? (
                  <>
                    {countList?.lastConferneceRegister?.conferenceName ? (
                      <p>
                        {countList?.lastConferneceRegister?.status}{" "}
                        <span>
                          {countList?.lastConferneceRegister?.conferenceName}
                        </span>{" "}
                        <img
                          src={"/assets/icons/crose.svg"}
                          className="img-fluid"
                          alt=""
                          style={{ cursor: "pointer" }}
                          onClick={() => setCloseSug(false)}
                        />
                      </p>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-8">
                <h6>Announcements</h6>
                <Swiper
                  slidesPerView={1}
                  spaceBetween={30}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 1,
                    },
                    1024: {
                      slidesPerView: 1,
                    },
                  }}
                  modules={[Pagination]}
                  className="mySwiper"
                >
                  {announcementsDetails?.length > 0 ? (
                    announcementsDetails?.map((item, i) => (
                      <SwiperSlide key={i}>
                        <div className="welcome-box">
                          <div className="row">
                            <div className="col-lg-8">
                              <h1 className="mb-3">{item?.title}</h1>
                              <p className="mb-0">
                                {item?.description
                                  ? item?.description
                                  : "No Description"}
                              </p>
                            </div>
                            <div className="col-lg-4">
                              <div className="welcome-img">
                                <img
                                  loading="lazy"
                                  className="img-fluid"
                                  src={
                                    item?.eventImageUrl
                                      ? Config.API_HOST_URL_live +
                                        item?.eventImageUrl
                                      : welcome
                                  }
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = welcome;
                                  }}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))
                  ) : (
                    <div className="f-20-600">No Data</div>
                  )}
                </Swiper>
              </div>
              <div className="col-lg-4 padding-top">
                <div className="wht-box">
                  <h6>My Classes/Sessions</h6>
                  <Charts series={highchartSession} highcharts={Highcharts} />
                </div>
              </div>
              <div className="col-md-12 mt-3">
                <div className="statistics_card">
                  <h3>Statistics</h3>
                  <div className="count-box">
                    <div
                      className="box pink"
                      data-aos="fade-up"
                      data-aos-offset="200"
                    >
                      <img
                        src={"/assets/icons/count_box/totalConference.svg"}
                        className="img-fluid"
                        alt="totalConference"
                      />
                      <h1>{countList?.totalConference}</h1>
                      <span>Total Conferences</span>
                    </div>
                    <div
                      className="box skin"
                      data-aos="fade-up"
                      data-aos-offset="200"
                    >
                      <img
                        src={"/assets/icons/count_box/totalProduct.svg"}
                        className="img-fluid"
                        alt=""
                      />
                      <h1>{countList?.totalProduct}</h1>
                      <span>Total Products</span>
                    </div>
                    <div
                      className="box green"
                      data-aos="fade-up"
                      data-aos-offset="200"
                    >
                      <img
                        src={"/assets/icons/count_box/attendeesInterested.svg"}
                        className="img-fluid"
                        alt=""
                      />
                      <h1>{countList?.attendeesInterested}</h1>
                      <span>Attendees Likes</span>
                    </div>
                    <div
                      className="box purple"
                      data-aos="fade-up"
                      data-aos-offset="200"
                    >
                      <img
                        src={"/assets/icons/count_box/totalAttendees.svg"}
                        className="img-fluid"
                        alt=""
                      />
                      <h1>{countList?.totalProfileView}</h1>
                      <span>Profile Views</span>
                    </div>
                    <div
                      className="box blue"
                      data-aos="fade-up"
                      data-aos-offset="200"
                    >
                      <img
                        src={"/assets/icons/count_box/totalInquiries.svg"}
                        className="img-fluid"
                        alt=""
                      />
                      <h1>{countList?.totalInquiries}</h1>
                      <span>Total Inquiries</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-3">
                <div className="new_card p-0">
                  <NewListTable
                    title="Interested Attendees in Product or Services"
                    headCells={headCellsUser}
                    action={true}
                    ListData={interestedProductList}
                    dropdown={false}
                    btnshow={true}
                    exportBtn={true}
                    showSubscribtn={
                      interestedProductList?.length >= 3 ? true : false
                    }
                    showSubscribeUrlName={
                      show ? "View All Leads" : "Subscribe To View More Leads"
                    }
                    showSubscribeUrl={
                      show
                        ? `/leads-report`
                        : // ? `/company-lead-tracking-updates`
                          `/subscription/exhibitor`
                    }
                    hidePagination={true}
                    showUserListInTable={true}
                    showUserListWithoutAPi={true}
                    subHeadCells={[
                      {
                        id: "id",
                        label: "id",
                      },
                      {
                        id: "profile",
                        label: "ProductImage",
                      },
                      {
                        id: "productName",
                        label: "Product Name",
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-12 mt-3">
                <div className="new_card">
                  <h3 className="card_title">Products Likes</h3>
                  <Charts series={barChartOptions} highcharts={Highcharts} />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-12 mt-3">
                <div className="new_card">
                  <h3 className="card_title">Profile Views</h3>
                  <Charts series={lineChartOptions} highcharts={Highcharts} />
                </div>
              </div>
              <div className="col-md-12 mt-3">
                <div className="new_card p-0">
                  <NewLIstTable
                    title="Conference List"
                    headCells={headCellsConference}
                    action={true}
                    ListData={conferenceTableList}
                    dropdown={false}
                    btnshow={true}
                    exportBtn={false}
                  />
                </div>
              </div>
              <div className="col-lg-6 mt-4">
                <div className="wht-box">
                  <h6>CEU&apos;s Earned</h6>
                  {/* <h6>CEU's Earned</h6> */}
                  <Charts series={highchartCEU} highcharts={Highcharts} />
                </div>
              </div>

              <div className="col-lg-6 mt-4">
                <div className="new_card p-0">
                  <NewLIstTable
                    title="Sessions Attended and CEU's Earned"
                    headCells={headCellsSessionAttended}
                    action={true}
                    ListData={attendedCEUEarned}
                    dropdown={false}
                    btnshow={true}
                    exportBtn={false}
                  />
                </div>
              </div>
              <div className="col-lg-12 mt-4">
                <div className="new_card p-0">
                  <NewLIstTable
                    title="Your Interested Products"
                    headCells={headCellInterestedProducts}
                    action={true}
                    ListData={attendeeInterestedProductList}
                    dropdown={false}
                    btnshow={true}
                    exportBtn={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="attendee-dashboard">
          <div className="container-fluid">
            <h1>Dashboard</h1>
          </div>
        </section> */}
      </Suspense>
    </>
  );
}

export default ExhibitorDashboard;
