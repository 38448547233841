import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Axios from "../../../../Utils/AxiosConfig";
import { Config } from "../../../../Utils/Config";
import { Button, IconButton } from "@mui/material";
import CropModel from "../../../Components/Reusable/CropModel";
import { useNavigate, useParams } from "react-router-dom";
import { GetCallApi } from "../../../../Action/Action";
import "./index.scss";
import { toast } from "react-toastify";
import Select from "react-select";
import { customFilter } from "../../../Components/CustomFilterForReactSelect";

function EventCreate() {
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const [derror, setDerror] = useState();
  const [conferenceList, setConferenceList] = useState([]);
  const [confId, setConfId] = useState();

  const LogDetails = useSelector((state) => state.login.LoginDetails);

  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  let minDate = new Date()
    .toLocaleString("en-us", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");

  useEffect(() => {
    if (conferenceList?.length > 0) {
      if (id) {
        Axios.get(
          `/api/Events/GetEventsDataByEventId?eventId=${id}&userId=${LogDetails?.userId}`,
          {
            headers,
          }
        )
          .then((res) => {
            if (res.status === 200) {
              if (res.data.success) {
                let useDe = res.data.payload;
                setValue("EventId", id);
                setValue("EventName", useDe?.eventName);
                setValue("ConferenceId", useDe?.conferenceId);
                setConfId(useDe?.conferenceId);

                setValue(
                  "EventStartdate",
                  useDe?.eventStartdate
                    ? convert(useDe?.eventStartdate?.split("T")[0])
                    : ""
                );
                setValue(
                  "EventEnddate",
                  useDe?.eventEnddate
                    ? convert(useDe?.eventEnddate?.split("T")[0])
                    : ""
                );
                setValue("startTime", useDe?.startTime);
                setValue("endTime", useDe?.endTime);
                setValue("EventWebsite", useDe?.eventWebsite);
                setValue("AttendeeFees", useDe?.attendeeFees);
                setValue("Description", useDe?.description);
                setValue("ContactName", useDe?.contactName);
                setValue("Email", useDe?.email);
                setValue("PhoneNumber", useDe?.phoneNumber);
                setValue("CoverImage", useDe?.coverImage);
                setValue("CoverImageUrl", useDe?.coverImage);
                setValue("DisplayImage", useDe?.displayImage);
                setValue("DisplayImageUrl", useDe?.displayImage);
              }
            }
          })
          .catch((e) => console.log(e));
      }
    }
    // eslint-disable-next-line
  }, [id, conferenceList]);
  useEffect(() => {
    GetConferenceList();
    // eslint-disable-next-line
  }, []);

  function convert(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  let nav = useNavigate();
  const onSubmit = (data) => {
    let EData = {
      ...data,
      CreatedBy: LogDetails?.userId,
      ExhibitorFees:
        data?.priceType === "SinglePrice" ? data?.ExhibitorFees : 0,
    };

    var datest1 = new Date(data.EventStartdate).toISOString();
    datest1 = datest1?.split(".")[0];
    var datest2 = new Date(data.EventEnddate).toISOString();
    datest2 = datest2?.split(".")[0];
    let dateData = {
      EventStartdate: datest1,
      EventEnddate: datest2,
      // EventStartdate: convert1(data.EventStartdate),
      // EventEnddate: convert1(data.EventEnddate),
    };
    ["EventStartdate", "EventEnddate"].forEach((e) => delete EData[e]);
    EData = { ...EData, ...dateData };
    Axios.post(`${Config.API_HOST_URL_live}/api/Events/CreateEvents`, EData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            // let EventDeatil = res.data?.payload;
            toast.success(res.data.message, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            nav("/event-list", { replace: true });

            reset();
          } else {
            toast.error(res.data.message, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            setDerror({ er_msg: res.data.message });
          }
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      })
      .catch((e) => console.log(e));
  };

  const GetConferenceList = async () => {
    let seData = {
      url: `/api/Conference/GetAllConferenceList?userId=${LogDetails?.userId}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);
    if (res.status === 200) {
      if (res.data.success) {
        let comList = res?.data?.payload
          ?.filter((x) => x?.status === "Approved")
          ?.map((c) => {
            return {
              cofId: c.conferenceId,
              title: c?.conferenceName,
              value: c.conferenceId,
              label: c?.conferenceName,
            };
          });
        setConferenceList(comList);
      }
    }
  };

  // crop code start
  const [src, setSrc] = useState(null);
  const [filename, setFileName] = useState(null);
  const [croppedImageUrl] = useState(null);
  const [openCropModal, setCropModal] = useState(false);
  const setCroppedImageUrlCallback = useCallback(
    (url) => {
      if (url) {
        setValue("DisplayImage", url);
        setValue("DisplayImageUrl", url.name);
        // handleProImg(url);
        // setCroppedImageUrl(URL.createObjectURL(url));
        setSrc(null);
      } else {
        setSrc(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl]
  );
  const handleFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setFileName(e.target.files[0].name);
      reader.addEventListener(
        "load",
        () => {
          setSrc(reader.result);
          setCropModal(true);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
    }
  };
  // crop code end

  const handleConferenceSelect = (e) => {
    setConfId(e);
    setValue("ConferenceId", e);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form_box_event_create">
      <div className="main_wrapper">
        <div className="title mt-5"> {id ? "Edit" : "Create"} Event</div>
        <div className="card">
          <div className="card-body">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 mb-4">
                  <div className="title">Basic Event details</div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">
                      Name
                      <span className="red-color"> *</span>
                    </label>
                    <span>
                      {" "}
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter event name"
                        {...register("EventName", {
                          required: "This field is required",
                          maxLength: {
                            value: 50,
                            message: "Max character 50 allowed",
                          },
                        })}
                      />
                    </span>
                    {errors.EventName && (
                      <span role="alert" className="error_text">
                        {errors.EventName.message}
                      </span>
                    )}
                  </div>
                </div>{" "}
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">
                      Select Conference
                      <span className="red-color"> *</span>
                    </label>
                    <Select
                      isMulti={false}
                      name="colors"
                      options={conferenceList}
                      isClearable={true}
                      className="dropdown-single-select"
                      classNamePrefix="select"
                      placeholder="Select Conference"
                      filterOption={customFilter}
                      value={conferenceList?.filter(
                        (obj) => obj.value === confId
                      )}
                      onChange={(e) => handleConferenceSelect(e?.value)}
                    />
                    <select
                      className="form-select d-none"
                      {...register("ConferenceId", {
                        required: "This field is required",
                      })}
                    >
                      <option value="">Select Conference</option>
                      {conferenceList &&
                        conferenceList?.map((m, i) => (
                          <option value={m.cofId} key={i}>
                            {m.title}
                          </option>
                        ))}
                    </select>
                    {errors.ConferenceId && (
                      <span role="alert" className="error_text">
                        {errors.ConferenceId.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">
                      {" "}
                      Logo
                      <span className="red-color"> *</span>
                    </label>
                    <div className="d-flex upload_btn_sec">
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        placeholder="No file choose"
                        {...register(`DisplayImageUrl`, {
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                          // onChange: (e) => handleFile(e),
                        })}
                      />
                      <IconButton component="label" className="upload_btn m-0">
                        Choose File
                        <input
                          id="upload"
                          type="file"
                          accept="image/*"
                          className="form-control"
                          hidden
                          onChange={(e) => handleFile(e)}
                        />
                      </IconButton>
                    </div>
                    {/* crop code start */}
                    {src && (
                      <CropModel
                        src={src}
                        filename={filename}
                        setCroppedImageUrl={setCroppedImageUrlCallback}
                        openCropModal={openCropModal}
                        setCropModal={setCropModal}
                      />
                    )}
                    {/* crop code end */}
                    {errors.DisplayImageUrl && (
                      <span role="alert" className="error_text">
                        {errors.DisplayImageUrl.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">
                      Start Date
                      <span className="red-color"> *</span>
                    </label>

                    <input
                      type="Date"
                      className="form-control"
                      min={id ? "" : minDate}
                      {...register("EventStartdate", {
                        required: "This field is required",
                        valueAsDate: true,
                      })}
                    />
                    {errors.EventStartdate && (
                      <span role="alert" className="error_text">
                        {errors.EventStartdate.message}
                      </span>
                    )}
                  </div>
                </div>{" "}
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">
                      End Date
                      <span className="red-color"> *</span>
                    </label>

                    <input
                      type="Date"
                      className="form-control"
                      min={id ? "" : minDate}
                      {...register("EventEnddate", {
                        required: "This field is required",
                        valueAsDate: true,
                        validate: (val) => {
                          // if (new Date(minDate) > val) {
                          //   return "Your end date can not less than from today date";
                          // }
                          if (watch("EventStartdate") > val) {
                            return "Your end date can not greater than from start date";
                          }
                        },
                      })}
                    />
                    {errors.EventEnddate && (
                      <span role="alert" className="error_text">
                        {errors.EventEnddate.message}
                      </span>
                    )}
                  </div>
                </div>{" "}
                <div className="col-md-2">
                  <div className="form-group">
                    <label className="form-label">
                      Start Time
                      <span className="red-color"> *</span>
                    </label>

                    <input
                      type="time"
                      className="form-control"
                      {...register("startTime", {
                        required: "This field is required",
                      })}
                    />
                    {errors.startTime && (
                      <span role="alert" className="error_text">
                        {errors.startTime.message}
                      </span>
                    )}
                  </div>
                </div>{" "}
                <div className="col-md-2">
                  <div className="form-group">
                    <label className="form-label">
                      End Time
                      <span className="red-color"> *</span>
                    </label>

                    <input
                      type="time"
                      className="form-control"
                      {...register("endTime", {
                        required: "This field is required",
                        validate: (val) => {
                          if (watch("startTime") >= val) {
                            return "Your end time can not smaller than from start time";
                          }
                        },
                      })}
                    />
                    {errors.endTime && (
                      <span role="alert" className="error_text">
                        {errors.endTime.message}
                      </span>
                    )}
                  </div>
                </div>{" "}
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">
                      {" "}
                      Registration Fee For User
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter registration fee"
                      {...register("AttendeeFees", {
                        required: "This field is required",

                        pattern: {
                          value: /^\d+(\.\d{1,2})?$/,
                          message: "Enter valid price",
                        },
                      })}
                    />
                    {errors.AttendeeFees && (
                      <span role="alert" className="error_text">
                        {errors.AttendeeFees.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label"> Website</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter event website"
                      {...register("EventWebsite", {
                        // required: "This field is required",
                      })}
                    />
                    {errors.EventWebsite && (
                      <span role="alert" className="error_text">
                        {errors.EventWebsite.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label"> Description</label>
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Enter description"
                      rows="5"
                      {...register("Description", {
                        // required: "This field is required",
                      })}
                    ></textarea>
                    {errors.Description && (
                      <span role="alert" className="error_text">
                        {errors.Description.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <hr /> */}
      <div className="main_wrapper">
        <div className="card mt-4">
          <div className="card-body">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 mb-4">
                  <div className="title"> Contact Person Information</div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">
                      Contact Name
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter  contact name"
                      {...register("ContactName", {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                      })}
                    />
                    {errors.ContactName && (
                      <span role="alert" className="error_text">
                        {errors.ContactName.message}
                      </span>
                    )}
                  </div>
                </div>{" "}
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">
                      Email
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your email ID"
                      {...register("Email", {
                        required: "This field is required",
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Please Enter Valid Email",
                        },
                      })}
                    />
                    {errors.Email && (
                      <span role="alert" className="error_text">
                        {errors.Email.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">
                      Phone Number
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your phone number"
                      {...register("PhoneNumber", {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                        pattern: {
                          value:
                            /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                          message: "Enter valid phone number",
                        },
                      })}
                    />
                    {errors.PhoneNumber && (
                      <span role="alert" className="error_text">
                        {errors.PhoneNumber.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-12 text-end">
                  <Button
                    className="back_button me-3 w-10"
                    onClick={() => nav(-1)}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" className="detail_button w-10">
                    Submit
                  </Button>
                  {derror?.er_msg && (
                    <span role="alert" className="d-block error_text">
                      {derror.er_msg}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default EventCreate;
