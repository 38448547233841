import React, { Suspense, lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Typography,
  Button,
} from "@mui/material";
import { VscEdit } from "react-icons/vsc";
import "./index.scss";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
import TableHeader from "../../../../TableHeader.json";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { customFilter } from "../../../Components/CustomFilterForReactSelect";
import Select from "react-select";
import { useForm } from "react-hook-form";

const NewLIstTable = lazy(() =>
  import("../../../Components/Reusable/NewLIstTable")
);

const Togglebutton = lazy(() =>
  import("../../../Components/Reusable/Togglebutton")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function QualifyingQuestionforNonFigEvents() {
  const [dataOfTable, setDataOfTable] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [questionTypeVal, setQuestionTypeVal] = useState();
  const [questionCategory, setQuestionCategory] = useState();
  const [filterPage, setFilterPage] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [companyId, setCompanyId] = useState();

  const logindetail = useSelector((state) => state.login.LoginDetails);
  useEffect(() => {
    document.title = "FIG Events | Qualifying Question ";
    GetCompanyRegistrationList();
    // eslint-disable-next-line
  }, []);

  const { handleSubmit, reset, setValue } = useForm();
  const GetCompanyRegistrationList = async () => {
    let seData = {
      url:
        logindetail?.roleId === 1
          ? `/api/CompanyRegistration/GetAllCompanyRegistration?userId=${logindetail?.userId}`
          : `/api/CompanyRegistration/GetCompanyUserAllocationListByUserId?userId=${logindetail?.userId}`,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200 && res.data.success) {
      let comList = res?.data?.payload?.map((c, i) => {
        return {
          id: i + 1,
          ...c,
          value: c?.companyId,
          label: c?.companyName,
        };
      });
      setCompanyList(comList);
    }
  };
  const GetAllQuestionList = async (data) => {
    setDataOfTable([]);

    setOpenCircle(true);
    let seData = {
      url: `/api/Questions/GetAllQuestionList`,
      body: {
        ...data,
        questionCategory: data?.questionCategory ? data?.questionCategory : "",
        questionType: data?.questionType ? data?.questionType : "",
        CompanyId: data?.CompanyId ? data?.CompanyId : null,
        userId: logindetail?.userId,
      },
    };
    let res = await PostCallApi(seData);
    setOpenCircle(false);
    setFilterPage(!filterPage);
    if (res.status === 200) {
      if (res.data.success) {
        let comList = res?.data?.payload?.map((c, i) => {
          return {
            id: i + 1,
            ...c,
            isRequired: c?.isRequired ? "True" : "False",
            isConferenceQuestion: c?.isConferenceQuestion ? "True" : "False",

            Action: (
              <>
                {c?.isActive && (
                  <Link
                    to={`edit/${c.questionId}`}
                    className="btn_edit me-3 d-flex align-items-center"
                  >
                    <VscEdit />
                  </Link>
                )}
                <Togglebutton
                  checked={c?.isActive === true ? true : false}
                  title={"Delete Qualifying Question for Non Fig Events"}
                  disabled={false}
                  text={c?.questionTitle}
                  url={`/api/Questions/DeleteQuestion?questionId=${c?.questionId}&createdBy=${logindetail?.userId}`}
                  callBack={true}
                  callBackFunction={GetAllQuestionList}
                />
              </>
            ),
          };
        });
        setDataOfTable(comList);
      }
    }
  };

  const questionTypeOption = [
    { value: "BigText", label: "BigText" },
    { value: "Listbox", label: "Listbox" },
    { value: "Multiple Choice", label: "Multiple Choice" },
    { value: "Radio", label: "Radio" },
    { value: "Rating", label: "Rating" },
    { value: "Text", label: "Text" },
  ];
  const handleQuestionType = (e) => {
    setValue("questionType", e);
    setQuestionTypeVal(e);
  };

  const questionCategoryOption = [
    { value: "Conference Question", label: "Conference Question" },
    { value: "Request a Call", label: "Request a Call" },
  ];

  const handleQuestionCategory = (e) => {
    setValue("questionCategory", e);
    setQuestionCategory(e);
    if (e === "Conference Question") {
      setValue("CompanyId");
      setCompanyId();
    }
  };
  const handleCompany = (e) => {
    setValue("CompanyId", e);
    setCompanyId(e);
    if (e) {
      setValue("questionCategory", "Request a Call");
      setQuestionCategory("Request a Call");
    }
  };

  const handleClearFilter = () => {
    setQuestionTypeVal();
    setQuestionCategory();
    setCompanyId();
    setDataOfTable([]);
    reset();
  };

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography style={{ marginLeft: "20px" }}>
                    <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                    <span className="filter_by">Filter By</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <form onSubmit={handleSubmit(GetAllQuestionList)}>
                    <div className="row ">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Company</label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={companyList}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Company"
                            filterOption={customFilter}
                            value={companyList?.filter(
                              (obj) => companyId === obj.value
                            )}
                            onChange={(e) => handleCompany(e?.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label"> Question Type</label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={questionTypeOption}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Question Type"
                            filterOption={customFilter}
                            value={questionTypeOption?.filter(
                              (obj) => obj.value === questionTypeVal
                            )}
                            onChange={(e) => handleQuestionType(e?.value)}
                          />
                        </div>
                      </div>
                      {logindetail?.roleId === 1 ? (
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">
                              {" "}
                              Question Category
                            </label>
                            <Select
                              isMulti={false}
                              name="colors"
                              options={questionCategoryOption}
                              isClearable={true}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select Question Category"
                              filterOption={customFilter}
                              value={questionCategoryOption?.filter(
                                (obj) => obj.value === questionCategory
                              )}
                              onChange={(e) => handleQuestionCategory(e?.value)}
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="col-md-6 d-flex justify-content-start mt-auto">
                        <div className="form-group">
                          <Button
                            className="detail_button ms-3"
                            type="submit"
                            disabled={openCircle ? true : false}
                          >
                            {openCircle ? (
                              <CircularProgress
                                color="inherit"
                                sx={{
                                  color: "#fff",
                                  marginLeft: "0.5rem",
                                  height: "23px !important",
                                  width: "23px !important",
                                }}
                              />
                            ) : (
                              "Apply Filter"
                            )}
                          </Button>
                          <Button
                            className={`back_button ms-3 ${
                              window.innerWidth > 400 ? "" : "mt-3"
                            }`}
                            onClick={() => handleClearFilter()}
                          >
                            Clear Filter
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                </AccordionDetails>
              </Accordion>
              <div className="col-md-12 accordian_card mb-4">
                <NewLIstTable
                  addressLink={`create`}
                  headCells={
                    logindetail?.roleId === 1
                      ? [
                          ...(TableHeader?.QualifyingQuestionforNonFigEventsHeader ??
                            {}),
                          {
                            id: "questionCategory",
                            label: "Question Category",
                          },
                        ]
                      : TableHeader?.QualifyingQuestionforNonFigEventsHeader
                  }
                  title="Qualifying Question List"
                  action={true}
                  ListData={dataOfTable}
                  addBtn={true}
                  filterPage={filterPage}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default QualifyingQuestionforNonFigEvents;
