import React, { useEffect, useMemo, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { PostCallApi } from "../../../../../Action/Action";
// import Swal from "sweetalert2";
import Invoice from "./Invoice";
import { AttendeeRegistrationConference } from "../../../../../reducers/Common";
import ReactQuill from "react-quill";
import { toast } from "react-toastify";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import { useNavigate, useParams } from "react-router-dom";
import {
  // CardElement,
  Elements,
  useElements,
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import useResponsiveFontSize from "./useResponsiveFontSize";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

function AssociationUserPayment(props) {
  const { id } = useParams();
  const { companyData, handleReset } = props;
  const [clientPublicKey, setClientPublicKey] = useState();

  const [acBtn, setAcBtn] = useState(true);
  const [showInvoise, setShowInvoise] = useState(false);
  const [allInVData, setAllInVData] = useState();
  const [subtotal, setSubTotal] = useState("");
  const [payment, setPayment] = useState();
  const [discountValue] = useState();
  const [btnLoad, setBtnLoad] = useState(false);

  // const [discountValue, setDiscountValue] = useState();
  const { setValue } = useForm();

  const LogDetails = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  let ATRC = useSelector((state) => state.Common?.ATRC);

  let dispatch = useDispatch();
  let nav = useNavigate();
  useEffect(() => {
    if (companyData) {
      setValue("amount", companyData.subtotal);
      setSubTotal(companyData?.subtotal);
    }
    // eslint-disable-next-line
  }, [companyData]);

  const CLSec = async () => {
    if (parseInt(subtotal) === 0) {
      setBtnLoad(true);
      let paymentData = {
        ...companyData,
        userId: LogDetails?.userId,
        email: LogDetails?.email,
        amount: subtotal,
        discountAmount: discountValue?.discountValue,
        promoCodeId: discountValue?.promoCodeId,
        associationMembershipsTypesId:
          companyData?.associationMembershipsTypesId,
        associationId: companyData?.associationId,
      };
      let sveCC = {
        url: `/api/AssociationPayment/AssociationPaymentProcess`,
        body: paymentData,
        headers: headers,
      };
      let res = await PostCallApi(sveCC);
      if (res?.status === 200 && res.data.isPaid) {
        dispatch(AttendeeRegistrationConference());
        nav(
          `/association-list/payment-success/${id}/${subtotal}?payment_intent=${res?.data?.paymentId}&payment_intent_client_secret=${res?.data?.paymentReference}&redirect_status=succeeded`,
          {
            replace: true,
          }
        );
      } else {
        setBtnLoad(false);
      }
    } else {
      setPayment(true);
    }
  };

  const RequestInvoice = async (data) => {
    setBtnLoad(true);
    let seData = {
      url: `/api/AssociationPayment/AddAssociationInvoice`,
      body: {
        ...data,
        createdBy: LogDetails?.userId,
        userId: LogDetails?.userId,
        totalAmount: subtotal,
        addressLine1: data?.address1,
        zipCode: data?.zip,
        invoiceInstructions: data?.editorValue,
        paymentMode: "Invoice",
        paymentStatus: "Success",
        invoiceStatus: "Approve",
        discountValue: discountValue?.discountValue,
        associationName: companyData?.name,
        associationPrice: subtotal.toString(),
        associationMembershipsTypesId:
          companyData?.associationMembershipsTypesId,
        associationId: companyData?.associationId,
        associationMemberId: companyData?.associationMemberId,
        companyName: companyData?.companyName,
        paymentAddress:
          ATRC?.paymentRemittanceAddress +
          " \r\n" +
          ATRC?.paymentCity +
          " " +
          ATRC?.paymentState +
          " " +
          ATRC?.paymentZip,
      },
      headers: headers,
    };
    let res = await PostCallApi(seData);
    if (res?.status === 200 && res?.data?.success) {
      setAllInVData({
        ...ATRC,
        subtotal: subtotal,

        discountValue: discountValue?.discountValue,
        data,
      });
      dispatch(AttendeeRegistrationConference());
      setShowInvoise(true);
    } else {
      setBtnLoad(false);
    }
  };

  const ShowInvoice = ({ RequestInvoice, btnLoad }) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();

    const [editorValue, setEditorValue] = useState("");
    const [derror, setDerror] = useState();

    const handleEditotValue = (value) => {
      if (value?.length > 1000) {
        setDerror({ ed: "Max 1000 character" });
      } else {
        setDerror({ ed: "" });
        setEditorValue(value);
      }
    };

    return (
      <form
        onSubmit={handleSubmit(async (data) => {
          RequestInvoice({ ...data, editorValue });
        })}
      >
        <div className="row">
          <div className="col-md-6">
            <label className="form-label">
              Email Invoice To
              <span className="red-color"> *</span>
            </label>

            <div className="form-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Email"
                {...register("email", {
                  required: "This field is require",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Please Enter Valid Email",
                  },
                })}
              />
              {errors?.email && (
                <span className="error_text">{errors.email.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-3">
            <div className="form-group mb-3">
              <div>
                <label className="form-label">Address</label>
              </div>
              <label className="form-label">
                Name<span className="red-color"> *</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Name"
                {...register("name", {
                  required: "This field is require",
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                  maxLength: {
                    value: 500,
                    message: "You can enter max 500 charactor",
                  },
                })}
              />
              {errors?.name && (
                <span className="error_text">{errors.name.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-3 mt-auto">
            <div className="form-group  mb-3">
              <label className="form-label">
                Address Line 1<span className="red-color"> *</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Address Line 1"
                {...register("address1", {
                  required: "This field is require",
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                })}
              />
              {errors?.address1 && (
                <span className="error_text">{errors.address1.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-6 mt-auto">
            <div className="form-group mb-3">
              <label className="form-label">PO Number </label>
              <input
                type="text"
                placeholder="Enter PO Number"
                className="form-control"
                {...register("purchaseOrderNumber", {
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                })}
              />
              {errors?.purchaseOrderNumber && (
                <span className="error_text">
                  {errors.purchaseOrderNumber.message}
                </span>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group mb-3">
              <label className="form-label">
                City<span className="red-color"> *</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter City"
                {...register("city", {
                  required: "This field is require",
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                  maxLength: {
                    value: 100,
                    message: "You can enter max 100 charactor",
                  },
                })}
              />
              {errors?.city && (
                <span className="error_text">{errors.city.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group mb-3">
              <label className="form-label">
                State<span className="red-color"> *</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter State"
                {...register("State", {
                  required: "This field is require",
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                  maxLength: {
                    value: 100,
                    message: "You can enter max 100 charactor",
                  },
                })}
              />
              {errors?.State && (
                <span className="error_text">{errors.State.message}</span>
              )}
            </div>
          </div>{" "}
          <div className="col-md-3">
            <div className="form-group mb-3">
              <label className="form-label">
                Zip Code
                <span className="red-color"> *</span>
              </label>
              <input
                type="number"
                min={0}
                className="form-control"
                placeholder="Enter Zip Code"
                {...register("zip", {
                  required: "This field is require",
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                  maxLength: {
                    value: 10,
                    message: "You can enter max 10 charactor",
                  },
                })}
              />
              {errors?.zip && (
                <span className="error_text">{errors.zip.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-12 ">
            <label className="form-label">Invoice Introduction</label>
            <ReactQuill
              value={editorValue}
              onChange={(value) => handleEditotValue(value)}
              // style={{ height: "200px", overflow: "auto" }}
              className="ql_show"
              modules={{
                toolbar: [
                  ["bold", "italic", "underline", "strike"], // toggled buttons
                  ["blockquote", "code-block"],

                  [{ header: 1 }, { header: 2 }], // custom button values
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ script: "sub" }, { script: "super" }], // superscript/subscript
                  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                  [{ direction: "rtl" }], // text direction

                  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],

                  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                  [{ font: [] }],
                  [{ align: [] }],

                  ["link", "image", "video"],
                  ["clean"],
                ],
              }}
              theme="snow"
            />
            {derror?.ed && (
              <span role="alert" className="d-block error_text">
                {derror.ed}
              </span>
            )}
          </div>
          <div className="col-md-12 ">
            <Button
              className="detail_button mt-3"
              disabled={btnLoad}
              type="submit"
            >
              {btnLoad ? (
                <CircularProgress
                  color="inherit"
                  sx={{
                    color: "#fff",
                    marginLeft: "0.5rem",
                    height: "23px !important",
                    width: "23px !important",
                  }}
                />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </div>
      </form>
    );
  };

  // const DiscountApply = ({ discountValue, handlePromo, handleRemoveDC }) => {
  //   const {
  //     register,
  //     handleSubmit,
  //     formState: { errors },
  //   } = useForm();
  //   return (
  //     <form onSubmit={handleSubmit(handlePromo)} className="mt-3">
  //       <div className="row">
  //         <div className="col-md-6">
  //           <div className="form-group">
  //             <label className="form-label">Discount Code</label>
  //             <input
  //               type="text"
  //               placeholder="Enter Discount Code"
  //               className="form-control"
  //               disabled={discountValue ? true : false}
  //               {...register("promoCode", {
  //                 pattern: {
  //                   value: /^[^\s]+(\s+[^\s]+)*$/,
  //                   message: "Starting and Ending Space not allowed",
  //                 },
  //               })}
  //             />
  //             {errors.promoCode && (
  //               <span role="alert" className="error_text">
  //                 {errors.promoCode.message}
  //               </span>
  //             )}
  //           </div>
  //         </div>
  //         <div className="col-md-6 my-auto">
  //           <Button
  //             className="detail_button"
  //             type="submit"
  //             disabled={discountValue ? true : false}
  //           >
  //             Apply
  //           </Button>
  //           {discountValue ? (
  //             <Button
  //               className="detail_button ms-3"
  //               onClick={() => handleRemoveDC()}
  //             >
  //               Remove
  //             </Button>
  //           ) : (
  //             ""
  //           )}
  //         </div>
  //       </div>
  //     </form>
  //   );
  // };
  // const applyPromo = async (data, pro) => {
  //   setDiscountValue();
  //   let seData = {
  //     url: `/api/Payment/GetAmountAfterApplyPromoCodeOnConference`,
  //     body: data,
  //     headers: headers,
  //   };
  //   let res = await PostCallApi(seData);
  //   if (res?.status === 200) {
  //     if (res.data.success) {
  //       let am = res.data.payload;
  //       setSubTotal(am?.finalAmount);
  //       setDiscountValue(am);
  //     } else {
  //       setSubTotal(companyData?.subtotal);

  //       toast.error(res.data.message, {
  //         position: "top-right",
  //         autoClose: 1500,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "dark",
  //       });
  //     }
  //   } else {
  //     toast.error(res.data.message, {
  //       position: "top-right",
  //       autoClose: 1500,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "dark",
  //     });
  //   }
  // };
  // const handlePromo = (d) => {
  //   if (d) {
  //     let send = {
  //       amount: subtotal,
  //       conferenceId: companyData?.conferenceId,
  //       userId: LogDetails?.userId,
  //       promoCode: d?.promoCode,
  //     };
  //     applyPromo(send, d);
  //   } else {
  //     Swal.fire({
  //       position: "center",
  //       width: "50%",
  //       title: "Please enter discount code",
  //       confirmButtonText: "Close",
  //       confirmButtonColor: "#E64942",
  //     });
  //     setSubTotal(companyData?.subtotal);
  //   }
  // };

  // const handleRemoveDC = () => {
  //   setDiscountValue();
  //   setSubTotal(companyData?.subtotal);
  // };

  const handlePay = () => {
    CLSec();
  };

  // const CallReqInvoice = async () => {
  //   setPayment(true);
  //   setAcBtn(false);
  // };

  useEffect(() => {
    PCSec();
    // eslint-disable-next-line
  }, []);
  const PCSec = async () => {
    let seData = {
      url: `/api/AssociationPayment/GetPaymentKeysByAssociationId?associationId=${id}`,
      body: {},
    };
    const res = await PostCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        const clientPub = res.data?.payload?.publicKey;
        setClientPublicKey(clientPub);
      } else {
        toast.error(res.data.message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } else {
      toast.error(res.data.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const stripePromise = loadStripe(clientPublicKey);
  const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();
    const [derror, setDerror] = useState();
    useEffect(() => {
      setTimeout(() => {
        loadCaptchaEnginge(6, "black", "white");
      }, 500);
    }, []);
    const [dis, setDis] = useState(false);
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();
    const handleSubmitStripe = async (data) => {
      // event.preventDefault();
      setDerror({ h_er: "" });

      if (validateCaptcha(data?.captcha, false) === true) {
        loadCaptchaEnginge(6, "black", "white");
        if (!stripe || !elements) {
          return;
        }

        const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: data?.cardName,
            address: { postal_code: data?.zip ? data?.zip : null },
          },
          // card: elements.getElement(CardElement),
        });

        if (error) {
          // handle payment error
        } else {
          const sourceId = paymentMethod.id;
          let paymentData = {
            ...companyData,
            userId: LogDetails?.userId,
            email: LogDetails?.email,
            amount: subtotal,
            discountAmount: discountValue?.discountValue,
            promoCodeId: discountValue?.promoCodeId,
            associationMembershipsTypesId:
              companyData?.associationMembershipsTypesId,
            associationId: companyData?.associationId,
            paymentMode: "Online",
          };
          let sveCC = {
            url: `/api/AssociationPayment/AssociationPaymentProcess`,
            body: {
              ...paymentData,
              PaymentMethodId: sourceId,
            },
            headers: headers,
          };
          setDis(true);
          let res = await PostCallApi(sveCC);
          if (res.status === 200) {
            if (res.data.isPaid) {
              setDis(false);
              dispatch(AttendeeRegistrationConference());
              nav(
                `/association-list/payment-success/${id}/${subtotal}?payment_intent=${res?.data?.paymentId}&payment_intent_client_secret=${res?.data?.paymentReference}&redirect_status=succeeded`,
                {
                  replace: true,
                }
              );
              setTimeout(() => {
                handleReset();
              }, 2000);
              toast.success(res.data.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            } else {
              setDis(false);

              toast.error(res.data.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          } else {
            setDis(false);

            toast.error(res.data.message, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }
        }
      } else {
        setDerror({ h_er: "Captcha does not match." });
      }
    };
    // const CARD_OPTIONS = {
    //   iconStyle: "solid",
    //   style: {
    //     base: {
    //       iconColor: "#666EE8",
    //       color: "#31325F",
    //       lineHeight: "40px",
    //       fontWeight: 600,
    //       fontFamily: "Quicksand, Open Sans, Segoe UI, sans-serif",
    //       fontSize: "16px",

    //       "::placeholder": {
    //         color: "#CFD7E0",
    //       },
    //     },
    //   },
    // };
    return (
      <form onSubmit={handleSubmit(handleSubmitStripe)}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Name On Card</label>{" "}
              <input
                type="text"
                className="form-control"
                placeholder="Enter name on card"
                {...register("cardName", {
                  required: "This field is required",
                })}
              />
              {errors.cardName && (
                <span role="alert" className="error_text">
                  {errors.cardName.message}
                </span>
              )}
            </div>
          </div>
          <div
            className="col-md-6 "
            // style={{ padding: "0.5rem", border: "1px solid gainsboro" }}
          >
            <div className="form-group">
              <label className="form-label">Card Number</label>
              <CardNumberElement className="form-control" options={options} />
            </div>
            {/* <CardElement options={CARD_OPTIONS} /> */}
          </div>
          <div className="col-md-6 ">
            <div className="form-group">
              <label className="form-label"> Expiration date</label>
              <CardExpiryElement className="form-control" options={options} />
            </div>
          </div>
          <div className="col-md-6 ">
            <div className="form-group">
              <label className="form-label">CVC</label>
              <CardCvcElement className="form-control" options={options} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Zip Code</label>{" "}
              <input
                type="text"
                className="form-control"
                placeholder="Enter Zip Code"
                {...register("zip", {
                  // required: "This field is required",
                  maxLength: {
                    value: 6,
                    message: "Max 6 characters",
                  },
                })}
              />
              {errors.zip && (
                <span role="alert" className="error_text">
                  {errors.zip.message}
                </span>
              )}
            </div>
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-6 my-auto">
            <div className="captcha_box ">
              <LoadCanvasTemplate />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Captcha</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Captcha"
                {...register("captcha", {
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                  maxLength: {
                    value: 6,
                    message: "Max 6 characters",
                  },
                })}
              />
              {errors?.captcha && (
                <span role="alert" className="d-block error_text">
                  {errors.captcha.message}
                </span>
              )}
              {derror?.h_er && (
                <span role="alert" className="d-block error_text">
                  {derror.h_er}
                </span>
              )}
            </div>
          </div>
        </div>
        <Button
          className="detail_button"
          type="submit"
          disabled={!stripe || dis}
        >
          {dis ? (
            <CircularProgress
              color="inherit"
              sx={{
                color: "#fff",
                marginLeft: "0.5rem",
                height: "23px !important",
                width: "23px !important",
              }}
            />
          ) : (
            "Complete Payment"
          )}
        </Button>
      </form>
    );
  };
  return (
    <>
      {showInvoise ? (
        <Invoice handleReset={handleReset} ATRC={allInVData} />
      ) : (
        <div className="">
          <div className="title attend-title mb-3">Payment Details</div>
          <div className="col-md-6">
            <div className="conference_details border-bottom-0 mb-4">
              <ul>
                <li>
                  Fees
                  <span>
                    {companyData?.subtotal?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </span>
                </li>{" "}
                {discountValue ? (
                  <li>
                    Discount
                    <span>
                      {" "}
                      -
                      {discountValue
                        ? discountValue.discountValue?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        : ""}
                    </span>
                  </li>
                ) : (
                  ""
                )}
              </ul>
              <div className="subtotal">
                <p>
                  Total{" "}
                  <span>
                    {subtotal
                      ? subtotal?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      : "0.00"}
                  </span>
                </p>
              </div>
              {/* {!payment && (
                <DiscountApply
                  handlePromo={handlePromo}
                  handleRemoveDC={handleRemoveDC}
                  discountValue={discountValue}
                />
              )} */}
              {!payment && (
                <div className="mt-3 text-end">
                  <Button
                    className="detail_button"
                    onClick={handlePay}
                    disabled={btnLoad}
                  >
                    {btnLoad ? (
                      <CircularProgress
                        color="inherit"
                        sx={{
                          color: "#fff",
                          marginLeft: "0.5rem",
                          height: "23px !important",
                          width: "23px !important",
                        }}
                      />
                    ) : (
                      "Pay"
                    )}
                  </Button>
                </div>
              )}
            </div>
          </div>
          {payment ? (
            <>
              <div className="d-flex gap-3 mb-3">
                <Button
                  className={`${acBtn ? "detail_button" : "back_button"}`}
                  onClick={() => setAcBtn(true)}
                >
                  Pay Online
                </Button>

                <Button
                  className={`${!acBtn ? "detail_button" : "back_button"}`}
                  onClick={() => setAcBtn(false)}
                >
                  Request Invoice
                </Button>
              </div>
              {acBtn ? (
                <Elements stripe={stripePromise}>
                  <CheckoutForm />
                </Elements>
              ) : (
                <ShowInvoice
                  RequestInvoice={RequestInvoice}
                  btnLoad={btnLoad}
                />
              )}
            </>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
}

export default AssociationUserPayment;
