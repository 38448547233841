import React, { useEffect, useState } from "react";
import "./index.scss";
import { useForm } from "react-hook-form";
import {
  Backdrop,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { GetCallApi } from "../../../../Action/Action";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { AttendeeRegistrationConference } from "../../../../reducers/Common";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import GroupInvoiceEdit from "../Edit";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { customFilter } from "../../../Components/CustomFilterForReactSelect";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F6FC",
  },
  padding: "5px",
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f6f6f6",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F1F6FC",
  },
  // hide last border
  //   "&:last-child td, &:last-child th": {
  //     border: 0,
  //   },
}));

function GroupRegisterEdit() {
  const { id } = useParams();
  const [dataOfEvent, setDataOfEvent] = useState();
  const [evList, setEvList] = useState([]);
  //   const [evAddList, setEvAddList] = useState([]);
  const [seList, setSeList] = useState([]);
  const [seAddList, setSeAddList] = useState([]);
  const [subtotal, setSubTotal] = useState("");
  const [conList, setConList] = useState([]);
  const [asList, setAsList] = useState([]);
  const [conQt, setConQt] = useState(1);
  const [paymentD, setPaymentD] = useState();
  const [payDetail, setPayDetail] = useState();
  const [openCircle, setOpenCircle] = useState(false);
  const [conPrice, setConPrice] = useState();
  // const [asId, setAsId] = useState("");
  // const [confId, setConfId] = useState("");
  // const [pyCC, setPyCC] = useState(false);
  // const [pyCCAm, setPyCCAm] = useState();
  const [showUserList, setShowUserList] = useState([]);
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  let dispatch = useDispatch();

  useEffect(() => {
    getConfList();
    GetAssociationMemberList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (conList?.length > 0) {
      if (asList?.length > 0) {
        getGroupRegisterData(id);
      }
    }
    // eslint-disable-next-line
  }, [conList, asList]);

  const getGroupRegisterData = async (id) => {
    let seData = {
      url: `/api/MemberManagement/GetMemberManagementInvoiceDataByMemberManagementInvoiceId?memberManagementInvoiceId=${id}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res?.data?.success) {
        let conData = res.data.payload;
        setValue(
          "FirstName",
          conData?.firstName ? conData?.firstName : LogDetail?.firstName
        );
        setValue(
          "LastName",
          conData?.lastName ? conData?.lastName : LogDetail?.lastName
        );
        setValue(
          "email",
          conData?.mmEmail ? conData?.mmEmail : LogDetail?.email
        );
        setValue(
          "phone",
          conData?.mmPhone ? conData?.mmPhone : LogDetail?.phone
        );
        setValue("memberManagementId", conData?.memberManagementId);

        setValue("associationMemberId", conData?.associationMemberId);
        // setAsId(conData?.associationMemberId);

        setValue("conferenceId", conData?.conferenceId);

        // setConfId(conData?.conferenceId);
        getConfData(conData?.conferenceId, conData);
        GetUserList(
          conData?.associationMemberId,
          conData?.conferenceId,
          conData?.userEventList
        );
      }
    }
  };

  const GetAssociationMemberList = async () => {
    let seData = {
      url:
        LogDetail?.roleId === 1
          ? `/api/AssociationMembers/GetAllAssociationMembersList?userId=${LogDetail?.userId}`
          : `/api/AssociationMembers/GetAssociationListByAdmin?userId=${LogDetail?.userId}`,
      headers: headers,
    };

    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        let asm = res.data.payload?.map((x) => {
          return {
            ...x,
            ...x.am,
          };
        });
        setAsList(asm);
      }
    }
  };

  const getConfList = async () => {
    let seData = {
      url: `/api/Conference/GetAllWithoutTokenConferenceList?isAlphanumericOrder=true`,
    };
    let res = await GetCallApi(seData);
    if (res.status === 200) {
      if (res.data.success) {
        let co = res.data.payload;
        setConList(co);
      }
    }
  };

  const getConfData = async (id, conData) => {
    if (id) {
      let res = await GetCallApi({
        url: `/api/Conference/GetAllConferenceInformation?conferenceId=${id}`,
        headers: headers,
      });
      if (res.status === 200) {
        if (res.data.success) {
          let comList1 = res?.data?.payload;

          setDataOfEvent(comList1);

          setSubTotal(comList1?.attendeeFees);
          let sevList = comList1?.eventLists
            ?.map((x) => {
              if (
                conData?.eventList?.filter(
                  (y) => y?.eventOrSessionId === x?.eventId
                )?.length > 0
              ) {
                return {
                  ...x,
                  Ql: conData?.eventList?.filter(
                    (y) => y?.eventOrSessionId === x?.eventId
                  )[0]?.eventAttendee,
                };
              }
              return x;
            })
            ?.filter((x) => new Date(x?.eventEnddate) > new Date());
          setEvList(sevList);

          setSeList(
            comList1?.classLists
              ?.map((x) => {
                if (
                  conData?.sessionList?.filter((y) => y === x?.classId)
                    ?.length > 0
                ) {
                  return {
                    ...x,
                    add: true,
                  };
                }
                return { ...x, add: false };
              })
              ?.filter((x) => new Date(x?.classesStartdate) > new Date())
          );
          setSeAddList(
            comList1?.classLists?.filter((x) =>
              conData?.sessionList?.includes(x?.classId)
            )
          );
          setConPrice(conData?.conferenceMembersPrice);

          setTimeout(() => {
            setConQt(conData?.conferenceMembersAttendee);
            setValue("ConferenceQuantity", conData?.conferenceMembersAttendee);
            setValue("orType", conData?.organizationOrAttendeeType);
          }, 1000);
          // setTimeout(() => {
          //   handleChnagePrice(conData?.organizationOrAttendeeType);
          // }, 2000);
          // setSeAddList(comList1?.classLists);
        }
      }
    } else {
      setDataOfEvent();
      setSubTotal();
      setEvList();
      setSeList([]);
      setSeAddList([]);
    }
  };

  const onSubmit = async (data) => {
    let is_success = true;
    let uslst = showUserList
      ?.filter((y) => y?.isRegister === false)
      ?.filter((x) => x?.add === true);
    if (uslst?.length === 0) {
      is_success = false;
      Swal.fire({
        position: "center",
        width: "50%",
        title: "Please select minimum one user",
        confirmButtonText: "Close",
        confirmButtonColor: "#E64942",
      });
    }
    if (!data?.orType) {
      is_success = false;
      Swal.fire({
        position: "center",
        width: "50%",
        title: "Register type is require",
        confirmButtonText: "Close",
        confirmButtonColor: "#E64942",
      });
    }
    if (is_success) {
      setOpenCircle(true);
      let sed = {
        ...dataOfEvent,
        ...data,
        subtotal: subtotal,
        evtList: evList?.filter((x) => x.Ql),
        organizationOrAttendeeType: data?.orType,
        conferenceMembersAttendee: parseInt(data?.ConferenceQuantity),
        conferenceMembersPrice: parseInt(data?.ConferenceQuantity) * conPrice,
        eventAttendeePriceList: evList
          ?.filter((x) => x.Ql)
          ?.map((x) => {
            return {
              eventId: x?.eventId,
              eventAttendee: parseInt(x?.Ql),
              eventPrice: x?.attendeeFees * parseInt(x?.Ql),
            };
          }),
        totalPrice: subtotal,
        sessionList: seAddList?.map((x) => x?.classId),
        createdBy: LogDetail?.userId,
        lstUserId: uslst?.map((x) => {
          return {
            userId: x.userId,
            isNewUser: x?.isNewUser,
            UserEventList: x?.events,
          };
        }),
        lstUser: uslst,
      };
      setPayDetail({
        ...sed,
      });
      setPaymentD(true);
      dispatch(
        AttendeeRegistrationConference({
          ...sed,
        })
      );
      setOpenCircle(false);
    }
  };

  const AddSessionList = (e) => {
    setSeAddList([...seAddList, e]);
    let ead = seList?.map((x) => {
      if (x?.classId === e?.classId) {
        return { ...x, add: true };
      }
      return { ...x };
    });
    setSeList(ead);
  };

  const GetUserList = async (aId, cId, addedusList) => {
    if (aId && cId) {
      let seData = {
        // url: `/api/Association/GetAllAssociationMembersTypeAllocationListByAssociationMembersId?associationMembersId=${id}`,
        url: `/api/Association/GetAllAssociationMembersTypeAllocationListByConferenceId?associationMembersId=${aId}&conferenceId=${cId}`,
        headers: headers,
      };
      let res = await GetCallApi(seData);
      if (res?.status === 200) {
        if (res?.data?.success) {
          let usList = res.data.payload?.map((x) => {
            if (
              addedusList?.filter((y) => y?.userId === x?.userId)?.length > 0
            ) {
              return {
                ...x,
                add: true,
                isRegister: false,
                isNewUser: false,
                events: addedusList?.filter((y) => y?.userId === x?.userId)[0]
                  ?.eventList,
              };
            }
            return {
              ...x,
              add: false,
              isNewUser: true,
            };
          });
          setShowUserList(usList);
          setCheckBoxVal(false);
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      } else {
        toast.error(res.data.message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } else {
      setShowUserList([]);
    }
  };

  const RemoveSessionList = (e) => {
    setSeAddList((oldArray) =>
      oldArray?.filter((data) => data?.classId !== e?.classId)
    );
    let ead = seList?.map((x) => {
      if (x?.classId === e?.classId) {
        return { ...x, add: false };
      }
      return { ...x };
    });
    setSeList(ead);
  };

  const handleCQ = (e) => {
    setConQt(e);
  };

  const handleManageEvent = (val, eId) => {
    let ev = evList?.map((x) => {
      if (x?.eventId === eId) {
        return { ...x, Ql: val };
      }
      return x;
    });
    setEvList(ev);
  };

  useEffect(() => {
    handleTotal();
    // eslint-disable-next-line
  }, [conQt, evList, conPrice]);

  const handleTotal = () => {
    let cp = conQt ? conPrice * parseInt(conQt) : conPrice * 1;
    let ep = evList
      ?.filter((x) => x.Ql)
      ?.map((ev) => ev?.attendeeFees * parseInt(ev?.Ql));
    const sum =
      ep?.length > 0
        ? ep?.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          )
        : 0;
    let sm = cp + sum;
    // if (pyCC) {
    //   setPyCCAm(sm * 0.03);
    //   sm = sm + sm * 0.03;
    // }
    setSubTotal(sm);
  };

  //   function mathRound(val) {
  //     return (Math.round(val * 100) / 100).toFixed(2);
  //   }

  const handleReset = () => {
    reset();
    setDataOfEvent();
    setEvList();
    setSeAddList();
    setSeList();
    setConQt();
    setPaymentD();
    setShowUserList([]);
    setTimeout(() => {
      setValue("FirstName", LogDetail?.firstName);
      setValue("LastName", LogDetail?.lastName);
      setValue("email", LogDetail?.email);
      setValue("phone", LogDetail?.phone);
    }, 1000);
  };
  const handleChnagePrice = (n) => {
    let pr = dataOfEvent?.conferenceAttendeePriceTypeData?.filter(
      (x) => x.name === n
    )[0]?.price;
    setConPrice(pr);
  };

  const [checkBoxVal, setCheckBoxVal] = useState(false);

  const handleAll = (e) => {
    const newArr = showUserList.map((obj) => {
      if (obj.isRegister) {
        return obj;
      }
      return { ...obj, add: e };
    });
    setShowUserList(newArr);
    setCheckBoxVal(e);
    if (dataOfEvent && e) {
      handleCQ(newArr?.filter((y) => y?.isRegister === false)?.length);
      setValue(
        "ConferenceQuantity",
        newArr?.filter((y) => y?.isRegister === false)?.length
      );
    }
  };

  const handleMainCheck = (e, index) => {
    let allMainCh = true;
    const newArr = showUserList.map((obj) => {
      if (obj.userId === index.userId) {
        return { ...obj, add: e };
      }
      return obj;
    });

    setShowUserList(newArr);
    if (dataOfEvent) {
      handleCQ(newArr?.filter((x) => x?.add === true)?.length);
      setValue(
        "ConferenceQuantity",
        newArr?.filter((x) => x?.add === true)?.length
      );
    }
    if (e) {
      newArr
        ?.filter((y) => y?.isRegister === false)
        ?.map((x) => {
          if (x.add === false || x.add === undefined) {
            return (allMainCh = false);
          }

          return 0;
        });
      // setCourseSchedule()
      if (allMainCh) {
        setCheckBoxVal(true);
      } else {
        setCheckBoxVal(false);
      }
    } else {
      setCheckBoxVal(false);
    }
  };
  const handleAddEventsToUser = (e, us) => {
    let newArr = showUserList.map((obj) => {
      if (obj.userId === us.userId) {
        return {
          ...obj,
          isNewUser: true,
          events: Array.isArray(e) ? e?.map((x) => x?.value) : [],
        };
      }
      return obj;
    });
    setShowUserList(newArr);
    let nuvList = newArr
      ?.filter((x) => x?.add === true)
      ?.filter((x) => x?.events?.length > 0);
    let evs = [];
    nuvList?.map((x) => {
      if (x?.events) {
        evs.push(...x.events); // Use push for safer mutation
      }
      // return       evs = [...evs, ...x?.events];
    });
    let evsave = evList?.map((x) => {
      if (evs?.length > 0) {
        let cn = evs?.filter((y) => y === x?.eventId)?.length;
        return {
          ...x,

          Ql: cn ? cn : 0,
        };
      }
      return { ...x, Ql: 0 };
    });
    setEvList(evsave);
  };

  return (
    <section className="Register_Conference_edit_Form ">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="main_wrapper">
        <div className="card">
          <div className="card-body">
            {paymentD ? (
              <GroupInvoiceEdit
                companyData={payDetail}
                handleReset={handleReset}
              />
            ) : (
              <>
                <div className="title">Edit Register Group in Conference</div>
                <form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          District or Organization Name
                          <span className="red-color"> *</span>
                        </label>
                        <select
                          className="form-select"
                          {...register("associationMemberId", {
                            required: "This field is required",
                            // onChange: (e) => setAsId(e.target.value),
                          })}
                          disabled
                        >
                          <option value="">
                            Select District or Organization Name
                          </option>
                          {asList &&
                            asList?.map((con) => (
                              <option
                                value={con.associationMembersId}
                                key={con.associationMembersId}
                              >
                                {con.name} - {con?.type}
                              </option>
                            ))}
                        </select>
                        {errors.associationMemberId && (
                          <span role="alert" className="error_text">
                            {errors.associationMemberId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6"></div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Conference
                          <span className="red-color"> *</span>
                        </label>
                        <select
                          className="form-select"
                          {...register("conferenceId", {
                            required: "This field is required",
                            // onChange: (e) => {
                            //   getConfData(e.target.value);
                            //   setConfId(e.target.value);
                            // },
                          })}
                          disabled
                        >
                          <option value="">Select Conference</option>
                          {conList &&
                            conList?.map((con) => (
                              <option
                                value={con.conferenceId}
                                key={con.conferenceId}
                              >
                                {con.conferenceName}
                              </option>
                            ))}
                        </select>
                        {errors.conferenceId && (
                          <span role="alert" className="error_text">
                            {errors.conferenceId.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Register Type
                          {/* Organization or Attendee Type */}
                          <span className="red-color"> *</span>
                        </label>
                        {dataOfEvent?.conferenceAttendeePriceTypeData?.map(
                          (pr) => (
                            <div key={pr?.conferenceAttendeePriceTypeId}>
                              <input
                                type="radio"
                                value={pr?.name}
                                {...register("orType", {
                                  // required: "This field is required",
                                  onChange: (e) =>
                                    handleChnagePrice(e.target.value, pr.price),
                                })}
                                disabled
                              />{" "}
                              {pr?.name}
                            </div>
                          )
                        )}

                        {errors.orType && (
                          <span role="alert" className="error_text">
                            {errors.orType.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter First Name"
                          {...register("FirstName", {
                            pattern: {
                              value: /^[^\s]+(\s+[^\s]+)*$/,
                              message: "Starting and Ending Space not allowed",
                            },
                          })}
                        />
                        {errors.FirstName && (
                          <span role="alert" className="error_text">
                            {errors.FirstName.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Last Name"
                          {...register("LastName", {
                            pattern: {
                              value: /^[^\s]+(\s+[^\s]+)*$/,
                              message: "Starting and Ending Space not allowed",
                            },
                          })}
                        />
                        {errors.LastName && (
                          <span role="alert" className="error_text">
                            {errors.LastName.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Phone/Mobile
                          <span className="red-color"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Phone/Mobile"
                          {...register("phone", {
                            required: "This field is required",
                            pattern: {
                              value:
                                /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                              message: "Enter valid phone number",
                            },
                          })}
                        />
                        {errors.phone && (
                          <span role="alert" className="error_text">
                            {errors.phone.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Email
                          <span className="red-color"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Email"
                          {...register("email", {
                            required: "This field is required",
                            pattern: {
                              value: /\S+@\S+\.\S+/,
                              message: "Please Enter Valid Email",
                            },
                          })}
                        />
                        {errors.email && (
                          <span role="alert" className="error_text">
                            {errors.email.message}
                          </span>
                        )}
                      </div>
                    </div>
                    {dataOfEvent && conPrice ? (
                      <>
                        <div className="col-md-6 my-auto">
                          <h6 className="f-14-400">
                            {dataOfEvent?.conferenceName}
                          </h6>
                          <p className="f-14-400 mb-0">
                            {" "}
                            Price:${conPrice ? conPrice : ""}
                          </p>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              Quantity
                              <span className="red-color"> *</span>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter Quantity"
                              min={1}
                              {...register("ConferenceQuantity", {
                                required: "This field is required",
                                onChange: (e) => handleCQ(e.target.value),
                              })}
                              disabled
                            />
                            {errors.ConferenceQuantity && (
                              <span role="alert" className="error_text">
                                {errors.ConferenceQuantity.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {evList?.length || evList?.length > 0 ? (
                      <>
                        <hr />
                        <h5 className="form-label"> Events</h5>

                        {evList?.map((ev, i) => (
                          <React.Fragment key={i}>
                            <div className="col-md-3 my-auto">
                              <h6 className="f-14-400">{ev?.eventName}</h6>
                              <p className="f-14-400 mb-0">
                                {" "}
                                Price:${ev?.attendeeFees}
                              </p>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label className="form-label">
                                  Quantity
                                  {/* <span className="red-color"> *</span> */}
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Enter Quantity"
                                  min={0}
                                  value={ev?.Ql}
                                  onChange={(e) =>
                                    handleManageEvent(
                                      e.target.value,
                                      ev.eventId
                                    )
                                  }
                                  disabled
                                />
                              </div>
                            </div>
                          </React.Fragment>
                        ))}
                      </>
                    ) : (
                      ""
                    )}
                    {seList?.length || seAddList?.length > 0 ? (
                      <>
                        <hr />

                        <div className="col-md-12">
                          <h5 className="form-label">Add Session</h5>
                          {seList?.length === 0 ? (
                            ""
                          ) : (
                            <div className="pr_box_gtup">
                              {seList &&
                                seList?.map((item, index) => (
                                  <div key={index} className="form-group1">
                                    <div className="d-flex justify-content-between align-items-center">
                                      <div>{item?.classTitle}</div>
                                      {item?.add === true ? (
                                        <>
                                          <Button
                                            className="grey_button"
                                            onClick={() =>
                                              RemoveSessionList(item)
                                            }
                                          >
                                            <AiOutlineMinus />
                                          </Button>
                                        </>
                                      ) : (
                                        <Button
                                          className="green_button"
                                          onClick={() => AddSessionList(item)}
                                        >
                                          <AiOutlinePlus className="icon" />
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {/* <div className="col-md-12 mt-3">
                      <div className="form-group">
                        <label className="form-label">
                          {" "}
                          File Upload - Upload your list of attendee's (First
                          Name, Last Name, Email, SNA Number, Position, Phone
                          Number, Please include any meal preferences or
                          requirements)
                          <span className="red-color"> *</span>
                        </label>
                        <div className="d-flex upload_btn_sec">
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            placeholder="No file choose"
                            {...register(`DisplayImageUrl`, {
                              required: {
                                value: true,
                                message: "This field is required",
                              },
                            })}
                          />
                          <IconButton
                            component="label"
                            className="upload_btn m-0"
                          >
                            Choose File
                            <input
                              id="upload"
                              type="file"
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              className="form-control"
                              hidden
                              onChange={(e) => handleExcelFile(e)}
                            />
                          </IconButton>
                        </div>

                        {errors.DisplayImageUrl && (
                          <span role="alert" className="error_text">
                            {errors.DisplayImageUrl.message}
                          </span>
                        )}
                      </div>
                    </div> */}
                    {showUserList?.length > 0 ? (
                      <div className="table-wrap mt-5">
                        <Table className="table-bordered Conference-table">
                          <TableHead className="thead-primary">
                            <StyledTableRow>
                              <StyledTableCell>
                                <label className="">
                                  <input
                                    type="checkbox"
                                    checked={checkBoxVal}
                                    onChange={(e) =>
                                      handleAll(e.target.checked)
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </StyledTableCell>
                              <StyledTableCell>Id</StyledTableCell>
                              <StyledTableCell>User Name</StyledTableCell>
                              <StyledTableCell>Role</StyledTableCell>
                              <StyledTableCell>Email</StyledTableCell>
                              <StyledTableCell>Phone</StyledTableCell>
                              <StyledTableCell>Event</StyledTableCell>
                            </StyledTableRow>
                          </TableHead>
                          <TableBody>
                            {showUserList?.map((us, id) => (
                              <StyledTableRow className="table_body" key={id}>
                                <StyledTableCell>
                                  {us?.isRegister ? (
                                    "Registered"
                                  ) : (
                                    <label className=" ">
                                      <input
                                        type="checkbox"
                                        checked={us?.add}
                                        onChange={(e) =>
                                          handleMainCheck(
                                            e?.target?.checked,
                                            us
                                          )
                                        }
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  )}
                                </StyledTableCell>
                                <StyledTableCell>{id + 1}</StyledTableCell>

                                <StyledTableCell>
                                  {us?.userName}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {us?.userRole}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {us?.userEmail}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {us?.userPhone}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {us?.add ? (
                                    <Select
                                      isMulti
                                      name="colors"
                                      options={evList?.map((x) => {
                                        return {
                                          ...x,
                                          label: x?.eventName,
                                          value: x?.eventId,
                                        };
                                      })}
                                      className="company-multi-select"
                                      classNamePrefix="select"
                                      placeholder="Select Events"
                                      filterOption={customFilter}
                                      value={evList
                                        ?.map((x) => {
                                          return {
                                            ...x,
                                            label: x?.eventName,
                                            value: x?.eventId,
                                          };
                                        })
                                        ?.filter((obj) =>
                                          us?.events?.includes(obj.value)
                                        )}
                                      onChange={(e) =>
                                        handleAddEventsToUser(e, us)
                                      }
                                    />
                                  ) : (
                                    ""
                                  )}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                    ) : (
                      ""
                    )}
                    {dataOfEvent && conPrice ? (
                      <div className="table-wrap mt-5">
                        <Table className="table-bordered Conference-table">
                          <TableHead className="thead-primary">
                            <StyledTableRow>
                              <StyledTableCell className="txt_st">
                                Item
                              </StyledTableCell>
                              <StyledTableCell className="txt_st">
                                Qty
                              </StyledTableCell>
                              <StyledTableCell className="txt_st">
                                Line Total
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableHead>
                          <TableBody>
                            <StyledTableRow>
                              <StyledTableCell>
                                {dataOfEvent?.conferenceName}
                              </StyledTableCell>
                              <StyledTableCell>
                                {conQt ? conQt : 1}
                              </StyledTableCell>
                              <StyledTableCell>
                                $
                                {conQt
                                  ? conPrice * parseInt(conQt)
                                  : conPrice * 1}
                              </StyledTableCell>
                            </StyledTableRow>
                            {evList?.filter((x) => x.Ql)?.length > 0
                              ? evList
                                  ?.filter((x) => x.Ql)
                                  ?.map((ev, i) => (
                                    <StyledTableRow key={i}>
                                      <StyledTableCell>
                                        {ev?.eventName}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {ev?.Ql}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        ${ev?.attendeeFees * parseInt(ev?.Ql)}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                              : ""}
                            {/* {pyCC ? (
                              <StyledTableRow>
                                <StyledTableCell
                                 
                                  colSpan={2}
                                >
                                  Credit Card Fee 3%
                                </StyledTableCell>
                                <StyledTableCell
                                >
                                  ${pyCCAm}
                                </StyledTableCell>
                              </StyledTableRow>
                            ) : (
                              ""
                            )} */}
                            <StyledTableRow>
                              <StyledTableCell
                                style={{
                                  backgroundColor: "white",
                                  textAlign: "right",
                                }}
                                colSpan={2}
                              >
                                <b>Total</b>
                              </StyledTableCell>
                              <StyledTableCell
                                style={{ backgroundColor: "white" }}
                              >
                                <b>${subtotal}</b>
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* <div className="mt-3">
                      <label className=" personal_detail_label">
                        <input
                          type="checkbox"
                          className="login_check"
                          name="Remember Me"
                          checked={pyCC}
                          // disabled={c?.status}
                          onChange={(e) => setPyCC(e?.target?.checked)}
                        />
                        <span className="checkmark"></span>
                        <span className="ms-4">Paying with credit card</span>
                      </label>
                    </div> */}
                    <div className="col-md-12 mt-3">
                      <Button type="submit" className="detail_button">
                        Submit
                      </Button>
                    </div>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default GroupRegisterEdit;
