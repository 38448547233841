export const Config = {
  // API_HOST_URL: "http://eventplanner.vidhaninfotech.com",
  // API_HOST_URL_live: "http://apieventplan.vidhaninfotech.com",
  STRIPE_PUBLIC_KEY:
    "pk_test_51Jcb3kEf9tRIx2k2ERTwAkqw325lvTvxlxHmJqy4Nl1iuWCAApvbya7XgY7awLar37fFqkoAZWrI964TKxYjcvZP00EBvSnMNV",
  API_HOST_URL: "https://fig.events",
  API_HOST_URL_live: "https://dev.fig.events",
  // STRIPE_PUBLIC_KEY:
  //   "pk_live_51IDehfDxGiRp8cjuAQ79rK3S8RMyB6fLbUESE42EgZhePcZBY6DQxly6iQHPlIE5f8KewcWps7mpGa4bCkM0AVaa009R40meby",
};
