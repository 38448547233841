import React, { Suspense, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import "./index.scss";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
function UserAssociationPaymentSuccess() {
  const { amt } = useParams();
  const location = useLocation();
  const [payment, setPayment] = useState();
  const [loading, setLoading] = useState(false);

  const LogDetails = useSelector((state) => state.login.LoginDetails);

  const intervalIDRef = React.useRef(null);
  const startTimer = React.useCallback(() => {
    var count = 0;
    intervalIDRef.current = setInterval(() => {
      document.getElementById("loading").innerHTML =
        "Loading" + new Array((++count % 4) + 1).join(".");
    }, 500);
  }, []);
  const stopTimer = React.useCallback(() => {
    clearInterval(intervalIDRef.current);
    document.getElementById("loading").innerHTML = "";
  }, []);

  useEffect(() => {
    document.title = "FIG Events | Payment Success";
    if (location?.search) {
      startTimer();
      setLoading(true);
      getPayment(location?.search);
    }
    // eslint-disable-next-line
  }, [location]);
  const getPayment = async (name) => {
    let pid = name
      ?.split("&payment_intent_client_secret=")[0]
      ?.split("?payment_intent=")[1];
    let scid = name
      ?.split("&payment_intent_client_secret=")[1]
      ?.split("&redirect_status=")[0];
    let rest = name
      ?.split("&payment_intent_client_secret=")[1]
      ?.split("&redirect_status=")[1];
    if (pid && scid && rest) {
      stopTimer();
      setLoading(false);
      setPayment(scid);
    }
  };
  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="payment_success_usere_association py-5">
          {/* <img src={paymentBanner} className="img-fluid" /> */}
          <div className="main_wrapper">
            <div className="payment_inner">
              <div className="row">
                <div className="col-md-12">
                  <div id="loading" className="h6_text text-center"></div>
                  {!loading && payment && (
                    <div className="success_message_box">
                      <BsFillPatchCheckFill className="icon" />
                      <div>
                        <h1 className="m-0">Payment Successful</h1>
                        <p>
                          We will share payment receipt on registered email.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row paymentAndUserInfo mt-4">
                {!loading && payment && (
                  <>
                    <div className="col-md-6">
                      <ul>
                        <li>
                          Payment Reference Id :{" "}
                          <span
                            style={{
                              maxWidth: "300px",
                              wordBreak: "break-all",
                            }}
                          >
                            {payment}
                          </span>
                        </li>
                        <li>
                          Paid Amount : <span>{amt}</span>
                        </li>
                        {/* <li>Payment Date : <span>17 Aug 2023</span></li> */}
                        <li>
                          Payment Status : <span>Success</span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul>
                        <li>
                          Name :{" "}
                          <span>
                            {LogDetails?.firstName + " " + LogDetails?.lastName}
                          </span>
                        </li>
                        <li>
                          Email : <span>{LogDetails?.email}</span>
                        </li>
                        <li>
                          Phone : <span>{LogDetails?.phone}</span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-12 text-end mt-4">
                      {LogDetails?.authenticate ? (
                        <>
                          <Link
                            className="detail_button"
                            to={
                              LogDetails?.roleId === 4
                                ? "/exhibitor-dashboard"
                                : LogDetails?.roleId === 5 ||
                                  LogDetails?.roleName === "Speaker"
                                ? "/attendee-dashboard"
                                : LogDetails?.roleId === 1 ||
                                  LogDetails?.roleId === 2
                                ? "/home"
                                : ""
                            }
                          >
                            Go To Dashboard
                          </Link>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default UserAssociationPaymentSuccess;
