import React, { useEffect, useState } from "react";
import "./index.scss";
import { useForm } from "react-hook-form";
import { Button, CircularProgress, IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
import { useParams } from "react-router";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import { customFilter } from "../../../Components/CustomFilterForReactSelect";
import { ToastError } from "../../../Components/Toast";

function VendorCreate(props) {
  const { CloseFunction } = props;
  const [openCircle, setOpenCircle] = useState(false);
  const [companyList, setCompanyList] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [derror, setDerror] = useState();
  const [ecDe, setEcDe] = useState();
  const { id } = useParams();
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  let nav = useNavigate();
  const { pathname } = useLocation();
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const onSubmit = async (data) => {
    let sendData = { ...data, createdBy: LogDetail?.userId };
    delete sendData.PrIm;
    let Sessiondata = new FormData();

    for (var key in sendData) {
      Sessiondata.append(key, sendData[key]);
    }
    // console.log(sendData);
    let seData = {
      url: "/api/ExhibitorVendor/CreateExhibitorVendor",
      body: Sessiondata,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    };
    setOpenCircle(true);
    setDerror({ err: "" });

    let res = await PostCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200 && res.data.success) {
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      if (pathname?.split("/")[1] === "conference-exhibotor-register") {
        CloseFunction();
      } else {
        nav(-1);
      }
    } else {
      setDerror({ err: res.data.message });
      ToastError(res.data.message);
    }
  };

  const handlePRIm = async (e) => {
    if (e.target.files?.length > 0) {
      let multifile = e.target.files[0]?.name;
      let base64_namemulti = e.target.files[0];

      setValue(`Image`, base64_namemulti);
      setValue(`PrIm`, multifile);
    }
  };
  useEffect(() => {
    GetCompanyRegistrationList();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (companyList?.legth > 0) {
      if (ecDe) {
        setValue("CompanyId", ecDe?.companyId);
        setEcDe();
      }
    }
    // eslint-disable-next-line
  }, [companyList, ecDe]);
  useEffect(() => {
    document.title = "FIG Events | Team Members";
    if (id) {
      GetVrDdetails(id);
    }
    // eslint-disable-next-line
  }, [id]);
  const GetVrDdetails = async (id) => {
    let seData = {
      url: `/api/ExhibitorVendor/GetExhibitorVerdorByExhibitorVendorId?exhibitorVendorId=${id}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        let sendData = res?.data?.payload;
        setEcDe(sendData);

        setValue("exhibitorVendorId", id);
        setValue("firstName", sendData?.firstName);
        setValue("lastName", sendData?.lastName);
        setValue("Email", sendData?.email);
        setValue("PrIm", sendData?.image);
        setValue("Image", sendData?.image);
        setValue("CompanyId", sendData?.companyId);
        setCompanyId(sendData?.companyId);
      }
    }
  };
  const GetCompanyRegistrationList = async () => {
    let seData = {
      url: `/api/CompanyRegistration/GetAllCompanyRegistration?userId=${LogDetail?.userId}`,
      headers: headers,
    };
    setCompanyList([]);

    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res.data.success) {
        let comList = res?.data?.payload?.map((c, i) => {
          return {
            id: i + 1,
            ...c,
            value: c?.companyId,
            label: c?.companyName,
          };
        });
        setCompanyList(comList);
      }
    }
  };
  const [companyId, setCompanyId] = useState();

  const handleChangeCompany = (val) => {
    setValue("CompanyId", val);
    setCompanyId(val);
  };
  const HandleTeam = ({ showCancel }) => {
    return (
      <form onSubmit={handleSubmit(onSubmit)} className="form_box">
        <div className="row">
          <div className="col-xl-6 col-md-12 col-12">
            <div className="form-group">
              <label className="form-label">
                First Name
                <span className="red-color"> *</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter First Name"
                {...register("firstName", {
                  required: "This field is required",
                })}
              />
              {errors.firstName && (
                <span role="alert" className="error_text">
                  {errors.firstName.message}
                </span>
              )}
            </div>
          </div>
          <div className="col-xl-6 col-md-12 col-12">
            <div className="form-group">
              <label className="form-label">
                Last Name
                <span className="red-color"> *</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Last Name"
                {...register("lastName", {
                  required: "This field is required",
                })}
              />
              {errors.lastName && (
                <span role="alert" className="error_text">
                  {errors.lastName.message}
                </span>
              )}
            </div>
          </div>{" "}
          <div className="col-xl-6 col-md-12 col-12">
            <div className="form-group">
              <label className="form-label"> Email</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter  Email"
                {...register("Email", {
                  required: "This field is required",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Please Enter Valid Email",
                  },
                })}
              />
              {errors.Email && (
                <span role="alert" className="error_text">
                  {errors.Email.message}
                </span>
              )}
            </div>
          </div>{" "}
          <div className="col-xl-6 col-md-12 col-12">
            <div className="form-group">
              <label className="form-label">Company </label>
              <Select
                isMulti={false}
                name="colors"
                options={companyList}
                isClearable={true}
                className="dropdown-single-select"
                classNamePrefix="select"
                placeholder="Select Company"
                filterOption={customFilter}
                value={companyList?.filter((obj) => obj.value === companyId)}
                onChange={(e) => handleChangeCompany(e?.value)}
              />
              <select
                type="text"
                className="form-select d-none"
                {...register("CompanyId", {
                  required: "This field is required",
                })}
              >
                <option value="">Select Company</option>
                {companyList &&
                  companyList?.map((x, i) => (
                    <option value={x?.companyId} key={i}>
                      {x?.companyName}
                    </option>
                  ))}
              </select>
              {errors.CompanyId && (
                <span role="alert" className="error_text">
                  {errors.CompanyId.message}
                </span>
              )}
            </div>
          </div>
          <div className="col-xl-12 col-md-12 col-12">
            <div className="form-group">
              <label className="form-label">Profile Image</label>
              <div className="d-flex upload_btn_sec">
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  placeholder="No file choose"
                  {...register(`PrIm`)}
                />
                <IconButton component="label" className="upload_btn m-0">
                  Choose Image
                  <input
                    id="upload_img"
                    type="file"
                    accept="image/*"
                    className="form-control"
                    hidden
                    onChange={(e) => handlePRIm(e)}
                  />
                </IconButton>
              </div>

              {/* crop code end */}
              {errors.PrIm && (
                <span role="alert" className="error_text">
                  {errors.PrIm.message}
                </span>
              )}
            </div>
          </div>
          <div className="col-md-12 d-flex justify-content-end mt-5">
            <div className="form-group">
              <Button
                className="detail_button"
                type="submit"
                disabled={openCircle}
              >
                {openCircle ? (
                  <CircularProgress
                    color="inherit"
                    sx={{
                      color: "#fff",
                      marginLeft: "0.5rem",
                      height: "23px !important",
                      width: "23px !important",
                    }}
                  />
                ) : showCancel ? (
                  id ? (
                    "Update"
                  ) : (
                    "Create"
                  )
                ) : (
                  "Create"
                )}
              </Button>
              {showCancel ? (
                <Button onClick={() => nav(-1)} className="back_button ms-2">
                  {" "}
                  Back
                </Button>
              ) : (
                ""
              )}
              {derror?.err && (
                <span role="alert" className="d-block error_text">
                  {derror.err}
                </span>
              )}
            </div>
          </div>
        </div>
      </form>
    );
  };

  return (
    <>
      {pathname?.split("/")[1] === "conference-exhibotor-register" ? (
        <HandleTeam />
      ) : (
        <section className="Ex_prouct_Create">
          {" "}
          <div className="main_wrapper">
            <div className="title mb-3">
              {id ? "Edit" : "Create"} Team Members
            </div>
            <div className="card">
              <div className="card-body">
                <HandleTeam showCancel={true} />
              </div>
            </div>
          </div>
        </section>
      )}{" "}
    </>
  );
}

export default VendorCreate;
