import React, { Suspense } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import Config from "../../../Utils/Config";
import "react-tabs/style/react-tabs.css";
import CRMHeader from "../Shared/CRMHeader";
import { MdContacts } from "react-icons/md";
import { FaRegStar } from "react-icons/fa";
import { BsWindow } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";
import { BsTrash3 } from "react-icons/bs";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { MdOutlinePublic } from "react-icons/md";
import IconButton from "@mui/material/IconButton";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import Tooltip from "@mui/joy/Tooltip";
import Button from "@mui/joy/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { RxCross2 } from "react-icons/rx";
import { BsUpload } from "react-icons/bs";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { CiSettings } from "react-icons/ci";
import { LuArchive } from "react-icons/lu";
import { LuBook } from "react-icons/lu";
import { CircularProgress } from "@mui/material";

const avatar_sm = "/assets/images/avatar-sm.jpg";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

function ProjectsBoard() {
  const [openSide, setOpenSide] = React.useState(true);
  const [open1, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose1 = () => setOpen(false);
  // const [dataOfTable, setDataOfTable] = useState([]);
  // const [hoverData, setHoverData] = useState(null);
  const [variant] = React.useState("solid");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CRMHeader />
      <Suspense fallback={renderLoader()}>
        <section className="contactlist-section project-board-section">
          <div className="container-fluid">
            <div className="row">
              <div
                className={`${
                  openSide ? "" : "hide_side"
                } col-lg-2 col-md-4 contact-border`}
              >
                <div className="cotact-left-section">
                  <Link to="" className="primary-btn btn-border">
                    Add New Board
                  </Link>
                  <div className="menu-group">
                    <ul>
                      <li>
                        <Link className="nav-link">
                          <MdContacts /> All Boards
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <FaRegStar /> Stared Boards
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <BsWindow /> Private Boards
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <FaRegEdit /> Public Boards
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <BsTrash3 /> Deleted
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="separator separator-light"></div>
                  <div className="menu-group">
                    <ul>
                      <li>
                        <Link className="nav-link">
                          <MdContacts /> Jampack
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <FaRegStar /> Hencework
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="separator separator-light"></div>
                </div>
                <div className="contactapp-fixednav">
                  <div className="menu-group">
                    <ul>
                      <li>
                        <Link>
                          <CiSettings />
                        </Link>
                      </li>
                      <li>
                        <Link>
                          <LuArchive />
                        </Link>
                      </li>
                      <li>
                        <Link>
                          <LuBook />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  openSide ? "col-lg-10 col-md-8" : "col-lg-12"
                } contact-right-side`}
              >
                <div className="togglebar">
                  <div
                    className="sidebar-togglable"
                    onClick={() => setOpenSide(!openSide)}
                  >
                    <MdKeyboardArrowLeft />
                  </div>
                </div>
                <div className="board-tab-block">
                  <Tabs aria-label="Basic tabs" defaultValue={0}>
                    <TabList>
                      <Tab>Board</Tab>
                      <Tab>Team</Tab>
                    </TabList>

                    <TabPanel value={0}>
                      <div className="board-tab">
                        <div className="row justify-content-center">
                          <div className="col-lg-8 col-md-12">
                            <h4 className="mb-4">Frequent Boards</h4>
                            <div className="row">
                              <div className="col-lg-6 col-md-6 mb-3">
                                <div className="board-box">
                                  <div className="top-board">
                                    <div className="name-box bg-pink">J</div>
                                    <h6>Jampack</h6>
                                  </div>
                                  <div className="bottom-board">
                                    <div>
                                      <AvatarGroup max={4}>
                                        <Avatar
                                          alt="Remy Sharp"
                                          src="/static/images/avatar/1.jpg"
                                        />
                                        <Avatar
                                          alt="Travis Howard"
                                          src="/static/images/avatar/2.jpg"
                                        />
                                        <Avatar
                                          alt="Cindy Baker"
                                          src="/static/images/avatar/3.jpg"
                                        />
                                        <Avatar
                                          alt="Agnes Walker"
                                          src="/static/images/avatar/4.jpg"
                                        />
                                        <Avatar
                                          alt="Trevor Henderson"
                                          src="/static/images/avatar/5.jpg"
                                        />
                                      </AvatarGroup>
                                    </div>
                                    <div className="bottom-content">
                                      <p>Updated 25 min ago</p>
                                      <Link>
                                        <MdOutlinePublic />
                                      </Link>
                                      <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={
                                          open ? "long-menu" : undefined
                                        }
                                        aria-expanded={
                                          open ? "true" : undefined
                                        }
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                        className="icon-btn"
                                      >
                                        <HiOutlineDotsHorizontal />
                                      </IconButton>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 mb-3">
                                <div className="board-box">
                                  <div className="top-board">
                                    <div className="name-box bg-orang">A</div>
                                    <h6>Angular - Jampack</h6>
                                  </div>
                                  <div className="bottom-board">
                                    <div>
                                      <AvatarGroup max={4}>
                                        <Avatar
                                          alt="Remy Sharp"
                                          src="/static/images/avatar/1.jpg"
                                        />
                                        <Avatar
                                          alt="Travis Howard"
                                          src="/static/images/avatar/2.jpg"
                                        />
                                        <Avatar
                                          alt="Cindy Baker"
                                          src="/static/images/avatar/3.jpg"
                                        />
                                        <Avatar
                                          alt="Agnes Walker"
                                          src="/static/images/avatar/4.jpg"
                                        />
                                        <Avatar
                                          alt="Trevor Henderson"
                                          src="/static/images/avatar/5.jpg"
                                        />
                                      </AvatarGroup>
                                    </div>
                                    <div className="bottom-content">
                                      <p>Updated 25 min ago</p>
                                      <Link>
                                        <MdOutlinePublic />
                                      </Link>
                                      <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={
                                          open ? "long-menu" : undefined
                                        }
                                        aria-expanded={
                                          open ? "true" : undefined
                                        }
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                        className="icon-btn"
                                      >
                                        <HiOutlineDotsHorizontal />
                                      </IconButton>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <h4 className="mb-4 mt-4">All Boards</h4>
                              <div className="col-lg-6 col-md-6 mb-3">
                                <div className="board-box">
                                  <div className="top-board">
                                    <div className="name-box bg-pink">J</div>
                                    <h6>Jampack</h6>
                                  </div>
                                  <div className="bottom-board">
                                    <div>
                                      <AvatarGroup max={4}>
                                        <Avatar
                                          alt="Remy Sharp"
                                          src="/static/images/avatar/1.jpg"
                                        />
                                        <Avatar
                                          alt="Travis Howard"
                                          src="/static/images/avatar/2.jpg"
                                        />
                                        <Avatar
                                          alt="Cindy Baker"
                                          src="/static/images/avatar/3.jpg"
                                        />
                                        <Avatar
                                          alt="Agnes Walker"
                                          src="/static/images/avatar/4.jpg"
                                        />
                                        <Avatar
                                          alt="Trevor Henderson"
                                          src="/static/images/avatar/5.jpg"
                                        />
                                      </AvatarGroup>
                                    </div>
                                    <div className="bottom-content">
                                      <p>Updated 25 min ago</p>
                                      <Link>
                                        <MdOutlinePublic />
                                      </Link>
                                      <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={
                                          open ? "long-menu" : undefined
                                        }
                                        aria-expanded={
                                          open ? "true" : undefined
                                        }
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                        className="icon-btn"
                                      >
                                        <HiOutlineDotsHorizontal />
                                      </IconButton>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 mb-3">
                                <div className="board-box">
                                  <div className="top-board">
                                    <div className="name-box bg-orang">A</div>
                                    <h6>Angular - Jampack</h6>
                                  </div>
                                  <div className="bottom-board">
                                    <div>
                                      <AvatarGroup max={4}>
                                        <Avatar
                                          alt="Remy Sharp"
                                          src="/static/images/avatar/1.jpg"
                                        />
                                        <Avatar
                                          alt="Travis Howard"
                                          src="/static/images/avatar/2.jpg"
                                        />
                                        <Avatar
                                          alt="Cindy Baker"
                                          src="/static/images/avatar/3.jpg"
                                        />
                                        <Avatar
                                          alt="Agnes Walker"
                                          src="/static/images/avatar/4.jpg"
                                        />
                                        <Avatar
                                          alt="Trevor Henderson"
                                          src="/static/images/avatar/5.jpg"
                                        />
                                      </AvatarGroup>
                                    </div>
                                    <div className="bottom-content">
                                      <p>Updated 25 min ago</p>
                                      <Link>
                                        <MdOutlinePublic />
                                      </Link>
                                      <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={
                                          open ? "long-menu" : undefined
                                        }
                                        aria-expanded={
                                          open ? "true" : undefined
                                        }
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                        className="icon-btn"
                                      >
                                        <HiOutlineDotsHorizontal />
                                      </IconButton>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 mb-3">
                                <div className="board-box">
                                  <div className="top-board">
                                    <div className="name-box bg-pink">J</div>
                                    <h6>Jampack</h6>
                                  </div>
                                  <div className="bottom-board">
                                    <div>
                                      <AvatarGroup max={4}>
                                        <Avatar
                                          alt="Remy Sharp"
                                          src="/static/images/avatar/1.jpg"
                                        />
                                        <Avatar
                                          alt="Travis Howard"
                                          src="/static/images/avatar/2.jpg"
                                        />
                                        <Avatar
                                          alt="Cindy Baker"
                                          src="/static/images/avatar/3.jpg"
                                        />
                                        <Avatar
                                          alt="Agnes Walker"
                                          src="/static/images/avatar/4.jpg"
                                        />
                                        <Avatar
                                          alt="Trevor Henderson"
                                          src="/static/images/avatar/5.jpg"
                                        />
                                      </AvatarGroup>
                                    </div>
                                    <div className="bottom-content">
                                      <p>Updated 25 min ago</p>
                                      <Link>
                                        <MdOutlinePublic />
                                      </Link>
                                      <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={
                                          open ? "long-menu" : undefined
                                        }
                                        aria-expanded={
                                          open ? "true" : undefined
                                        }
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                        className="icon-btn"
                                      >
                                        <HiOutlineDotsHorizontal />
                                      </IconButton>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 mb-3">
                                <div className="board-box">
                                  <div className="top-board">
                                    <div className="name-box bg-orang">A</div>
                                    <h6>Angular - Jampack</h6>
                                  </div>
                                  <div className="bottom-board">
                                    <div>
                                      <AvatarGroup max={4}>
                                        <Avatar
                                          alt="Remy Sharp"
                                          src="/static/images/avatar/1.jpg"
                                        />
                                        <Avatar
                                          alt="Travis Howard"
                                          src="/static/images/avatar/2.jpg"
                                        />
                                        <Avatar
                                          alt="Cindy Baker"
                                          src="/static/images/avatar/3.jpg"
                                        />
                                        <Avatar
                                          alt="Agnes Walker"
                                          src="/static/images/avatar/4.jpg"
                                        />
                                        <Avatar
                                          alt="Trevor Henderson"
                                          src="/static/images/avatar/5.jpg"
                                        />
                                      </AvatarGroup>
                                    </div>
                                    <div className="bottom-content">
                                      <p>Updated 25 min ago</p>
                                      <Link>
                                        <MdOutlinePublic />
                                      </Link>
                                      <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={
                                          open ? "long-menu" : undefined
                                        }
                                        aria-expanded={
                                          open ? "true" : undefined
                                        }
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                        className="icon-btn"
                                      >
                                        <HiOutlineDotsHorizontal />
                                      </IconButton>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value={1}>
                      <div className="board-second-tab">
                        <div className="row justify-content-center">
                          <div className="col-lg-8 col-md-12">
                            <div className="d-flex justify-content-between align-items-center mb-5">
                              <div className="d-flex align-items-center form-group mb-0">
                                <h5 className="mb-0 me-4">Members</h5>
                                <label className="flex-shrink-0 mb-0 me-2">
                                  Sort by:
                                </label>
                                <select className="form-select me-auto">
                                  <option selected="" value="1">
                                    Date Created{" "}
                                  </option>
                                  <option value="2">A - Z</option>
                                  <option value="3">Z - A</option>
                                </select>
                              </div>
                              <button
                                className="btn btn-light btn-icon"
                                onClick={handleOpen}
                              >
                                <span
                                  className="icon"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title=""
                                  data-bs-original-title="Add New Member"
                                >
                                  <span className="feather-icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-plus"
                                    >
                                      <line
                                        x1="12"
                                        y1="5"
                                        x2="12"
                                        y2="19"
                                      ></line>
                                      <line
                                        x1="5"
                                        y1="12"
                                        x2="19"
                                        y2="12"
                                      ></line>
                                    </svg>
                                  </span>
                                </span>
                              </button>
                            </div>
                            <div className="row">
                              <div className="col-md-6 mb-4">
                                <div className="board-card">
                                  <div className="inner-content">
                                    <div className="image">
                                      <img src={avatar_sm} alt="" />
                                    </div>
                                    <div>
                                      <div className="d-flex align-items-center">
                                        <h5>Morgan Freeman</h5>
                                        <Tooltip
                                          title="Designer"
                                          color="neutral"
                                          placement="top"
                                          variant={variant}
                                        >
                                          <div color="neutral">
                                            <span className="badge badge-primary badge-indicator badge-indicator-nobdr"></span>
                                          </div>
                                        </Tooltip>
                                      </div>
                                      <p>morgan@jampack.com</p>
                                    </div>
                                  </div>
                                  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={
                                      open ? "long-menu" : undefined
                                    }
                                    aria-expanded={open ? "true" : undefined}
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                    className="icon-btn"
                                  >
                                    <HiOutlineDotsHorizontal />
                                  </IconButton>
                                </div>
                              </div>
                              <div className="col-md-6 mb-4">
                                <div className="board-card">
                                  <div className="inner-content">
                                    <div className="image">
                                      <img src={avatar_sm} alt="" />
                                    </div>
                                    <div>
                                      <div className="d-flex align-items-center">
                                        <h5>Morgan Freeman</h5>
                                        <Tooltip
                                          title="Designer"
                                          color="neutral"
                                          placement="top"
                                          variant={variant}
                                        >
                                          <div color="neutral">
                                            <span className="badge badge-primary badge-indicator badge-indicator-nobdr"></span>
                                          </div>
                                        </Tooltip>
                                      </div>
                                      <p>morgan@jampack.com</p>
                                    </div>
                                  </div>
                                  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={
                                      open ? "long-menu" : undefined
                                    }
                                    aria-expanded={open ? "true" : undefined}
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                    className="icon-btn"
                                  >
                                    <HiOutlineDotsHorizontal />
                                  </IconButton>
                                </div>
                              </div>
                              <div className="col-md-6 mb-4">
                                <div className="board-card">
                                  <div className="inner-content">
                                    <div className="image">
                                      <img src={avatar_sm} alt="" />
                                    </div>
                                    <div>
                                      <div className="d-flex align-items-center">
                                        <h5>Morgan Freeman</h5>
                                        <Tooltip
                                          title="Designer"
                                          color="neutral"
                                          placement="top"
                                          variant={variant}
                                        >
                                          <div color="neutral">
                                            <span className="badge badge-primary badge-indicator badge-indicator-nobdr"></span>
                                          </div>
                                        </Tooltip>
                                      </div>
                                      <p>morgan@jampack.com</p>
                                    </div>
                                  </div>
                                  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={
                                      open ? "long-menu" : undefined
                                    }
                                    aria-expanded={open ? "true" : undefined}
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                    className="icon-btn"
                                  >
                                    <HiOutlineDotsHorizontal />
                                  </IconButton>
                                </div>
                              </div>
                              <div className="col-md-6 mb-4">
                                <div className="board-card">
                                  <div className="inner-content">
                                    <div className="image">
                                      <img src={avatar_sm} alt="" />
                                    </div>
                                    <div>
                                      <div className="d-flex align-items-center">
                                        <h5>Morgan Freeman</h5>
                                        <Tooltip
                                          title="Designer"
                                          color="neutral"
                                          placement="top"
                                          variant={variant}
                                        >
                                          <div color="neutral">
                                            <span className="badge badge-primary badge-indicator badge-indicator-nobdr"></span>
                                          </div>
                                        </Tooltip>
                                      </div>
                                      <p>morgan@jampack.com</p>
                                    </div>
                                  </div>
                                  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={
                                      open ? "long-menu" : undefined
                                    }
                                    aria-expanded={open ? "true" : undefined}
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                    className="icon-btn"
                                  >
                                    <HiOutlineDotsHorizontal />
                                  </IconButton>
                                </div>
                              </div>
                              <div className="col-md-6 mb-4">
                                <div className="board-card">
                                  <div className="inner-content">
                                    <div className="image">
                                      <img src={avatar_sm} alt="" />
                                    </div>
                                    <div>
                                      <div className="d-flex align-items-center">
                                        <h5>Morgan Freeman</h5>
                                        <Tooltip
                                          title="Designer"
                                          color="neutral"
                                          placement="top"
                                          variant={variant}
                                        >
                                          <div color="neutral">
                                            <span className="badge badge-primary badge-indicator badge-indicator-nobdr"></span>
                                          </div>
                                        </Tooltip>
                                      </div>
                                      <p>morgan@jampack.com</p>
                                    </div>
                                  </div>
                                  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={
                                      open ? "long-menu" : undefined
                                    }
                                    aria-expanded={open ? "true" : undefined}
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                    className="icon-btn"
                                  >
                                    <HiOutlineDotsHorizontal />
                                  </IconButton>
                                </div>
                              </div>
                              <div className="col-md-6 mb-4">
                                <div className="board-card">
                                  <div className="inner-content">
                                    <div className="image">
                                      <img src={avatar_sm} alt="" />
                                    </div>
                                    <div>
                                      <div className="d-flex align-items-center">
                                        <h5>Morgan Freeman</h5>
                                        <Tooltip
                                          title="Designer"
                                          color="neutral"
                                          placement="top"
                                          variant={variant}
                                        >
                                          <div color="neutral">
                                            <span className="badge badge-primary badge-indicator badge-indicator-nobdr"></span>
                                          </div>
                                        </Tooltip>
                                      </div>
                                      <p>morgan@jampack.com</p>
                                    </div>
                                  </div>
                                  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={
                                      open ? "long-menu" : undefined
                                    }
                                    aria-expanded={open ? "true" : undefined}
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                    className="icon-btn"
                                  >
                                    <HiOutlineDotsHorizontal />
                                  </IconButton>
                                </div>
                              </div>
                              <div className="col-md-6 mb-4">
                                <div className="board-card">
                                  <div className="inner-content">
                                    <div className="image">
                                      <img src={avatar_sm} alt="" />
                                    </div>
                                    <div>
                                      <div className="d-flex align-items-center">
                                        <h5>Morgan Freeman</h5>
                                        <Tooltip
                                          title="Designer"
                                          color="neutral"
                                          placement="top"
                                          variant={variant}
                                        >
                                          <div color="neutral">
                                            <span className="badge badge-primary badge-indicator badge-indicator-nobdr"></span>
                                          </div>
                                        </Tooltip>
                                      </div>
                                      <p>morgan@jampack.com</p>
                                    </div>
                                  </div>
                                  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={
                                      open ? "long-menu" : undefined
                                    }
                                    aria-expanded={open ? "true" : undefined}
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                    className="icon-btn"
                                  >
                                    <HiOutlineDotsHorizontal />
                                  </IconButton>
                                </div>
                              </div>
                              <div className="col-md-6 mb-4">
                                <div className="board-card">
                                  <div className="inner-content">
                                    <div className="image">
                                      <img src={avatar_sm} alt="" />
                                    </div>
                                    <div>
                                      <div className="d-flex align-items-center">
                                        <h5>Morgan Freeman</h5>
                                        <Tooltip
                                          title="Designer"
                                          color="neutral"
                                          placement="top"
                                          variant={variant}
                                        >
                                          <div color="neutral">
                                            <span className="badge badge-primary badge-indicator badge-indicator-nobdr"></span>
                                          </div>
                                        </Tooltip>
                                      </div>
                                      <p>morgan@jampack.com</p>
                                    </div>
                                  </div>
                                  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={
                                      open ? "long-menu" : undefined
                                    }
                                    aria-expanded={open ? "true" : undefined}
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                    className="icon-btn"
                                  >
                                    <HiOutlineDotsHorizontal />
                                  </IconButton>
                                </div>
                              </div>
                              <div className="col-md-6 mb-4">
                                <div className="board-card">
                                  <div className="inner-content">
                                    <div className="image">
                                      <img src={avatar_sm} alt="" />
                                    </div>
                                    <div>
                                      <div className="d-flex align-items-center">
                                        <h5>Morgan Freeman</h5>
                                        <Tooltip
                                          title="Designer"
                                          color="neutral"
                                          placement="top"
                                          variant={variant}
                                        >
                                          <div color="neutral">
                                            <span className="badge badge-primary badge-indicator badge-indicator-nobdr"></span>
                                          </div>
                                        </Tooltip>
                                      </div>
                                      <p>morgan@jampack.com</p>
                                    </div>
                                  </div>
                                  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={
                                      open ? "long-menu" : undefined
                                    }
                                    aria-expanded={open ? "true" : undefined}
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                    className="icon-btn"
                                  >
                                    <HiOutlineDotsHorizontal />
                                  </IconButton>
                                </div>
                              </div>
                              <div className="col-md-6 mb-4">
                                <div className="board-card">
                                  <div className="inner-content">
                                    <div className="image">
                                      <img src={avatar_sm} alt="" />
                                    </div>
                                    <div>
                                      <div className="d-flex align-items-center">
                                        <h5>Morgan Freeman</h5>
                                        <Tooltip
                                          title="Designer"
                                          color="neutral"
                                          placement="top"
                                          variant={variant}
                                        >
                                          <div color="neutral">
                                            <span className="badge badge-primary badge-indicator badge-indicator-nobdr"></span>
                                          </div>
                                        </Tooltip>
                                      </div>
                                      <p>morgan@jampack.com</p>
                                    </div>
                                  </div>
                                  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={
                                      open ? "long-menu" : undefined
                                    }
                                    aria-expanded={open ? "true" : undefined}
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                    className="icon-btn"
                                  >
                                    <HiOutlineDotsHorizontal />
                                  </IconButton>
                                </div>
                              </div>
                              <div className="col-md-6 mb-4">
                                <div className="board-card">
                                  <div className="inner-content">
                                    <div className="image">
                                      <img src={avatar_sm} alt="" />
                                    </div>
                                    <div>
                                      <div className="d-flex align-items-center">
                                        <h5>Morgan Freeman</h5>
                                        <Tooltip
                                          title="Designer"
                                          color="neutral"
                                          placement="top"
                                          variant={variant}
                                        >
                                          <div color="neutral">
                                            <span className="badge badge-primary badge-indicator badge-indicator-nobdr"></span>
                                          </div>
                                        </Tooltip>
                                      </div>
                                      <p>morgan@jampack.com</p>
                                    </div>
                                  </div>
                                  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={
                                      open ? "long-menu" : undefined
                                    }
                                    aria-expanded={open ? "true" : undefined}
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                    className="icon-btn"
                                  >
                                    <HiOutlineDotsHorizontal />
                                  </IconButton>
                                </div>
                              </div>
                              <div className="col-md-6 mb-4">
                                <div className="board-card">
                                  <div className="inner-content">
                                    <div className="image">
                                      <img src={avatar_sm} alt="" />
                                    </div>
                                    <div>
                                      <div className="d-flex align-items-center">
                                        <h5>Morgan Freeman</h5>
                                        <Tooltip
                                          title="Designer"
                                          color="neutral"
                                          placement="top"
                                          variant={variant}
                                        >
                                          <div color="neutral">
                                            <span className="badge badge-primary badge-indicator badge-indicator-nobdr"></span>
                                          </div>
                                        </Tooltip>
                                      </div>
                                      <p>morgan@jampack.com</p>
                                    </div>
                                  </div>
                                  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={
                                      open ? "long-menu" : undefined
                                    }
                                    aria-expanded={open ? "true" : undefined}
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                    className="icon-btn"
                                  >
                                    <HiOutlineDotsHorizontal />
                                  </IconButton>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem>
          <Link to={``} className="dropdown-link">
            {" "}
            Edit
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to={``} className="dropdown-link">
            {" "}
            Copy Link
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to={``} className="dropdown-link">
            Delete{" "}
          </Link>
        </MenuItem>
      </Menu>

      {/* Add deal popup */}
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="deal-popup"
      >
        <Box sx={style}>
          <div className="modal-head">
            <h5>Add New Member</h5>
            <Link onClick={handleClose1}>
              <RxCross2 />
            </Link>
          </div>
          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">Name</label>
                    <input className="form-control task-name" type="text" />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">Email Id</label>
                    <input className="form-control task-name" type="text" />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="upload-logo">
                    <div className="dropify-wrapper">
                      <span className="file-icon">
                        <BsUpload />
                      </span>
                      <p>Upload Logo</p>
                      <input type="file" className="dropify-2" />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <Button
              type="submit"
              className="secondary-btn me-2"
              onClick={handleClose1}
            >
              Cancel
            </Button>
            <Button type="submit" className="primary-btn">
              Add
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ProjectsBoard;
