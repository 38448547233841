import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import Navigation from "../Shared/Navigation";

function PrivateRoute() {
  // function PrivateRoute({ children }) {
  const loggedIn = useSelector(
    (state) => state.login?.LoginDetails?.authenticate
  );
  // let nav = useNavigate();
  // const onBackButtonEvent = (e) => {
  //   e.preventDefault();
  //   nav("/home");
  // };

  // useEffect(() => {
  //   window.history.pushState(null, null, window.location.pathname);
  //   window.addEventListener("popstate", onBackButtonEvent);
  //   return () => {
  //     window.removeEventListener("popstate", onBackButtonEvent);
  //   };
  //   // eslint-disable-next-line
  // }, []);
  return loggedIn ? (
    <>
      <Navigation />
      <Outlet />
      {/* {children} */}
    </>
  ) : (
    <Navigate to="/" />
  );
}

export default PrivateRoute;
