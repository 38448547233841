import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "reactstrap";
import "./index.scss";
import { CircularProgress } from "@mui/material";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
import Select from "react-select";
import { customFilter } from "../../../Components/CustomFilterForReactSelect";

function MenuCreate() {
  const { id } = useParams();
  const [derror, setDerror] = useState();
  const [menuList, setMenuList] = useState([]);
  const [menuType, setMenuType] = useState();
  const [parentMenuId, setParentMenuId] = useState();
  const [openCircle, setOpenCircle] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const logDetail = useSelector((state) => state.login.LoginDetails);
  let nav = useNavigate();

  useEffect(() => {
    if (menuList?.length > 0) {
      if (id) {
        getMenuData(id);
      }
    }
    // eslint-disable-next-line
  }, [id, menuList]);
  useEffect(() => {
    GetMenuList();
    // eslint-disable-next-line
  }, []);
  const getMenuData = async () => {
    let seData = {
      url: `/api/Menu/GetMenuDataByMenuID?MenuId=${id}`,
    };

    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        let useDe = res.data.payload;
        setValue("menuId", useDe?.menuId);
        setValue("menuName", useDe?.menuName);
        setValue("url", useDe?.url);
        setValue("menuType", useDe?.menuType);
        setMenuType(useDe?.menuType);
        setValue("parentMenuId", useDe?.parentMenuId);
        setParentMenuId(useDe?.parentMenuId);
        setValue("menuDescription", useDe?.menuDescription);
        setValue("menuIcon", useDe?.menuIcon);
        setValue("sequenceNumber", useDe?.sequenceNumber);
      }
    }
  };

  const onSubmit = async (data) => {
    let Menu = { ...data, CreatedBy: logDetail?.userId };
    let seData = {
      url: "/api/Menu/CreateMenu",
      body: Menu,
    };
    setOpenCircle(true);
    let res = await PostCallApi(seData);
    setOpenCircle(false);

    if (res.status === 200) {
      if (res.data.success) {
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        reset();
        nav("/menus", { replace: true });
        setDerror({ err: "" });
      } else {
        setDerror({ err: res.data?.message });
        toast.error(res.data.message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } else {
      toast.error(res.data.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const GetMenuList = async () => {
    let res = await GetCallApi({ url: `/api/Menu/GetAllMenuData` });

    if (res.status === 200) {
      if (res.data.success) {
        let comList = res?.data?.payload
          .filter((x) => x?.menuType === 0)
          ?.map((c) => {
            return {
              menuId: c?.menuId,
              name: c?.menuName,
              value: c?.menuId,
              label: c?.menuName,
            };
          });
        setMenuList(comList);
      } else {
        setMenuList([]);
      }
    }
  };
  const handleSubmenu = (e) => {
    if (e === 0) {
      setValue("parentMenuId", "");
    }
  };

  const menuOption = [
    {
      value: 0,
      label: "Main Menu",
    },
    {
      value: 1,
      label: "Sub Menu",
    },
  ];

  const handleMenuType = (e) => {
    setMenuType(e);
    setValue("menuType", e);
    if (e === 0) {
      setValue("parentMenuId", "");
    }
  };

  const handleParentMenu = (e) => {
    setParentMenuId(e);
    setValue("parentMenuId", e);
  };
  return (
    <section className="create_menu">
      <div className="main_wrapper">
        <div className="title mb-3">{id ? "Edit" : "Create"} Menu </div>
        <div className="card">
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)} className="form_box">
              <div className="row">
                {/* <div className="col-md-12">
                  <div className="title mb-3">
                    {id ? "Edit" : "Create"} Menu{" "}
                  </div>
                </div> */}
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">
                      Menu Name
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter menu name"
                      {...register("menuName", {
                        required: "This field is required",
                      })}
                    />
                    {errors.menuName && (
                      <span role="alert" className="error_text">
                        {errors.menuName.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">
                      Sequence Number
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter sequence number"
                      {...register("sequenceNumber", {
                        required: "This field is required",
                        valueAsNumber: true,
                        pattern: {
                          value: /^[0-9+-]+$/,
                          message: "Enter valid number",
                        },
                      })}
                    />
                    {errors.sequenceNumber && (
                      <span role="alert" className="error_text">
                        {errors.sequenceNumber.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">
                      Menu Type
                      <span className="red-color"> *</span>
                    </label>
                    <Select
                      isMulti={false}
                      name="colors"
                      options={menuOption}
                      isClearable={true}
                      className="dropdown-single-select"
                      classNamePrefix="select"
                      placeholder="Select Menu Type"
                      filterOption={customFilter}
                      value={menuOption?.filter((obj) => obj.value === menuType)}
                      onChange={(e) => handleMenuType(e?.value)}
                    />
                    <select
                      type="text"
                      className="form-select d-none"
                      {...register(`menuType`, {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                        valueAsNumber: true,
                        onChange: (e) => handleSubmenu(e.target.value),
                      })}
                    >
                      <option value="">Select Menu Type</option>
                      <option value="0">Main Menu</option>
                      <option value="1">Sub Menu</option>
                    </select>
                    {errors.menuType && (
                      <span role="alert" className="error_text">
                        {errors.menuType.message}
                      </span>
                    )}
                  </div>
                </div>{" "}
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">Parent Menu </label>

                    <Select
                      isMulti={false}
                      name="colors"
                      isDisabled={watch("menuType") === 0 ? true : false}
                      options={menuList}
                      isClearable={true}
                      className="dropdown-single-select"
                      classNamePrefix="select"
                      placeholder="Select Parent Menu"
                      filterOption={customFilter}
                      value={menuList?.filter(
                        (obj) => obj.value === parentMenuId
                      )}
                      onChange={(e) => handleParentMenu(e?.value)}
                    />
                    <select
                      type="text"
                      className="form-select d-none"
                      {...register(`parentMenuId`, {
                        valueAsNumber: true,
                      })}
                      disabled={watch("menuType") === 0 ? true : false}
                    >
                      <option value="">Select Parent Menu</option>

                      {menuList &&
                        menuList?.map((m, i) => (
                          <option value={m?.menuId} key={i}>
                            {m?.name}
                          </option>
                        ))}
                    </select>
                    {errors.parentMenuId && (
                      <span role="alert" className="error_text">
                        {errors.parentMenuId.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">
                      Menu url
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter menu url"
                      {...register("url", {
                        required: "This field is required",
                      })}
                    />
                    {errors.url && (
                      <span role="alert" className="error_text">
                        {errors.url.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">Menu Icon</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Icon Name Example: car"
                      {...register("menuIcon", {
                        // required: "This field is required",
                      })}
                    />
                    <span className="refrence">
                      For Reference :
                      <Link
                        to="https://fontawesome.com/v5/search?o=r&m=free&s=solid"
                        target="_blank"
                      >
                        {" "}
                        https://fontawesome.com/v5/search
                      </Link>
                    </span>
                    {errors.menuIcon && (
                      <span role="alert" className="error_text">
                        {errors.menuIcon.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">
                      Description
                      <span className="red-color"> *</span>
                    </label>
                    <textarea
                      rows="5"
                      type="text"
                      className="form-control"
                      placeholder="Enter description"
                      {...register("menuDescription", {
                        required: "This field is required",
                      })}
                    />
                    {errors.menuDescription && (
                      <span role="alert" className="error_text">
                        {errors.menuDescription.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-12 d-flex justify-content-end mt-3">
                  <div className="form-group">
                    <Button
                      className="back_button"
                      onClick={() => nav("/menus", { replace: true })}
                    >
                      Back
                    </Button>
                    <Button className="detail_button ms-3" type="submit">
                      {openCircle ? (
                        <CircularProgress
                          color="inherit"
                          sx={{
                            color: "#fff",
                            marginLeft: "0.5rem",
                            height: "23px !important",
                            width: "23px !important",
                          }}
                        />
                      ) : id ? (
                        "Update"
                      ) : (
                        "Save"
                      )}
                    </Button>

                    {derror?.err && (
                      <span role="alert" className="d-block error_text">
                        {derror.err}
                      </span>
                    )}
                  </div>
                </div>
                <ToastContainer
                  position="top-right"
                  autoClose={2000}
                  hideProgressBar
                  newestOnTop
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="dark"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MenuCreate;
