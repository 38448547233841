import React, { Suspense, lazy, useEffect, useState } from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Typography,
  Button,
} from "@mui/material";
import { VscEdit, VscEye } from "react-icons/vsc";
import { GetCallApi, PostCallApi } from "../../../Action/Action.jsx";
import { ToastError } from "../../Components/Toast";
import { useForm } from "react-hook-form";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import Select from "react-select";
import { customFilter } from "../../Components/CustomFilterForReactSelect";

const DeletePopup = lazy(() => import("../../Components/Reusable/DeletePopup"));
const NewLIstTable = lazy(() =>
  import("../../Components/Reusable/NewLIstTable")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const headCellsEvent = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "firstName",
    label: "First Name",
  },
  {
    id: "lastName",
    label: "Last Name",
  },
  {
    id: "role",
    label: "Role Name",
    widthFix: 600,
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "phone",
    label: "Phone",
  },
  //   {
  //     id: "isActive",
  //     label: "Status",
  //   },
];
function UserRegisterationList() {
  const [dataOfTable, setDataOfTable] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [roleId, setRoleId] = useState();
  const [filterPage, setFilterPage] = useState(false);

  const LogDetails = useSelector((state) => state.login.LoginDetails);
  const { register, getValues, setValue, reset, handleSubmit } = useForm();

  useEffect(() => {
    document.title = "FIG Events | Users";
    GetRoleList();
    // eslint-disable-next-line
  }, []);

  const GetRoleList = async () => {
    let res = await GetCallApi({
      url: `/api/Role/RoleList?userId=${LogDetails?.userId}`,
    });
    if (res.status === 200 && res.data.success) {
      let comList = res?.data?.payload?.map((c) => {
        return {
          ...c,
          value: c?.roleId,
          label: c?.roleName,
        };
      });
      setRoleList(comList);
    }
  };

  const GetUserList = async (data) => {
    setDataOfTable([]);
    setOpenCircle(true);
    let res = await PostCallApi({
      url: "/api/UserMst/UserList",
      body: { ...data, userId: LogDetails?.userId },
    });
    setOpenCircle(false);
    setFilterPage(!filterPage);
    if (res.status === 200 && res.data.success) {
      setOpenCircle(false);
      let comList = res?.data?.payload?.map((c, i) => {
        return {
          ...c,
          id: i + 1,

          dateOfBirth: c?.dateOfBirth
            ? convert(c?.dateOfBirth?.split("T")[0])
            : "",
          isActive: c?.isActive ? "True" : "False",

          role: c?.rollname,
          Action: (
            <>
              <Link
                className="btn_edit me-3 d-flex align-items-center"
                to={`/user-edit/${c?.userId}`}
              >
                <VscEdit className="icon" />
              </Link>
              {LogDetails?.roleId === 1 ? (
                <DeletePopup
                  title={"Delete User"}
                  text={c?.firstName + " " + c?.lastName}
                  url={`/api/UserMst/DeleteUser?userId=${c?.userId}`}
                  callBack={true}
                  callBackFunction={getApiData}
                />
              ) : (
                ""
              )}
              <Link
                className="btn_edit ms-3 d-flex align-items-center"
                to={`/attendee-profile/view/${c?.userId}`}
              >
                <VscEye />
              </Link>
            </>
          ),
        };
      });
      setDataOfTable(comList);
    } else {
      ToastError(res?.data?.message);
    }
  };
  function convert(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  const getApiData = () => {
    const sddata = {
      userName: getValues("userName") ?? "",
      email: getValues("email") ?? "",
      roleId: getValues("roleId") ? parseInt(getValues("roleId")) : 0,
    };

    GetUserList(sddata);
  };

  const handleRole = (e) => {
    setValue("roleId", e);
    setRoleId(e);
  };

  const handleClearFilter = () => {
    setRoleId();
    setDataOfTable([]);
    reset();
  };

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography style={{ marginLeft: "20px" }}>
                    <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                    <span className="filter_by">Filter By</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <form onSubmit={handleSubmit(GetUserList)}>
                    <div className="row ">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label"> User Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search by User Name"
                            {...register("userName")}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label"> Email </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search by Email"
                            {...register("email")}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label"> Role</label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={roleList}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Role"
                            filterOption={customFilter}
                            value={roleList?.filter(
                              (obj) => obj.value === roleId
                            )}
                            onChange={(e) => handleRole(e?.value)}
                          />
                        </div>
                      </div>

                      <div className="col-md-12 d-flex justify-content-start mt-auto">
                        <div className="form-group">
                          <Button
                            className="detail_button ms-3"
                            type="submit"
                            disabled={openCircle}
                          >
                            {openCircle ? (
                              <CircularProgress
                                color="inherit"
                                sx={{
                                  color: "#fff",
                                  marginLeft: "0.5rem",
                                  height: "23px !important",
                                  width: "23px !important",
                                }}
                              />
                            ) : (
                              "Apply Filter"
                            )}
                          </Button>
                          <Button
                            className={`back_button ms-3 ${
                              window.innerWidth > 400 ? "" : "mt-3"
                            }`}
                            onClick={() => handleClearFilter()}
                          >
                            Clear Filter
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                </AccordionDetails>
              </Accordion>
              <div className="col-md-12 accordian_card p-0 mb-4">
                <NewLIstTable
                  addressLink={`/user-create`}
                  headCells={headCellsEvent}
                  title="User List"
                  action={true}
                  ListData={dataOfTable}
                  addBtn={true}
                  filterPage={filterPage}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default UserRegisterationList;
