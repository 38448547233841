import React, { useEffect, useRef, useState } from "react";
import logo from "../../../../assets/images/fig_events.png";
import "./index.scss";
import jsPDF from "jspdf";
import {
  Backdrop,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { GetCallApi } from "../../../../Action/Action";
import { useSelector } from "react-redux";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F6FC",
  },
  padding: "5px",
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f6f6f6",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F1F6FC",
  },
  // hide last border
  //   "&:last-child td, &:last-child th": {
  //     border: 0,
  //   },
}));
function StripeRecipe() {
  const [ATRC, setATRC] = useState();
  const [open, setOpen] = useState(false);
  const [downloadPdf, setDownloadpdf] = useState(false);
  const [comp, setComp] = useState([]);
  const { name, id } = useParams();
  const tableRef = useRef(null);
  let nav = useNavigate();

  let Time = new Date();

  const loinDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  function convertDate(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  useEffect(() => {
    if (name === "Exhibitor") {
      getCompany();
    }
    // eslint-disable-next-line
  }, [name]);
  useEffect(() => {
    if (id) {
      getStripeData();
    }
    // eslint-disable-next-line
  }, [id, name]);

  const getStripeData = async () => {
    if (id && name) {
      setOpen(true);
      let seData = {
        url:
          name === "Group"
            ? `/api/MemberManagement/GetMemberManagementStripInvoice?memberManagementId=${id}`
            : `/api/Payment/GetExhibitorAttendeeStripInvoice?paymentId=${id}&usertype=${name}`,
        headers: headers,
      };

      let res = await GetCallApi(seData);
      setOpen(false);
      if (res?.status === 200) {
        if (res?.data?.success) {
          const cData = res?.data?.payload;
          setATRC(cData);
        }
      }
    }
  };
  const getCompany = async () => {
    let seData = {
      url: `/api/CompanyRegistration/GetExhibitorPrimaryCompanyDetails?userId=${loinDetail?.userId}`,
      headers: headers,
    };

    let resp = await GetCallApi(seData);
    const cData = resp?.data?.payload;
    setComp(cData);
  };

  const handleDownloadPdf = async () => {
    // return false;
    setOpen(true);

    const element = tableRef.current;
    let newPdfDate =
      convertDate(
        Time.toLocaleString("en-us", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")
      ) +
      " " +
      Time.toLocaleTimeString();
    // return false;
    const doc = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
    doc
      .html(element, {
        margin: [20, 10, 20, 10],
        autoPaging: "text",
        html2canvas: { scale: 0.57 },
      })
      .then(async () => {
        let totalPages = doc.internal.pages.length - 1;
        for (let i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          doc.setFontSize(10);
          doc.setTextColor(150);
          doc.text(
            newPdfDate,
            doc.internal.pageSize.width / 2,
            doc.internal.pageSize.getHeight() - 10,
            {
              align: "center",
            }
          );
        }
        doc.save(`invoice.pdf`);
        setOpen(false);
        setDownloadpdf(false);
        nav(-1);
      });
  };

  useEffect(() => {
    if (downloadPdf) {
      handleDownloadPdf();
    }
    // eslint-disable-next-line
  }, [downloadPdf]);

  const back = {
    maxWidth: `${downloadPdf ? "200mm" : "200mm"}`,
    // maxWidth: `${downloadPdf ? "200mm" : "100%"}`,
    width: "100%",
    height: "100%",
    position: "relative",
    margin: "0",
  };

  let newtime = new Date();
  newtime?.setDate(newtime?.getDate() + 30);
  newtime = newtime.toLocaleString("en-us", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  return (
    <>
      <section className="admin_dashboard py-4">
        <div className="main_wrapper">
          <div className="card">
            <div className="card-body">
              <Button
                className="detail_button mb-3"
                onClick={() => setDownloadpdf(true)}
              >
                Download
              </Button>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <section className="stripe-recipe" ref={tableRef} style={back}>
                <div className="invoice-section">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="logo">
                        <img
                          src={logo}
                          style={{
                            height: "60px",
                          }}
                          alt="Confrence Planner"
                          title="Confrence Planner"
                        />
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="d-flex align-items-center gap-3 justify-content-end">
                        <h2>Online/Stripe</h2>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-6  mb-4">
                      <h5 className="text-uppercase">{ATRC?.conferenceName}</h5>
                      <div className="Billto-wrap">
                        <p style={{ whiteSpace: "pre-line" }}>
                          {ATRC?.paymentAddress}
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-6 text-end">
                      <div className="invoice-details">
                        <ul>
                          <li>
                            <span className="primarylight-color">
                              Payment Reference
                            </span>
                            <span
                              className="invoice-text"
                              style={{
                                maxWidth: "150px",
                                wordBreak: "break-all",
                              }}
                            >
                              {ATRC?.paymentReference}
                            </span>
                          </li>

                          <li>
                            <span className="primarylight-color">
                              Payment Date
                            </span>
                            <span className="invoice-text">
                              {ATRC?.paidDate
                                ? new Date(ATRC?.paidDate).toLocaleString(
                                    "en-us",
                                    {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                    }
                                  )
                                : ""}
                            </span>
                          </li>
                          <li>
                            <span className="primarylight-color">Status</span>
                            <span className="invoice-text">Paid</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6  mb-4">
                      <h5 className="text-uppercase">Billed To</h5>
                      <div className="Billto-wrap">
                        {name === "Exhibitor" ? (
                          <>
                            <h5 className="text-uppercase">
                              {comp?.companyName}
                            </h5>
                            <p>{comp?.phoneNumber}</p>
                          </>
                        ) : (
                          ""
                        )}
                        <p>{ATRC?.userName ? ATRC?.userName : ATRC?.name}</p>
                        <p style={{ whiteSpace: "pre-line" }}>
                          {ATRC?.userAddress
                            ? ATRC?.userAddress
                            : ATRC?.address}
                        </p>
                        <p>{ATRC?.email}</p>
                      </div>
                    </div>
                  </div>
                  <div className="table-wrap mt-5">
                    {name === "Exhibitor" || name === "User" ? (
                      <Table className="table-bordered Conference-table">
                        <TableHead className="thead-primary">
                          <StyledTableRow>
                            <StyledTableCell
                              className="txt_st"
                              style={{
                                borderTop: "1px solid #808080",
                              }}
                            >
                              {name === "User" ? "Item" : "Booth Number/Item"}
                            </StyledTableCell>
                            <StyledTableCell
                              className="txt_st"
                              style={{
                                borderTop: "1px solid #808080",
                              }}
                            >
                              Line Total
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableHead>
                        <TableBody>
                          {name === "User" ? (
                            <>
                              <StyledTableRow>
                                <StyledTableCell>
                                  {ATRC?.conferenceName}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {parseFloat(ATRC?.confees)?.toLocaleString(
                                    "en-US",
                                    {
                                      style: "currency",
                                      currency: "USD",
                                    }
                                  )}
                                </StyledTableCell>
                              </StyledTableRow>
                              {ATRC?.evtListDetails?.length > 0
                                ? ATRC?.evtListDetails?.map((ev, i) => (
                                    <StyledTableRow key={i}>
                                      <StyledTableCell>
                                        {ev?.eventName}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {parseFloat(
                                          ev?.attendeeFees
                                        )?.toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "USD",
                                        })}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                : ""}
                            </>
                          ) : (
                            <>
                              {ATRC?.boothListDetails?.length > 0
                                ? ATRC?.boothListDetails?.map((ev, i) => (
                                    <StyledTableRow key={i}>
                                      <StyledTableCell>
                                        {ev?.seatEventNumber}
                                      </StyledTableCell>

                                      <StyledTableCell>
                                        {parseFloat(ev?.price)?.toLocaleString(
                                          "en-US",
                                          {
                                            style: "currency",
                                            currency: "USD",
                                          }
                                        )}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                : ""}
                              {ATRC?.teamMembersEventList?.map((x, i) => (
                                <StyledTableRow key={i}>
                                  <StyledTableCell>
                                    {x?.eventName} - ({x?.eventQuntitiy})
                                  </StyledTableCell>

                                  <StyledTableCell>
                                    {parseFloat(x?.totalPrice)?.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "USD",
                                      }
                                    )}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}{" "}
                              {ATRC?.exhiProductsList?.map((x, i) => (
                                <React.Fragment key={i}>
                                  <StyledTableRow key={i}>
                                    <StyledTableCell>
                                      {x?.productName} - ({x?.productQuntitiy})
                                    </StyledTableCell>

                                    <StyledTableCell>
                                      {parseFloat(
                                        x?.productTotalPrice
                                      )?.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      })}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  {x?.productPromoCodeDetail?.map(
                                    (dis, val) => (
                                      <StyledTableRow key={val}>
                                        <StyledTableCell
                                          style={{
                                            backgroundColor: "white",
                                            textAlign: "right",
                                            borderBottom: "1px solid #808080",
                                          }}
                                        >
                                          {dis?.productPromoCode}
                                        </StyledTableCell>

                                        <StyledTableCell
                                          style={{
                                            backgroundColor: "white",
                                            borderBottom: "1px solid #808080",
                                          }}
                                        >
                                          -
                                          {dis?.disountAmount?.toLocaleString(
                                            "en-US",
                                            {
                                              style: "currency",
                                              currency: "USD",
                                            }
                                          )}
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    )
                                  )}
                                  {x?.productTotalDiscount ? (
                                    <StyledTableRow>
                                      <StyledTableCell
                                        style={{
                                          backgroundColor: "white",
                                          textAlign: "right",
                                          borderBottom: "1px solid #808080",
                                        }}
                                        // colSpan={2}
                                      >
                                        Product Total Discount
                                      </StyledTableCell>
                                      <StyledTableCell
                                        style={{
                                          backgroundColor: "white",
                                          borderBottom: "1px solid #808080",
                                        }}
                                      >
                                        -
                                        {x?.productTotalDiscount?.toLocaleString(
                                          "en-US",
                                          {
                                            style: "currency",
                                            currency: "USD",
                                          }
                                        )}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ) : (
                                    ""
                                  )}
                                </React.Fragment>
                              ))}{" "}
                              {ATRC?.exhiProductSubscriptionList?.map(
                                (x, i) => (
                                  <StyledTableRow key={i}>
                                    <StyledTableCell>
                                      {x?.companyName} - ({x?.subscriptionName})
                                    </StyledTableCell>

                                    <StyledTableCell>
                                      {parseFloat(
                                        x?.subscriptionPrice
                                          ? x?.subscriptionPrice
                                          : 0
                                      )?.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      })}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                )
                              )}
                            </>
                          )}
                          {ATRC?.applyPromoCodeDetail?.length > 0
                            ? ATRC?.applyPromoCodeDetail?.map((x, i) => (
                                <StyledTableRow key={i}>
                                  <StyledTableCell
                                    style={{
                                      backgroundColor: "white",
                                      textAlign: "right",
                                      borderBottom: "1px solid #808080",
                                    }}
                                  >
                                    {x?.promoCode}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      backgroundColor: "white",
                                      borderBottom: "1px solid #808080",
                                    }}
                                  >
                                    -
                                    {x?.discountAmount?.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "USD",
                                      }
                                    )}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))
                            : ""}
                          {ATRC?.discountValue ? (
                            <StyledTableRow>
                              <StyledTableCell
                                style={{
                                  backgroundColor: "white",
                                  textAlign: "right",
                                  borderBottom: "1px solid #808080",
                                }}
                              >
                                Total Discount
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  backgroundColor: "white",
                                  borderBottom: "1px solid #808080",
                                }}
                              >
                                -
                                {ATRC?.discountValue?.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })}
                              </StyledTableCell>
                            </StyledTableRow>
                          ) : (
                            ""
                          )}
                          <StyledTableRow>
                            <StyledTableCell
                              style={{
                                backgroundColor: "white",
                                textAlign: "right",
                                borderBottom: "1px solid #808080",
                              }}
                              // colSpan={2}
                            >
                              <b>Total</b>
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                backgroundColor: "white",
                                borderBottom: "1px solid #808080",
                              }}
                            >
                              <b>
                                {parseFloat(ATRC?.totalAmount)?.toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "USD",
                                  }
                                )}
                              </b>
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      </Table>
                    ) : (
                      <Table className="table-bordered Conference-table">
                        <TableHead className="thead-primary">
                          <StyledTableRow>
                            <StyledTableCell
                              className="txt_st"
                              style={{
                                borderTop: "1px solid #808080",
                              }}
                            >
                              Item
                            </StyledTableCell>
                            <StyledTableCell
                              className="txt_st"
                              style={{
                                borderTop: "1px solid #808080",
                              }}
                            >
                              Qty
                            </StyledTableCell>
                            <StyledTableCell
                              className="txt_st"
                              style={{
                                borderTop: "1px solid #808080",
                              }}
                            >
                              Line Total
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableHead>
                        <TableBody>
                          <StyledTableRow>
                            <StyledTableCell>
                              {ATRC?.conferenceName}
                            </StyledTableCell>
                            <StyledTableCell>
                              {ATRC?.conferenceQuantity}
                            </StyledTableCell>
                            <StyledTableCell>
                              {parseFloat(
                                ATRC?.conferenceMembersPrice
                              )?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}
                            </StyledTableCell>
                          </StyledTableRow>
                          {ATRC?.mmevtListDetails?.length > 0
                            ? ATRC?.mmevtListDetails?.map((ev, i) => (
                                <StyledTableRow key={i}>
                                  <StyledTableCell>
                                    {ev?.eventName}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {ev?.quantity}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {parseFloat(ev?.price)?.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "USD",
                                      }
                                    )}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))
                            : ""}
                          {ATRC?.applayPromoCodeList?.length > 0
                            ? ATRC?.applayPromoCodeList?.map((ev, i) => (
                                <StyledTableRow key={i}>
                                  <StyledTableCell
                                    style={{
                                      backgroundColor: "white",
                                      textAlign: "right",
                                      borderBottom: "1px solid #808080",
                                    }}
                                    colSpan={2}
                                  >
                                    {ev?.promoCode}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      backgroundColor: "white",
                                      borderBottom: "1px solid #808080",
                                    }}
                                  >
                                    -
                                    {ev?.discountAmount?.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "USD",
                                      }
                                    )}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))
                            : ""}

                          {ATRC?.discountValue ? (
                            <StyledTableRow>
                              <StyledTableCell
                                style={{
                                  backgroundColor: "white",
                                  textAlign: "right",
                                  borderBottom: "1px solid #808080",
                                }}
                                colSpan={2}
                              >
                                Total Discount
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  backgroundColor: "white",
                                  borderBottom: "1px solid #808080",
                                }}
                              >
                                -
                                {ATRC?.discountValue?.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })}
                              </StyledTableCell>
                            </StyledTableRow>
                          ) : (
                            ""
                          )}
                          <StyledTableRow>
                            <StyledTableCell
                              style={{
                                backgroundColor: "white",
                                textAlign: "right",
                                borderBottom: "1px solid #808080",
                              }}
                              colSpan={2}
                            >
                              <b>Total</b>
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                backgroundColor: "white",
                                borderBottom: "1px solid #808080",
                              }}
                            >
                              <b>
                                {parseFloat(ATRC?.totalAmount)?.toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "USD",
                                  }
                                )}
                              </b>
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      </Table>
                    )}
                  </div>
                  <div className="table-wrap mt-3">
                    <h5>User Details</h5>
                    <Table className="table-bordered Conference-table">
                      <TableHead className="thead-primary">
                        <StyledTableRow>
                          {name === "Exhibitor" || name === "User" ? (
                            ""
                          ) : (
                            <StyledTableCell
                              className="txt_st"
                              style={{
                                borderTop: "1px solid #808080",
                              }}
                            >
                              #
                            </StyledTableCell>
                          )}
                          <StyledTableCell
                            className="txt_st"
                            style={{
                              borderTop: "1px solid #808080",
                            }}
                          >
                            User Name
                          </StyledTableCell>
                          <StyledTableCell
                            className="txt_st"
                            style={{
                              borderTop: "1px solid #808080",
                            }}
                          >
                            Email
                          </StyledTableCell>
                          <StyledTableCell
                            className="txt_st"
                            style={{
                              borderTop: "1px solid #808080",
                            }}
                          >
                            Phone
                          </StyledTableCell>
                          <StyledTableCell
                            className="txt_st"
                            style={{
                              borderTop: "1px solid #808080",
                            }}
                          >
                            Price
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>
                        {name === "Exhibitor" || name === "User" ? (
                          <StyledTableRow>
                            <StyledTableCell>
                              {ATRC?.userName ? ATRC?.userName : ATRC?.name}
                            </StyledTableCell>
                            <StyledTableCell>{ATRC?.email}</StyledTableCell>
                            <StyledTableCell>{ATRC?.userPhone}</StyledTableCell>
                            <StyledTableCell>
                              {parseFloat(ATRC?.totalAmount)?.toLocaleString(
                                "en-US",
                                {
                                  style: "currency",
                                  currency: "USD",
                                }
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ) : ATRC?.mmGroupUserList?.length > 0 ? (
                          ATRC?.mmGroupUserList?.map((ev, i) => (
                            <StyledTableRow key={i}>
                              <StyledTableCell>{i + 1}</StyledTableCell>
                              <StyledTableCell>{ev?.userName}</StyledTableCell>
                              <StyledTableCell>{ev?.userEmail}</StyledTableCell>
                              <StyledTableCell>{ev?.userPhone}</StyledTableCell>
                              <StyledTableCell>
                                {parseFloat(
                                  ev?.userPaymentPrice
                                )?.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                        ) : (
                          ""
                        )}
                      </TableBody>
                    </Table>
                  </div>

                  <div className="row mt-5">
                    <div className="col-lg-12 text-lg-end mt-3">
                      <h5 className="mt-lg-7">Becki Swan</h5>
                      <p>Co-founder, Fig Events</p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default StripeRecipe;
