import React, { Suspense } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import CRMHeader from "../Shared/CRMHeader";
import { MdContacts } from "react-icons/md";
import { FaRegStar } from "react-icons/fa";
import { BsWindow } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";
import { BsTrash3 } from "react-icons/bs";
import { AiOutlineDelete } from "react-icons/ai";
import { TbArchive } from "react-icons/tb";
import Pagination from "@mui/material/Pagination";
import { TfiImport } from "react-icons/tfi";
import { TfiExport } from "react-icons/tfi";
import { FaStar } from "react-icons/fa";
import { TbSortAscending } from "react-icons/tb";
import { RxTextAlignBottom } from "react-icons/rx";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import { HiOutlineDuplicate } from "react-icons/hi";
import { MdKeyboardArrowLeft } from "react-icons/md";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { RxCross2 } from "react-icons/rx";
import { BsUpload } from "react-icons/bs";
import { Button, CircularProgress } from "@mui/material";
import { IoIosList } from "react-icons/io";
import { LuRefreshCw } from "react-icons/lu";
import { RiSettings2Line } from "react-icons/ri";
import { FiGrid } from "react-icons/fi";
import { GoPlus } from "react-icons/go";
import { CiSettings } from "react-icons/ci";
import { LuArchive } from "react-icons/lu";
import { LuBook } from "react-icons/lu";
import { FaEdit } from "react-icons/fa";

const danial = "/assets/images/danial.jpg";
const dean = "/assets/images/dean.jpg";
const huma = "/assets/images/huma.jpg";
const katharine = "/assets/images/katharine.jpg";
const morgan = "/assets/images/morgan.jpg";
const tom = "/assets/images/tom.jpg";
const winston = "/assets/images/winston.jpg";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const avatar_img = "/assets/images/avatar.jpg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  maxHeight: "80%",
};

function ContactList() {
  const [openSide, setOpenSide] = React.useState(true);
  const [open1, setOpen1] = React.useState(false);
  const [openlabel, setOpenlabel] = React.useState(false);
  const [opentag, setOpentag] = React.useState(false);
  const handleOpen = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const handleOpenlabel = () => setOpenlabel(true);
  const handleCloselabel = () => setOpenlabel(false);
  const handleOpentag = () => setOpentag(true);
  const handleClosetag = () => setOpentag(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleListClick = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleListClose = () => {
    setAnchorEl2(null);
  };

  return (
    <>
      <CRMHeader />
      <Suspense fallback={renderLoader()}>
        <section className="contactlist-section">
          <div className="container-fluid">
            <div className="row">
              {/* {openSide && ( */}
              <div
                className={`${
                  openSide ? "" : "hide_side"
                } col-lg-2 col-md-4 contact-border`}
              >
                <div className="cotact-left-section">
                  <Link
                    to=""
                    className="primary-btn btn-border"
                    onClick={handleOpen}
                  >
                    Add New Contact
                  </Link>
                  <div className="menu-group">
                    <ul>
                      <li>
                        <Link className="nav-link">
                          <MdContacts /> All Contacts
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <FaRegStar /> Important
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <BsWindow /> Archive
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <FaRegEdit /> Pending
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <BsTrash3 /> Deleted
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="separator separator-light"></div>
                  <div className="menu-group">
                    <ul>
                      <li>
                        <Link className="nav-link">
                          <TfiExport /> Export
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <TfiImport /> Import
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          <BsWindow /> Print
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="separator separator-light"></div>
                  <div className="menu-group">
                    <ul>
                      <li className="menu-title">
                        Labels
                        <button
                          className="btn btn-light btn-icon"
                          onClick={handleOpenlabel}
                        >
                          <GoPlus />
                        </button>
                      </li>
                      <li>
                        <Link className="nav-link">
                          Design
                          <span className="badge badge-pill badge-sm badge-soft-primary ms-auto">
                            136
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          Development
                          <span className="badge badge-pill badge-sm badge-soft-primary ms-auto">
                            16
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          Inventory
                          <span className="badge badge-pill badge-sm badge-soft-primary ms-auto">
                            6
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link">
                          Human Resource
                          <span className="badge badge-pill badge-sm badge-soft-primary ms-auto">
                            25
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="separator separator-light"></div>
                  <div className="tag-group">
                    <div className="menu-title">
                      Tags
                      <button
                        className="btn btn-light btn-icon"
                        onClick={handleOpentag}
                      >
                        <GoPlus />
                      </button>
                    </div>
                    <Link className="tag-menu">Collaboration</Link>
                    <Link className="tag-menu">React Developer</Link>
                    <Link className="tag-menu">Angular Developer</Link>
                    <Link className="tag-menu">promotion</Link>
                    <Link className="tag-menu">Advertisement</Link>
                  </div>
                  <div className="separator separator-light"></div>
                </div>
                <div className="contactapp-fixednav">
                  <div className="menu-group">
                    <ul>
                      <li>
                        <Link>
                          <CiSettings />
                        </Link>
                      </li>
                      <li>
                        <Link>
                          <LuArchive />
                        </Link>
                      </li>
                      <li>
                        <Link>
                          <LuBook />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* )} */}
              <div
                className={`${
                  openSide ? "col-lg-10 col-md-8" : "col-lg-12"
                } contact-right-side`}
              >
                <div className="togglebar">
                  <div
                    className="sidebar-togglable"
                    onClick={() => setOpenSide(!openSide)}
                  >
                    <MdKeyboardArrowLeft />
                  </div>
                </div>
                <div className="top-contact">
                  <div className="title-text">
                    <h3>Contact</h3>
                    <select className="form-select me-auto">
                      <option value="1" selected>
                        Create New
                      </option>
                      <option value="2">Add New Contact</option>
                      <option value="2">Add New Department</option>
                      <option value="2">Add Category</option>
                      <option value="3">Add New Tag</option>
                    </select>
                  </div>
                  <div className="contact-options-wrap">
                    <div className="list-dropdown">
                      <IconButton
                        aria-label="more"
                        id="list-button"
                        aria-controls={open ? "list-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleListClick}
                        className="icon-btn"
                      >
                        <IoIosList />
                      </IconButton>
                    </div>
                    <Link>
                      <LuRefreshCw />
                    </Link>
                    <div className="v-separator d-lg-block d-none"></div>
                    <IconButton
                      aria-label="more"
                      id="setting-button"
                      aria-controls={open ? "setting-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleClick}
                      className="icon-btn"
                    >
                      <RiSettings2Line />
                    </IconButton>
                    <IconButton
                      aria-label="more"
                      id="setting-button"
                      aria-controls={open ? "setting-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleClick}
                      className="icon-btn"
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </div>
                </div>
                <div className="bottom-contact">
                  <div className="left-bottom-contact">
                    <div className="d-flex gap-3 align-items-center">
                      <select className="form-select me-auto">
                        <option value="Edit" selected>
                          Edit
                        </option>
                        <option value="Bulk">Bulk action</option>
                        <option value="Move">Move to trash</option>
                      </select>
                      <button type="text" className="apply-btn">
                        Apply
                      </button>
                      <p className="m-0 w-50">Sort by:</p>
                      <select className="form-select w-auto me-auto">
                        <option value="1" selected>
                          Date Created
                        </option>
                        <option value="2">Date Edited</option>
                        <option value="3">Recently Added</option>
                      </select>
                      <select className="form-select w-auto me-auto">
                        <option value="1" selected>
                          Export to CSV
                        </option>
                        <option value="2">Export to PDF</option>
                        <option value="3">Send Message</option>
                        <option value="4">Delegate Access</option>
                      </select>
                    </div>
                  </div>
                  <div className="right-bottom-contact">
                    <input
                      type="search"
                      placeholder="Search"
                      className="form-control w-auto"
                    />
                  </div>
                </div>
                <div className="table-details mt-3">
                  <div className="mt-3">
                    <table className="table-responisve dashboard-table">
                      <thead>
                        <tr>
                          <th>
                            <span className="form-check fs-6 mb-0">
                              <input
                                type="checkbox"
                                className="form-check-input check-select-all"
                                id="customCheck1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="customCheck1"
                              ></label>
                            </span>
                          </th>
                          <th className="tabel-head">
                            Name <TbSortAscending />
                          </th>
                          <th className="tabel-head">
                            Email Address <RxTextAlignBottom />
                          </th>
                          <th className="tabel-head">
                            Phone <TbSortAscending />
                          </th>
                          <th className="tabel-head">
                            Tags <TbSortAscending />
                          </th>
                          <th className="tabel-head">
                            Labels <RxTextAlignBottom />
                          </th>
                          <th className="tabel-head">
                            Date Created <RxTextAlignBottom />
                          </th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr className="selected">
                          <td>
                            <span className="form-check mb-0">
                              <input
                                type="checkbox"
                                className="form-check-input check-select me-3"
                                id="chk_sel_4"
                              />
                              <FaStar className="yellow-color" />
                            </span>
                          </td>
                          <td>
                            <div className="table-name">
                              <div className="image">
                                <img src={danial} alt="danial" />
                              </div>
                              <div className="name-content">
                                <h6>Danial Craig</h6>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p>danialc@jampack.com</p>
                          </td>
                          <td>+145 52 5689 </td>
                          <td>
                            <span className="badge badge-soft-danger me-2">
                              Collaborator
                            </span>
                          </td>
                          <td>Developer</td>
                          <td>24 Jun, 2019</td>
                          <td>
                            <div className="social-action">
                              <Link to="">
                                <TbArchive />
                              </Link>
                              <Link to="/contact-edit">
                                <FaEdit />
                              </Link>
                              <Link to="">
                                <AiOutlineDelete />
                              </Link>
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? "long-menu" : undefined}
                                aria-expanded={open ? "true" : undefined}
                                aria-haspopup="true"
                                onClick={handleClick}
                                className="icon-btn"
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                        <tr className="selected">
                          <td>
                            <span className="form-check mb-0">
                              <input
                                type="checkbox"
                                className="form-check-input check-select me-3"
                                id="chk_sel_4"
                              />
                              <FaStar className="yellow-color" />
                            </span>
                          </td>
                          <td>
                            <div className="table-name">
                              <div className="image">
                                <img src={dean} alt="dean" />
                              </div>
                              <div className="name-content">
                                <h6>Dean Shaw</h6>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p>dean-shaw@poww.me</p>
                          </td>
                          <td>+234 48 2365 </td>
                          <td>
                            <span className="badge badge-soft-danger me-2">
                              Collaborator
                            </span>
                            <span className="badge badge-soft-success">
                              Angular Developer
                            </span>
                          </td>
                          <td>Design</td>
                          <td>21 Feb, 2019</td>
                          <td>
                            <div className="social-action">
                              <Link to="">
                                <TbArchive />
                              </Link>
                              <Link to="/contact-edit">
                                <FaEdit />
                              </Link>
                              <Link to="">
                                <AiOutlineDelete />
                              </Link>
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? "long-menu" : undefined}
                                aria-expanded={open ? "true" : undefined}
                                aria-haspopup="true"
                                onClick={handleClick}
                                className="icon-btn"
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                        <tr className="selected">
                          <td>
                            <span className="form-check mb-0">
                              <input
                                type="checkbox"
                                className="form-check-input check-select me-3"
                                id="chk_sel_4"
                              />
                            </span>
                          </td>
                          <td>
                            <div className="table-name">
                              <div className="image">
                                <img src={huma} alt="huma" />
                              </div>
                              <div className="name-content">
                                <h6>Huma Therman</h6>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p>huma@clariesup.au</p>
                          </td>
                          <td>+234 48 2365</td>
                          <td>
                            <span className="badge badge-soft-danger me-2">
                              Collaborator
                            </span>
                            <span className="badge badge-soft-success">
                              Angular Developer
                            </span>
                          </td>
                          <td>Developer</td>
                          <td>13 Jan, 2020</td>
                          <td>
                            <div className="social-action">
                              <Link to="">
                                <TbArchive />
                              </Link>
                              <Link to="/contact-edit">
                                <FaEdit />
                              </Link>
                              <Link to="">
                                <AiOutlineDelete />
                              </Link>
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? "long-menu" : undefined}
                                aria-expanded={open ? "true" : undefined}
                                aria-haspopup="true"
                                onClick={handleClick}
                                className="icon-btn"
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                        <tr className="selected">
                          <td>
                            <span className="form-check mb-0">
                              <input
                                type="checkbox"
                                className="form-check-input check-select me-3"
                                id="chk_sel_4"
                              />
                              <FaStar className="yellow-color" />
                            </span>
                          </td>
                          <td>
                            <div className="table-name">
                              <div className="image">
                                <img src={avatar_img} alt="" />
                              </div>
                              <div className="name-content">
                                <h6>Jaquiline Joker</h6>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p>jaquljoker@jampack.com </p>
                          </td>
                          <td>+145 53 4715 </td>
                          <td>
                            <span className="badge badge-soft-danger me-2">
                              Promotion
                            </span>
                            <span className="badge badge-soft-success">
                              Angular Developer
                            </span>
                          </td>
                          <td>Design</td>
                          <td>3 July, 2020</td>
                          <td>
                            <div className="social-action">
                              <Link to="">
                                <TbArchive />
                              </Link>
                              <Link to="/contact-edit">
                                <FaEdit />
                              </Link>
                              <Link to="">
                                <AiOutlineDelete />
                              </Link>
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? "long-menu" : undefined}
                                aria-expanded={open ? "true" : undefined}
                                aria-haspopup="true"
                                onClick={handleClick}
                                className="icon-btn"
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                        <tr className="selected">
                          <td>
                            <span className="form-check mb-0">
                              <input
                                type="checkbox"
                                className="form-check-input check-select me-3"
                                id="chk_sel_4"
                              />
                              <FaStar className="yellow-color" />
                            </span>
                          </td>
                          <td>
                            <div className="table-name">
                              <div className="image">
                                <img src={katharine} alt="katharine" />
                              </div>
                              <div className="name-content">
                                <h6>Katharine Jones</h6>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p>joneskath@jampack.com</p>
                          </td>
                          <td>+741 56 7896</td>
                          <td>
                            <span className="badge badge-soft-success">
                              Promotion
                            </span>
                          </td>
                          <td>Inventory</td>
                          <td>24 Jun, 2019</td>
                          <td>
                            <div className="social-action">
                              <Link to="">
                                <TbArchive />
                              </Link>
                              <Link to="/contact-edit">
                                <FaEdit />
                              </Link>
                              <Link to="">
                                <AiOutlineDelete />
                              </Link>
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? "long-menu" : undefined}
                                aria-expanded={open ? "true" : undefined}
                                aria-haspopup="true"
                                onClick={handleClick}
                                className="icon-btn"
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                        <tr className="selected">
                          <td>
                            <span className="form-check mb-0">
                              <input
                                type="checkbox"
                                className="form-check-input check-select me-3"
                                id="chk_sel_4"
                              />
                            </span>
                          </td>
                          <td>
                            <div className="table-name">
                              <div className="image">
                                <img src={morgan} alt="morgan" />
                              </div>
                              <div className="name-content">
                                <h6>Morgan Freeman</h6>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p>morgan@jampack.com</p>
                          </td>
                          <td>+145 52 5689 </td>
                          <td>
                            <span className="badge badge-soft-danger me-2">
                              Collaborator
                            </span>
                          </td>
                          <td>Design</td>
                          <td>13 Jan, 2020</td>
                          <td>
                            <div className="social-action">
                              <Link to="">
                                <TbArchive />
                              </Link>
                              <Link to="/contact-edit">
                                <FaEdit />
                              </Link>
                              <Link to="">
                                <AiOutlineDelete />
                              </Link>
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? "long-menu" : undefined}
                                aria-expanded={open ? "true" : undefined}
                                aria-haspopup="true"
                                onClick={handleClick}
                                className="icon-btn"
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                        <tr className="selected">
                          <td>
                            <span className="form-check mb-0">
                              <input
                                type="checkbox"
                                className="form-check-input check-select me-3"
                                id="chk_sel_4"
                              />
                            </span>
                          </td>
                          <td>
                            <div className="table-name">
                              <div className="image">
                                <img src={tom} alt="tom" />
                              </div>
                              <div className="name-content">
                                <h6>Tom Cruz</h6>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p>tomcz@jampack.com</p>
                          </td>
                          <td>+456 52 4862 </td>
                          <td>
                            <span className="badge badge-soft-success">
                              Angular Developer
                            </span>
                          </td>
                          <td>Inventory</td>
                          <td>24 Jun, 2019</td>
                          <td>
                            <div className="social-action">
                              <Link to="">
                                <TbArchive />
                              </Link>
                              <Link to="/contact-edit">
                                <FaEdit />
                              </Link>
                              <Link to="">
                                <AiOutlineDelete />
                              </Link>
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? "long-menu" : undefined}
                                aria-expanded={open ? "true" : undefined}
                                aria-haspopup="true"
                                onClick={handleClick}
                                className="icon-btn"
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                        <tr className="selected">
                          <td>
                            <span className="form-check mb-0">
                              <input
                                type="checkbox"
                                className="form-check-input check-select me-3"
                                id="chk_sel_4"
                              />
                              <FaStar className="yellow-color" />
                            </span>
                          </td>
                          <td>
                            <div className="table-name">
                              <div className="image">
                                <img src={winston} alt="winston" />
                              </div>
                              <div className="name-content">
                                <h6>Winston Churchil</h6>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p>winston@worthniza.ga</p>
                          </td>
                          <td>+145 52 5463 </td>
                          <td>
                            <span className="badge badge-soft-danger me-2">
                              Collaborator
                            </span>
                            <span className="badge badge-soft-success">
                              Angular Developer
                            </span>
                          </td>
                          <td>Human Resource </td>
                          <td>13 Jan, 2020 </td>
                          <td>
                            <div className="social-action">
                              <Link to="">
                                <TbArchive />
                              </Link>
                              <Link to="/contact-edit">
                                <FaEdit />
                              </Link>
                              <Link to="">
                                <AiOutlineDelete />
                              </Link>
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? "long-menu" : undefined}
                                aria-expanded={open ? "true" : undefined}
                                aria-haspopup="true"
                                onClick={handleClick}
                                className="icon-btn"
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="table-footer">
                      <p>1 - 6 of 6</p>
                      <Pagination count={2} className="pagination-table" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem>
          <Link to={``} className="dropdown-link">
            {" "}
            <FaRegEdit className="me-1" /> Edit Contact
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to={``} className="dropdown-link">
            {" "}
            <BsTrash3 className="me-1" /> Delete
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to={``} className="dropdown-link">
            {" "}
            <HiOutlineDuplicate className="me-1" /> Duplicate{" "}
          </Link>
        </MenuItem>
      </Menu>

      <Menu
        id="list-menu"
        MenuListProps={{
          "aria-labelledby": "list-button",
        }}
        anchorEl={anchorEl2}
        open={open2}
        onClose={handleListClose}
      >
        <MenuItem>
          <Link to={`/contact-list`} className="dropdown-link">
            {" "}
            <IoIosList className="me-2" /> List View
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to={`/contact-card`} className="dropdown-link">
            {" "}
            <FiGrid className="me-2" /> Grid View
          </Link>
        </MenuItem>
      </Menu>

      {/* Add Contact popup */}
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="contact-popup"
      >
        <Box sx={style}>
          <div className="modal-head">
            <h5>Create New Conatct</h5>
            <Link onClick={handleClose1}>
              <RxCross2 />
            </Link>
          </div>
          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-2">
                  <div className="upload-logo">
                    <div className="dropify-wrapper">
                      <span className="file-icon">
                        <BsUpload />
                      </span>
                      <p>Upload Logo</p>
                      <input type="file" className="dropify-2" />
                    </div>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      rows="4"
                      placeholder="Add Biography"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="title-text">
                {" "}
                <span>Basic INFO</span>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">First Name</label>
                    <input className="form-control task-name" type="text" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">Middle Name</label>
                    <input className="form-control task-name" type="text" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">Last Name</label>
                    <input className="form-control task-name" type="text" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Email ID</label>
                    <input className="form-control task-name" type="text" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Phone</label>
                    <input className="form-control task-name" type="text" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">City</label>
                    <select className="form-control custom-select">
                      <option selected="">Choose Category</option>
                      <option value="1">--</option>
                      <option value="2">Open</option>
                      <option value="3">Two</option>
                      <option value="4">Three</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">State</label>
                    <select className="form-control custom-select">
                      <option value="1">--</option>
                      <option value="2">Open</option>
                      <option value="3">Two</option>
                      <option value="4">Three</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">Country</label>
                    <select className="form-control custom-select">
                      <option value="1">--</option>
                      <option value="2">Open</option>
                      <option value="3">Two</option>
                      <option value="4">Three</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="title-text">
                {" "}
                <span>COMPANY INFO</span>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Company Name</label>
                    <input className="form-control task-name" type="text" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Designation</label>
                    <input className="form-control task-name" type="text" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Website</label>
                    <input className="form-control task-name" type="text" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Work Phone</label>
                    <input className="form-control task-name" type="text" />
                  </div>
                </div>
              </div>
              <div className="title-text">
                {" "}
                <span>ADDITIONAL INFO</span>
              </div>
              <div className="row mt-2">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">Tags</label>
                    <input className="form-control task-name" type="text" />
                    <span className="mt-1 primarylight-color">
                      You can add upto 4 tags per contact
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      className="form-control task-name"
                      type="text"
                      placeholder="Facebook"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      className="form-control task-name"
                      type="text"
                      placeholder="Twitter"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      className="form-control task-name"
                      type="text"
                      placeholder="Linkedin"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      className="form-control task-name"
                      type="text"
                      placeholder="Gmail"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <Button
              type="submit"
              className="secondary-btn me-2"
              onClick={handleClose1}
            >
              Discard
            </Button>
            <Button type="submit" className="primary-btn">
              Create Contact
            </Button>
          </div>
        </Box>
      </Modal>

      {/* Add new labels popup */}
      <Modal
        open={openlabel}
        onClose={handleCloselabel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="deal-popup"
      >
        <Box sx={style}>
          <div className="modal-head">
            <h5>ADD LABEL</h5>
            <Link onClick={handleCloselabel}>
              <RxCross2 />
            </Link>
          </div>
          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      className="form-control task-name"
                      type="text"
                      placeholder="Label Name"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <Button
              type="submit"
              className="primary-btn"
              onClick={handleCloselabel}
            >
              Add
            </Button>
          </div>
        </Box>
      </Modal>

      {/* Add new tag popup */}
      <Modal
        open={opentag}
        onClose={handleClosetag}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="deal-popup"
      >
        <Box sx={style}>
          <div className="modal-head">
            <h5>ADD TAG</h5>
            <Link onClick={handleClosetag}>
              <RxCross2 />
            </Link>
          </div>
          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <textarea className="form-control" rows="2"></textarea>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <Button
              type="submit"
              className="primary-btn"
              onClick={handleClosetag}
            >
              Add
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ContactList;
